import axios from "axios";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
// import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const CollectionCalender = ({userId, showSideBar}) => {
	const [sDate, setsDate] = useState(new Date());
	const [allMonthDays, setAllMonthDays] = useState([]);
	const [currentMonthYear, setCurrrentMonthYear] = useState({month: "", year: ""});

	const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const findMonthDays = (y, m) => {
		return new Date(y, m + 1, 0).getDate();
	};

	const findFirstDay = (y, m) => {
		return new Date(y, m, 1).getDay();
	};

	// const changeToPrevMonth = () => {
	// 	setsDate((pDate) => {
	// 	   const pMonth = pDate.getMonth() - 1;
	// 	   const pYear = pDate.getFullYear();
	// 	   return new Date(pYear, pMonth);
	// 	});
	//  };
  
	//  const changeToNextMonth = () => {
	// 	setsDate((pDate) => {
	// 	   const nMonth = pDate.getMonth() + 1;
	// 	   const nYear = pDate.getFullYear();
	// 	   return new Date(nYear, nMonth);
	// 	});
	//  };
  
	const showCalender = async () => {
		const currDate = new Date();
		const y = sDate.getFullYear();
		const m = sDate.getMonth();
		const monthDays = findMonthDays(y, m);
		const firstDay = findFirstDay(y, m);
		let collectionData = [];
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/collection-calender`, 
			{month: m+1, year: y, user_enc_id: userId}
		).then(
			response => {
				if(response.data.response.status === 200){
					collectionData = response.data.response.data;
				}
			}
		);

		const allDays = [];

		// For empty cells
		for (let p = 0; p < firstDay; p++) {
			allDays.push({date: "", key:`em-${p}`, isSelected: "", dateType:"prev"});
		}

		// Show actual days
		for (let d = 1; d <= monthDays; d++) {
			const date = new Date(y, m, d);
			const isSelected = sDate && date.toDateString() === currDate.toDateString();
			
			let cd = collectionData.filter(e => parseInt(e.date) === d);

			allDays.push( 
				{	
					date: d, 
					key:`d-${d}`, 
					isSelected: isSelected, 
					dateType:"current",
					fullDate: date,
					collection: cd?.length ? cd[0] : ""
				}
			);


		}

		setAllMonthDays(allDays);
	};

	
	useEffect(() => {
		if (showSideBar) {
			showCalender();
		}
		setCurrrentMonthYear({
			month: sDate.toLocaleString("default", {month: "long"}),
			year: sDate.toLocaleString("default", {year: "numeric"})
		});
	}, [sDate]);
	
	const handleDateClick = (date) => {
		setsDate(date);
	};


	return(
		<>
        	<div className="emis-details-tab ">
				<div className="panel-body mb20">
					<div className="d-flex align-items-center justify-content-center calender-div-header">
						{/* <button type="button" className="empty-btn" onClick = {changeToPrevMonth}> <FaChevronLeft /> </button> */}
						<h3 className="mx-5 mb-0">
							{currentMonthYear?.month} <span className="mx-2">{currentMonthYear?.year} </span>
						</h3>
						{/* <button type="button" className="empty-btn" onClick = {changeToNextMonth}> <FaChevronRight /> </button> */}
					</div>
					<div className="calendar-body">
						{dayNames?.length ? dayNames.map((value, index) => {
							return(
								<div className = "date-box daynames" key={`day-${index}`}>
									{value}
								</div>
							);
						}) : ""}

						{allMonthDays?.length ? allMonthDays.map((value, index) => {
							return(
								<>								
									{value.dateType != "prev" ? (
										<OverlayTrigger
											key = {`${value.key}-${index}`}
											placement="top"
											delay={{ show: 250, hide: 400 }}
											overlay={<Tooltip id={value.key}>
												<div>Total Collections: {value?.collection?.count ? value.collection.count : "0"}</div>
												<div>Total Amount: <BiRupee size={16} />{value?.collection?.sum ? parseFloat(value.collection.sum).toLocaleString("en-IN") : "0"}</div>
											</Tooltip>}
										>
											<div
												className = {`date-box ${value.isSelected ? "selected" : ""} ${value.dateType === "prev" ? "empty" : ""}`}
												onClick = {() => handleDateClick(value.fullDate)}
											>
												<p className="calendar-date mb-0">{value?.date}</p>
												<p className="calender-amt color-orange justify-content-end mb-0">{value?.collection?.count}</p>
												<p className="calender-amt font-14 justify-content-end">{value.collection && value.collection.sum ? (
													<>
														<BiRupee size={16} /> {parseFloat(value.collection.sum).toLocaleString("en-IN")}
													</>
												) : ""}</p>
											</div>
										</OverlayTrigger>
									) : (
										<div
											className = {`date-box ${value.isSelected ? "selected" : ""} ${value.dateType === "prev" ? "empty" : ""}`}
											onClick = {() => handleDateClick(value.fullDate)}
										>
											<p className="calendar-date mb-0">{value?.date}</p>
											<p className="calender-amt">{value?.collection?.count}</p>
											<p className="calender-amt">{value.collection && value.collection.sum ? (
												<>
													<BiRupee size={16} /> {parseFloat(value.collection.sum).toLocaleString("en-IN")}
												</>
											) : ""}</p>
										</div>
									)
									}
								</>
							);
						}) : "" }
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectionCalender;