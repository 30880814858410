import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const SmsFrequencyTable = ({tableData, templates, loan_products, reloadData, setEditFrequencyType, setEditFrequencyData, type}) => {
	const [seletedTemplate, setSelectedTemplate] = useState("");
	const [showModal, setShowModal] = useState(false);
	const onHide = () => {
		setShowModal(false);
	};

	const showTemplate = (temp) => {
		setSelectedTemplate(temp);
		setShowModal(true);
	};

	const deleteFrequency = async (data) => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}sms/remove-sms-frequency`,
			{sms_frequency_enc_id: data.sms_frequency_enc_id}
		).then(
			response => {
				if(response.data.response.status === 200){
					reloadData();
				}
			}
		);
	};

	const editFrequency = (row) => {
		setEditFrequencyData(row);
		setEditFrequencyType(type);
	};

	const columns = [
		{
			name: "Bucket",
			cell: row => {
				return (
					<>
						{row.bucket ? row.bucket.map((value, index) => {
							return(
								<span className="mx-1" key={`buc-${type}-${index}`}>{value}</span>
							);
						}) : ""}
					</>  
			    );
			},
			width:"100px",
			omit: type === "emi",
		},
		{
			name:"EMI Date",
			selector: row => row.emi_date ? row.emi_date : "",
			omit: type === "bucket",
			width:"100px",
		},
		{
			name: "Loan Products",
			width:"250px",
			cell: row => {
				return (
					<>
						<ul style={{marginBottom:"0px", padding: "5px"}}>
							{row.loan_product ? row.loan_product.map((value, index) => {
								if(value !== "all"){
									let elem = loan_products?.filter(item => item.financer_loan_product_enc_id === value);
									return(
										<li key={`lp-${type}-${index}`}>{elem ? elem[0].name : ""}</li>	
									);		
								}else{
									return(
										<li key={`lp-${type}-${index}`} className="text-capitalize">{value}</li>
									);
								}
							}) : ""}
						</ul>
					</>
				);
			}

		},
		{
			name: "Frequency",
			selector: row => row.sms_frequency
		},
		
		{
			name: "Messages",
			selector: row => row.number_of_messages
		},
		{
			name: "Time",
			cell: row => {
				return(
					<>{ moment(row.start_time, "HH:mm A").format("LT") } - { moment(row.end_time, "HH:mm A").format("LT") }</>
				);
			}
		},           
		{
			name: "Template Name",
			cell: row => {
				let temp = row.template_id && templates ? templates?.filter(item => item.template_id === row.template_id) : "";
				return(
					<>
						<span onClick={() => showTemplate(temp)} className="hover-link">{temp ? temp[0].template_name : ""}</span>
					</>
				);
			}
		},
		{
			name: "Actions",
			width:"80px",
			cell: row => {
				return(
					<>
						<span onClick={() => editFrequency(row)} style={{color: "#00a0e3", cursor:"pointer"}}> <FaEdit size={16} /></span>
						<span onClick={() => deleteFrequency(row)} style={{color: "red", cursor:"pointer", marginLeft:"8px"}}> <MdDelete size={18} /></span>
					</>
				);
			}
		}

	];


	return(
		<>
			<DataTable
				columns={columns}
				data={tableData}				
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
			/>

			<Modal show={showModal} onHide={onHide} size={"sm"} centered>
				<Modal.Header>
					<Modal.Title>{seletedTemplate ? seletedTemplate[0].template_name : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{seletedTemplate ? seletedTemplate[0].content : ""}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SmsFrequencyTable;