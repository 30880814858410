import React, { useContext, useEffect, useRef, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import LoanApplicationDetails from "./LoanApplicationDetails";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import { BiRupee, BiTrash } from "react-icons/bi";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { loanStatusData } from "../../data/loanStatusData";
import EditInput from "../forms/EditInput";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ShareAccessModal from "../ShareAccessModal";
import NameComp from "../NameComp";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import { OrganizationSidebarProvider } from "../../context/OrganizationSidebarState";
import { RiUserSharedFill } from "react-icons/ri";
import { BsPersonFillAdd } from "react-icons/bs";
import MinMaxFilter from "../MinMaxFilter";
import EditSelectInput from "../forms/EditSelectInput";
import UpdateLoanStatus from "./UpdateLoanStatus";
import { GoDotFill } from "react-icons/go";


const LoanApplicationsTable = ({ formType = "", type = "all", loadDefault = null, finalDate = null}) => {
	const { getDashboardLoanApplications, loan_applications, branches_list, states_list} =
		useContext(OrganizationDashboardContext);
	const tempDate = new Date();
	const fromDate = moment(tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1").format(
		"YYYY-MM-DD"
	);
	const toDate = moment(tempDate).format("YYYY-MM-DD");
	const { user, loan_products, financer_loan_status } =
		useContext(MainContext);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [loanDetails, setLoanDetails] = useState("");
	const [createrName, setCreaterName] = useState("");
	const [selectedApp, setSelectedApp] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const dateFilters = {
		start_date: fromDate,
		end_date: toDate
	};

	const onHide = () => {
		setSelectedApp({});
	};
	const [tableLoading, setTableLoading] = useState(type);
	const [userDsa, setUserDsa] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	useEffect(() => {
		setUserDsa(user.user_type === "DSA" ? false : true);
	}, [user]);

	const getStatus = key => {
		const selectedStatus = loanStatusData.loanStatus.filter(
			value => value.value === parseInt(key)
		);
		return selectedStatus && selectedStatus.length > 0 ? selectedStatus[0].name : "--";
	};
	const [statusToggle, setStatusToggle] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const reloadData = () => {
		setTableLoading(type);
		getDashboardLoanApplications({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			formType,
			type,
			start_date: dateFilters.start_date ? dateFilters.start_date : "",
			end_date: dateFilters.end_date ? dateFilters.end_date : ""
		});
	};
	const handleStatusField = (reload = false) => {
		setShowCurrent("1");
		setStatusToggle(false);
		if (reload) {
			setTableLoading(type);
			getDashboardLoanApplications({
				page: currentPage,
				limit: rowsPerPage,
				fields_search: searchArray,
				formType,
				type,
				start_date: dateFilters.start_date ? dateFilters.start_date : "",
				end_date: dateFilters.end_date ? dateFilters.end_date : ""
			});
		}
	};

	const headingObj = {
		all: "Running Cases",
		rejected: "Rejected/CNI Cases",
		disbursed: "Disbursed Cases",
		tvr: "TVR Applications",
		fi: "FI Applications",
		pd: "PD Applications",
		legal: "Legal Initiated Applications",
		technical: "Technical Initiated Applications",
		release_payment: "Release Payment Applications",
		new_lead: "New Leads",
		completed: "Completed",
		disbursed_only: "Disubrsed/Disbursment Approval",
		pending: "Pending Cases",
		tv_scheme: "TV Scheme Loan Applications"
	};

	const handleApplicationDelete = id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/remove-loan-application`,
				{ loan_id: id }
			)
			.then(response => {
				if (response.data.response.status === 200) {
					reloadData();
					toast.success("Application Successfully Deleted");
				} else {
					toast.error("An error has occurred, Please try again later");
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const ref = useRef(null);

	// const useOutsideAlerter = event => {
	// 	if (ref.current && !ref.current.contains(event.target)) {
	// handleClose()
	// removeEvent()
	// }
	// };
	// const removeEvent = () => {
	// 	document.removeEventListener("click", useOutsideAlerter, true);
	// };
	// const addEvent = () => {
	// 	document.addEventListener("click", useOutsideAlerter, true);
	// };
	const handleShareAccess = row => {
		setSelectedApp(row);
		setCreaterName(row.creator_name);
	};
	const dataLoaded = useRef(false);
	useEffect(() => {
		if (searchArray) {
			const timerId = setTimeout(() => {
				dataLoaded.current = true;
				getDashboardLoanApplications({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					formType,
					type,
					start_date: dateFilters.start_date ? dateFilters.start_date : "",
					end_date: dateFilters.end_date ? dateFilters.end_date : ""
				});
				setClearFilters(false);
				setTableLoading(type);
				setCurrentPage(1);
			}, [1000]);
			
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	
	}, [searchArray, dateFilters.start_date]);
	
	useEffect(() => {
		if (searchArray && loadDefault && finalDate) {
			const timerId = setTimeout(() => {
				dataLoaded.current = true;
				getDashboardLoanApplications({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					formType,
					type,
					start_date: finalDate.start_date ? finalDate.start_date : "",
					end_date: finalDate.end_date ? finalDate.end_date : ""
				});
				setClearFilters(false);
				setTableLoading(type);
				setCurrentPage(1);
			}, [1000]);
			
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
	
	}, [searchArray, loadDefault, finalDate]);


	let priorityList = [
		{
			label: "High",
			value: "2"
		},
		{
			label: "Medium",
			value: "3"
		},
		{
			label: "Low",
			value: "4"
		}
	];

	const columns = [
		{
			name: (
				<NameComp
					title={"Application Number"}
					id={"application_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.application_number ? row.application_number : "--"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setLoanDetails(row.loan_app_enc_id)}
					>
						{row.application_number ? row.application_number : "--"}
					</h6>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"TAT"}
					id={"tat"}
					placeholder="TAT"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.tat ? row.tat : "-"),
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => {
				return(
					<div className="justify-content-between d-flex align-content-center w-100">
						{row.tat ? row.tat : "-"}
						{row?.nach_approved && (type === "all" || type === "disbursed") ? (
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id="invite-nach">Nach {row.nach_approved}</Tooltip>}
							>
								<div style={{ display: "inline-block", marginRight: "15px" }}>
									<GoDotFill size={16} color={row.nach_approved === "Active" ? "green" 
										: row.nach_approved === "Issued" ? "yellow" : "red"}/>
								</div>
							</OverlayTrigger>
						) : ""}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch ? row.branch : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Employee Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			sortable: false,
			omit: userDsa
		},
		{
			name: (
				<NameComp
					title={"Applicant Name"}
					id={"applicant_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() => setLoanDetails(row.loan_app_enc_id)}
					>
						{row.applicant_name}
					</h6>
				);
			},
			selector: row => row.applicant_name,
			sortable: false
		}, // {
		//   name: (
		//     <NameComp
		//       title={`Loan Type`}
		//       id={`loan_type`}
		//       searchArray={searchArray}
		//       setSearchArray={setSearchArray}
		//       loanTypes={loan_types}
		//     />
		//   ),
		//   minWidth: "160px",
		//   selector: row => (row.loan_type ? row.loan_type : "-"),
		//   sortable: false
		// },
		{
			name: (
				<NameComp
					title={"Loan Product"}
					id={"loan_product_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.loan_product ? row.loan_product : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"EOB"}
					id={"priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => {
				return (
					<EditSelectInput
						optionList={priorityList}
						getData={reloadData}
						defaultValue={row.priority}
						defaultLabel={row.priority}
						api={"loans/loan-update"}
						id={row.loan_app_enc_id}
						selectPlaceholder="Choose EOB"
						parentId={user.organization_enc_id}
						required={true}
						type="priority"
						loanDetailStatus={parseInt(row.loan_status) || ""}
					/>
				);
			}			
		},
		{
			name: (
				<NameComp
					title={"Loan Purpose"}
					id={"purpose"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanProducts={loan_products}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "280px",
			selector: row => (row.loanPurposes ? row.loanPurposes : "-"),
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => {
				return (
					<div className="list-data d-inline-block">
						{row.loanPurposes && row.loanPurposes.length
							? row.loanPurposes.map((value, index) => {
								return (
									<span
										key={`purposes-${row.loan_app_enc_id}${index}`}
									>
										{value.purpose}
									</span>
								);
							})
							: "-"}
					</div>
				);
			}
		},
		// {
		// 	name: "Documents",
		// 	minWidth: "120px",
		// 	cell: row => {
		// 		return (
		// 			<h6 className="mb-0 emiAmt">
		// 				{row.uploaded_certificates} / {row.total_certificates}
		// 			</h6>
		// 		);
		// 	},
		// 	selector: row => row.amount,
		// 	sortable: false
		// },
		// {
		// 	name: "Images",
		// 	minWidth: "120px",
		// 	cell: row => {
		// 		return (
		// 			<h6 className="mb-0 emiAmt">
		// 				{row.uploaded_loan_images} / {row.total_loan_images}
		// 			</h6>
		// 		);
		// 	},
		// 	selector: row => row.amount,
		// 	sortable: false
		// },
		{
			name: (
				<MinMaxFilter
					title={"Loan Applied"}
					id={"amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			omit: type === "tv_scheme",
			cell: row => {
				return (
					<h6 className="mb-0 emiAmt">
						<BiRupee size={16} /> {parseFloat(row.amount).toLocaleString("en-IN")}
					</h6>
				);
			},
			selector: row => row.amount,
			sortable: false
		},
		{
			name: (
				<NameComp
					title={`${user.user_type === "DSA" ? "Lenders and" : ""} Status`}
					id={"status_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					loanStatus={financer_loan_status}
				/>
			),
			minWidth: "200px",
			omit: type === "tv_scheme",
			cell: row => {
				return (
					<div>
						{row.assignedLoanProviders && row.assignedLoanProviders.length
							? row.assignedLoanProviders.map((value, index) => {
								return (
									<>
										{statusToggle &&
											showCurrent === value.assigned_loan_provider_enc_id ? (
												<div className="d-flex align-items-center">
													<UpdateLoanStatus
														key={`ap-${index}`}
														allStatus={loanStatusData.loanStatus}
														loanType={
															row.loan_product ? row.loan_product : ""
														}
														defaultStatus={value.status}
														loanId={row.loan_app_enc_id}
														label={false}
														callback={handleStatusField}
														completeLoanDetails={row}
													/>
													<FaTimes
														style={{
															color: "red",
															cursor: "pointer",
															margin: "0 5px"
														}}
														size={14}
														onClick={() => {
															setShowCurrent("");
															setStatusToggle(false);
														}}
													/>
												</div>
											) : (
												<h6
													key={`ap-${index}`}
													onDoubleClick={() => {
														setShowCurrent(
															value.assigned_loan_provider_enc_id
														);
														setStatusToggle(true);
													}}
												>
													{user.user_type === "DSA"
														? value.providerEnc.name
														: ""}
													<span
														className={`status-color status-color-${value.status}`}
													>
														{user.user_type === "DSA"
															? `(${getStatus(value.status)})`
															: `${getStatus(value.status)}`}
													</span>
													<FaRegEdit
														style={{
															color: "rgb(41, 121, 255)",
															cursor: "pointer"
														}}
														size={14}
														onClick={() => {
															setShowCurrent(
																value.assigned_loan_provider_enc_id
															);
															setStatusToggle(true);
														}}
													/>
												</h6>
											)}
									</>
								);
							  })
							: "N/A"}
					</div>
				);
			},
			selector: row => {
				return row.assignedLoanProviders && row.assignedLoanProviders.length
					? row.assignedLoanProviders.map(value => {
						return `${getStatus(value.status)}`;
					  })
					: "";
			},
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Login Date"}
					id={"login_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.login_date).valueOf(),
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => row.login_date ? moment(row.login_date).format("DD MMM YYYY") : "-"
		},
		{
			name: (
				<MinMaxFilter
					title={"BDO Approved Amount"}
					id={"bdo_approved_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.bdo_approved_amount,
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.bdo_approved_amount ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.bdo_approved_amount).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.bdo_approved_amount ? row.bdo_approved_amount : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"bdo_approved_amount"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"TL Approved Amount"}
					id={"tl_approved_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.tl_approved_amount,
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.tl_approved_amount ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.tl_approved_amount).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.tl_approved_amount ? row.tl_approved_amount : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"tl_approved_amount"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"TVR / Soft Approval"}
					id={"soft_approval"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.soft_approval,
			sortable: false,
			omit: type === "new_lead",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.soft_approval ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.soft_approval).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.soft_approval ? row.soft_approval : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"soft_approval"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Conditional / Soft Sanction"}
					id={"soft_sanction"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.soft_sanction,
			sortable: false,
			omit: type === "new_lead",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.soft_sanction ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.soft_sanction).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.soft_sanction ? row.soft_sanction : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"soft_sanction"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Valuation"}
					id={"Valuation"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.valuation,
			sortable: false,
			omit: type === "new_lead",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.valuation ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.valuation).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.valuation ? row.valuation : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"valuation"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursement Approval"}
					id={"disbursement_approved"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.disbursement_approved,
			sortable: false,
			omit: type === "new_lead" && type === "tv_scheme",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.disbursement_approved ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.disbursement_approved).toLocaleString("en-IN")}
							</>
						) : (
							"--"
						)
					) : (
						<EditInput
							getData={reloadData}
							defaultValue={row.disbursement_approved ? row.disbursement_approved : ""}
							api={"loans/update-loan-amounts"}
							parentId={row.loan_app_enc_id}
							provider={user.organization_enc_id}
							id={"disbursement_approved"}
							asAmount={true}
							validateAsNumber={true}
							type="onlyAmount"
						/>
					);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Disbursed Amount"}
					id={"disbursed_amount"}
					placeholder="Amount"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.financed_amount,
			sortable: false,
			omit: type !== "tv_scheme",
			cell: row => {
				return row.status_number === "31" ||
					row.status_number === "28" ||
					row.status_number === "32" ? (
						row.disbursement_approved ? (
							<>
								<BiRupee size={16} />{" "}
								{parseFloat(row.disbursement_approved).toLocaleString("en-IN")}
							</>
						) : ( "--" )
					) : "" ;
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Updated On"}
					id={"last_updated_on"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.last_updated_on).valueOf(),
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => moment(row.last_updated_on).format("DD MMM YYYY h:mm a")
		},
		{
			name: (
				<MinMaxFilter
					title={"Application Date"}
					id={"application_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.apply_date).valueOf(),
			sortable: false,
			omit: type === "tv_scheme",
			cell: row => moment(row.apply_date).format("DD MMM YYYY h:mm a")
		},
		
		{
			name: (
				<NameComp
					title={"Prize Won"}
					id={"claimedDeals"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			sortable: true,
			omit: formType !== "diwali-dhamaka",
			cell: row => {
				const labelObj = {
					OVEN: "Oven",
					POWERBANK: "Power Bank",
					BAG: "Bag",
					WASHINGMACHINE: "Washing Machine",
					LED: "LED",
					HEADPHONES: "Headphones"
				};
				return (
					<>
						{row.claimedDeals && row.claimedDeals.length > 0 ? (
							<h6 className="mb-0 emiAmt">
								{labelObj[row.claimedDeals[0].claimed_coupon_code]}
							</h6>
						) : (
							""
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Created By"}
					id={"created_by"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => row.creator_name,
			style: { padding: "0px" },
			cell: row => {
				return (
					<div
						style={{
							backgroundColor: row.is_self == "1" ? "#ff7d0db0" : "",
							fontWeight: 500,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%"
						}}
					>
						{row.creator_name}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Shared With"}
					id={"sharedTo"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => moment(row.apply_date).valueOf(), // omit: user.user_type !== 'Financer',
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								{row.sharedTo && row.sharedTo.length > 0 ? (
									<div
										className="register-count"
										onClick={() => handleShareAccess(row)}
									>
										<div className="reg-img">
											{row.sharedTo.map((value, index) => {
												if (index <= 1) {
													return (
														<span
															className={`reg${index + 1} reg`}
															key={`accessList-${index}`}
														>
															<img src={value.image} />
														</span>
													);
												}
											})}
										</div>
										{row.sharedTo.length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
												+{row.sharedTo.length - 2}{" "}
											</span>
										) : (
											""
										)}

										<span className="cont">
											<RiUserSharedFill color="#00a0e3" size={20} />
											{/* <?= count($web['webinarRegistrations']) ?> Registered*/}
										</span>
									</div>
								) : (
									<span
										onClick={() => handleShareAccess(row)}
										className=""
									>
									 <BsPersonFillAdd color="#00A0E3" size={22} />
									</span>
								)}
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: "Actions",
			minWidth: "70px",
			style: { textAlign: "center" },
			selector: row => moment(row.apply_date).valueOf(),
			omit: user.user_type !== "Financer",
			cell: row => {
				return (
					<div className="d-flex justify-content-center w-100">
						<button
							type="button"
							className="link-button"
							onClick={() => handleApplicationDelete(row.loan_app_enc_id)}
						>
							<BiTrash size={18} color="red" />
						</button>
					</div>
				);
			}
		}
	];

	useEffect(() => {
		if (loan_applications && loan_applications[type]) {
			setTableLoading(false);
			// setAllLoans(loan_applications[type]);
		}
	}, [loan_applications]);

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setTableLoading(type);
		getDashboardLoanApplications({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			formType,
			type,
			start_date: dateFilters.start_date ? dateFilters.start_date : "",
			end_date: dateFilters.end_date ? dateFilters.end_date : ""
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setTableLoading(type);
		getDashboardLoanApplications({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			formType,
			type,
			start_date: dateFilters.start_date ? dateFilters.start_date : "",
			end_date: dateFilters.end_date ? dateFilters.end_date : ""
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setTableLoading(type);
		setClearFilters(true);
		setSearchArray({});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(loan_applications[type].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		if (
			loan_applications &&
			loan_applications[type] &&
			loan_applications[type].loans.length > 0
		) {
			return loan_applications[type].loans;
		} else {
			return loan_applications &&
				loan_applications[type] &&
				loan_applications[type].loans.length > 0
				? loan_applications[type].loans.slice(0, rowsPerPage)
				: [];
		}
	};

	// const convertArrayOfObjectsToCSV = (array) => {
	//     let result;
	//
	//     const columnDelimiter = ',';
	//     const lineDelimiter = '\n';
	//     const keys = Object.keys(array[0]);
	//
	//     result = '';
	//     result += keys.join(columnDelimiter);
	//     result += lineDelimiter;
	//
	//     array.forEach(item => {
	//         let ctr = 0;
	//         keys.forEach(key => {
	//             if (ctr > 0) result += columnDelimiter;
	//
	//             result += item[key];
	//             // eslint-disable-next-line no-plusplus
	//             ctr++;
	//         });
	//         result += lineDelimiter;
	//     });
	//
	//     return result;
	// }

	// const downloadCSV = (array) => {
	//     const link = document.createElement('a');
	//     let csv = convertArrayOfObjectsToCSV(array);
	//     if (csv == null) return;
	//
	//     const filename = 'export.csv';
	//
	//     if (!csv.match(/^data:text\/csv/i)) {
	//         csv = `data:text/csv;charset=utf-8,${csv}`;
	//     }
	//
	//     link.setAttribute('href', encodeURI(csv));
	//     link.setAttribute('download', filename);
	//     link.click();
	// }
	// const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
	// const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(allLoans)} />, [allLoans]);
	return (
		<div className="loan-application-table">
			<div className="d-flex justify-content-between align-items-center table-heading-line">
				<h4 className="m-0 text-capitalize">{headingObj[type]}</h4>
				<Row className="mx-0 mt-1 mb-50">
					<Col sm="6">
						<div className="d-flex align-items-center">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select"
								style={{
									width: "60px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								{/* <option value={10}>10</option>*/}
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
				</Row>
			</div>
			
			<div className="filters-container d-flex justify-content-end">
				{showClearBtn ? (
					<button className="btn btn-primary btn-xs my-2" onClick={(e) => clearFilter(e)}>Reset</button>
				): ""}
			</div>
			
			<hr />
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				paginationComponent={CustomPagination}
				persistTableHead={true}
				// actions={actionsMemo}
				progressPending={tableLoading === type}
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
				highlightOnHover="true"
				className="loan-app-table-container fix-overflow report-tables z-0"
				fixedHeader="true"
				fixedHeaderScrollHeight="80vh"
			/>
			<div ref={ref}>
				<OrganizationSidebarProvider>
					<LoanApplicationDetails
						handleCloseSideBar={() => setLoanDetails("")}
						loanId={loanDetails}
						params={{page: currentPage ? currentPage : "",
							limit: rowsPerPage ? rowsPerPage : "",
							formType: formType ? formType : "",
							type: type ? type : "",
							start_date: dateFilters.start_date ? dateFilters.start_date : "",
							end_date: dateFilters.end_date ? dateFilters.end_date : "",
							fields_search: searchArray ? searchArray : {}}}
						setTableLoading={setTableLoading}
					/>
				</OrganizationSidebarProvider>
			</div>
			<ShareAccessModal
				selectedApp={selectedApp}
				onHide={onHide}
				createrName={createrName}
				setTableLoading={setTableLoading}
				getDashboardLoanApplications={getDashboardLoanApplications}
				sharedTo={selectedApp.sharedTo}
				params={{page: currentPage ? currentPage : "",
					limit: rowsPerPage ? rowsPerPage : "",
					formType: formType ? formType : "",
					type: type ? type : "",
					start_date: dateFilters.start_date ? dateFilters.start_date : "",
					end_date: dateFilters.end_date ? dateFilters.end_date : "",
					fields_search: searchArray ? searchArray : {}}}
			/>
		</div>
	);
};
export default LoanApplicationsTable;
