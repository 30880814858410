import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { ImCross } from "react-icons/im";
import axios from "axios";
import { toast } from "react-toastify";
import { BsCameraFill, BsListCheck } from "react-icons/bs";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MainContext } from "../../context/MainState";
import NoticeBoard from "../NoticeBoard";
import Announcements from "../Announcements";
import CashModalDashboard from "./CashModalDashboard";
import ApproveCashEmployee from "./ApproveCashEmployee";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoStar } from "react-icons/io5";
import { authServices } from "../../helpers/authServices";
import { MdEdit } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { HiBuildingOffice } from "react-icons/hi2";
import MultiDataFilters from "../MultiDataFilters";
import { OverlayTrigger,Tooltip } from "react-bootstrap";
import SkeletonCard from "./SkeletonCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const OrganizationNewStats = () => {
	const { loan_products, user, updateUser } = useContext(MainContext);
	const { getNewDashboardStatsAmount, new_stats_amounts, EmiCollectionStatsData, emiCombinedData} = useContext(
		OrganizationDashboardContext
	);
	const [taskVal, setTaskVal] = useState("");
	const [tasks, setTasks] = useState([]);
	const [statusToggle, setStatusToggle] = useState(false);
	const [cashCollect, setCashCollect] = useState(false);
	const [cashReceived, setCashReceived] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [emiListData, setEmiListData] = useState("");
	const [totalCounts, setTotalCounts] = useState({ count: 0, amount: 0, enachCount: 0 });
	const [userIncentivePoints, setUserIncentivePoints] = useState("");
	const [employeesCashReport, setEmployeesCashReport] = useState([]);
	const [page, setPage] = useState(1);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [loading, setloading]= useState(true);
	const [skeletonLength, setSkeletonLength] = useState(12); // default to 12
	const submitTask = value => {
		if (!value) {
			return;
		}
		let data = { name: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/create`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTaskVal("");
					toast.success("Task created successfully");
					getTasks();
				}else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while creating task, Please try again");
			});
	};
	SwiperCore.use([Autoplay]);

	const saveTask = e => {
		if (e.keyCode === 13) {
			submitTask(e.target.value);
		}
	};

	const changeTaskStatus = (task_id, value) => {
		let data = { task_enc_id: task_id, is_completed: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/is-complete`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					getTasks();
					toast.success(`Task ${value ? "Marked as Completed" : "Incompleted"}`);
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred" + " " + error.message);
			});
	};

	const handleSave = (value, task_id) => {
		let data = { task_enc_id: task_id, name: value };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/update`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success("Task Updated Successfully");
					getTasks();
					setStatusToggle(false);
					setShowCurrent("654656566");
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getTasks = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTasks(response.data.response.tasks);
				} else {
					setTasks([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting tasks list, Please try again");
			});
	};

	const deleteTask = async task_enc_id => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}tasks/delete`,
				{
					task_enc_id
				}
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Task Deleted Successfully");
					getTasks();
				} else {
					toast.error(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while removing tasks, Please try again");
			});
	};
	
	useEffect(() => {
		if (new_stats_amounts && Object.keys(new_stats_amounts).length) {
			let totalCount = 0;
			let totalAmounts = 0;
			Object.keys(new_stats_amounts)
				.filter(val => ["total_leads_count", "under_process_count", "approved_count", "sanction_count", "disbursement_count", "total_rejected_count"].includes(val))
				.forEach(val => {
					totalCount += new_stats_amounts[val] ? parseInt(new_stats_amounts[val]) : 0;
				});
			Object.keys(new_stats_amounts)
				.filter(val => val.match(/_amount/))
				.forEach(val => {
					totalAmounts += new_stats_amounts[val] ? parseFloat(new_stats_amounts[val]) : 0;
				});
			const totalEnachCount = parseFloat(new_stats_amounts.registered_nach) + parseFloat(new_stats_amounts.unregistered_nach);
			setTotalCounts({ count: totalCount, amount: totalAmounts, enachCount: totalEnachCount });
		}
	}, [new_stats_amounts]);


	const getUserIncentivePoints = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/get-incentive-sum`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setUserIncentivePoints(response.data.response.data);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getJuniorsCashReport = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/employee-cash-stats`,
				{
					page: page,
				}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmployeesCashReport([
						...employeesCashReport,
						...response.data.response.data
					]);
				}
			})
			.catch(error => {
				console.error("An Error Occoured:", error);
			});
	};

	const fetchMoreData = () => {
		setPage(page + 1);
	};

	const getDataApi = filters => {
		setloading(true);
		const timerId = setTimeout(() => {
			emiCombinedData({
				start_date: filters.start_date,
				end_date: filters.end_date,
				loan_product_name: filters.loanNameType,
				branch_enc_id: filters.branch_name,
				state_enc_id: filters.state_name,
				type: "dashboard"
			}).then(() => setloading(false));

			getNewDashboardStatsAmount({
				start_date: filters.start_date,
				end_date: filters.end_date,
				loan_product: filters.loan_product,
				loan_type: filters.loan_type,
				branch_name: filters.branch_name,
				state_enc_id: filters.state_name
			}).then(() => setloading(false));
		},1000);
		return () => {
			clearTimeout(timerId);
		};
	};

	useEffect(() => {
		getJuniorsCashReport(page);
	}, [page]);

	const getEmiList = () => {
		const data = {
			user_id: user.user_enc_id
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/employee-emi-collection`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmiListData(response.data.response);
					// setLoading(false);
				} else {
					setEmiListData([]);
				}
			})
			.catch(error => {
				toast.error("An error has occurred while getting employee emi list", error);
			});
	};

	useEffect(() => {
		if (userIncentivePoints === "") {
			getUserIncentivePoints();
		}
	}, []);

	useEffect(() => {
		getTasks();
		if (
			process.env.REACT_APP_TYPE === "DEV" ||
			(user.organization_username && user.organization_username === "phfleasing")
		) {
			getEmiList();
		}
	}, []);

	const handleSaveImage = e => {
		if (e.target.files) {
			if (e.target.files.length !== 0) {
				setFormIsLoading(true);
				const file = e.target.files[0];
				const formData = new FormData();
				formData.append("profile_image", file);
				axios
					.post(
						`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}auth/upload-profile-picture`,
						formData
					)
					.then(response => {
						setFormIsLoading(false);
						if (response.data.response.status === 200) {
							toast.success("Profile updated");
							authServices.findUser(updateUser);
						} else {
							toast.error("An error has occurred, Please try again");
						}
					})
					.catch(error => {
						console.log(error);
						setFormIsLoading(false);
						toast.error("An error has occurred, Please try again");
					});
			}
		}
	};

	useEffect(() => {
		// Check if the app is opened on a mobile device
		const isTab = window.innerWidth <= 768; // You can adjust the width threshold as needed
		const isMobile = window.innerWidth <= 576; // You can adjust the width threshold as needed
		setSkeletonLength(isMobile ? 1 : isTab ? 2 : 12);
	  }, []); // Empty dependency array ensures it runs only once on mount

	return (
		<div className="dsa-stats row mb-6">
			<div className="col-md-4 col-lg-3">
				{user?.user_type === "Employee" ? (
					<div className="col-md-12 mb-1">
						<div className="incentive-points-card mb-4 shadow-lg rounded-3 d-flex align-items-center">
							<div className="user-db-img img-preview-user text-center shadow-ell rounded-2 position-relative" style={{ flexShrink: 0 }}>
								<label
									className="form-label edit-image"
									htmlFor="change_image"
								>
									<BsCameraFill size={30} className="edit-image-camera" />
								</label>
								<input
									type={"file"}
									id="change_image"
									className="opacity-0 position-absolute input-hide"
									onChange={e => handleSaveImage(e)}
								/>
								{formIsLoading ? (
									<img
										src="/images/pages/home/loader-black.svg"
										alt={process.env.REACT_APP_NAME}
									/>
								) : (
									<img
										src={user.image}
										className="img-fluid"
										alt={user.name}
									/>
								)}
								 <label className="edit-button" htmlFor="change_image"><MdEdit size="16px" /></label>
							</div>
							<div className="d-flex flex-column mb-2">
								<h3 className="ms-2 mb-0 fw-600 text-capitalize">{user.first_name + " " + user.last_name}</h3>
								<p className="mb-0 ms-2 fs-5"><FaUserTie size="14px" className="me-1" />{user.designation}</p>
								<p className="mb-0 ms-2 fw-600 fs-5"><HiBuildingOffice size="14px" className="me-1" />{user.branch_name}</p>
								<p className="ms-2 mb-0 fs-5 d-flex align-items-center fw-600 primary-color flex-wrap"><IoStar size="14px" className="me-1" color="#F6BE00"/> Incentive Points: <span className="dark-text ms-1">{userIncentivePoints ? userIncentivePoints.active_count : "0"}
									{userIncentivePoints.pending_count ? (
										<OverlayTrigger
											placement="top"
											overlay={<Tooltip className="pending-count-tooltip">Pending Points</Tooltip>}
										>
											<span style={{fontSize:"13px"}}> ({userIncentivePoints.pending_count})</span>
										</OverlayTrigger>
									) : ""}
								</span>
									 </p>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{process.env.REACT_APP_TYPE === "DEV" ||
				(user.organization_username && user.organization_username === "phfleasing") ? (
						<>
							<NoticeBoard />
							<Announcements />
						</>
					) : (
						""
					)}
				<div className="task-main shadow-ell rounded-3 overflow-hidden mb-3 mobile-view-hidden">
					<div className="task-heading-box bg-gray-400 py-3 px-4">
						<h3 className={"m-0"}>
							<BsListCheck /> Tasks
						</h3>
					</div>
					<div className="disFlex mb-3">
						<input
							type="text"
							className={"form-control p-2 w-75 rounded-0"}
							style={{ height: "40px" }}
							onKeyDown={e => saveTask(e)}
							onChange={e => setTaskVal(e.target.value)}
							value={taskVal}
							placeholder={"Type Here"}
						/>
						<button
							className="btn bg-gray-700 text-white btn-sm w-25 rounded-0"
							style={{ height: "40px" }}
							onClick={() => submitTask(taskVal)}
						>
							Add
						</button>
					</div>
					<ul className="task-list list-unstyled">
						<PerfectScrollbar>
							{tasks && tasks.length ? tasks.map(value => {
								return (
									<li
										className="disFlex justify-content-between px-4 mb-2 align-items-center"
										key={`tasklist${value.task_enc_id}`}
									>
										<div className="checkbox-custom checkbox-default text-left position-relative">
											<input
												type="checkbox"
												name="task"
												id={`${value.task_enc_id}`}
												checked={value.is_completed === "1"}
												className="uncheck"
												onChange={e =>
													changeTaskStatus(
														value.task_enc_id,
														e.target.checked
													)
												}
											/>
											{statusToggle && showCurrent === value.task_enc_id ? (
												<input
													style={{
														width: "auto",
														margin: "0px 5px",
														padding: "5px 10px",
														maxWidth: "calc(100% - 50px)"
													}}
													placeholder=""
													defaultValue={value.name}
													onKeyDown={event => {
														if (event.key === "Enter") {
															handleSave(
																event.target.value,
																value.task_enc_id
															);
														} else if (event.key === "Escape") {
															setStatusToggle(false);
															event.preventDefault();
															event.stopPropagation();
														}
													}}
													className="form-control"
												/>
											) : (
												<label
													className={`todo-label ${
														value.is_completed === "1"
															? "line-pass"
															: ""
													}`}
													onDoubleClick={() => {
														setShowCurrent(value.task_enc_id);
														setStatusToggle(true);
													}}
												>
													{value.name}
												</label>
											)}
										</div>
										<div className="todo-actions">
											<button
												type="button"
												className="link-button"
												onClick={() =>
													deleteTask(value.task_enc_id)
												}
											>
												<ImCross size={10} color={"#ff6a6a"} />
											</button>
										</div>
									</li>
								);
							})
								: ""}
						</PerfectScrollbar>
					</ul>
				</div>
				{emiListData?.display_data?.total_sum || employeesCashReport.length ? (
					<div className="shadow-ell rounded-3 overflow-hidden pt-3 pb-2 mb-3">
						<h3 className="px-3 mb-1">Cash in hand</h3>
						<hr style={{ marginTop: "2px", marginBottom: "4px" }} />
						<div id="scrollableDiv" className="employees-cash-report px-2">
							<InfiniteScroll
								dataLength={employeesCashReport.length}
								next={fetchMoreData}
								hasMore={true}
								scrollableTarget="scrollableDiv"
							>
								{emiListData?.display_data?.total_sum ? (
									<>
										<div className="user-single">
											<div className="user-avatar">
												<img src={user.image} alt={user.first_name} />
											</div>
											<div className="user-description">
												<div>
													Me {user.employee_code ? `(${user.employee_code})` : ""}
													<span>{user.designation}</span>
												</div>
												<div>
													<span>Cash in hand</span>₹{" "}
													{parseFloat(
														emiListData?.display_data?.total_sum
													).toLocaleString("en-IN")}
												</div>
											</div>
										</div>
										<hr style={{ margin: "5px" }} />
									</>
								) : ""}

								{employeesCashReport.map((value, index) => (
									<React.Fragment key={`employeesCash${index}`}>
										<div className="user-single">
											<div className="user-avatar">
												<img src={value.image} alt={value.name} />
											</div>
											<div className="user-description">
												<div>
													{value.name}{" "}
													{value.employee_code
														? `(${value.employee_code})`
														: ""}
													<span>{value.designation}</span>
												</div>
												<div>
													<span>Cash in hand</span>₹{""}
													{parseFloat(
														parseFloat(value.collected_cash) +
														parseFloat(value.received_cash)
													).toLocaleString("en-IN")}
												</div>
											</div>
										</div>
										{employeesCashReport.length !== index + 1 ? (
											<hr style={{ margin: "5px" }} />
										) : (
											""
										)}
									</React.Fragment>
								))}
							</InfiniteScroll>
						</div>
					</div>
				): (
					""
				)}
			</div>
			<div className="col-md-8 col-lg-9">
				<div className="row d-flex align-items-end">
					<div className="col-md-12 col-12 d-flex align-items-center text-start">
						{emiListData.display_data && emiListData.display_data.total_sum !== 0 ? (
							<div className="pending-amount text-center mx-1">
								<button
									className="btn-primary btn btn-sm m-0"
									onClick={() => setCashCollect(true)}
								>
									Submit Cash
								</button>
							</div>
						) : (
							""
						)}
						{emiListData.display_data &&
						emiListData.display_data.pending_amount_approval !== null ? (
								<div className="pending-amount text-center">
									<button
										className="btn-primary btn btn-sm m-0"
										onClick={() => setCashReceived(true)}
									>
										Received Cash Requests
									</button>
								</div>
							) : (
								""
							)}
					</div>
				</div>
				<div className="row emi-stat gx-3 gy-3">
					<div className="col-md-12 col-12">
						<div className={`d-flex align-items-center mb-2 ${skeletonLength === 12 ? "justify-content-end" : "justify-content-center"}  flex-wrap`}>
							<MultiDataFilters
								loan_products={loan_products}
								getData={getDataApi}
								type={["LoanProduct", "branches", "states"]}
								defaultDate={{value: "1", label: "This Month"}}
								nameLoanType={true}
							/>
						</div>
					</div>
					{loading ? (
						<div className="row gx-3 gy-3 mt-0">
							{[...Array(skeletonLength)].map((index) => (
								<SkeletonCard key={index} type={"md"} widthMatch={true}/>
							))}
						</div>
					) :
						<>
							<div className="row emi-stat gx-3 gy-3 stats-hidden mt-0">
								<div className="col-xl-3 col-lg-4 col-md-6">
									<div className="card d-flex flex-column">
										<div className="card-body d-block emi-card-stats mb-0">
											<img
												src="/images/pages/dashboard/total-applications.png"
												className="card-right-img"
												alt="ds"
											/>
											<p className="dashboard-stats-card-head mb-3">
												<span className="d-block">{totalCounts.count}</span>
												<span className="fs-4">Total Cases</span>
												<span className="bold d-block">
									₹ {parseFloat(totalCounts.amount).toLocaleString("en-IN")}
												</span>
											</p>
											<div className="d-flex align-items-center justify-content-between">
												<p className="pending-amounts align-items-start">
													<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.new_cases_count ? new_stats_amounts.new_cases_count : "0"}</span>
													<span className="fs-4">New</span>
													<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.new_cases_amount ? parseFloat(new_stats_amounts.new_cases_amount).toLocaleString("en-IN") : "0"}</span>
												</p>
												<p className="pending-amounts align-items-start">
													<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.spillover_count ? new_stats_amounts.spillover_count : "0"}</span>
													<span className="fs-4">Spillover</span>
													<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.spillover_amount ? parseFloat(new_stats_amounts.spillover_amount).toLocaleString("en-IN") : "0"}</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0">
										<img
											src="/images/pages/dashboard/new-leads.png"
											className="card-right-img"
											alt="ds"
											style={{height: "50px !important"}}
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">{new_stats_amounts && new_stats_amounts.total_leads_count ? new_stats_amounts.total_leads_count : "0"}</span>
											<span className="fs-4">Leads</span>
											<span className="bold d-block">
									₹{" "}{new_stats_amounts && new_stats_amounts.total_leads_amount ? parseFloat(new_stats_amounts.total_leads_amount).toLocaleString("en-IN") : "0"}
											</span>
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.new_lead_count ? new_stats_amounts.new_lead_count : "0"}</span>
												<span className="fs-4">New Leads</span>
												<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.new_lead_amount ? parseFloat(new_stats_amounts.new_lead_amount).toLocaleString("en-IN") : "0"}</span>
											</p>
											<p className="pending-amounts align-items-start align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.login_count ? new_stats_amounts.login_count : "0"}</span>
												<span className="fs-4">Login</span>
												<span className="fs-5 text-start w-100">₹ {" "} {new_stats_amounts && new_stats_amounts.login_amount ? parseFloat(new_stats_amounts.login_amount).toLocaleString("en-IN"): "0"}</span>
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0">
										<img
											src="/images/pages/dashboard/under-process.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">{new_stats_amounts && new_stats_amounts.under_process_count ? new_stats_amounts.under_process_count : "0"}</span>
											<span className="fs-4">Under Process</span>
											<span className="bold d-block">
									₹{" "}
												{new_stats_amounts && new_stats_amounts.under_process_amount
													? parseFloat(
														new_stats_amounts.under_process_amount
													).toLocaleString("en-IN")
													: "0"}
											</span>
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.verification_count ? new_stats_amounts.verification_count : "0"}</span>
												<span className="fs-4">Verification</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.verification_amount
														? parseFloat(
															new_stats_amounts.verification_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.pd_count ? new_stats_amounts.pd_count : "0"}</span>
												<span className="fs-4">PD</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.pd_amount
														? parseFloat(
															new_stats_amounts.pd_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0 card-height">
										<img
											src="/images/pages/dashboard/soft-approvals.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">{new_stats_amounts && new_stats_amounts.approved_count ? new_stats_amounts.approved_count : "0"}</span>
											<span className="fs-4">Approved</span>
											<span className="bold d-block">
									₹ {" "}
												{new_stats_amounts && new_stats_amounts.approved_amount
													? parseFloat(new_stats_amounts.approved_amount).toLocaleString("en-IN")
													: "0"}
											</span>
										</p>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0">
										<img
											src="/images/pages/dashboard/sanctioned.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">
												{new_stats_amounts && new_stats_amounts.sanction_count ? new_stats_amounts.sanction_count : "0"}
											</span>
											<span className="fs-4">Sanction</span>
											<span className="bold d-block">
								₹{" "}
												{new_stats_amounts && new_stats_amounts.sanction_amount
													? parseFloat(
														new_stats_amounts.sanction_amount
													).toLocaleString("en-IN")
													: "0"}
											</span>
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.legal_count ? new_stats_amounts.legal_count : "0"}</span>
												<span className="fs-4">Legal</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.legal_amount
														? parseFloat(
															new_stats_amounts.legal_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.sanctioned_count ? new_stats_amounts.sanctioned_count : "0"}</span>
												<span className="fs-4">Sanctioned</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.sanctioned_amount
														? parseFloat(
															new_stats_amounts.sanctioned_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0">
										<img
											src="/images/pages/dashboard/disbursed-approval.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">
												{new_stats_amounts && new_stats_amounts.disbursement_count
													? new_stats_amounts.disbursement_count
													: "0"}
											</span>
											<span className="fs-4">Disbursement</span>
											<span className="bold d-block">
									₹{" "}
												{new_stats_amounts && new_stats_amounts.disbursement_amount
													? parseFloat(
														new_stats_amounts.disbursement_amount
													).toLocaleString("en-IN")
													: "0"}
											</span>
										</p>
										<div className="d-flex align-items-center justify-content-between mt-auto">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.disbursement_approval_count	? new_stats_amounts.disbursement_approval_count : "0"}</span>
												<span className="fs-4">DA</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.disbursed_approval_amount
														? parseFloat(
															new_stats_amounts.disbursed_approval_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.disbursed_count	? new_stats_amounts.disbursed_count : "0"}</span>
												<span className="fs-4">Disbursed</span>
												<span className="fs-5 text-start w-100">
									₹{" "}
													{new_stats_amounts && new_stats_amounts.disbursed_amount
														? parseFloat(
															new_stats_amounts.disbursed_amount
														).toLocaleString("en-IN")
														: "0"}
												</span>
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0">
										<img
											src="/images/pages/dashboard/rejected.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-3">
											<span className="d-block">
												{new_stats_amounts && new_stats_amounts.total_rejected_count
													? new_stats_amounts.total_rejected_count
													: "0"}
											</span>
											<span className="fs-4">Rejected</span>
											<span className="bold d-block">
									₹{" "}
												{new_stats_amounts && new_stats_amounts.total_rejected_amount
													? parseFloat(new_stats_amounts.total_rejected_amount).toLocaleString(
														"en-IN"
													)
													: "0"}
											</span>
										</p>
										<div className="d-flex align-items-center justify-content-between mt-auto">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.rejected_count ? new_stats_amounts.rejected_count : "0"}</span>
												<span className="fs-4">Rejected</span>
												<span className="fs-5 text-start w-100">
										₹{" "}
													{new_stats_amounts && new_stats_amounts.rejected_amount
														? parseFloat(new_stats_amounts.rejected_amount).toLocaleString(
															"en-IN"
														)
														: "0"}
												</span>
											</p>
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.cni_count ? new_stats_amounts.cni_count : "0"}</span>
												<span className="fs-4">CNI</span>
												<span className="fs-5 text-start w-100">
										₹{" "}
													{new_stats_amounts && new_stats_amounts.cni_amount
														? parseFloat(new_stats_amounts.cni_amount).toLocaleString(
															"en-IN"
														)
														: "0"}
												</span>
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
									<div className="card-body d-block emi-card-stats mb-0 card-height">
										<img
											src="/images/pages/dashboard/insurancecharges.png"
											className="card-right-img"
											alt="ds"
										/>
										<p className="dashboard-stats-card-head	mb-6">
											<span className="d-block">
												{totalCounts.enachCount}
											</span>
											<span className="fs-4">eNACH</span>
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.registered_nach ? new_stats_amounts.registered_nach : "0"}</span>
												<span className="fs-4">Registered</span>
											</p>
											<p className="pending-amounts align-items-start">
												<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.unregistered_nach ? new_stats_amounts.unregistered_nach : "0"}</span>
												<span className="fs-4">Unregistered</span>
											</p>
										</div>
									</div>
								</div>

								{EmiCollectionStatsData?.length ? (
									EmiCollectionStatsData.map((value, index) => {
										return (
											<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={index}>
												<div className="card-body d-block emi-card-stats mb-0">
													<img src={`/images/pages/dashboard/emi-stats-icons/${value.payment_method?.replace(/[\s/]/g, "-")}.png`} className="card-right-img" alt="ds"/>
													<p className="dashboard-stats-card-head	mb-3">
														<span className="d-block">
															{(value.count ? value.count : 0) + (value.pending ? value.pending.count : 0)}
														</span>
														<span className="fs-4">{value.payment_method} {value.payment_method === "Total" ? "Collection": ""}</span>
														<span className="bold d-block">
														₹{" "}
															{(
																(value.sum ? parseFloat(value.sum) : 0) +
														(value.pending
															? parseFloat(value.pending.sum)
															: 0)
															).toLocaleString("en-IN")}
														</span>
													</p>
													<div className="d-flex align-items-center justify-content-between mt-auto">
														<p className="pending-amounts align-items-start">
															<span className="fs-5 text-start w-100">{value.count ? value.count : "0"}</span>
															<span className="fs-4">Paid</span>
															<span className="fs-5 text-start w-100">
															₹{" "}
																{value.sum ? parseFloat(value.sum).toLocaleString("en-IN") : "0"}
															</span>
														</p>
														<p className="pending-amounts align-items-start">
															<span className="fs-5 text-start w-100">{value.pending
																? value.pending.count
																: "0"}{" "}
															</span>
															<span className="fs-4">{!["QR/Link"].includes(value.payment_method)? "Collected":"Pending"}</span>
															<span className="fs-5 text-start w-100">
															₹{value.pending ? parseFloat(value.pending.sum).toLocaleString("en-IN") : "0"}
															</span>
														</p>
													</div>
												</div>
											</div>
										);
									})) : ""}
							</div>
							<div className="swiper-stats">
								<Swiper
									className="swiper-wrapper"
									spaceBetween={5}
									slidesPerView={1}
									autoplay={true}
									autoHeight={true}
									breakpoints={{
										576: {
											slidesPerView: 2,
											spaceBetween: 20,
										  },
										  768: {
											slidesPerView: 4,
											spaceBetween: 40,
										  },
									}}
								>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card d-flex flex-column">
												<div className="card-body d-block emi-card-stats mb-0">
													<img
														src="/images/pages/dashboard/total-applications.png"
														className="card-right-img"
														alt="ds"
													/>
													<p className="dashboard-stats-card-head mb-3">
														<span className="d-block">{totalCounts.count}</span>
														<span className="fs-4">Total Cases</span>
														<span className="bold d-block">
													₹ {parseFloat(totalCounts.amount).toLocaleString("en-IN")}
														</span>
													</p>
													<div className="d-flex align-items-center justify-content-between">
														<p className="pending-amounts align-items-start">
															<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.new_cases_count ? new_stats_amounts.new_cases_count : "0"}</span>
															<span className="fs-4">New</span>
															<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.new_cases_amount ? parseFloat(new_stats_amounts.new_cases_amount).toLocaleString("en-IN") : "0"}</span>
														</p>
														<p className="pending-amounts align-items-start">
															<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.spillover_count ? new_stats_amounts.spillover_count : "0"}</span>
															<span className="fs-4">Spillover</span>
															<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.spillover_amount ? parseFloat(new_stats_amounts.spillover_amount).toLocaleString("en-IN") : "0"}</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0">
												<img
													src="/images/pages/dashboard/new-leads.png"
													className="card-right-img"
													alt="ds"
													style={{height: "50px !important"}}
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">{new_stats_amounts && new_stats_amounts.total_leads_count ? new_stats_amounts.total_leads_count : "0"}</span>
													<span className="fs-4">Leads</span>
													<span className="bold d-block">
												₹{" "}{new_stats_amounts && new_stats_amounts.total_leads_amount ? parseFloat(new_stats_amounts.total_leads_amount).toLocaleString("en-IN") : "0"}
													</span>
												</p>
												<div className="d-flex align-items-center justify-content-between">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.new_lead_count ? new_stats_amounts.new_lead_count : "0"}</span>
														<span className="fs-4">New Leads</span>
														<span className="fs-5 text-start w-100">₹{" "}{new_stats_amounts && new_stats_amounts.new_lead_amount ? parseFloat(new_stats_amounts.new_lead_amount).toLocaleString("en-IN") : "0"}</span>
													</p>
													<p className="pending-amounts align-items-start align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.login_count ? new_stats_amounts.login_count : "0"}</span>
														<span className="fs-4">Login</span>
														<span className="fs-5 text-start w-100">₹ {" "} {new_stats_amounts && new_stats_amounts.login_amount ? parseFloat(new_stats_amounts.login_amount).toLocaleString("en-IN"): "0"}</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0">
												<img
													src="/images/pages/dashboard/under-process.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">{new_stats_amounts && new_stats_amounts.under_process_count ? new_stats_amounts.under_process_count : "0"}</span>
													<span className="fs-4">Under Process</span>
													<span className="bold d-block">
												₹{" "}
														{new_stats_amounts && new_stats_amounts.under_process_amount
															? parseFloat(
																new_stats_amounts.under_process_amount
															).toLocaleString("en-IN")
															: "0"}
													</span>
												</p>
												<div className="d-flex align-items-center justify-content-between">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.verification_count ? new_stats_amounts.verification_count : "0"}</span>
														<span className="fs-4">Verification</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.verification_amount
																? parseFloat(
																	new_stats_amounts.verification_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.pd_count ? new_stats_amounts.pd_count : "0"}</span>
														<span className="fs-4">PD</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.pd_amount
																? parseFloat(
																	new_stats_amounts.pd_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0 card-height">
												<img
													src="/images/pages/dashboard/soft-approvals.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">{new_stats_amounts && new_stats_amounts.approved_count ? new_stats_amounts.approved_count : "0"}</span>
													<span className="fs-4">Approved</span>
													<span className="bold d-block">
												₹ {" "}
														{new_stats_amounts && new_stats_amounts.approved_amount
															? parseFloat(new_stats_amounts.approved_amount).toLocaleString("en-IN")
															: "0"}
													</span>
												</p>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0">
												<img
													src="/images/pages/dashboard/sanctioned.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">
														{new_stats_amounts && new_stats_amounts.sanction_count ? new_stats_amounts.sanction_count : "0"}
													</span>
													<span className="fs-4">Sanction</span>
													<span className="bold d-block">
												₹{" "}
														{new_stats_amounts && new_stats_amounts.sanction_amount
															? parseFloat(
																new_stats_amounts.sanction_amount
															).toLocaleString("en-IN")
															: "0"}
													</span>
												</p>
												<div className="d-flex align-items-center justify-content-between">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.legal_count ? new_stats_amounts.legal_count : "0"}</span>
														<span className="fs-4">Legal</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.legal_amount
																? parseFloat(
																	new_stats_amounts.legal_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.sanctioned_count ? new_stats_amounts.sanctioned_count : "0"}</span>
														<span className="fs-4">Sanctioned</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.sanctioned_amount
																? parseFloat(
																	new_stats_amounts.sanctioned_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0">
												<img
													src="/images/pages/dashboard/disbursed-approval.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">
														{new_stats_amounts && new_stats_amounts.disbursement_count
															? new_stats_amounts.disbursement_count
															: "0"}
													</span>
													<span className="fs-4">Disbursement</span>
													<span className="bold d-block">
												₹{" "}
														{new_stats_amounts && new_stats_amounts.disbursement_amount
															? parseFloat(
																new_stats_amounts.disbursement_amount
															).toLocaleString("en-IN")
															: "0"}
													</span>
												</p>
												<div className="d-flex align-items-center justify-content-between mt-auto">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.disbursement_approval_count	? new_stats_amounts.disbursement_approval_count : "0"}</span>
														<span className="fs-4">DA</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.disbursed_approval_amount
																? parseFloat(
																	new_stats_amounts.disbursed_approval_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.disbursed_count	? new_stats_amounts.disbursed_count : "0"}</span>
														<span className="fs-4">Disbursed</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.disbursed_amount
																? parseFloat(
																	new_stats_amounts.disbursed_amount
																).toLocaleString("en-IN")
																: "0"}
														</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0">
												<img
													src="/images/pages/dashboard/rejected.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-3">
													<span className="d-block">
														{new_stats_amounts && new_stats_amounts.total_rejected_count
															? new_stats_amounts.total_rejected_count
															: "0"}
													</span>
													<span className="fs-4">Rejected</span>
													<span className="bold d-block">
												₹{" "}
														{new_stats_amounts && new_stats_amounts.total_rejected_amount
															? parseFloat(new_stats_amounts.total_rejected_amount).toLocaleString(
																"en-IN"
															)
															: "0"}
													</span>
												</p>
												<div className="d-flex align-items-center justify-content-between mt-auto">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.rejected_count ? new_stats_amounts.rejected_count : "0"}</span>
														<span className="fs-4">Rejected</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.rejected_amount
																? parseFloat(new_stats_amounts.rejected_amount).toLocaleString(
																	"en-IN"
																)
																: "0"}
														</span>
													</p>
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.cni_count ? new_stats_amounts.cni_count : "0"}</span>
														<span className="fs-4">CNI</span>
														<span className="fs-5 text-start w-100">
													₹{" "}
															{new_stats_amounts && new_stats_amounts.cni_amount
																? parseFloat(new_stats_amounts.cni_amount).toLocaleString(
																	"en-IN"
																)
																: "0"}
														</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="col-xl-3 col-lg-4 col-md-12">
											<div className="card-body d-block emi-card-stats mb-0 card-height">
												<img
													src="/images/pages/dashboard/insurancecharges.png"
													className="card-right-img"
													alt="ds"
												/>
												<p className="dashboard-stats-card-head	mb-6">
													<span className="d-block">
														{totalCounts.enachCount}
													</span>
													<span className="fs-4">eNACH</span>
												</p>
												<div className="d-flex align-items-center justify-content-between">
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.registered_nach ? new_stats_amounts.registered_nach : "0"}</span>
														<span className="fs-4">Registered</span>
													</p>
													<p className="pending-amounts align-items-start">
														<span className="fs-5 text-start w-100">{new_stats_amounts && new_stats_amounts.unregistered_nach ? new_stats_amounts.unregistered_nach : "0"}</span>
														<span className="fs-4">Unregistered</span>
													</p>
												</div>
											</div>
										</div>
									</SwiperSlide>

									{EmiCollectionStatsData?.length ? (
										EmiCollectionStatsData.map((value, index) => {
											return (
												<SwiperSlide key={`collection-stats${index}`}>
													<div className="col-xl-3 col-lg-4 col-md-12" key={index}>
														<div className="card-body d-block emi-card-stats mb-0">
															<img src={`/images/pages/dashboard/emi-stats-icons/${value.payment_method?.replace(/[\s/]/g, "-")}.png`} className="card-right-img" alt="ds"/>
															<p className="dashboard-stats-card-head	mb-3">
																<span className="d-block">
																	{(value.count ? value.count : 0) + (value.pending ? value.pending.count : 0)}
																</span>
																<span className="fs-4">{value.payment_method} {value.payment_method === "Total" ? "Collection": ""}</span>
																<span className="bold d-block">
															₹{" "}
																	{(
																		(value.sum ? parseFloat(value.sum) : 0) +
															(value.pending
																? parseFloat(value.pending.sum)
																: 0)
																	).toLocaleString("en-IN")}
																</span>
															</p>
															<div className="d-flex align-items-center justify-content-between mt-auto">
																<p className="pending-amounts align-items-start">
																	<span className="fs-5 text-start w-100">{value.count ? value.count : "0"}</span>
																	<span className="fs-4">Paid</span>
																	<span className="fs-5 text-start w-100">
																₹{" "}
																		{value.sum ? parseFloat(value.sum).toLocaleString("en-IN") : "0"}
																	</span>
																</p>
																<p className="pending-amounts align-items-start">
																	<span className="fs-5 text-start w-100">{value.pending
																		? value.pending.count
																		: "0"}{" "}
																	</span>
																	<span className="fs-4">{!["QR/Link"].includes(value.payment_method)? "Collected":"Pending"}</span>
																	<span className="fs-5 text-start w-100">
																₹{value.pending ? parseFloat(value.pending.sum).toLocaleString("en-IN") : "0"}
																	</span>
																</p>
															</div>
														</div>
													</div>
												</SwiperSlide>
											);
										})) : ""}
								</Swiper>
							</div>
						</>
					}

				</div>
			</div>

			{process.env.REACT_APP_TYPE === "DEV" ||
			(user.organization_username && user.organization_username === "phfleasing") ? (
					<>
						<CashModalDashboard
							getEmiList={getEmiList}
							cashCollect={cashCollect}
							setCashCollect={setCashCollect}
							emiListData={emiListData}
						/>

						<ApproveCashEmployee
							getEmiList={getEmiList}
							setCashReceived={setCashReceived}
							cashReceived={cashReceived}
						/>
					</>
				) : (
					""
				)}
		</div>
	);
};
export default OrganizationNewStats;
