import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import PieChart from "../../components/charts/PieChart";
import { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import MultiDataFilters from "../../components/MultiDataFilters";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EmiCollectionStats from "../../components/dashboard/EmiCollectionStats";
import { Nav } from "react-bootstrap";
import SkeletonCard from "../../components/dashboard/SkeletonCard";

const ReportsDashboard = () => {
	const history = useNavigate();
	const { getStates, states_list, branches_list } = useContext(OrganizationDashboardContext);
	const [isFiltered, setIsFiltered] = useState(false);
	const [loading, setLoading] = useState(false);
	const [allData, setAllData] = useState({});
	const [weeklyData, setWeeklyData] = useState({});
	const [bucketData, setBucketData] = useState({});
	const [targetBucketData, setTargetBucketData] = useState({});
	const [prodData, setProdData] = useState({});
	const [loanAccData, setLoanAccData] = useState({});
	const [targetLoanAccData, setTargetLoanAccData] = useState({});
	const [emiDueData, setEmiDueData] = useState({});
	const [totalNachStats, setTotalNachStats] = useState({});
	const [statesData, setStatesData] = useState({});
	const [targetStatesData, setTargetStatesData] = useState({});
	const [unassignedStates, setUnassignedStates] = useState([]);
	const [priorityData, setPriorityData] = useState([]);
	const [registeredEnach, setRegisteredEnach] = useState([]);
	const [targetCollectionStats, setTargetCollectionStats] = useState([]);
	const [lastCollectionStats, setLastCollectionStats] = useState({});
	const [enachStats, setEnachStats] = useState([]);
	const [brachesData, setBrachesData] = useState({});
	const [statesDataFirstSix, setStatesDataFirstSix] = useState({});
	const [brachesLoanAccounts, setBrachesLoanAccounts] = useState({});
	const [collectedEmiData, setCollectedEmiData] = useState([]);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [overdueData, setOverdueData] = useState({});
	// const [statesArray, setStatesArray] = useState([]);
	const [enachData, setEnachData] = useState({});
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		state_name: [],
		branch_name: []
	});
	const [active, setActive] = useState("overall");
	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
		}
	};
	const { loanAccountTypes, user } = useContext(MainContext);

	const weeklyCollectionBucketList = [
		{ api: "stats/bucket-daily-collection-stats", keyword: ["x", "1", "2", "3", "4"] },
		{ api: "stats/bucket-daily-collection-stats", keyword: ["5", "6", "7", "8"] },
	];
	
	const targetBucketList = [
		{ api: "stats/target-bucket-wise", keyword: ["x", "1", "2", "3", "4"] },
		{ api: "stats/target-bucket-wise", keyword: ["5", "6", "7", "8"] },
	];

	const unassignedCollectionOfficer = "stats/unassigned-collection-officer";
	
	const enachCount =  "stats/collection-nach-stats";

	const emiCollectedStats = "stats/collected-emis";

	const weeklyCollectionProdList = [
		{ api: "stats/product-daily-collection-stats", keyword:["MSME", "LCV", "Used Commercial Vehical Loan", "Three Wheeler"]},
		{ api: "stats/product-daily-collection-stats", keyword:["Personal Loan Top Up", "Used E-Rickshaw", "Used Car Loan"] },
		{ api: "stats/product-daily-collection-stats", keyword:["Used Bike Loan", "Capital HL BC 2010", "Capital HL BC 2025", "EV Two Wheeler"] },
		{ api: "stats/product-daily-collection-stats", keyword:["Amrit LAP", "BHN LAP", "School Fee Loan"] },
		{ api: "stats/product-daily-collection-stats", keyword:["Consumer Durable Loan", "Two Wheeler", "E-Rickshaw"] },
	];

	const totalNachProductStats = [
		{ api: "stats/total-nach-product-stats", keyword:["MSME", "LCV", "Used Commercial Vehical Loan", "Three Wheeler"]},
		{ api: "stats/total-nach-product-stats", keyword:["Personal Loan Top Up", "Used E-Rickshaw", "Used Car Loan"] },
		{ api: "stats/total-nach-product-stats", keyword:["Used Bike Loan", "Capital HL BC 2010", "Capital HL BC 2025", "EV Two Wheeler"] },
		{ api: "stats/total-nach-product-stats", keyword:["Amrit LAP", "BHN LAP", "School Fee Loan"] },
		{ api: "stats/total-nach-product-stats", keyword:["Consumer Durable Loan", "Two Wheeler", "E-Rickshaw"] },
	];

	const weeklyCollectionDepartmentList = [
		{api: "stats/daily-collection-stats", keyword: ["collection", "sales", "call center", "operations"]}
	];

	const collectionStatsList = [
		{ api: "stats/collection-stats", keyword: ["bucket", "product"]},
		{ api: "stats/collection-stats", keyword: ["department", "state"]},
	];

	const loanAccountProductStats =
		[
			{ api: "stats/product-stats-all-buckets", keyword:["MSME", "LCV", "Used Commercial Vehical Loan", "Three Wheeler"] },
			{ api: "stats/product-stats-all-buckets", keyword:["Personal Loan Top Up", "Used E-Rickshaw", "Used Car Loan"] },
			{ api: "stats/product-stats-all-buckets", keyword:["Used Bike Loan", "Capital HL BC 2010", "Capital HL BC 2025", "EV Two Wheeler"] },
			{ api: "stats/product-stats-all-buckets", keyword:["Amrit LAP", "BHN LAP", "School Fee Loan"]},
			{ api: "stats/product-stats-all-buckets", keyword:["Consumer Durable Loan", "Two Wheeler", "E-Rickshaw"] },
		];
	
	const loanAccountTargetProductStats =
		[
			{ api: "stats/target-product-wise", keyword:["MSME", "LCV", "Used Commercial Vehical Loan", "Three Wheeler"] },
			{ api: "stats/target-product-wise", keyword:["Personal Loan Top Up", "Used E-Rickshaw", "Used Car Loan"] },
			{ api: "stats/target-product-wise", keyword:["Used Bike Loan", "Capital HL BC 2010", "Capital HL BC 2025", "EV Two Wheeler"] },
			{ api: "stats/target-product-wise", keyword:["Amrit LAP", "BHN LAP", "School Fee Loan"]},
			{ api: "stats/target-product-wise", keyword:["Consumer Durable Loan", "Two Wheeler", "E-Rickshaw"] },
		];
	
	const emiDueStats =
		[
			{ api: "stats/no-emi-received-stats"},
		];

	const getData = async (filters, api, keyword = null, type) => {
		try {
			let filter = { ...filters, keyword: keyword, type: type };

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				filter
			);

			if (response.data.data) {
				return response.data.data; // Return the fetched data
			} else {
				return {};
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const fetchData = async (arrayList, setState) => {
		try{
			const promises = arrayList.map(value => {
				return getData(filters, value.api, value.keyword, active);
			});
			const results = await Promise.all(promises);
			const combinedData = results.reduce((acc, data) => {
				return { ...acc, ...data };
			}, {});
			setState(combinedData);

		}
		catch (error) {
			console.error("Error in fetching data:", error);
		}
	};
	const fetchSingleData = async (api, setState, keywords = null, params = {}) => {
		try{
			await getData({...filters,...params}, api, keywords, active).then(results => {
				if (results) {
					setState(results);
				}
			});
		}
		catch (error) {
			console.error("Error in fetching data:", error);
		}
	};

	const getDataApi = filters => {
		setFilters({ ...filters, ...filters });
		if (filters?.branch_name?.length || filters?.state_name?.length) {
			setIsFiltered(true); // hide state section 
			if (filters.state_name?.length) {
				const stateNames = {}; 
				// getting selected states name
				filters.state_name.map(stateName => stateNames[stateName] = states_list.filter(state => state.state_enc_id === stateName)[0].name);
				
				const filteredBranches = branches_list.filter(branch => filters.state_name.includes(branch.state_enc_id)).map(value => {
					return {...value,
						state_name: stateNames[value.state_enc_id] // Assigning state name to branch 
					};
				});
				setBranchesOptions(filteredBranches);
			}
		} else {
			setIsFiltered(false);
		}
	};

	useEffect(() => {
		if (process.env.REACT_APP_TYPE !== "DEV" && !["phfleasing", "phf110", "phf1148", "DHIRAJSINGH", "phf1111", "ghuman", "divyajain", "Rachyita"].includes(user.username)) {
			history("/account/dashboard");
		}
		if (!states_list) {
			getStates();
		}
	}, []);

	const divideArrayList = (arrayList, api, type) => {
		const chunkSize = 3;
		let finalArr = [];
		if(arrayList){
			for (let i = 0; i < arrayList.length; i += chunkSize) {
				const chunk = arrayList.slice(i, i + chunkSize);
				let tempArr = [];
				chunk.map((value) => {tempArr.push(type === "states" ? value.name : value.location_enc_id);});
				let apiObj = {api: api, keyword: tempArr};
				finalArr = [...finalArr, apiObj];
			}
		}
		return finalArr;
	};
	
	useEffect(() => {
		if(active && states_list?.length){
			let arrayResult = divideArrayList(states_list, "stats/state-wise-loan-accounts", "states");
			let arrayResult2 = divideArrayList(states_list, "stats/first-six-emi-cases", "states");
			let arrayResult3 = divideArrayList(states_list, "stats/state-wise-overdue", "states");
			let arrayResult4 = divideArrayList(states_list, "stats/target-state-wise", "states");
			// setStatesArray(arrayResult);
			if(arrayResult?.length){
				fetchData(arrayResult, setStatesData);
			}
			if(arrayResult2?.length){
				fetchData(arrayResult2, setStatesDataFirstSix);
			}
			if(arrayResult3?.length){
				fetchData(arrayResult3, setOverdueData);
			}
			if(arrayResult4?.length){
				fetchData(arrayResult4, setTargetStatesData);
			}
		}
	}, [states_list, active]);
	
	useEffect(() => {
		let arrayResult = divideArrayList(branchesOptions, "stats/state-wise-branch-stats", "branches");
		let arrayResult2 = divideArrayList(branchesOptions, "stats/branch-wise-loan-accounts", "branches");
		// setBranchesArray(arrayResult);
		if(arrayResult?.length){
			fetchData(arrayResult, setBrachesData);
		}
		if(arrayResult2?.length){
			fetchData(arrayResult2, setBrachesLoanAccounts);
		}
	}, [branchesOptions, active]);

	useEffect(() => {
		const fetchAllData = async () => {
			if (filters.start_date && filters.end_date) {
				setLoading(true); 
				try {
					await Promise.all([
						fetchData(collectionStatsList, setAllData),
						fetchData(weeklyCollectionDepartmentList, setWeeklyData),
						fetchData(weeklyCollectionBucketList, setBucketData),
						fetchData(weeklyCollectionProdList, setProdData),
						fetchData(loanAccountProductStats, setLoanAccData),
						fetchData(loanAccountTargetProductStats, setTargetLoanAccData),
						fetchData(targetBucketList, setTargetBucketData),
						fetchData(emiDueStats, setEmiDueData),
						fetchSingleData(enachCount, setEnachData),
						fetchSingleData(unassignedCollectionOfficer, setUnassignedStates),
						fetchSingleData("stats/collection-priority-wise", setPriorityData),
						fetchSingleData("stats/total-enach-active-stats", setEnachStats),
						fetchSingleData("stats/total-enach-active-stats", setRegisteredEnach, null, {date_range:true}),
						fetchSingleData("stats/target-collection-stats", setTargetCollectionStats),
						fetchSingleData("stats/no-collection-portfolio-wise", setLastCollectionStats),
						fetchSingleData(emiCollectedStats, setCollectedEmiData),
						fetchData(totalNachProductStats, setTotalNachStats),
					]);
				} catch (error) {
					console.error("Error in fetching data:", error);
				}
				setLoading(false); 
			}
		};

		fetchAllData();		
	}, [filters, active]);



	return (
		<div>
			<Helmet>
				<title>Collection Dashboard</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<div className="dashboard-dsa position-relative">
				<div className="container-fluid">
					<div className="row py-6 mb-5 bg-white all">
						<div className="d-flex flex-wrap justify-content-between align-items-center">
							<h4 className="mb-0 mb-xs-3 ptp-heading">Collection Stats</h4>
							<div className="d-flex flex-wrap">
								<MultiDataFilters
									branches_list={branches_list}
									loan_types={loanAccountTypes}
									getData={getDataApi}
									type={["branches", "states"]}
									defaultDate={{ value: "3", label: "Today" }}
								/>
							</div>
						</div>

						<div className="mb-3">
							<EmiCollectionStats loan_types={loanAccountTypes} isPieFilters={true} piefilters={filters}/>
						</div>

						<Nav className="mb-4 users-nav px-3">
							<Nav.Item>
								<Nav.Link
									active={active === "overall"}
									onClick={() => {
										toggle("overall");
									}}
								>
									Overall
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									active={active === "sales-collection"}
									onClick={() => {
										toggle("sales-collection");
									}}
								>
									Sales & Collection
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									active={active === "call-center"}
									onClick={() => {
										toggle("call-center");
									}}
								>
									Call Center
								</Nav.Link>
							</Nav.Item>
						</Nav>
					
						{filters.start_date && filters.end_date ? (
							<>
								<div className="row">
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(allData).length 
											? Object.entries(allData).map(([key,  value], index) => {
												return (
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`collection-stats-${index}`}>
														{value.length ?  <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											})
											: "")}
								</div>
									
								<h4 className="text-center ptp-heading mt-3 mb-2">By Departments</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(weeklyData).length
										?  Object.entries(weeklyData).map(([key, value], index) => {
											return (
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`weekly-stats-${index}`}>
													{value.length ? <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										})
										: "")}
							

								<h4 className="text-center ptp-heading mt-3 mb-2">By Products</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(prodData).length
										?  Object.entries(prodData).map(([key, value], index) => {
											return (
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`prodData-stats-${index}`}>
													{value.length ? <PieChart title={key} allData={value}  /> : ""}
												</div>
											);
										})
										: "")} 

								<h4 className="text-center ptp-heading mt-3 mb-2">eNACH Stats</h4>	
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(		
									Object.keys(enachData).length
										?  Object.entries(enachData).map(([key, value], index) => {
											return (
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`encahData-stats-${index}`}>
													{value.length ? <PieChart title={key} allData={value}  /> : ""}
												</div>
											);
										})
										: "")} 	

								<h4 className="text-center ptp-heading mt-3 mb-2">EMI&#39;s Collected Today</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									    <div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">	
										{collectedEmiData.length ? <PieChart title="Collection" titleCapitalize={false} allData={collectedEmiData} />  : ""}
									</div>
								)}
							</>
						) : (
							""
						)}
					</div>

					{isFiltered && !filters?.branch_name?.length ?
						<div className="row px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all ">
							<>
								<h4 className="text-center mb-3 mt-4 ptp-heading">By Branches</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(brachesData).length
										? Object.entries(brachesData).map(([key, value], index) => {
											return(
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
													{value.length ? <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										}) : "")}
							</>
						</div>	
						: ""}
				
					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all ">
						<h4 className="mb-3 mt-4 ptp-heading">Loan Account Stats</h4>
						<h4 className="text-center ptp-heading mt-3 mb-2">By Products</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(loanAccData).length 
								? Object.entries(loanAccData).map(([key, value], index) => {
									return (
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ?  <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}) : "")
						}	

						<h4 className="text-center ptp-heading mt-3 mb-2">EMI Not Received</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(emiDueData).length 
								? Object.entries(emiDueData).map(([key, value], index) => {
									return (
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ?  <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}) : "")
						}	



						{!isFiltered ? (
							<>
								<h4 className="text-center mb-3 mt-4 ptp-heading">First Six EMI Cases</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(statesDataFirstSix).length ? 
										Object.entries(statesDataFirstSix).map(([key, value], index) => {
											return(
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
													{value.length ?  <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										}): "")}
							</>
						) : (
							""
						)} 
						{!isFiltered ? (
							<>
								<h4 className="text-center mb-3 mt-4 ptp-heading"> By States</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(statesData).length ? 
										Object.entries(statesData).map(([key, value], index) => {
											return(
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
													{value.length ?  <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										}): "")}
							</>
						) : (
							""
						)}
						<h4 className="text-center mb-3 mt-4 ptp-heading"> By Overdue Amount</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(overdueData).length ? 
								Object.entries(overdueData).map(([key, value], index) => {
									return(
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ? <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}): "")}
						{isFiltered && !filters?.branch_name?.length ?
							<div className="row px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all ">
								<>
									<h4 className="text-center mb-3 mt-4 ptp-heading">By Branches</h4>
									{loading?(
										<div className="row">
											{[...Array(4)].map((index) => (
												<>
													<SkeletonCard key={index} type={"pie_chart"}/>
												</>
											))}
										</div>
									):(
										Object.keys(brachesLoanAccounts).length
											? Object.entries(brachesLoanAccounts).map(([key, value], index) => {
												return(
													<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
														{value.length ? <PieChart title={key} allData={value} /> : ""}
													</div>
												);
											}) : "")}
								</>
							</div>	
							: ""}
					</div>
					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all ">
						{loading ? (
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							<>
								{unassignedStates.length ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Unassigned Collection Officer" allData={unassignedStates} />
									</div>
						 		: ""}
								{enachStats.length ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Total Active eNACH" titleCapitalize={false} allData={enachStats} />
									</div>
									: ""}
								{priorityData.length ? 
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Assigned Priorities" allData={priorityData} />
									</div>
									: ""}
								{registeredEnach.length ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Registered eNACH" titleCapitalize={false} allData={registeredEnach} />
									</div>
									: ""}
								{targetCollectionStats.length ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Today Target Collection" allData={targetCollectionStats} />
									</div>
									: ""}
								{lastCollectionStats && lastCollectionStats["30"] ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Last 30 days Collection" allData={lastCollectionStats["30"]} />
									</div>
									: ""}
								{lastCollectionStats && lastCollectionStats["45"] ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Last 45 days Collection" allData={lastCollectionStats["45"]} />
									</div>
									: ""}
								{lastCollectionStats && lastCollectionStats["60"] ?
									<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column">
										<PieChart title="Last 60 days Collection" allData={lastCollectionStats["60"]} />
									</div>
									: ""}
							</>
						)}
					</div>
					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all ">
						<h4 className="mb-3 mt-4 ptp-heading">eNACH Stats</h4>
						<h4 className="text-center ptp-heading mt-3 mb-2">By Products</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(totalNachStats).length
								? Object.entries(totalNachStats).map(([key, value], index) => {
									return (
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ?  <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}) : "")}
					</div>

					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all">
						{filters.start_date && filters.end_date ? (
							<>
								<h4 className="text-center ptp-heading mt-3 mb-2">Collection By Buckets</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(bucketData).length
										? Object.entries(bucketData).map(([key, value], index) => {
											return (
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
													{value.length ?  <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										})
										: "")}
							</>
						) : ""}
					</div>

					<div className="row py-6 px-6 px-xs-1-5 py-xs-1-5 mb-5 bg-white all">
						<h4 className="mb-3 mt-4 ptp-heading">Target vs Achieved</h4>
						<h4 className="text-center ptp-heading mt-3 mb-2">By Products</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(targetLoanAccData).length 
								? Object.entries(targetLoanAccData).map(([key, value], index) => {
									return (
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ?  <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}) : "")
						}	
						
						{filters.start_date && filters.end_date ? (
							<>
								<h4 className="text-center ptp-heading mt-3 mb-2">By Buckets</h4>
								{loading?(
									<div className="row">
										{[...Array(4)].map((index) => (
											<>
												<SkeletonCard key={index} type={"pie_chart"}/>
											</>
										))}
									</div>
								):(
									Object.keys(targetBucketData).length
										? Object.entries(targetBucketData).map(([key, value], index) => {
											return (
												<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
													{value.length ?  <PieChart title={key} allData={value} /> : ""}
												</div>
											);
										})
										: "")}
							</>
						) : ""}

						<h4 className="text-center mb-3 mt-4 ptp-heading"> By States</h4>
						{loading?(
							<div className="row">
								{[...Array(4)].map((index) => (
									<>
										<SkeletonCard key={index} type={"pie_chart"}/>
									</>
								))}
							</div>
						):(
							Object.keys(targetStatesData).length ? 
								Object.entries(targetStatesData).map(([key, value], index) => {
									return(
										<div className="d-flex align-items-center justify-content-center col-md-3 mb-5 flex-column" key={`bucket-stats-${index}`}>
											{value.length ?  <PieChart title={key} allData={value} /> : ""}
										</div>
									);
								}): "")}
					</div>
				</div>
			</div>
		</div>
	);
};
export default ReportsDashboard;
