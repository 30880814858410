import React, { useContext } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import NotFound from "../pages/NotFound";
import CandidateDashboard from "../pages/account/CandidateDashboard";
import OrganizationDashboard from "../pages/account/OrganizationDashboard";
import { MainContext } from "../context/MainState";
import SignUpPage from "../pages/SignUpPage";
import MedicalLoan from "../pages/account/MedicalLoan";
import TestPage from "../pages/account/TestPage";
import CustomerNotifications from "../pages/account/CustomerNotifications";
import ProMember from "../pages/account/ProMember";
import OrganizationESign from "../pages/account/OrganizationESign";
import OrganizationUsers from "../pages/account/OrganizationUsers";
import DsaUsers from "../pages/account/DsaUsers";
import CreateMyApp from "../pages/account/CreateMyApp";
import AppView from "../pages/account/AppView";
import CreateApps from "../pages/account/CreateApps";
import List from "../pages/account/apps/List";
import DiwaliOffersList from "../pages/account/DiwaliOffersList";
import MyRewards from "../pages/account/MyRewards";
import ReEmpowerForm from "../pages/account/ReEmpowerForm";
import VehicleList from "../pages/account/VehicleList";
import ReEmpowerMobile from "../pages/account/ReEmpowerMobile";
import MyProfile from "../pages/account/MyProfile";
import FinancerProfile from "../pages/account/FinancerProfile";
import MobilesList from "../pages/account/MobilesList";
import LoanApplications from "../pages/account/LoanApplications";
// import DefaultAreas from "../pages/account/DefaultAreas";
import CreditReportHistory from "../pages/account/CreditReportHistory";
import ReportSales from "../pages/account/ReportSales";
import EmiCollection from "../pages/account/EmiCollection";
import TvrApplications from "../pages/account/TvrApplications";
import PdApplications from "../pages/account/PdApplications";
import ReleasePaymentApplications from "../pages/account/ReleasePaymentApplications";
import BranchReport from "../pages/BranchReport";
import FiApplications from "../pages/account/FiApplications";
import Notifications from "../pages/Notifications";
import ApplicantDetail from "../pages/ApplicantDetail";
import LoanAccounts from "../pages/account/LoanAccounts";
import EmiPendingCollection from "../pages/account/EmiPendingCollection";
import EmiCollectionReport from "../pages/account/EmiCollectionReport";
import LoanProductReport from "../pages/LoanProductReport";
import DealersList from "../pages/account/DealersList";
import RepoRequests from "../pages/account/RepoRequests";
import LegalRequests from "../pages/account/LegalRequests";
import AccidentRequests from "../pages/account/AccidentRequests";
import HealthRequests from "../pages/account/HealthRequests";
import LoanApplicationCase from "../pages/account/LoanApplicationCase";
import DiscrepancyEmiCollection from "../pages/account/DiscrepancyEmiCollection";
import PartnerApplications from "../pages/account/PartnerApplications";
import RecoveryAccounts from "../pages/account/RecoveryAccounts";
import DealersSalesReport from "../pages/account/DealersSalesReport";
import CollectionReport from "../pages/account/CollectionReport";
import EmiPaymentLinks from "../pages/account/EmiPaymentLinks";
import CandidateLoanDetails from "../pages/account/CandidateLoanDetails";
import DailyCollectionReport from "../pages/account/DailyCollectionReport";
import EmandateRecords from "../pages/account/EmandateRecords";
import { OrganizationDashboardProvider } from "../context/OrganizationDashboardState";
import LoanAccountDetailPage from "../pages/LoanAccountDetailPage";
import CandidateLoanAccount from "../pages/CandidateLoanAccount";
import ReportsDashboard from "../pages/account/ReportsDashboard";
import RcPendingApplications from "../pages/account/RcPendingApplications";
import ExceptionReport from "../pages/account/ExceptionReport";
import NachRequests from "../pages/account/NachRequests";
import PtpApplications from "../pages/PtpApplications";
import DealerCollection from "../pages/DealerCollection";
import PickCash from "../pages/account/PickCash";
import ApplicationReportsDashboard from "../pages/account/ApplicationDashboard";
import TvSchemeLoanApplications from "../pages/account/TvSchemeLoanApplications";
import IncentivePointsReport from "../pages/account/IncentivePointsReport";
import MyLoans from "../components/MyLoans";
import IndividualProfile from "../components/IndividualProfile";
import IndividualDetails from "../pages/account/IndividualDetails";
import TestStats from "../pages/TestPage";
import TechnicalApplications from "../pages/account/TechnicalApplications";
import LegalApplications from "../pages/account/LegalApplications";

const ProtectedRoutes = () => {
	const { user } = useContext(MainContext);
	const params = useParams();
	const accountId = params && params["*"] && params["*"].split("/").pop();
	const emiArray = [
		"pending",
		"qr",
		"link",
		"POS",
		"cash",
		"cheque",
		"nach",
		"eNach",
		"net-banking",
		"RTGS-NEFT",
		"scanned-qr",
		"digital-transfer",
		"paid-to-dealer"
	];
	{
		if (user) {
			switch (user.user_type) {
			case "Financer":
			case user.user_type === "Employee" && user.organization_enc_id ? "Employee" : false:
				return (
					<OrganizationDashboardProvider>
						<Routes>
							<Route path="/dashboard" element={<OrganizationDashboard />} />
							<Route path="/reports/collection-dashboard" element={<ReportsDashboard />} />
							<Route path="/reports/sales-dashboard" element={<ApplicationReportsDashboard />} />
							<Route path="/reports/incentive-points" element={<IncentivePointsReport/>} />
							<Route path="/loan-applications/disbursed" element={<LoanApplicationCase />} />
							<Route path="/loan-applications/removed" element={<LoanApplicationCase />} />
							<Route path="/loan-applications/rejected" element={<LoanApplicationCase />} />
							<Route path="/offers/applications" element={<DiwaliOffersList />} />
							<Route path="/users" element={<OrganizationUsers />} />
							<Route path="/test-page" element={<TestStats />} />
							<Route path="/dealers" element={<DealersList />} />
							<Route path="/loan-accounts" element={<LoanAccounts />} />
							<Route path="/pick-cash" element={<PickCash />} />
							<Route path="/foreclosure-requests" element={<LoanAccounts />} />
							<Route path="/noc-requests" element={<LoanAccounts />} />
							<Route path="/initiate-requests" element={<LoanAccounts />} />
							<Route path="/recovery-loan-accounts" element={<RecoveryAccounts />} />
							<Route path="/repo-requests" element={<RepoRequests />} />
							<Route path="/legal-requests" element={<LegalRequests />} />
							<Route path="/accident-requests" element={<AccidentRequests />} />
							<Route path="/health-requests" element={<HealthRequests />} />
							<Route path="/e-signing" element={<TestPage />} />
							<Route path="/e-sign/:id" element={<TestPage />} />
							<Route path="/e-sign/list" element={<OrganizationESign />} />
							<Route path="/apps/create" element={<CreateMyApp />} />
							<Route path="/apps/view" element={<AppView />} />
							<Route path="/apps/create-app" element={<CreateApps />} />
							<Route path="/apps/list" element={<List />} />
							<Route path="/dealer-collection" element={<DealerCollection />} />
							<Route
								path="/product/:type"
								element={<LoanApplications />}
							/>
							<Route
								path="/portfolio/:type"
								element={<LoanApplications />}
							/>
							<Route
								path="/partner-applications"
								element={<PartnerApplications />}
							/>
							<Route path="/app/:id" element={<AppView />} />
							{/* <Route path="/negative-locations" element={<DefaultAreas />} />*/}
							<Route path="/credit-reports" element={<CreditReportHistory />} />
							<Route path="/emi-collection" element={<EmiCollection />} />
							<Route path="/emi-collection/discrepancy" element={<DiscrepancyEmiCollection />} />
							{emiArray.map((value, index) => {
								return (
									<Route
										key={`emiCollectionRoutes-${index}`}
										path={`/emi-collection/${value}`}
										element={<EmiPendingCollection />}
									/>
								);
							})}
							<Route path="/tvr-applications" element={<TvrApplications />} />
							<Route path="/fi-applications" element={<FiApplications />} />
							<Route path="/pd-applications" element={<PdApplications />} />
							<Route path="/technical-applications" element={<TechnicalApplications />} />
							<Route path="/legal-applications" element={<LegalApplications />} />
							<Route path="/tv-scheme-loan-applications" element={<TvSchemeLoanApplications />}/>
							<Route
								path="/release-payment-applications"
								element={<ReleasePaymentApplications />}
							/>
							<Route path="/notifications" element={<Notifications />} />
							<Route
								path={
									user.user_type === "Financer" ? "/settings" : "/my-profile"
								}
								element={
									user.user_type === "Financer" ? (
										<FinancerProfile />
									) : (
										<MyProfile />
									)
								}
							/>
							<Route path="/loan-application/:id" element={<ApplicantDetail />} />
							<Route path="/loan-account/:id" element={<LoanAccountDetailPage/>} />
							<Route path="/reports/sales" element={<ReportSales />} />
							<Route path="/reports/collection" element={<CollectionReport />} />
							<Route path="/reports/daily-collection" element={<DailyCollectionReport />} />
							<Route path="/reports/enach-registrations" element={<NachRequests />} />
							<Route path="/records/e-mandate" element={<EmandateRecords />} />
							<Route path="/reports/dealers" element={<DealersSalesReport />} />
							<Route path="/emi-payment-links" element={<EmiPaymentLinks />} />
							<Route path="/cash-in-hand" element={<EmiCollectionReport />} />
							<Route path="/reports/branches" element={<BranchReport />} />
							<Route path="/reports/loan-product" element={<LoanProductReport />} />
							<Route path="/reports/exception" element={<ExceptionReport />} />
							<Route path="/rc-pending-applications" element={<RcPendingApplications />} />
							<Route path="/ptp-applications" element={<PtpApplications />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					</OrganizationDashboardProvider>
				);
			case "Individual":
			case "Employee":
				return (
					<>
						<Routes>
							<Route path="/dashboard" element={<CandidateDashboard />} />
							<Route path="/medical-loan-apply" element={<MedicalLoan />} />
							<Route
								path="/customer-notifications"
								element={<CustomerNotifications />}
							/>
							<Route path="/loan-application/:id" element={<CandidateLoanDetails />} />
							<Route path="/loan-account/:id" element={<CandidateLoanAccount/>} />
							<Route path="/my-rewards" element={<MyRewards />} />
							<Route path="/pro-membership" element={<ProMember />} />
							<Route path="/app/:id" element={<AppView />} />
							<Route path="/my-profile" element={<MyProfile />} />
							<Route path="/notifications" element={<Notifications />} />
							<Route path="/my-loans" element={<MyLoans />} />
							<Route path="/loan-details/:id" element={<IndividualDetails />} />
							<Route path="/individual-profile/" element={<IndividualProfile />} />
							{/* <Route path="/e-signing" element={<TestPage/>}/>*/}
							<Route path="*" element={<NotFound />} />
							{/* <Route exact path="/sign-up" render={() => (<Redirect to="/"/>)}/>*/}
						</Routes>
					</>
				);
			case "Lender Auditor":
				return (
					<>
						<OrganizationDashboardProvider>
							<Routes>
								<Route path="/dashboard" element={<LoanApplicationCase type="disbursed"/>} />
								<Route path="/loan-application/:id" element={<ApplicantDetail />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</OrganizationDashboardProvider>
					</>
				);
			case "DSA":
				return (
					<>
						<Routes>
							<Route path="/dashboard" element={<OrganizationDashboard />} />
							<Route path="/users" element={<DsaUsers />} />
							<Route path="/app/:id" element={<AppView />} />
							<Route path="/my-profile" element={<MyProfile />} />
							<Route path="/notifications" element={<Notifications />} />
							{/* <Route path="/e-signing" element={<TestPage/>}/>*/}
							{/* <Route path="/dashboard" element={<Dashboard/>}/>*/}
							{/* <Route exact path={["/sign-up", "/reset-password/:id"]}*/}
							{/*       render={() => (<Redirect to="/"/>)}/>*/}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</>
				);
			case "Connector":
				return (
					<>
						<Routes>
							<Route path="/dashboard" element={<OrganizationDashboard />} />
							<Route path="/app/:id" element={<AppView />} />
							<Route path="/my-profile" element={<MyProfile />} />
							<Route path="/notifications" element={<Notifications />} />
							{/* <Route path="/e-signing" element={<TestPage/>}/>*/}
							{/* <Route path="/dashboard" element={<Dashboard/>}/>*/}
							{/* <Route exact path={["/sign-up", "/reset-password/:id"]}*/}
							{/*       render={() => (<Redirect to="/"/>)}/>*/}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</>
				);
			case "Dealer":
				return (
					<>
						<Routes>
							<Route path="/dashboard" element={<OrganizationDashboard />} />
							<Route path="/re-empower/add-vehicle" element={<ReEmpowerForm />} />
							<Route
								path="/re-empower/vehicle/:slug"
								element={<ReEmpowerForm />}
							/>
							<Route path="/re-empower/vehicles" element={<VehicleList />} />
							<Route path="/re-empower/mobiles" element={<MobilesList />} />
							<Route path="/app/:id" element={<AppView />} />
							<Route path="/notifications" element={<Notifications />} />
							<Route
								path="/re-empower/add-mobile"
								element={<ReEmpowerMobile />}
							/>
							<Route
								path="/re-empower/mobile/:slug"
								element={<ReEmpowerMobile />}
							/>
							<Route path="/my-profile" element={<MyProfile />} />
							{/* <Route path="/e-signing" element={<TestPage/>}/>*/}
							{/* <Route path="/dashboard" element={<Dashboard/>}/>*/}
							{/* <Route exact path={["/sign-up", "/reset-password/:id"]}*/}
							{/*       render={() => (<Redirect to="/"/>)}/>*/}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</>
				);
			default:
				return (
					<>
						<Routes>
							{/* <Route path="/e-signing" element={<TestPage/>}/>*/}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</>
				);
			}
		} else {
			return (
				<>
					<Routes>
						{/* <Route path="/sign-up" element={SignUp} exact/>*/}
						{/* <Route path="/reset-password/:id" element={ResetPassword} exact/>*/}
						<Route path="/signup/dsa/:id" element={<SignUpPage />} />
						<Route path="/loan-account/:id" element={<Navigate to={`/loan-account/${accountId}`}/>} /> 
						<Route path="*" element={<Navigate to="/login" />} />
					</Routes>
				</>
			);
		}
	}
};
export default ProtectedRoutes;
