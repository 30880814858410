import React, { useContext, useEffect, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { MainContext } from "../../context/MainState";
import EmployeeLoanList from "./EmployeeLoanList";
import axios from "axios";
import NameComp from "../NameComp";
import MinMaxFilter from "../MinMaxFilter";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import MultiDataFilters from "../MultiDataFilters";
import { toast } from "react-toastify";
import { BiRupee } from "react-icons/bi";


const EmployeeSalesTableByAmount = ({callback = () => {return true;}, type = "", pageType = ""}) => {
	const { getEmployeeData,  employeeData } = useContext(OrganizationUsersContext);
	const { branches_list, states_list, financer_assigned_loan_types, getFinancerAssignedLoanTypes, departmentList, getDesignationList, designationList} = useContext(OrganizationDashboardContext);
	const {loan_products} = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	// const [searchValue, setSearchValue] = useState("");
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [sortArr, setSortArr] = useState({});
	// const [selectedUser, setSelectedUser] = useState('')
	const [userLoans, setUserLoans] = useState([]);
	const [userResponse, setUserResponse] = useState("");
	const [pageLoading, setPageLoading] = useState(true);
	const [selectedRow, setSelectedRow] = useState({
		value: "",
		status: "",
		page: 1
	});
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		product_id: "",
		loan_type_enc_id:"",
		field: "",
		order_by: ""
	});

	const openSideBar = () => {
		setShowSidebar(true);
		// setLoading(true);

		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}
		getEmployeeLoanApps();
	};

	const getEmployeeLoanApps = () => {
		setLoading(true);
		let data = {
			user_enc_id: selectedRow.value.user_enc_id,
			status: selectedRow.status,
			page: selectedRow.page,
			start_date: filters.start_date + " 00:00:00",
			end_date: filters.end_date + " 23:59:59",
			product_id: filters.loan_product,
			loan_type_enc_id: filters.loan_product_portfolio,
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/employee-loan-list`,
				data
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setUserLoans(prev => [...prev, ...response.data.response.loanDetails]);
					setUserResponse(response.data.response);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(error.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (selectedRow.value) {
			openSideBar(selectedRow.value.user_enc_id, selectedRow.status, selectedRow.value);
		}
	}, [selectedRow]);

	useEffect(() => {
		if( employeeData && employeeData[type]){
			setPageLoading(false);
		}
	},[ employeeData]);

	const handleClose = () => {
		setShowSidebar(false);
		setSelectedRow({ value: "", status: "", page: "" });
		setUserResponse("");
		setUserLoans([]);

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize d-flex align-items-center cursor-point" onClick={() => setSelectedRow({ value: row, status: [], page: 1 })}>
						<img
							src={row.employee_image}
							alt={row.employee_name}
							className="img-fluid rounded-50 mr-1"
							style={{  width: 25, height:25, objectFit: "cover" }}
						/>
						{row.employee_name}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Employee Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					departmentList={departmentList}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.department ? row.department : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}	
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
					designations={designationList}
				/>
			),
			minWidth: "200px",
			selector: row => (row.designation ? row.designation : "--"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.reporting_person ? row.reporting_person : "--"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}	
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "240px",
			selector: row => (row.branch_name ? row.branch_name : "--"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					states={states_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "160px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<MinMaxFilter
					title={"Incentive Points"}
					id={"incentive_points"}
					placeholder="Incentve Points"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "120px",
			sortable: false,
			cell: row => {
				return (
					<div>{row.incentive_points ? row.incentive_points : "-"}</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Total Cases"}
				id={"total_cases"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			cell: row => {
				return (
					<div
						// onClick={() => setSelectedRow({ value: row, status: [], page: 1 })}
					>
						{/* {parseInt(row.total_cases)} */}
						<BiRupee size={16} />{row.total_cases
							? parseFloat(row.total_cases).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			},
			sortable: false
		},
		// {
		//     name: 'Active',
		//     selector: row => row.active ? row.active : '-',
		//     sortable: false,
		// },
		{
			name: <MinMaxFilter
				title={"New Lead"}
				id={"new_lead"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			// selector: row => row.new_lead ? row.new_lead : '-',
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({ value: row, status: ["New Lead"], page: 1 })
						// }
					>   
						<BiRupee size={16} />{row.new_lead
							? parseFloat(row.new_lead).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Login"}
				id={"login"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			selector: row => row.login ? row.login : "-",
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({ value: row, status: ["Login"], page: 1 })
						// }
					>   
						<BiRupee size={16} />{row.login
							? parseFloat(row.login).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Under Process"}
				id={"under_process"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			selector: row => (row.under_process ? row.under_process : "-"),
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({ value: row, status: ["Under Process"], page: 1 })
						// }
					> 
						<BiRupee size={16} />{row.under_process
							? parseFloat(row.under_process).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Sanctioned"}
				id={"sanctioned"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			selector: row => (row.sanctioned ? row.sanctioned : "-"),
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({ value: row, status: ["Sanctioned"], page: 1 })
						// }
					>     
						<BiRupee size={16} />{row.sanctioned
							? parseFloat(row.sanctioned).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Disbursed"}
				id={"disbursed"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			selector: row => (row.disbursed ? row.disbursed : "-"),
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({ value: row, status: ["Disbursed"], page: 1 })
						// }
					>         
						<BiRupee size={16} />{row.disbursed
							? parseFloat(row.disbursed).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
		{
			name: <MinMaxFilter
				title={"Rejected/CNI"}
				id={"rejected"}
				placeholder="Cases"
				searchArray={searchArray}
				setSearchArray={setSearchArray}
				sortArr={sortArr}
				setSortArr={setSortArr}
				sortType="numeric"
				clearFilters={clearFilters}
			/>,
			minWidth: "160px",
			selector: row => (row.rejected ? row.rejected : "-"),
			sortable: false,
			cell: row => {
				return (
					<div
						// onClick={() =>
						// 	setSelectedRow({
						// 		value: row,
						// 		status: ["Rejected", "CNI"],
						// 		page: 1
						// 	})
						// }
					>
						<BiRupee size={16} />{row.rejected
							? parseFloat(row.rejected).toLocaleString("en-IN")
							: "0"}
					</div>
				);
			}
		},
	];
	const handlePagination = page => {
		setPageLoading(true);
		getEmployeeData({
			page: page.selected + 1,
			limit: rowsPerPage,
			keyword: searchArray,
			start_date: filters.start_date,
			end_date: filters.end_date,
			product_id: filters.loan_product,
			loan_type_enc_id: filters.loan_product_portfolio,
			fields_search: searchArray,
			order_by: filters.order_by,
			pageType: "employee_amount_sales",
			fields_sort: sortArr,
			data_type:type,
			page_type: pageType
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setPageLoading(true);
		getEmployeeData({
			page: 1,
			limit: parseInt(e.target.value),
			keyword: searchArray,
			start_date: filters.start_date,
			end_date: filters.end_date,
			product_id: filters.loan_product,
			loan_type_enc_id: filters.loan_product_portfolio,
			fields_search: searchArray,
			order_by: filters.order_by,
			pageType: "employee_amount_sales",
			fields_sort: sortArr,
			data_type:type,
			page_type: pageType
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
		setPageLoading(true);
		setSortArr({});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = employeeData && parseInt(employeeData[type].count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if ( employeeData &&  employeeData[type].data.length > 0) {
			return  employeeData[type].data;
		} else if ( employeeData &&  employeeData[type].data.length === 0 && isFiltered) {
			return [];
		} else {
			return  employeeData &&  employeeData[type].data.length > 0
				?  employeeData[type].data.slice(0, rowsPerPage)
				: [];
		}
	};

	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	useEffect(() => {
		if (!designationList) {
			getDesignationList();
		}
		if (!financer_assigned_loan_types) {
			getFinancerAssignedLoanTypes();
		}
	}, []);

	useEffect(() => {
		if (searchArray && filters.start_date && filters.end_date) {
			setPageLoading(true);
			if(filters){
				callback(filters);
			}
			const timerId = setTimeout(() => {
				getEmployeeData({
					page: 1,
					limit: rowsPerPage,
					// keyword: searchValue,
					start_date: filters.start_date,
					end_date: filters.end_date,
					product_id: filters.loan_product,
					loan_type_enc_id: filters.loan_product_portfolio,
					fields_search: searchArray,
					order_by: filters.order_by,
					fields_sort: sortArr,
					data_type:type,
		         	page_type: pageType
				});
				setCurrentPage(1);
			}, 1000);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [filters, searchArray, sortArr]);

	const getDataApi = (filters) => {
		setPageLoading(true);
		setFilters({...searchArray, ...filters});
	};

	// useEffect(() => {
	// 	if (filters.start_date && filters.end_date) {
	// 		getSalesReportStats({
	// 			start_date: filters.start_date + " 00:00:00",
	// 			end_date: filters.end_date + " 23:59:59",
	// 			product_id: filters.loan_product,
	// 			loan_type_enc_id: filters.loan_product_portfolio,
	// 		});
	// 	}
	// }, [filters]);

	return (
		<>
			<Row className="mx-0 mt-1 mb-5">
				<Col sm="10">
					<div className="d-flex align-items-center py-2 flex-wrap">
						<div className="form-group" style={{marginRight: 10}}>
							<label htmlFor="float-text" className="floating-label fw400 ft14 fs-5">
								Show Results
							</label>
							<select
								className="dataTable-select form-select"
								style={{ width: "95px", padding: "2px 8px", height: "48px" }}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
						</div>
						<MultiDataFilters
							branches_list={branches_list}
							loan_product_portfolio={financer_assigned_loan_types}
							loan_products={loan_products}
							getData={getDataApi}
							type={["LoanProductPortfolio", "LoanProduct"]}
						/>
					</div>
				</Col>
				<Col className="d-flex align-items-center justify-content-end" sm="2">
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				onSort={handleSort}
				paginationComponent={CustomPagination}
				progressPending={pageLoading}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={
					<p className="w-100 m-5 text-start">There are no records to display</p>
				}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow report-tables z-0"
				fixedHeader={true}
			/>
			<EmployeeLoanList
				showSidebar={showSidebar}
				handleClose={handleClose}
				userData={selectedRow.value}
				userResponse={userResponse}
				userLoans={userLoans}
				loading={loading}
				setSelectedRow={setSelectedRow}
				selectedRow={selectedRow}
			/>
		</>
	);
};
export default EmployeeSalesTableByAmount;
