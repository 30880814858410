import { useContext, useEffect, useState } from "react";
import SmsFrequencyModal from "./dashboard/SmsFrequencyModal";
import axios from "axios";
import SmsFrequencyTable from "./SmsFrequencyTable";
import { MainContext } from "../context/MainState";


const SmsSettings = () => {
	const [modalShow, setModalShow] = useState({show: false, type: ""});
	const [smsTemplates, setSmsTemplates] = useState("");
	const [frequencyData, setFrequencyData] = useState("");
	const {loan_products} = useContext(MainContext);
	const [editFrequencyData, setEditFrequencyData] = useState("");
	const [editFrequencyType, setEditFrequencyType] = useState("");

	const getSmsTemplates = () => {
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}sms/sms-template-list`).then(
			response => {
				if(response.data.response.status === 200){
					setSmsTemplates(response.data.response.posts);
				}
			}
		);
	};

	const getSmsFrequencyList = () => {
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}sms/sms-logs-list`).then(
			response => {
				if(response.data.response.status === 200){
					setFrequencyData(response.data.response.data);
				}
			}
		);
	};

	const reloadData = () => {
		getSmsFrequencyList();
	};

	useEffect(() => {
		if(!smsTemplates){
			getSmsTemplates();
		}
		getSmsFrequencyList();
	}, []);
	
	const hideModal = () => {
		setModalShow({show: false, type: ""});
		setEditFrequencyData("");
		setEditFrequencyType("");
	};

	useEffect(() => {
		if(editFrequencyData && editFrequencyType){
			setModalShow({show: true, type: editFrequencyType});
		}
	}, [editFrequencyData, editFrequencyType]);
	return (
		<>
			<h2 className="mb-5">SMS Settings</h2>
			<h4>Message Templates</h4>
			{smsTemplates ? (
				<div className="row">
					{smsTemplates.map((value, index) => {
						return (
							<div className="col-md-12" key={`sms-${index}`}>
								<div className="sms-box">
									<p>{value.content}</p>
								</div>
							</div>
						);
					})}
				</div>
			) : ""}

			<h3 className="mt-5">Message Frequencies</h3>
			<div className="d-flex justify-content-between align-item-center">
				<h4>By Bucket</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "bucket"})}>Add Bucket Frequency</button>
			</div>
			<div className="mb-5">
				<SmsFrequencyTable 
					tableData={frequencyData?.Bucket}
					templates={smsTemplates}
					loan_products={loan_products}
					reloadData={reloadData}
					setEditFrequencyData={setEditFrequencyData}
					setEditFrequencyType={setEditFrequencyType}
					type="bucket"
				/>
			</div>

			<div className="d-flex justify-content-between align-item-center">
				<h4>By EMI Date</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "emi"})}>Add EMI Date Frequency</button>
			</div>
			<div className="mb-5">
				<SmsFrequencyTable 
					tableData={frequencyData?.Emi}
					templates={smsTemplates}
					loan_products={loan_products}
					reloadData={reloadData}
					setEditFrequencyData={setEditFrequencyData}
					setEditFrequencyType={setEditFrequencyType}
					type="emi"
				/>
			</div>

			<div className="d-flex justify-content-between align-item-center">
				<h4>By Overdue</h4>
				<button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow({show: true, type: "overdue"})}>Add Overdue Frequency</button>
			</div>

			<div className="mb-5">
				<SmsFrequencyTable 
					tableData={frequencyData?.Overdue} 
					templates={smsTemplates}
					loan_products={loan_products}
					reloadData={reloadData}
					setEditFrequencyData={setEditFrequencyData}
					setEditFrequencyType={setEditFrequencyType}
					type="overdue"
				/>
			</div>

			<SmsFrequencyModal 
				onHide={hideModal} 
				show={modalShow.show} 
				type={modalShow.type} 
				templates={smsTemplates}
				reloadData={reloadData}
				editFrequencyData={editFrequencyData}
			/>
		</>
	);
};

export default SmsSettings;
