import React, { useContext, useEffect, useState } from "react";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import NameComp from "../NameComp";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdDelete, MdOutlineQrCode2 } from "react-icons/md";
import EmiCollectionForm from "./EmiCollectionForm";
import Geocode from "react-geocode";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { Col, Modal } from "react-bootstrap";
import CollectionManagerModal from "./CollectionManagerModal";
import { BsPersonFillAdd } from "react-icons/bs";
import AutoComplete from "../forms/AutoComplete";
import { useForm } from "react-hook-form";
import { IoCloseCircle} from "react-icons/io5";
import MinMaxFilter from "../MinMaxFilter";

const LoanAccountPtpTable = ({ type = null, pageType= null }) => {
	const { user, handlePageLoaded, loanAccountTypes } = useContext(MainContext);
	const {
		getPtpCases,
		ptpcases,
		branches_list,
		states_list
	} = useContext(OrganizationDashboardContext);
	const { handleSubmit, setValue, reset, watch, getValues } = useForm();
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [loanAccountNumber, setLoanAccountNmber] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [vals, setVals] = useState({ latitude: "" });
	const [loanAccountId, showLoanSideBar] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [qrId, setQrId] = useState("");
	const [qr, setQr] = useState("");
	const [sharedModal, setSharedModal] = useState(false);
	const [ptpDataLoading, setPtpDataLoading] = useState(false);
	const [ptpDateStats, setPtpDateStats] = useState({});
	const [ptpProductStats, setPtpProductStats] = useState({});
	const [bdoModal, setBdoModal] = useState(false);
	const [removeBtn, setRemoveBtn] = useState(false);
	const [finalUsers, setFinalUsers] = useState([]);
	const [reloadSidebar, setReloadSidebar] = useState(false);
	const [loanAccountSidebarId, setLoanAccountId] = useState("");
	let toBeSharedName = watch("name");
	const toBeShared = watch("shared_to");
	const [showClearBtn, setShowClearBtn] = useState(false);


	const location = type => {
		if ("geolocation" in navigator) {
			if (type === "emi") {
				setShowEmiModal(true);
			}
			navigator.geolocation.getCurrentPosition(type !== "emi" ? showPosition : success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};
	
	const showPosition = pos => {
		var crd = pos.coords;
		GenerateQrCode({
			latitude: crd.latitude,
			longitude: crd.longitude
		});
	};

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postal_code: postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};

	const fetchmylocation = type => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location(type);
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location(type);
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};

	const handleShowApplyLoan = type => {
		fetchmylocation(type);
	};

	const handleCloseEmi = () => {
		setLoanAccountNmber("");
		setShowEmiModal(false);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	const GenerateQrCode = id => {
		if (!window.confirm("Are you sure you want to generate QR code")) {
			return false;
		}
		const formData = new FormData();
		handlePageLoaded(true);
		formData.append("emi_collection_enc_id", qrId);
		formData.append("brand", user.organization_name);
		formData.append("latitude", id.latitude);
		formData.append("longitude", id.longitude);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/ptp-emi`,
				formData
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("QR Code Generated Successfully");
					setQr({ id: true, QR: response.data.response.links.qr });
					reloadDetails();
					setQrId("");
				} else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error occurred"
					);
				}
			})
			.catch(error => {
				setQrId("");
				handlePageLoaded(false);
				toast.error("Server Error Occured: " + error.message);
			});
	};

	const onSubmit = async data => {
		if (bdoModal === "bdo") {
			const alreadyExist = finalUsers.filter(user => user.shared_to === data.shared_to);
			if (alreadyExist.length) {
				reset();
				toast.error("Already Selected");
				return false;
			}
			setFinalUsers([...finalUsers, data]);
			reset();
		} else {
			assignBdo(bdoModal, data);
		}
	};
 
	const assignBdo = (type, userData = null) => {
		const data = {};
		const bdos = [];
		if (type === "bdo") {
			finalUsers.forEach(vals => bdos.push({ id: vals.shared_to, access: vals.access }));
		} else {
			bdos.push({ id: userData.shared_to });
		}
		data["loan_accounts"] = [loanAccountSidebarId];
		data["users"] = bdos;
		data["type"] = type;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-loan-accounts`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					setReloadSidebar(new Date());
					setLoanAccountId("");
					toast.success("Submit Successfully");
					resetBdoAssign();
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error(error.message);
			});
	};

	const handleBdoRemoved = id => {
		const tempAll = finalUsers.filter(list => list.shared_to !== id);
		setFinalUsers(tempAll);
	};

	const handleSidebarModal = val => {
		setBdoModal(val.type);
		setLoanAccountId(val.id);
	};

	const resetBdoAssign = () => {
		setFinalUsers([]);
		setBdoModal(false);
		reloadDetails();
	};

	const hideBdoModal = () => {
		setLoanAccountId("");
		reset();
		setBdoModal(false);
		setFinalUsers([]);
	};

	useEffect(() => {
		if (qrId !== "") {
			fetchmylocation(qrId);
		}
	}, [qrId]);

	useEffect(() => {
		if (toBeShared) {
			onSubmit(getValues());
		}
	}, [toBeShared]);

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "260px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return (
					<>
						<div className="justify-content-between d-flex align-content-center w-100">
							<span
								className="me-2 text-link"
								onClick={() =>
									showLoanSideBar({
										loan_account_enc_id: row.loan_account_enc_id,
										loan_account_number: row.loan_account_number,
										assignedLoanAccounts: row.assignedLoanAccounts
									})
								}
							>
								{row.loan_account_number ? row.loan_account_number : "-"}
							</span>
							<div className="d-flex align-items-center">
								<button
									type="button"
									className="btn-table-emi"
									onClick={() => {
										handleShowApplyLoan("emi");
										setLoanAccountNmber({
											loan_account_enc_id: row.loan_account_enc_id,
											loan_account_number: row.loan_account_number
										});
									}}
								>
									Collect EMI
								</button>
							</div>
						</div>
					</>
				);
			},
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.name ? row.name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() =>
							showLoanSideBar({
								loan_account_enc_id: row.loan_account_enc_id,
								loan_account_number: row.loan_account_number,
								assignedLoanAccounts: row.assignedLoanAccounts
							})
						}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			maxWidth: "155px",
			selector: row => (row.sub_bucket ? <b>{row.sub_bucket === "0" ? "X" : row.sub_bucket}</b> : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp 
					title={"Collection Executive"} 
					id={"collection_executive"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.collection_manager ? row.collection_manager : "-"),
			sortable: false,
			cell: row => {
				return (
					<div className="list-data d-inline-block p-2">
						{row.collection_manager && row.collection_manager.length
							? row.collection_manager.map((value, index) => {
								return (
									<span
										key={`collection-manager-${row.loan_app_enc_id}${index}`}
									>
										{value}
									</span>
								);
							})
							: "-"}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "170px"
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					branches={ branches_list ? branches_list : []}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list ? states_list : []}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name:( 
				<MinMaxFilter
					title={"PTP Amount"} 
					id={"proposed_amount"} 
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.proposed_amount ? row.proposed_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{parseFloat(row.proposed_amount).toLocaleString("en-IN")}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Date"}
					id={"proposed_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "170px",
			selector: row => (row.proposed_date ? row.proposed_date : "-"),
			sortable: false,
			cell: row => {
				return (
					<div>
						{row.proposed_date ? (
							<>
								{moment(row.proposed_date).format("DD MMM YYYY")}{" "}
								<button
									onClick={() => setQrId(row.emi_collection_enc_id)}
									className="btn btn-primary btn-xs m-0 mx-1 px-2"
									title="Generate QR Code"
								>
									<MdOutlineQrCode2 />
								</button>
							</>
						) : (
							"-"
						)}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"PTP Payment Method"}
					id={"proposed_payment_method"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.total_installments ? row.total_installments : "-"),
			sortable: false,
			cell: row =>
				row.proposed_payment_method && row.proposed_payment_method === "1"
					? "Cash"
					: row.proposed_payment_method && row.proposed_payment_method === "2"
						? "Online"
						: "-"
		},
		{
			name: (
				<MinMaxFilter
					title={"Overdue EMI"}
					id={"overdue_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortType="numeric"
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.overdue_amount ? row.overdue_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.overdue_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.overdue_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"POS"}
					id={"pos"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortType="numeric"
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.pos ? row.pos : "-"),
			omit: !(user.user_type === "Financer" || ["monika200", "phf1136"].includes(user.username)),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.pos ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} /> {parseFloat(row.pos).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"EMI Date"}
					id={"emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					searchFrom="loan_account"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => moment(row.emi_date).valueOf(),
			sortable: false,
			cell: row => (row.emi_date ? moment(row.emi_date).format("DD") : "-")
		},
		{
			name: (
				<MinMaxFilter
					title={"EMI Amount"}
					id={"emi_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.emi_amount ? row.emi_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emi_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.emi_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Amount"}
					id={"last_emi_received_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "190px",
			selector: row => row.last_emi_received_amount,
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{row.emiCollectionEnc
									? parseFloat(row.emiCollectionEnc.amount).toLocaleString(
										"en-IN"
									  )
									: parseFloat(row.last_emi_received_amount).toLocaleString(
										"en-IN"
									  )}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Date"}
					id={"last_emi_received_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row =>
				row.last_emi_received_date ? moment(row.last_emi_received_date).valueOf() : 0,
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emiCollectionEnc
							? moment(row.emiCollectionEnc.collection_date).format("DD MMM")
							: row.last_emi_received_date
								? moment(row.last_emi_received_date).format("DD MMM")
								: "-"}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Initiated On"}
					id={"created_on"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "190px",
			selector: row => moment(row.created_on).valueOf(),
			sortable: false,
			cell: row => {
				return (
					row.created_on ? moment(row.created_on).format("DD MMM YYYY h:mm a") : "-"
				);
			}
		},
		// {
		// 	name:(
		// 		<NameComp
		// 			title={"Pending Amount"}
		// 			id={"total_pending_amount"}
		// 			searchArray={searchArray}
		// 			setSearchArray={setSearchArray}
		// 		/>
		// 	),
		// 	minWidth: "150px",
		// 	selector: "",
		// 	sortable: false,
		// 	cell: row => {
		// 		return (
		// 			<>
		// 				<h6 className="mb-0 emiAmt">
		// 					<BiRupee size={16} />{" "}
		// 					{parseFloat(row.total_pending_amount).toLocaleString("en-IN")}
		// 				</h6>
		// 			</>
		// 		);
		// 	}
		// },
		{
			name: (
				<NameComp
					title={"Assigned Telecaller"}
					id={"assigned_caller"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			selector: row => row.assigned_caller,
			sortable: false,
			cell: row => (row.assigned_caller ? row.assigned_caller : "-")
		},
		{
			name: (
				<NameComp
					title={"Assigned BDO"}
					id={"assigned_bdo"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								{row.assignedLoanAccounts && row.assignedLoanAccounts.length > 0 ? (
									<div className="register-count">
										<div
											className="reg-img"
											onClick={() => setSharedModal({ ...row, hide: "CM" })}
										>
											{row.assignedLoanAccounts
												.filter(name => name.user_type === "bdo")
												.map((value, index) => {
													if (index <= 1) {
														return (
															<span
																className={`reg${index + 1} reg`}
																key={`accessList-${index}`}
															>
																<img src={value.image} />
															</span>
														);
													}
												})}
										</div>
										{row.assignedLoanAccounts.filter(
											name => name.user_type === "bdo"
										).length > 2 ? (
												<span style={{ color: "#00a0e3", fontSize: "13px" }}>
													{" "}
											+
													{row.assignedLoanAccounts.filter(
														name => name.user_type === "bdo"
													).length - 2}{" "}
												</span>
											) : (
												""
											)}
									</div>
								) : ""}
								{row.loan_account_enc_id !== null ? (
									<span className="cont cursor-point" onClick={() => {setBdoModal("bdo"), setLoanAccountId(row.loan_account_enc_id);}}>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								): "-"}
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Collection Manager"}
					id={"collection_manager"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								{row.assignedLoanAccounts && row.assignedLoanAccounts.length > 0 ? (
									<div
										className="register-count">
										<div className="reg-img" onClick={() => setSharedModal({...row, hide: "BDO"})}>
											{row.assignedLoanAccounts.filter(name => name.user_type === "collection_manager").map((value, index) => {
												if (index <= 1) {
													return (
														<span
															className={`reg${index + 1} reg`}
															key={`accessList-${index}`}
														>
															<img src={value.image} />
														</span>
													);
												}
											})}
										</div>
										{row.assignedLoanAccounts.filter(name => name.user_type === "collection_manager").length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
											+{row.assignedLoanAccounts.filter(name => name.user_type === "collection_manager").length - 2}{" "}
											</span>
										) : (
											""
										)}
										
									</div>
								):""}
								{row.loan_account_enc_id !== null ? (
									<span className="cont cursor-point" onClick={() => {setBdoModal("collection_manager"), setLoanAccountId(row.loan_account_enc_id);}}>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								) : "-"}
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
	];

	const handlePagination = page => {
		setPtpDataLoading(true);
		getPtpCases({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: type
		});
		setCurrentPage(page.selected + 1);
	};

	const reloadDetails = () => {
		clearFilter();
		setPtpDataLoading(true);
		getPtpCases({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: type
		});
	};

	const getPtpStats = data => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/upcoming-ptp-stats`,
				data
			)
			.then(response => {
				if (response?.data?.response?.status === 200) {
					setPtpDateStats(response.data.response.date_stats);
					setPtpProductStats(response.data.response.product_stats);
				} else {
					toast.error("An error has occured while getting ptp stats, Please try again");
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = ptpcases ? parseInt(ptpcases.count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};
	useEffect(() => {
		if (Object.keys(searchArray).length) {
			const timerId = setTimeout(() => {
				setRowsPerPage(25);
				setCurrentPage(1);
				setPtpDataLoading(true);
				getPtpCases({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					type: type
				});
				getPtpStats({
					fields_search: searchArray
				});
			}, [1000]);
			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray]);

	const loadPtpCases = () => {
		setPtpDataLoading(true);
		getPtpCases({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: type
		});
		setRemoveBtn(true);
	};

	useEffect(() => {
		getPtpStats();
		if (user.user_type === "Employee" || pageType === "ptpPage") {
			loadPtpCases();
		}
	}, []);
	useEffect(() => {
		if (ptpcases) {
			setPtpDataLoading(false);
		}
	}, [ptpcases]);
	return (
		<>
			<div className={type === null ? "shadow-lg rounded-3 py-4 px-6 bg-white" : ""}>
				<div className={`d-flex justify-content-${type === null ? "between":"end"} ${!ptpcases?.data.length && !removeBtn && type !== null ? "mt-negative-4 mobile-no-margin" : ""}`}>
					{type === null ? (<h2 className="ptp-heading mb-0">PTP Cases</h2>) : ""}
					{showClearBtn ? (
						<button className="btn btn-primary btn-xs txt-btn-sm-w" onClick={e => clearFilter(e)}>
								Reset
						</button>
					) : (
						""
					)}
					{!ptpcases?.data.length && !removeBtn && !pageType ? (
						<button type="button" className="btn btn-primary btn-sm txt-btn-sm-w mb-0 p-2" onClick={loadPtpCases}>Click here to load
						</button>
					) : ""}
				</div>

				{Object.keys(ptpDateStats).length ? (
					<div className="d-flex mb-2 flex-wrap">
						{ptpDateStats.map((value, index) => {
							return (
								<div key={`date-stats-${index}`} className="ptp-stats-div mb-2">
									{moment(value.proposed_date).format("DD MMM")}:-{" "}
									<span className="ml-2 bold">{value.count} Case{value.count !=="1"?"s":""}</span>,
									<span className="bold">
										<BiRupee size={16} style={{ margin: "-2px -5px 0 0px" }} />
										{parseFloat(value.sum).toLocaleString("en-IN")}
									</span>
								</div>
							);
						})}
					</div>
				) : ""}
				{Object.keys(ptpProductStats).length ? (
					<div className="d-flex mb-4 flex-wrap">
						{ptpProductStats.map((value, index) => {
							return (
								<div
									key={`date-stats-${index}`}
									className="ptp-stats-div text-capitalize mb-1"
								>
									{value.loan_type}:-{" "}
									<span className="ml-2 bold">{value.count} Case{value.count !=="1"?"s":""}</span>,
									<span className="bold">
										<BiRupee size={16} style={{ margin: "-2px -5px 0 0px" }} />{" "}
										{parseFloat(value.amount).toLocaleString("en-IN")}
									</span>
								</div>
							);
						})}
					</div>
				) : (
					""
				)}
				<DataTable
					columns={columns}
					data={ptpcases && ptpcases.data ? ptpcases.data : []}
					pagination
					paginationServer
					paginationComponent={CustomPagination}
					highlightOnHover="true"
					persistTableHead={ptpcases && ptpcases.data ? true : false}
					progressPending={ptpDataLoading}
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					reloadData={reloadDetails}
					className={`fix-overflow ${ptpcases && ptpcases.data ? "after-load-data" : "" }`}
					noDataComponent={ptpcases && ptpcases.data ?
						<p className="w-100 m-5 text-start">
							There are no records to display
						</p> : 
						""
						// <p className="w-100 m-5 text-center">
						// 	Click button to load data
						// </p>
					}
				/>
			</div>

			<EmiCollectionForm
				show={showEmiModal}
				hide={handleCloseEmi}
				geoLoc={vals}
				loan_number={loanAccountNumber}
			/>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
				reloadDetails={reloadDetails}
				handleSidebarModal={handleSidebarModal}
				reloadSidebar={reloadSidebar}
			/>

			<CollectionManagerModal
				sharedModal={sharedModal}
				setSharedModal={setSharedModal}
				reloadDetails={reloadDetails}
			/>

			<Modal show={bdoModal} onHide={hideBdoModal} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>
						Assign {" "}
						{bdoModal === "bdo" ? "BDO's" : "Collection Manager"}
					</Modal.Title><span className="close-icon" onClick={()=>hideBdoModal()}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						{bdoModal === "bdo" ? (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												type="loan_accounts"
											/>
										</div>
									</div>
								</div>
								{finalUsers && finalUsers.length ? (
									<div className="row">
										<h4 className="m-0 mt-2">Selected Users</h4>
										<div className="imageList">
											<table className="table">
												<thead>
													<tr>
														<th>User</th>
														<th className="text-center">Delete</th>
													</tr>
												</thead>
												<tbody className="imageListTd">
													{finalUsers.map((value, index) => {
														return (
															<>
																{value.shared_to ? (
																	<tr
																		key={`users.${index}-${value.shared_to}`}
																	>
																		<td>
																			<div>
																				<img
																					src={
																						value.image
																					}
																					alt={value.name}
																					className="emp-img"
																				/>{" "}
																				{value.name}
																			</div>
																		</td>
																		<td className="text-center">
																			<button
																				type="button"
																				onClick={() =>
																					handleBdoRemoved(
																						value.shared_to
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		</td>
																	</tr>
																) : (
																	""
																)}
															</>
														);
													})}
												</tbody>
											</table>
										</div>
										<div className="col-sm-12 col-12">
											<Col size={12} className="text-center mt-2">
												<button
													type="button"
													className="btn btn-primary"
													onClick={() => assignBdo(bdoModal)}
												>
													Save
												</button>
											</Col>
										</div>
									</div>
								) : (
									""
								)}
							</>
						) : (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												// type="loan_accounts"
											/>
											<div className="col-sm-12 col-12">
												<Col size={12} className="text-center mt-2">
													<button
														type="submit"
														className="btn btn-primary"
													>
														Save
													</button>
												</Col>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={qr.id} onHide={() => setQr(false)} size={"lg"} centered>
				<Modal.Body>
					<fieldset>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-12 text-center">
									<h3>Payment QR Code</h3>
									<img src={qr.QR} alt="Qr Code" className="qrImage" />
								</div>
							</div>
						</div>
					</fieldset>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default LoanAccountPtpTable;
