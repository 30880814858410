import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import { OrganizationDashboardContext } from "../context/OrganizationDashboardState";
import Multiselect from "multiselect-react-dropdown";
const CreateValuatorModal = ({show , onModalHide}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState("");
	const [selectedCount, setSelectedCount] = useState("");
	const [branchValue, setBranchValue] = useState("");
	const { branches_list } = useContext(OrganizationDashboardContext);
	 
	  useEffect(() => {
		setSelectedBranch(branches_list ? branches_list.map(data => ({label: data.value, value: data.id})) : []);
	  }, [branches_list]);

	  const selectedCustomData = (selected) => {
		setSelectedCount(selected.length);
		setBranchValue(selected.map(option => option.value));
	};

	const onSubmit = async data => {
		if(branchValue?.length <= 0){
			toast.error("Branch Cannot be null");
			return false;
		}
		setFormIsLoading(true);
		const allData = {
			...data,
			branch_id: branchValue,
		};

		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/create-evaluator`, allData)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 201) {
					toast.success("Data Saved Successfully");
					resetAllData();
				} else if (response.data.response.status === 500) {
					const errors = response.data.response.error;
					const messages = [];
					for (var propName in errors) {
						if (Object.prototype.hasOwnProperty.call(errors, propName)) {
							messages.push(errors[propName]);
						}
					}
					toast.error(messages.toString());
				} else if (response.data.response.status === 400){
					toast.error(response.data.response.message);
				}
			})
			.catch(error => {
				setFormIsLoading(false);
				toast.error("An error occurred: " + error);
			});
	};

	const resetAllData = () => {
		onModalHide();
		reset();
		setBranchValue("");
	};
	return (
		<div>
			<Modal show={show} onHide={resetAllData} size={"lg"} centered>
				<div className="sign-page">
					<div className="">
						<div className="signup-form">
							<h1 className="mb-3 text-center mb-4">Create Valuator <span className="close-icon" onClick={resetAllData}><IoCloseCircle /></span></h1>
							<form className="row" onSubmit={handleSubmit(onSubmit)}>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="comp_name">
										Company Name
										</label>
										<input
											placeholder="Company Name"
											className="form-control"
											{...register("comp_name", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="comp_name"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="cont_name">
										Contact Person Name
										</label>
										<input
											placeholder="Enter Name"
											className="form-control"
											{...register("cont_name", {
												required: "This is required."
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="cont_name"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="email">
										Email
										</label>
										<input
											placeholder="Email"
											className="form-control"
											{...register("email", {
												required: "This is required.",
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message: "Email is invalid"
												}
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="email"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="last_name">
										Phone Number
										</label>
										<input
											type="number"
											placeholder="Phone"
											className="form-control"
											min="0"
											{...register("phone", {
												required: "This is required.",
												minLength: {
													value: 10,
													message:
													"Mobile number Should be at least 10 numbers"
												},
												maxLength: {
													value: 10,
													message:
													"Mobile number maximum be at least 10 numbers"
												},
												// pattern: {
												// 	value: phoneRegExp,
												// 	message: "Invalid Phone number"
												// }
											})}
										/>
										<ErrorMessage
											errors={errors}
											name="phone"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
							
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="mb-3">
										<label className="form-label mb-0" htmlFor="first_name">
										Branch
										</label>
										<Multiselect
											className="custom-select-branches fs-5"
											avoidHighlightFirstOption
											placeholder={selectedCount > 0 ? selectedCount + " " + "Selected" : "Choose Branch"}
											hideSelectedList
											emptyRecordMsg="No Branches Found"
											displayValue="label"
											options={selectedBranch}
											showCheckbox
											onRemove={e => selectedCustomData(e)}
											onSelect={e => selectedCustomData(e)}
										/>
										<ErrorMessage
											errors={errors}
											name="Branch"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>
													{message}
												</p>
											)}
										/>
									</div>
								</div>
								
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 text-center">
									<div className="mb-3">
										{formIsLoading ? (
											<button
												type="button"
												id="Submit"
												name="Submit"
												className="btn btn-primary"
												disabled={true}
											>
												<img
													src="/images/pages/home/loader.svg"
													alt={process.env.REACT_APP_NAME}
												/>
											</button>
										) : (
											<button type="submit" className="btn btn-primary">
											Save
											</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CreateValuatorModal;