import moment from "moment";
import { BiRupee } from "react-icons/bi";
import InitiateLoanAccounts from "./InitiateLoanAccounts";
import { vehicleProductsList } from "../../data/authData";

const LoanAccountCaseDetails = ({ loanDetailsList, emiAccountDetails, reloadDetails, user, handleCloseSidebar}) => {
	const genderSelect = {
		1: "Male",
		2: "Female",
		3: "Others"
	};
	return (
		<>
			{emiAccountDetails?.loan_account_enc_id ? (
				<>
					{loanDetailsList ? (
						<div className="emis-details-tab px-3 pt-3">
							<div className="row px-2">
								<div className="col-md-6 ht	35">
									<p className="d-flex ht-48 m-0 align-items-center">
										Loan Amount:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{loanDetailsList.loan_amount
												? parseFloat(
													loanDetailsList.loan_amount
												).toLocaleString("en-IN")
												: "0"}
										</span>
									</p>
								</div>

								<div className="col-md-6">
									<p className="d-flex ht-48 m-0 align-items-center">
										Collection Date:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{loanDetailsList.collection_date
												? moment(loanDetailsList.collection_date).format(
													"DD MMM YYYY"
												)
												: ""}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex ht-48 m-0 align-items-center">
										Collected Amount:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{loanDetailsList.collected_amount
												? parseFloat(
													loanDetailsList.collected_amount
												).toLocaleString("en-IN")
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex ht-48 m-0 align-items-center">
										Pending Amount:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											<BiRupee size={16} />
											{loanDetailsList.pending_amount
												? parseFloat(
													loanDetailsList.pending_amount
												).toLocaleString("en-IN")
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<p className="d-flex ht-48 m-0 align-items-center">
										Branch:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{loanDetailsList.branch ? loanDetailsList.branch : "-"}
										</span>
									</p>
								</div>
								{/* <div className="col-md-6">
							<p className="d-flex ht-48 m-0 align-items-center">
								Branch ENC id:  {" "}
								<span className="fw-medium text-gray-700 mx-2 text-capitalize">
									{loanDetailsList.branch_enc_id}
								</span>
							</p>
						</div> */}
								<div className="col-md-6">
									<p className="d-flex ht-48 m-0 align-items-center">
										Number Of EMIs:{" "}
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{loanDetailsList.number_of_emis
												? loanDetailsList.number_of_emis
												: "-"}
										</span>
									</p>
								</div>
								{process.env.REACT_APP_TYPE === "DEV" || user.user_type === "Financer" || user.department === "Operations"  ? (
									<div className="col-md-6">
										<p className="d-flex flex-wrap align-items-center ht-48 m-0">
											Foreclose:{" "}
											<span className="mx-2">
												<InitiateLoanAccounts accountId={emiAccountDetails.loan_account_enc_id} basicDetail={loanDetailsList} reloadDetails={reloadDetails} type={"foreclose"} handleCloseSidebar={handleCloseSidebar}/>
											</span>
										</p>
									</div>
								): ""}

								{process.env.REACT_APP_TYPE === "DEV" || user.user_type === "Financer" ||
									user.designation === "Operations Manager" || user.designation === "Operations Executive" || user.designation === "Regional Operations Executive" || user.designation === "Branch Operations Executive"  ? (
										<>
											<div className="col-md-6">
												<p className="d-flex flex-wrap align-items-center ht-48 m-0">
													NOC:{" "}
													<span className="mx-2">
														<InitiateLoanAccounts accountId={emiAccountDetails.loan_account_enc_id} basicDetail={loanDetailsList} reloadDetails={reloadDetails} type={"noc"} handleCloseSidebar={handleCloseSidebar}/>
													</span>
												</p>
											</div>
											<div className="col-md-6">
												<p className="d-flex flex-wrap align-items-center ht-48 m-0">
													Loan Account Closure:{" "}
													<span className="mx-2">
														<InitiateLoanAccounts accountId={emiAccountDetails.loan_account_enc_id} basicDetail={loanDetailsList} reloadDetails={reloadDetails} type={"initiate"} handleCloseSidebar={handleCloseSidebar}/>
													</span>
												</p>
											</div>
										</>
									): ""}

								{/* Render other loan details as needed */}
							</div>
						</div>
					) : (
						<div className="cus-loader">
							<img
								src="/images/pages/home/loader-black.svg"
								alt={process.env.REACT_APP_NAME}
							/>
						</div>
					)}
				</>
			) : (
				""
			)}
			{emiAccountDetails && vehicleProductsList.includes(emiAccountDetails.loan_type) ? (
				<>
					<div
						className={`emis-details-tab px-3 ${!emiAccountDetails.loan_account_enc_id ? "mt-5" : ""}`}
					>
						<div className="row mb-1 mb-3 px-2">
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Invoice Number:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails.invoice_number
											? emiAccountDetails.invoice_number
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Invoice Date:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.invoice_date
											? moment(emiAccountDetails.invoice_date).format(
												"DD MMM YYYY"
											)
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Vehicle Color:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.vehicle_color
											? emiAccountDetails.vehicle_color
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Motor Number:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.motor_number
											? emiAccountDetails.motor_number
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Battery Number:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.battery_number
											? emiAccountDetails.battery_number
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									RC Number:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.rc_number
											? emiAccountDetails.rc_number
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Chassis Number:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.chassis_number
											? emiAccountDetails.chassis_number
											: "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Year of Model:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.model_year
											? emiAccountDetails.model_year
											: "-"}
									</span>
								</p>
							</div>

							{emiAccountDetails.loan_type === "Two Wheeler" ? (
								<div className="col-md-6">
									<p className="d-flex flex-wrap align-items-center ht-48 m-0">
										Engine Number:
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{emiAccountDetails?.engine_number
												? emiAccountDetails.engine_number
												: "-"}
										</span>
									</p>
								</div>
							) : (
								""
							)}

							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Ex-Showroom Price:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.ex_showroom_price ? (
											<>
												<BiRupee />{" "}
												{parseFloat(
													emiAccountDetails.ex_showroom_price
												).toLocaleString("en-IN")}
											</>
										) : (
											"-"
										)}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									On-Road Price:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.on_road_price ? (
											<>
												<BiRupee />{" "}
												{parseFloat(
													emiAccountDetails.on_road_price
												).toLocaleString("en-IN")}
											</>
										) : (
											"-"
										)}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Margin Money:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.margin_money ? (
											<>
												<BiRupee />{" "}
												{parseFloat(
													emiAccountDetails.margin_money
												).toLocaleString("en-IN")}
											</>
										) : (
											"-"
										)}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									LTV:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.ltv ? emiAccountDetails.ltv : "-"}
									</span>
								</p>
							</div>
							<div className="col-md-6">
								<p className="d-flex flex-wrap align-items-center ht-48 m-0">
									Dealer:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{emiAccountDetails?.dealer_name ? (
											<div className="text-capitalize">
												{emiAccountDetails.dealer_name}
											</div>
										) : (
											"-"
										)}
									</span>
								</p>
							</div>
						</div>
					</div>
				</>
			) : (
				""
			)}

			{emiAccountDetails?.loan_account_enc_id && loanDetailsList ? (
				<div className="emis-details-tab px-3 pt-3">
					<div className="row px-2">
						{loanDetailsList.loanAppEnc0 && loanDetailsList.loanAppEnc0.length
							? loanDetailsList.loanAppEnc0.map((value, index) => {
								return (
									<div
										className="col-12 col-xl-4 col-md-6"
										key={`loanCoApplicants-${value.borrower_type}-${index}`}
									>
										<div className="borrower-main p-3 mb-3 rounded-3 shadow-el position-relative">
											<div className="co-borrower-name">
												{value.borrower_type}
											</div>

											<div className="borrower-upper disFlex align-items-center ht-48 m-0">
												<div className="b-logo">
													<img
														src={
															value.image
																? value.image
																: "/images/pages/re-empower/avatar.svg"
														}
														className="img-fluid"
													/>
												</div>
												<div className="b-name-details mx-2">
													<h3
														className="fs-4"
														style={{ marginTop: "10px" }}
													>
														{value.name}
													</h3>
													<p>
															Gender :{" "}
														{genderSelect[value.gender]
															? genderSelect[value.gender]
															: "-"}
													</p>
													<p>
															DOB :{" "}
														{value.co_applicant_dob
															? moment(
																value.co_applicant_dob
															).format("DD MMM YYYY")
															: "-"}
													</p>
												</div>
											</div>
											<div className="borrower-middle">
												<div className="state-added mb-3">
													<p className="mb-1">
														<span className="text-black">
																Current Address:
														</span>{" "}
														{value.address ? value.address : "-"}
													</p>
													<p className="mb-1">
														<span className="text-black">
																Permanent Address:{" "}
														</span>
														{value.address ? value.address : "-"}
													</p>
													<div className="disFlex justify-content-between">
														<p className="mb-1">
															<span className="text-black">
																	State:{" "}
															</span>{" "}
															{value.state ? value.state : "-"}
														</p>
														<p className="mb-1">
															<span className="text-black">
																	City:{" "}
															</span>
															{value.city ? value.city : "-"}
														</p>
													</div>
													<div className="disFlex justify-content-between">
														<p className="mb-1">
															<span className="text-black">
																	Mobile:{" "}
															</span>
															{value.phone ? value.phone : "-"}
														</p>
														{value.borrower_type !== "Borrower" &&
															value.relation ? (
																<p className="mb-1">
																	<span className="text-black">
																		Relation :{" "}
																	</span>
																	{value.relation}
																</p>
															) : (
																""
															)}

														<p className="mb-1">
															<span className="text-black">
																	Marital Status :{" "}
															</span>
															{value.marital_status
																? value.marital_status
																: "-"}
														</p>
														<p className="mb-1">
															<span className="text-black">
																	Father Name :{" "}
															</span>
															{value.father_name
																? value.father_name
																: "-"}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})
							: ""}
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default LoanAccountCaseDetails;
