// import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ title = null, allData, titleCapitalize = true }) => {
	const [series, setSeries] = useState([]);
	const [options, setOptions] = useState([]);

	const colorMapping = {
		collection_green: "#5DCB3C",
		collection_red: "#E53E34",
		collection_yellow: "#F9C700",
		green: "#5DCB3C",
		lightblue: "#6D9BF7",
		blue: "#2040A4",
		purple: "#A06FC8",
		violet: "#6917A7",
		orange: "#FFA727",
		yellow: "#F8D749",
		pink: "#CA438F",
		red: "#EA3323",
		grey: "#a8adaa",
		cyan: "#F9C700",
		magenta: "#1261A0",
		brown: "#A52A2A",
		teal: "#008080",
		lavender: "#E6E6FA",
		maroon: "#800000",
		olive: "#808000",
		navy: "#000080",
		beige: "#F5F5DC",
		coral: "#FF7F50",
		gold: "#FFD700",
		silver: "#C0C0C0",
		peach: "#F6B092",
		mint: "#A2E4B8",
		indigo: "#4B0082",
		fuchsia: "#C3447A",
		crimson: "#9D2235",
		amber: "#A6674C",
		plum: "#9C50B6",
		rose: "#FF033E",
		salmon: "#FF8674",
		slate: "#768692",
		charcoal: "#36454F",
		lilac: "#C6A1CF",
		ivory: "#FFFFF0",
		mustard: "#EAAA00",
		ruby: "#E40046",
		rust: "#B94700",
		sand: "#85671C"
	};

	useEffect(() => {
		setSeries(allData ? allData.map(item => parseInt(item.value)) : []);

		setOptions({
			colors: allData ? allData.map(item => colorMapping[item.fill]) : [],
			fill: {
				colors: allData ? allData.map(item => colorMapping[item.fill]) : []
			},
			chart: {
				// width: auto,
				type: "pie"
			},
			labels: allData ? allData.map(item => item.name) : [],
			legend: {
				position: "bottom"
			},
			responsive: [
				{
					breakpoint: 380,
					options: {
						chart: {
							// width: 200
						},
						legend: {
							position: "bottom"
						}
					}
				}
			]
		});
	}, [allData]);
	return (
		<>
			{allData && allData.length ? (
				<>
					<h2
						className={`mb-1 pie-heading text-center ${
							titleCapitalize ? "text-capitalize" : ""
						}`}
					>
						{title}
					</h2>
					<div id="chart">
						{options && series.length > 0 ? (
							<ReactApexChart
								options={options}
								series={series}
								type="pie"
								width={380}
							/>
						) : (
							<span className="pie-loader">
								<img src="/images/pages/home/loader-black.svg" alt="Loading" />
							</span>
						)}
					</div>
					<div id="html-dist"></div>
				</>
			) : (
				""
			)}
		</>
	);
};

export default PieChart;
