import React, { useContext, useEffect, useState } from "react";
import { loanStatusData } from "../../data/loanStatusData";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Tab, Tabs } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import axios from "axios";
import FinancerDocumentsTab from "../forms/FinancerDocumentsTab";
import { MainContext } from "../../context/MainState";
import DropLocation from "../DropLocation";
import { toast } from "react-toastify";
import { MdOpenInNew } from "react-icons/md";
import CreditReport from "../CreditReport";
import AuditTrail from "../AuditTrail";
import LoanPaymentLogs from "../LoanPaymentLogs";
import TabBasicDetails from "./TabBasicDetails";
import FinancerImagesTab from "../forms/FinancerImagesTab";
import { OrganizationSidebarContext } from "../../context/OrganizationSidebarState";
import CaseReport from "./CaseReport";
import UpdateLoanStatus from "./UpdateLoanStatus";
import EnachLogs from "../EnachLogs";

const LoanApplicationDetails = ({
	handleCloseSideBar,
	setTableLoading,
	params,
	type = null,
	loanId
}) => {
	const { getFinancersList, all_financers, images_types, getAssignedLoanImages, user } = useContext(MainContext);
	const { getDocumentsData, clearSidebarData, certificates, loanImages, isLoading, getTatReport, tatReportData,getEnachLogs,enachLogsData} = useContext(
		OrganizationSidebarContext
	);
	const [auditTrails, setAuditTrails] = useState([]);
	const [creditReport, setCreditReport] = useState([]);
	const [commentVal, setCommentVal] = useState("");
	const [commentsData, setCommentsData] = useState([]);
	const [externalCommentsData, setExternalCommentsData] = useState([]);
	const [key, setKey] = useState("basic_details");
	const [loanPayments, setLoanPayments] = useState([]);
	const [imagesData, setImagesData] = useState([]);
	const [showDocuments, setShowDocuments] = useState(false);
	const [showImages, setShowImages] = useState(false);
	const [loadCaseReport, setLoadCaseReport] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loanDetails, setLoanDetails] = useState("");
	const [showSidebar, setShowsidebar] = useState(false);

	const openSideBar = () => {
		setShowsidebar(true);
		setLoading(true);

		// Disables Background Scrolling whilst the SideDrawer/Modal is open
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			if(type !== "fullwidth") {
				document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
			}
		}
		// addEvent()
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-detail`,
				{
					loan_id: loanId,
					provider_id: user.organization_enc_id
				}
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setLoanDetails(response.data.response.loan_detail);
					setLoading(false);
				} else {
					setLoanDetails({});
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred while getting loan application detail, Please try again");
			});
	};

	const handleClose = () => {
		setShowsidebar(false);
		handleCloseSideBar();

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	useEffect(() => {
		if(loanId){
			openSideBar();
		}
	}, [loanId]);

	useEffect(() => {
		if (!all_financers) {
			getFinancersList();
		}
	}, []);
	
	// const handleStatusField = (reload = false) => {
	// 	if (reload) {
	// 		openSideBar(loanDetails.loan_app_enc_id);
	// 	}
	// };

	const handleChangeData = (val, name) => {
		setLoanDetails({
			...loanDetails,
			[name]:val
		});
	};

	const getImageData = (id, newImage) => {
		const updatedCoApplicants = loanDetails?.loanCoApplicants?.length && loanDetails.loanCoApplicants.map((applicant) =>
			applicant.loan_co_app_enc_id === id ? { ...applicant, image: newImage } : applicant
		);
		setLoanDetails({...loanDetails, loanCoApplicants: updatedCoApplicants});
	};

	const getLoanPayments = async () => {
		let data = { loan_id: loanDetails.loan_app_enc_id };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/loan-payments`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setLoanPayments(response.data.response.data);
				} else {
					setLoanPayments([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
			});
	};

	const saveComment = (e, loan_id, type) => {
		if (e.keyCode === 13) {
			submitComment(e.target.value, loan_id, type);
		}
	};

	const submitComment = (value, loan_id, type) => {
		let data = { loan_id: loan_id, comment: value, comment_type : type };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/save-comment`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setCommentVal("");
					// openSideBar(loan_id);
					getComments(type);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getAuditTrail = () => {
		let data = { loan_id: loanDetails.loan_app_enc_id, limit: 100, page: 1 };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/audit-trail-list`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setAuditTrails(response.data.response.audit_list);
				} else{
					setAuditTrails([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getCreditReport = () => {
		let data = { loan_app_id: loanDetails.loan_app_enc_id };

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/credit-reports`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setCreditReport(response.data.response.data);
				} else {
					setCreditReport([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	
	const getComments = (type) => {
		let data = { loan_app_id: loanDetails.loan_app_enc_id, comment_type : type };
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-internal-chat`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					if(response.data.response.type === "2"){
						setExternalCommentsData(response.data.response.data);
					} else{
						setCommentsData(response.data.response.data);
					}
				} else{
					setExternalCommentsData([]);
					setCommentsData([]);
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getSelectedTab = k => {
		if (k === "documents") {
			setShowDocuments(true);
		}
		if (k === "images") {
			setShowImages(true);
		}
		if (k === "audit_trail" && !auditTrails?.length){
			getAuditTrail();
			getEnachLogs(loanDetails.loan_account_enc_id);
		}
		if (k === "Loan_Chat" && !commentsData?.length) {
			getComments("1");
		}
		if (k === "Loan_external_Chat" && !externalCommentsData?.length) {
			getComments("2");
		}
		if(k === "case_report"){
			setLoadCaseReport(true);
			getTatReport(loanDetails.loan_app_enc_id);
		}
	};

	useEffect(() => {
		if (images_types && images_types.length && loanDetails) {
			const filteredImages = images_types.filter(
				item => item.name === loanDetails.loan_product
			);
			setImagesData(filteredImages);
		} else {
			setImagesData([]);
		}
	}, [images_types, loanDetails]);

	useEffect(() => {
		if (loanDetails && loanDetails.loan_app_enc_id) {
			getCreditReport();
			clearSidebarData();
			setShowDocuments(false);
			setShowImages(false);
			getLoanPayments();
			if(!images_types) {
				getAssignedLoanImages();
			}
		}
	}, [loanDetails]);

	const clearData = () => {
		handleClose();
		setAuditTrails([]);
		clearSidebarData();
		setShowDocuments(false);
		setCommentsData([]);
		setExternalCommentsData([]);
		setLoadCaseReport(false);
	};

	// const sendNotification = (e, loan_id) => {
	//     if (e.keyCode === 13) {
	//         let data = {loan_id: loan_id, message: e.target.value}
	//         axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/save-notification`, data).then(
	//             (response) => {
	//                 if (response.data.response.status === 200) {
	//                     openSideBar(loan_id)
	//                 }
	//             }
	//         )
	//     }
	// }
	return (
		<>	
			{type !== "fullwidth" ? 
				<div className="backdrop-blur"></div>
				:""}
			<div className={`${type !=="fullwidth" ? "expend-detail" : "bg-white p-3 vertical-h mb-4"} ${showSidebar === false ? "expend-detail-close" : ""}`}> 
				{type !== "fullwidth" ? 
					<div
						className="closeIcon"
						onClick={() => {clearData();}}
					>
						<AiFillCloseCircle size={20}/>
					</div>
					:""}

				<PerfectScrollbar options={{ suppressScrollX: true }}>
					{loading ? (
						<div className="cus-loader">
							<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
						</div>
					) : (
						<div className="expend-data sidebar-sm-top expand-data-details">
							<div className="status-pos-abso d-flex align-items-center">
								<span className="fw-bold color-black mx-2">Application Status : </span>
								<div style={{ marginTop: "5px" }}>
									<UpdateLoanStatus
										allStatus={loanStatusData.loanStatus}
										defaultStatus={loanDetails.loan_status}
										loanType={
											loanDetails.loan_product
												? loanDetails.loan_product
												: ""
										}
										loanId={loanDetails.loan_app_enc_id}
										label={false}
										callback={handleChangeData}
										completeLoanDetails={loanDetails}
									/>
								</div>
							</div>
							<Tabs onSelect={k => (setKey(k), getSelectedTab(k))}>
								<Tab
									eventKey="basic_details"
									title="Basic Details"
									className="pt-4"
								>
									<TabBasicDetails
										loanDetails={loanDetails}
										openSideBar={openSideBar}
										handleChangeData={handleChangeData}
										getImageData={getImageData}
										// dropLocationSave={dropLocationSave}
										setTableLoading={setTableLoading}
										params={params}
									/>
								</Tab>
								<Tab eventKey="documents" title="Documents" className="pt-4">
									{key === "documents" ? (
										<FinancerDocumentsTab
											rawData={loanDetails}
											handleChangeData={handleChangeData}
											openSideBar={openSideBar}
											allCertificates={certificates}
											getDocumentsData={getDocumentsData}
											showDocuments={showDocuments}
											isLoading={isLoading}
										/>
									) : (
										""
									)}
								</Tab>
								{imagesData && imagesData.length ? (
									<Tab eventKey="images" title="Images" className="pt-4">
										{key === "images" ? (
											<FinancerImagesTab
												rawData={loanDetails}
												getImageData={getImageData}
												openSideBar={getAssignedLoanImages}
												handleChangeData={handleChangeData}
												showImages={showImages}
												loanImages={loanImages}
												getDocumentsData={getDocumentsData}
											/>
										) : (
											""
										)}
									</Tab>
								) : (
									""
								)}
								{loanDetails.loanSanctionReports ? (
									<Tab
										eventKey="loan-details"
										title="EMI Structure"
										className="pt-4"
									>
										<div className="row">
											<div className="col-md-6">
												<p>
													<span>Rate Of Interest:</span>{" "}
													{
														loanDetails.loanSanctionReports
															.rate_of_interest
													}{" "}
													%
												</p>
											</div>
											<div className="col-md-6">
												<p className="emiAmt">
													<span>Processing Fee:</span>
													<BiRupee />{" "}
													{loanDetails.loanSanctionReports.processing_fee}
												</p>
											</div>
											<div className="col-md-12">
												<h3>EMI Details</h3>
											</div>
											<div className="col-md-12">
												<table className="table">
													<thead>
														<tr>
															<th>Amount</th>
															<th>Due Date</th>
															<th>Paid in Advance</th>
														</tr>
													</thead>
													<tbody>
														{loanDetails.loanSanctionReports
															.loanEmiStructures &&
															loanDetails.loanSanctionReports.loanEmiStructures.map(
																(value, index) => {
																	return (
																		<tr
																			key={`emi-${index}`}
																			className="emi-table"
																		>
																			<td className="emiAmt">
																				<BiRupee />{" "}
																				{value.amount}
																			</td>
																			<td>
																				{value.due_date}
																			</td>
																			<td>
																				{value.is_advance ===
																				1
																					? "Yes"
																					: "No"}
																			</td>
																		</tr>
																	);
																}
															)}
													</tbody>
												</table>
											</div>
										</div>
									</Tab>
								) : (
									""
								)}
								{user.user_type !== "Lender Auditor" ? (
									<Tab eventKey="Loan_Chat" title="Chat" className="pt-4">
										<div className="row mb-5">
											{/* <div className="col-md-12">*/}
											{/*    <h3>Comments</h3>*/}
											{/* </div>*/}
											<div className="panel-body mb20">
												<textarea
													className="form-control"
													rows="2"
													value={commentVal}
													onKeyDown={e =>
														saveComment(e, loanDetails.loan_app_enc_id, "1")
													}
													onChange={e => setCommentVal(e.target.value)}
													placeholder="Add New Comment"
												></textarea>
												<button
													className="btn btn-primary btn-sm mt-2"
													onClick={() =>
														submitComment(
															commentVal,
															loanDetails.loan_app_enc_id,
															"1"
														)
													}
												>
													Comment
												</button>
											</div>
											{commentsData && commentsData.length
												? commentsData.map(
													(value, index) => {
														return (
															<div
																className="media g-mb-30 media-comment mb20"
																key={`comment-${index}`}
															>
																<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
																	<div className="g-mb-15 comment-main">
																		<h5 className="h5 g-color-gray-dark-v1 mb-0">
																			<span>
																				<img
																					src={
																						value.logo ||
																							value.user_image
																							? value.logo ||
																									value.user_image
																							: value.image
																					}
																					alt={
																						value.created_by
																					}
																					className="creator-img"
																				/>
																			</span>
																			{value.created_by}
																		</h5>
																		<span className="comment-date">
																			{moment(
																				value.created_on
																			).format(
																				"DD MMM YYYY h:mm A"
																			)}
																		</span>
																	</div>

																	<p>{value.comment}</p>
																</div>
															</div>
														);
													}
												)
												: ""}
										</div>
									</Tab>
								) : ""}
								{(loanDetails?.loan_partners?.length > 0 || loanDetails?.provider_enc_id !== user.organization_enc_id) && user.user_type !== "Lender Auditor" ? (
									<Tab eventKey="Loan_external_Chat" title="Chat with BC" className="pt-4">
										<div className="row mb-5">
											<div className="panel-body mb20">
												<textarea
													className="form-control"
													rows="2"
													onKeyDown={e =>
														saveComment(e, loanDetails.loan_app_enc_id, "2")
													}
													onChange={e => setCommentVal(e.target.value)}
													placeholder="Add New Comment"
												></textarea>
												<button
													className="btn btn-primary btn-sm mt-2"
													onClick={() =>
														submitComment(
															commentVal,
															loanDetails.loan_app_enc_id,
															"2"
														)
													}
												>
													Comment
												</button>
											</div>
											{externalCommentsData && externalCommentsData.length
												? externalCommentsData.map(
													(value, index) => {
														return (
															<div
																className="media g-mb-30 media-comment mb20"
																key={`comment-${index}`}
															>
																<div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
																	<div className="g-mb-15 comment-main">
																		<h5 className="h5 g-color-gray-dark-v1 mb-0">
																			<span>
																				<img
																					src={
																						value.logo ||
																							value.user_image
																							? value.logo ||
																									value.user_image
																							: value.image
																					}
																					alt={
																						value.created_by
																					}
																					className="creator-img"
																				/>
																			</span>
																			{value.created_by}
																		</h5>
																		<span className="comment-date">
																			{moment(
																				value.created_on
																			).format(
																				"DD MMM YYYY h:mm A"
																			)}
																		</span>
																	</div>

																	<p>{value.comment}</p>
																</div>
															</div>
														);
													}
												)
												: ""}
										</div>
									</Tab>
								): ""}
								{loanDetails &&
								loanDetails.loanVerificationLocations &&
								loanDetails.loanVerificationLocations.length > 0 ? (
										<Tab
											eventKey="Dropped_Locations"
											title="Dropped Locations"
											className="pt-4"
										>
											{user.user_type !== "Lender Auditor" ? (
												<div className="row justify-content-end">
													<div className="col-md-3 d-flex justify-content-end">
														<DropLocation
															label="Drop Location"
															modal={true}
															loanDetails={loanDetails}
															type="sidebar"
															openSideBar={openSideBar}
															// callback={dropLocationSave}
														/>
													</div>
												</div>
											) : ""}
											<div
												className="row mb-5 w-100"
												style={{ overflowX: "scroll" }}
											>
												<table className="table">
													<thead>
														<tr>
															<th>Applicant Name</th>
															<th>Location Name</th>
															<th>Address</th>
															<th>Added By</th>
														</tr>
													</thead>
													<tbody>
														{loanDetails.loanVerificationLocations.map(
															(value, index) => {
																return (
																	<tr
																		key={`emi-${index}`}
																		className="emi-table"
																	>
																		<td> {value.name ? value.name : "-"} {value.borrower_type ? `(${value.borrower_type})` : ""}</td>
																		<td> {value.location_name}</td>
																		<td>
																			{value.local_address
																				? value.local_address
																				: ""}
																		&nbsp;
																			{value.latitude &&
																		value.longitude ? (
																					<a
																						target="_blank"
																						href={`https://maps.google.com/?q=${value.latitude},${value.longitude}`}
																						className="btn btn-primary btn-sm m-0"
																						style={{
																							fontSize:
																						"11px",
																							padding:
																						"6px 12px",
																							borderRadius:
																						"21px"
																						}}
																						rel="noreferrer"
																					>
																						{" "}
																						<MdOpenInNew /> Open
																				on Map
																					</a>
																				) : (
																					""
																				)}
																		</td>
																		<td>
																			<img
																				src={value.image}
																				style={{
																					width: "30px",
																					borderRadius:
																					"50%",
																					height: "30px"
																				}}
																				alt={value.created_by}
																			/>{" "}
																			{value.created_by}
																			<p
																				style={{
																					fontSize: "11px",
																					margin: 0,
																					marginTop: "10px"
																				}}
																			>
																			Posted on:{" "}
																				{moment(
																					value.created_on
																				).format(
																					"DD MMM YYYY h:mm a"
																				)}
																			</p>
																		</td>
																	</tr>
																);
															}
														)}
													</tbody>
												</table>
											</div>
										</Tab>
									) : (
										""
									)}
									
								<Tab
									eventKey="audit_trail"
									title="Audit Trail"
									className="pt-4"
								>
									<div style={{ overflowX: "scroll" }}>
										<EnachLogs 
											enachLogsData={enachLogsData}
										/>
										{auditTrails.map((value, index) => {
											return (
												<div
													key={`audit-trail-${index}`}
													className="auditTrail-sec"
												>
													<AuditTrail
														auditKey={value}
													/>
												</div>
											);
										})}
									</div>
								</Tab>

								<Tab
									eventKey="credit_report"
									title="Other Details"
									className="pt-4"
								>
									<div className="row">
										{creditReport && creditReport.length ? (
											creditReport.map((value, index) => {
												return (
													<div className="col-12 col-xl-12 col-md-6" key={`credit-repo-item-${index}`}>
														<CreditReport
															loanDetails={loanDetails}
															reportDetails={value}
															reportKey={index}
														/>
													</div>
												);
											})
										) : (
											<div className="text-center">No Credit Reports Found</div>
										)}
									</div>
								</Tab>

								{/* <Tab
                  eventKey="pendencies_list"
                  title="Pendencies"
                  className="pt-4"
                >
                  <PendencyList/>
                </Tab> */}

								{loanPayments && loanPayments.length ? (
									<Tab
										eventKey="loan_payments_log"
										title="Payment Logs"
										className="pt-4"
									>
										<LoanPaymentLogs loanPayments={loanPayments} />
									</Tab>
								) : (
									""
								)}

								<Tab
									eventKey="case_report"
									title="Case Report"
									className="pt-4"
								>
									<CaseReport 
										loanDetails={loanDetails}
										loadCaseReport={loadCaseReport}
										tatReportData={tatReportData}
									/>
								</Tab>
							</Tabs>
						</div>
					)}
				</PerfectScrollbar>
			</div>
		</>
	);
};
export default LoanApplicationDetails;
