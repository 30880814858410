import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {FaTimes } from "react-icons/fa";
import { MainContext } from "../../context/MainState";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { RiFlag2Fill } from "react-icons/ri";
import { readOnlyUsers } from "../../helpers/authServices";

const EditSelectInput = ({
	defaultValue,
	defaultLabel,
	optionList,
	loanDetailStatus = null,
	getData,
	id,
	api,
	required = false,
	type = null,
	parentId = "",
	selectPlaceholder = "",
	isSearchable = false, 
	inheritedCondition = true,
	callback = () => {
		return true;
	}
}) => {
	const {user, handlePageLoaded} = useContext(MainContext);
	const [statusToggle, setStatusToggle] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCurrent, setShowCurrent] = useState("1324515487451");
	const [currentVal, setCurrentVal] = useState(defaultValue);
	const [options, setOptions] = useState([]);

	const handleSave = (value, loanId) => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		let data = { id: loanId, value: value, parent_id: parentId };
		if(type === "priority"){
			data["type"] = type;
			handlePageLoaded(true);
		}
		if(type === "loan_type") {
			data["key"] = type;
		}
		if (required && value === "") {
			toast.error("Please Enter Value");
			return false;
		}

		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}${api}`,
				data
			)
			.then(response => {
				setLoading(false);
				getData(id, value);
				if(type === "priority"){
					handlePageLoaded(false);
				}
				if (response.data.response.status === 200) {
					setStatusToggle(false);
					toast.success("Successfully updated");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
				toast.error("An error has occurred, Please try again");
			});
	};

	useEffect(() => {
		if (optionList && typeof optionList === "object" && type === "designation") {
			const groupedOptions = Object.entries(optionList).map(([group, optionsList]) => ({
				options: optionsList.map(option => ({ label: option.value, value: option.id, cat: group}))
			  }));
		
			  const flattenedOptions = groupedOptions.flatMap(group => group.options);
		
			  setOptions(flattenedOptions);
		  } else if(type === "priority"){
			setOptions(optionList ? optionList.map(data => ({label: data.label,value: data.value})) : []);
		  } else if (type === "loan_type") {
			setOptions(optionList ? optionList.map(data => ({label: data.loan_type,value: data.loan_type})) : []);
		  } else {
			setOptions(optionList ? optionList.map(data => ({label: data.value,value: data.id})) : []);
		  }
	  }, [optionList]);

	  const colorMapping = {
		
		2: "green",
		3: "yellow",
		4: "red",
	  };

	  const customOption = (props) => {
		const { data, innerRef, innerProps } = props;
		return (
		  <div ref={innerRef} {...innerProps} className="cursor-point custom-priority">
				<RiFlag2Fill color={colorMapping[data.value]}/> <span className="mx-1"> {data.label}</span>
		  </div>
		);
	  };

	useEffect(() => {
		callback(statusToggle);
	}, [statusToggle]);
	return (
		<>
			{statusToggle && showCurrent === id && inheritedCondition ? (
				<>
					{!loading ? (
						<>
							{!isSearchable ? (
								<Select
									options={options}
									menuPortalTarget={document.body} 
									styles={{ menuPortal: base => ({ ...base, zIndex: 9, fontSize: 13, color: "#000" }) }}
									className="smSelect smOption min-w-100"
									value={options.filter(option => option.value === currentVal)}
									name="designation"
									placeholder={selectPlaceholder ? selectPlaceholder : "Choose"}
									onChange={e => {
										setCurrentVal(e.value);
										handleSave(e.value, id);
									}}
									components={type === "priority" ? { Option: customOption } : false }
								/>
							) : (
								<Multiselect
									className="custom-multiselect label-color designation"
									avoidHighlightFirstOption
									selectedValues={options.filter(option => option.value === currentVal)}
									emptyRecordMsg="No Designation Found"
									singleSelect
									placeholder={"Choose Designation"}
									displayValue="label"
									options={options}
        							groupBy="cat"
									groupRenderer={(option) => (
										<div className="group-name">{option.cat}</div>
									)}
									onSelect={e => {
										setCurrentVal(e[0].value);
										handleSave(e[0].value, id);
									}}
								/>
							)} 
							<span
								style={{
									margin: "0px 5px",
									marginTop: "-2px",
									cursor: "pointer",
									color: "red"
								}}
								onClick={() => {
									setStatusToggle(false);
								}}
							>
								<FaTimes size={15} />
							</span>
						</>
					) : (
						<i>Please Wait Saving...</i>
					)}
				</>
			) : type === "priority" ? (
				<div
					style={{ margin: "0px 5px" }}
					onClick={
						loanDetailStatus !== 28 &&
						loanDetailStatus !== 32 &&
						inheritedCondition &&
						(user.user_type === "Financer" || user.department === "Credit")
							? () => {
								setCurrentVal(defaultValue);
								setShowCurrent(id);
								setStatusToggle(true);
							  }
							: () => {
								setStatusToggle(false);
							  }
					}
				>
					{defaultLabel && defaultLabel.trim() ? <RiFlag2Fill color={colorMapping[defaultLabel]}/> : "-"}
				</div>
			) : 
				<div
					style={{ margin: "0px 5px" }}
					onClick={
						loanDetailStatus !== 28 &&
						loanDetailStatus !== 32 &&
						inheritedCondition &&
						(!defaultValue || user.user_type === "Financer" || user.specialAccessRole || ((user.designation === "HR Executive" || user.designation === "HR Manager")  && (id === "designation_id" || id === "grade" || id === "branch_enc_id")))
							? () => {
								setCurrentVal(defaultValue);
								setShowCurrent(id);
								setStatusToggle(true);
							  }
							: () => {
								setStatusToggle(false);
							  }
					}
				>
					{defaultLabel && defaultLabel.trim() ? defaultLabel : "-"}
				</div>
			}
		</>
	);
};
export default EditSelectInput;
