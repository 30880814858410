import { useContext, useEffect, useState } from "react";
import { CandidateDashboardContext } from "../../context/CandidateDashboardState";
import { vehicleProductsList } from "../../data/authData";
import moment from "moment/moment";
import { Badge, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import { useForm } from "react-hook-form";
import Geocode from "react-geocode";

const IndividualLoanDetails = ({loan_id = null, type}) =>{
	const { selectedApplication, emiDetails, getLoanApplications } =
		useContext(CandidateDashboardContext);
	const [loading, setLoading] = useState(true);
	const { handlePageLoaded } = useContext(MainContext);
	const { handleSubmit, reset } = useForm();
	// const [isHidden, setIsHidden] = useState(false);
	const [collectNow, setCollectNow] = useState(false);
	const [vals, setVals] = useState({ latitude: "" });
	const onSubmit = async data => {
		data.address = vals.city;
		data.pincode = vals.postal_code;
		data.latitude = vals.latitude;
		data.longitude = vals.longitude;
		data.emi_amount = selectedApplication.emi_amount ? selectedApplication.emi_amount : "0";
		data.pay_by = "0";
		data.loan_app_enc_id = selectedApplication.loan_app_enc_id;
		handlePageLoaded(true);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}candidate-dashboard/create-payment-link`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response && response.data.response.status === 200) {
					toast.success("Payment link generated successfully");
					setCollectNow(false);
					reset();
				} else {
					toast.error(
						response.data.response.message
							? response.data.response.message.toString()
							: "An Error occurred"
					);
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error("Server Error Occurred: " + error.message);
			});
	};

	const locerrors = err => {
		toast.error("Please enable location to continue");
		alert(`ERROR(${err.code}): ${err.message}`);
	};

	const location = (type) => {
		if ("geolocation" in navigator) {
			setCollectNow(type);
			navigator.geolocation.getCurrentPosition(success);
		} else {
			navigator.geolocation.getCurrentPosition(locerrors);
		}
	};

	const success = async pos => {
		var crd = pos.coords;
		Geocode.fromLatLng(crd.latitude, crd.longitude).then(
			response => {
				let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
				for (let i = 0; i < response.results[0].address_components.length; i++) {
					for (
						let j = 0;
						j < response.results[0].address_components[i].types.length;
						j++
					) {
						switch (response.results[0].address_components[i].types[j]) {
						case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
							// case "country":
							// 	country = response.results[0].address_components[i].long_name;
							// 	break;
						case "postal_code":
							postalCode = response.results[0].address_components[i].long_name;
							break;
						case "premise":
							route = response.results[0].address_components[i].long_name;
							break;
						case "sublocality":
							sublocality = response.results[0].address_components[i].long_name;
							break;
						case "sublocality_level_2":
							sublocality_2 = response.results[0].address_components[i].long_name;
						}
					}
				}
				if (route && sublocality_2 && sublocality) {
					streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
				} else if (route && sublocality) {
					streetAdd = route + ", " + sublocality;
				} else {
					streetAdd = route;
				}
				setVals({
					latitude: crd.latitude,
					longitude: crd.longitude,
					postal_code: postalCode,
					streetAdd,
					city,
					state
				});
			},
			error => {
				console.error(error);
			}
		);
	};


	const fetchmylocation = type => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				if (result.state === "granted") {
					location(type);
					// If granted then you can directly call your function here
				} else if (result.state === "prompt") {
					location(type);
				} else if (result.state === "denied") {
					toast.error("Please enable location to continue");
					// If denied then you have to show instructions to enable location
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			// alert("Sorry Not available!");
			toast.error("Please enable location to continue");
		}
	};


	useEffect(() => {
		getLoanApplications({ loan_app_enc_id: loan_id });
	}, [ ]);

	useEffect(() => {
		if (selectedApplication && emiDetails) {
			setLoading(false);
		}
	}, [selectedApplication, emiDetails]);
        
	return (
		<>
			{loading ? (
				<div className="cus-loader vh-100">
					<img src="/images/pages/home/loader-black.svg" alt={process.env.REACT_APP_NAME} />
				</div>
			) : (
				<>
					{type === "mobile" ? (
						<div className="text-end py-4">
							<Link
								className="navbar-brand pe-5 me-0 dark-text fw-normal fs-4"
								to={"/account/my-loans"}
							><IoIosArrowBack  color={"#000"}/>Back</Link>
						</div>
					) : (
						""
					)}


					{type === "mobile" ? (
						<>
							{selectedApplication?.loan_status === "Disbursed" &&
                                emiDetails &&
                                process.env.REACT_APP_TYPE === "DEV" ? (
									<div className="pay-now-emi mb-3 nd-shadow bg-white mx-6">
										<div className="row align-items-center">
											<div className="col-xl-4 col-lg-6 col-md-12 col-12">
												<div className="pay-emi-img py-4">
													<img src="/./images/pages/dashboard/overdue-icon.png" className="img-fluid" alt="Overdue Icon" />
												</div>
											</div>
											<div className="col-xl-8 col-lg-6 col-md-12 col-12">
												<div className="pay-emi-des p-5">
													<h2 className="text-capitalize lh-md fw-bold">
									Overdue Loan Payments</h2>
													<p className="fw-medium info">
								   Due Date :{" "}
														<span className="fw-bold text-primary fs-text">{emiDetails.emi_date ? moment(emiDetails.emi_date).format("DD-MMM-YYYY") : ""}</span> <br />
										Overdue Amount : <span className="text-danger fs-3"> ₹{" "}</span> 
														<span className="fw-bold fs-3 text-danger">{emiDetails.overdue_amount ? parseFloat( emiDetails.overdue_amount).toLocaleString("en-IN" ): "-"}</span>
													</p> 
													{/* <button className="btn-primary btn" onClick={() => fetchmylocation("overdue")}>
										{selectedApplication.paid ? "Pay Advance" : "Pay Now"}
									</button> */}
												</div>
											</div>
							
							
										</div>
									</div>
								) : (
									""
								)}

							{selectedApplication?.loan_status === "Disbursed" &&
                                emiDetails &&
                                process.env.REACT_APP_TYPE === "DEV" ? (
									<div className="pay-now-emi nd-shadow bg-white mx-6">
										<div className="row align-items-center">
											<div className="col-xl-8 col-lg-6 col-md-12 col-12">
												<div className="pay-emi-des p-5">
													<h2 className="text-capitalize lh-md fw-bold">
								Take control and <span className="text-secondary"> pay now </span>to stay on top
								of your expenses
													</h2>
													<p className="mb-1">Keep track of your financial commitments:</p>
													<p className="mb-1">
										Next EMI due on :{" "}
														<span className="fw-bold text-primary fs-text">{emiDetails.emi_date ? moment(emiDetails.emi_date).format("DD-MMM-YYYY") : ""}</span> 
													</p>
													<p className="mb-2">
										Amount : ₹{" "}
														<span className="fw-bold fs-text"> {selectedApplication.target_collection_amount ? parseFloat(selectedApplication.target_collection_amount).toLocaleString(
															"en-IN"
										  ) : "0"}</span>
													</p>
													<button className="btn-primary btn" onClick={() => fetchmylocation("collect")}>
														{selectedApplication.paid ? "Pay Advance" : "Pay Now"}
													</button>
												</div>
											</div>
											<div className="col-xl-4 col-lg-6 col-md-12 col-12">
												<div className="pay-emi-img py-4">
													<img src="/./images/pages/dashboard/EMI.png" className="img-fluid" alt="EMI illustration" />
												</div>
											</div>
										</div>
									</div>
								): ""}
						</>
					) : (
						""
					)}
					

					<div className={`mb-6 nd-shadow bg-white p-6 ${type === "mobile" ? "m-6 mb-12" : ""}`}>
						<div className="row mb-3">
							{selectedApplication?.loan_status === "Disbursed" &&
											emiDetails &&
											process.env.REACT_APP_TYPE === "DEV" ? (
									<div className="row my-3">
										<div className="col-md-12">
										    <h3 className="text-primary fw-bold">EMI&#39;s Detail</h3>
									    </div>
										<div className="col-md-6">
											<p className="d-flex flex-wrap">
													EMI&#39;s Paid:
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiDetails.total_emis ? emiDetails.total_emis : "-"}
												</span>
											</p>
											{/* <p className="d-flex flex-wrap">
								Overdue EMI:
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
									₹{" "}
										{emiDetails.collected_amount
											? parseFloat(emiDetails.collected_amount).toLocaleString(
												"en-IN"
										  )
											: "0"}
									</span>
								</p> */}
											<p className="d-flex flex-wrap">
								Number of EMI&#39;s :
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
													{emiDetails.number_of_emis ? emiDetails.number_of_emis : "-"}
												</span>
											</p>
										</div>
										<div className="col-md-6">
								
											<p className="d-flex flex-wrap">
													Total Amount Paid:
												<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														₹{" "}
													{emiDetails.collected_amount
														? parseFloat(emiDetails.collected_amount).toLocaleString(
															"en-IN"
										  					)
														: "0"}
												</span>
											</p>
										</div>
									</div>
								) : (
									""
								)}

							<div className="col-12">
								<h3 className="text-primary fw-bold">Loan Details</h3>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Account Number :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{selectedApplication.application_number}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap align-items-center">
  									  Nach Status:
									<Badge
										className="text-capitalize mx-2"
										bg={selectedApplication.nach_approved === "Active" ? "success" : "danger"}
										pill
									>
										{selectedApplication.nach_approved}
									</Badge>
								</p>
							</div>

							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Type :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{selectedApplication.loan_type}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Branch :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{selectedApplication.branch_name}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Amount :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											₹{" "}
										{parseFloat(
											selectedApplication.loan_amount
										).toLocaleString("en-IN")}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Applied Date :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{moment(selectedApplication.applied_date).format(
											"DD MMM YYYY"
										)}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Status :
									<span
										className={`${
											selectedApplication.status === "28" || selectedApplication.status === "32" ? "text-danger" : 
												selectedApplication.status === "31"
													? "text-success"
													: "text-gray-700"
										} fw-medium mx-2 text-capitalize`}
									>
										{selectedApplication.loan_status}
									</span>
								</p>
							</div>
							<div className="col-lg-6 col-md-12">
								<p className="d-flex flex-wrap">
										Loan Purpose :
									<span className="fw-medium text-gray-700 mx-2 text-capitalize">
										{selectedApplication.purposes
											? selectedApplication.purposes
											: "-"}
									</span>
								</p>
							</div>
						</div>
						{vehicleProductsList.includes(selectedApplication.loan_type) ? (
							<div className="row mb-3">
								<div className="col-md-12">
									<h3 className="text-primary fw-bold">Other Details</h3>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Vehicle Model :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.model_year
												? selectedApplication.model_year
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Vehicle Color :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.vehicle_color
												? selectedApplication.vehicle_color
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Battery Number :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.battery_number
												? selectedApplication.battery_number
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											RC Number :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.rc_number
												? selectedApplication.rc_number
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Chassis Number :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.chassis_number
												? selectedApplication.chassis_number
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Invoice Number :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.invoice_number
												? selectedApplication.invoice_number
												: "-"}
										</span>
									</p>
								</div>
								<div className="col-lg-6 col-md-12">
									<p className="d-flex flex-wrap">
											Invoice Date :
										<span className="fw-medium text-gray-700 mx-2 text-capitalize">
											{selectedApplication.invoice_date
												? moment(
													selectedApplication.invoice_date
													  ).format("DD MMM YYYY")
												: "-"}
										</span>
									</p>
								</div>
							</div>
						) : (
							""
						)}
					</div>

					<Modal show={collectNow}  onHide={() => setCollectNow(false)} size={"md"} centered>
						<Modal.Body>
							<form onSubmit={handleSubmit(onSubmit)} className="form">
								<fieldset>
									<div className="container">
										<div className="row">
											<div className="col-12 text-center mb-3">
												<h2>{collectNow === "collect" ? "Pay EMI" : collectNow === "overdue" ? "Pay Overdue Amount":"" }</h2>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<p className="d-flex flex-wrap">
													{collectNow === "collect" ? "EMI Date :": collectNow === "overdue" ? "Overdue Amount Date :" : ""}	
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
														{collectNow === "collect" || collectNow === "overdue"  && emiDetails && emiDetails.emi_date
															? moment(emiDetails.emi_date).format(
																"DD MMM YYYY"
													  )
															:  ""
														}
													</span>
												</p>
											</div>
											<div className="col-md-6">
												<p className="d-flex flex-wrap">
    									{collectNow === "collect" ? "EMI Amount :": collectNow === "overdue" ? "Overdue Amount :" : ""}
													<span className="fw-medium text-gray-700 mx-2 text-capitalize">
												₹{" "}
														{collectNow === "collect" && selectedApplication.target_collection_amount
															? parseFloat(selectedApplication.target_collection_amount).toLocaleString("en-IN")
															: collectNow === "overdue" && selectedApplication.overdue_amount
																? parseFloat(selectedApplication.overdue_amount).toLocaleString("en-IN")
																: "0"}
													</span>
												</p>

											</div>
										</div>
										<div className="text-center mt-3">
											<button type="submit" className="btn btn-primary">
										Generate Qr
											</button>
										</div>
									</div>
								</fieldset>
							</form>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
};

export default IndividualLoanDetails;