import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { RiCloseFill } from "react-icons/ri";

const AssignTelecallersToBucket = () => {
	const [telecallerList, setTelecallerList] = useState([]);
	const [selectedCallers, setSelectedCallers] = useState([]);
	const [bucket, setBucket] = useState("");
	const [assignedTelecallers, setAssignedTelecallers] = useState("");
	const [formIsLoading, setFormIsLoading] = useState(false);

	const {handleSubmit} = useForm();

	const getTelecallerList = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-telecaller-list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTelecallerList(response.data.response.data);
				} else {
					setTelecallerList([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while getting Telecaller Data, Please try again"
				);
			});
	};

	const getAssignedTelecaller = async () => {
		await axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-assign-telecaller`).then(
			response => {
				if(response.data.response.status === 200){
					setAssignedTelecallers(response.data.response.data);
				}
			}
		);
	};

	const onSubmit = async () => {
		if (!bucket) {
			toast.error("Please Choose Bucket");
			return;
		}
		if (!selectedCallers?.length) {
			toast.error("please Choose Telecaller");
			return;
		}
		setFormIsLoading(true);
		let selectedBucket = bucket.value;
		let selectedTeleCallers = selectedCallers.map(item => item.value);
		await teleCallerApiReq(selectedTeleCallers, selectedBucket);
	};

	const removeCaller = async (id, bucket) => {
		if (!window.confirm("Are you sure you want to delete")) {
			return false;
		}
		let callerId = [];
		if(id){
			callerId.push(id);
		}
		await teleCallerApiReq(callerId, bucket, 1);
	};

	const teleCallerApiReq = (callerId, bucket, isDeleted=null) => {
		let data = !isDeleted ? {telecaller_id: callerId, bucket: bucket} : {telecaller_id: callerId, bucket: bucket, is_deleted: isDeleted};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/assign-telecallers`,
				data
			)
			.then(response => {
				setFormIsLoading(false);
				if (response.data.response.status === 200) {
					setBucket("");
					setSelectedCallers([]);
					getAssignedTelecaller();
				}else if(response.data.response.status === 201){
					toast.error("Already exists in the list");
				} else {
					toast.error("An error has occurred, Please try again");
				}
			});
	};
	const HandleSelect = (selected, id) => {
		if (id === "bucket") {
			setBucket(selected);
		} else {
			setSelectedCallers(selected);
		}
	};

	const telecallerOptions = telecallerList
		? telecallerList.map(data => ({
			label: data.name,
			value: data.user_enc_id
		  }))
		: [];

	useEffect(() => {
		if(process.env.REACT_APP_TYPE === "DEV"){
			getTelecallerList();
		}
	}, []);

	let bucketListData = [
		{
			label: "X",
			value: "X"
		},
		{
			label: "1",
			value: "1"
		},
		{
			label: "2",
			value: "2"
		},
		{
			label: "3",
			value: "3"
		},
		{
			label: "4",
			value: "4"
		},
		{
			label: "5",
			value: "5"
		},
		{
			label: "6",
			value: "6"
		},
		{
			label: "7",
			value: "7"
		},
		{
			label: "8",
			value: "8"
		}
	];

	useEffect(() => {
		if(!assignedTelecallers){
			getAssignedTelecaller();
		}
	}, []);
	return (
		<>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="row justify-content-center">
					<div className="col-6">
						<div className="mb-3">
							<label className="form-label mb-0" htmlFor="first_name">
								Choose Bucket
							</label>
							<Select
								onChange={e => HandleSelect(e, "bucket")}
								options={bucketListData}
								value={bucket}
								placeholder="Choose Bucket"
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">Assign Telecaller</label>
							<Select
								onChange={e => HandleSelect(e, "telecaller")}
								options={telecallerOptions}
								value={selectedCallers}
								isMulti
								placeholder="Choose Telecaller"
							/>
						</div>
						<div className="mb-3 text-center">
							{formIsLoading ? (
								<button type="button" className="btn btn-primary" disabled={true}>
									<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME}/>
								</button>
							) : (
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							)}
						</div>
					</div>
				</div>
			</form>
			<hr/>
			{assignedTelecallers ? 
				Object.entries(assignedTelecallers).map(([key, value], index) => {
					return(
						<div className="mt-5" key={`telle-${index}`}>
							<div style={{ border: "3px solid #eee", borderRadius: "10px", padding: "15px", marginBottom:"20px" }}>
								<h4>Bucket {key}</h4>
								{value.map((val, index) => {
									return(
										<div className="chip-list tele-chip-list" key={`username-${index}`}>
											<RiCloseFill
												style={{ marginRight: "6px", cursor: "pointer", color: "#bb2124"}}
												size={19}
												onClick={() => removeCaller(val.user_enc_id, key)}
											/>
											<img src={val.image}/>
											{val.name}
										</div>
									);
								})}
							</div>
						</div>
					);
						
				}) : ""
			}
		</>
	);
};
export default AssignTelecallersToBucket;
