import InitiateTvr from "./InitiateTvr";
import InitiatePd from "./InitiatePd";
import PaymentDetails from "../PaymentDetails";
import { loanStatusData } from "../../data/loanStatusData";
import { toast } from "react-toastify";
import { IoCloseCircle, IoOpenOutline, IoPerson, IoPersonAdd } from "react-icons/io5";
import InitiateReleasePayment from "./InitiateReleasePayment";
import DropLocation from "../DropLocation";
import EditInput from "../forms/EditInput";
import { BiRupee } from "react-icons/bi";
import moment from "moment/moment";
import AggrementTypeEdit from "../forms/AggrementTypeEdit";
import { Badge, Modal } from "react-bootstrap";
import { RiFlag2Fill, RiUserSharedFill } from "react-icons/ri";
import {
	MdAddCircleOutline,
	MdDelete,
	MdOutlineClose,
	MdOutlineContentCopy
} from "react-icons/md";
import AddReferenceModal from "./AddReferenceModal";
import { FaDownload, FaEdit, FaWhatsapp } from "react-icons/fa";
import BorrowerDetailCard from "./BorrowerDetailCard";
import React, { useContext, useEffect, useState } from "react";
import AddBorroweForm from "../forms/AddBorroweForm";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import ShareAccessModal from "../ShareAccessModal";
import DealerSearch from "./DealerSearch";
import axios from "axios";
import { cibilEvaluationData, cibilUrls } from "../../helpers/cibilEvaluationData";
import InitiateFi from "./InitiateFi";
import Select from "react-select";
import EditEmiCollectionDate from "../forms/EditEmiCollectionDate";
import UpdateBorrowerStatus from "./UpdateBorrowerStatus";
import ProductChangeModal from "./ProductChangeModal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import AutoComplete from "../forms/AutoComplete";
import { useParams } from "react-router-dom";
import EditSelectInput from "../forms/EditSelectInput";
import QRCode from "react-qr-code";
import EditRadioInput from "../forms/EditRadioInput";
import { propertyProductsList, vehicleProductsList } from "../../data/authData";
import { mortgage_type_options, property_asset_types, property_tv_scheme } from "../../data/loanApplicationOptions";
import NachRegisterModal from "./NachRegisterModal";
import { readOnlyUsers } from "../../helpers/authServices";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ActionRemarks from "../../pages/account/ActionRemarks";
import { hasSmartphone, requiredDetails, fiEligibleProducts, statusColorObj, eRishawPurposeList, eRishawTypeList, eRishawBatteryType, priorityList, genderSelect} from "../../data/pagesDataOptions";
import { Typeahead } from "react-bootstrap-typeahead";
import { GiCheckMark } from "react-icons/gi";
import InitiateTechnical from "./InitiateTechnical";
import InitiateLegal from "./InitiateLegal";


const TabBasicDetails = ({
	loanDetails,
	openSideBar,
	// dropLocationSave,
	handleChangeData,
	setTableLoading = null,
	params,
	getImageData
}) => {
	const {register,watch,reset,handleSubmit,setValue,setError,formState: { errors }} = useForm();
	const [collectedAmount, setCollectedAmount] = useState(0);
	const [feeStructures, setFeeStructures] = useState([]);
	const [charges, setCharges] = useState([]);
	const [purposeStructures, setPurposeStructures] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [openAddBorrower, setOpenAddBorrower] = useState(false);
	const [selectedBorrower, setSelectedBorrower] = useState({});
	const [selectedManager, setSelectedManager] = useState([]);
	const [typed, setTyped] = useState(false);
	const [managerList, setManagerList] = useState([]);
	const [chargesDetails, setChargesDetails] = useState({ hasCharges: false, alreadyAdded: 0 });
	const { branches_list, getDashboardLoanApplications } = useContext(OrganizationDashboardContext);
	const { user, all_financers, handlePageLoaded, loan_products } = useContext(MainContext);
	const [selectedApp, setSelectedApp] = useState({});
	const [dealerName, setDealerName] = useState({});
	const [creditCheckedOnce, setCreditCheckedOnce] = useState({status: false,id: ""});
	// const [borrowerReports, setBorrowerReports] = useState({
	// 	cibil: [],
	// 	equifax: []
	// });
	const [purposeModal, setPurposeModal] = useState(false);
	const [productChange, setProductChange] = useState(false);
	const [disbursementChargesModal, setDisbursementChargesModal] = useState(false);
	const flagColors = ["green", "yellow", "red"];
	const [bdoRefs, setBdoRefs] = useState([]);
	const [creditRefs, setCreditRefs] = useState([]);
	const [cancelNach, setCancelNach] = useState(false);
	
	const [cibilReportDetail, setCibilReportDetail] = useState("");
	const [reportReload, setReportReload] = useState(false);
	const [isHidden, setIsHidden] = useState(false);
	const [disbursementChargesData, setDisbursementChargesData] = useState({});
	const [updateBorrower, setUpdateBorrower] = useState({
		id: "",
		name: ""
	});
	const [applicationBorrower, setApplicationBorrower] = useState({});
	const [incentivePoints, setIncentivePoints] = useState([]);
	const numberValidateRegex = /[+-]?([0-9]*[.])?[0-9]+/;
	const [currentEmployee, setCurrentEmployee] = useState("");
	const [isUpdate, setIsUpdate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [managerIndex, setManagerIndex] = useState([]);
	const [cibilLoading, setCibilLoading] = useState(false);
	const [showReferenceModal, setShowReferenceModal] = useState({
		show: false,
		refType: "",
		references: ""
	});
	// Shivani-PHF491, MANPREET KAUR-PHF310, AMAN RANA-PHF607, VINIT-PHF141, VISHAL BHANOT-PHF519, GARIMA-PHF892
	const incentiveAccessForVehicle = ["PHF491", "PHF310", "phf607", "PHF141", "vishal22", "shgarima21"];
	// pushapduggal-PHF491, shivaniops-PHF606, Priyanka Kohli-phf1026
	const incentiveAccessForAll = ["pushapduggal", "shivaniops", "phf1026"];
	const handleInputChange = (e, charge_id) => {
		const { name, value } = e.target;
		setDisbursementChargesData({
			...disbursementChargesData,
			[name]: {
				amount: value,
				charge_id
			}
		});
	};

	const handleSubmitCharges = () => {
		handlePageLoaded(true);

		// Check if all required charges have been filled
		const allRequiredChargesFilled = charges
			.filter(charge => charge.amount === null) // filter out charges with no predefined amount
			.every(charge => disbursementChargesData[charges.indexOf(charge)] && disbursementChargesData[charges.indexOf(charge)].amount);

		if (!allRequiredChargesFilled) {
			toast.error("Please fill all Required Charges");
			handlePageLoaded(false);
			return;
		}

		const numeric = Object.values(disbursementChargesData).every(item => {
			const value = item.amount;
			const isNumeric = /^\d*\.?\d+$/;
			return isNumeric.test(value);
		});

		if (!numeric) {
			toast.error("Please enter valid Amount");
			handlePageLoaded(false);
			return;
		}
		var length = Object.keys(disbursementChargesData).length;

		if (!length) {
			toast.error("Please enter Amount");
			handlePageLoaded(false);
			return false;
		}

		let data = {
			charges: disbursementChargesData,
			loan_id: loanDetails.loan_app_enc_id
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-products/save-assigned-charges`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					openSideBar(loanDetails.loan_app_enc_id);
					toast.success("Disbursement Charges Updated Successfully");
					setDisbursementChargesModal(false);
				} else {
					toast.error("An error occurred, please try again");
				}
				handlePageLoaded(false);
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
			});
	};

	const fetchCollectionManagers = async () => {
		const data = { branch_id: loanDetails.branch_id };

		try {
		  const response = await axios.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-collection-managers`,
				data
		  );

		  if (response.data.response.status === 200) {
				setManagerList(response.data.response.data);
		  } else {
				setManagerList([]);
		  }
		} catch (error) {
		  toast.error(error.message);
		}
	  };

	useEffect (() => {
		if(loanDetails.field_officer_id && managerList){
			let selectedManagerIndex = managerList.filter(val => val.user_enc_id == loanDetails.field_officer_id);
			setManagerIndex(selectedManagerIndex);
		}else{
			setManagerIndex([]);
		}
	}, [managerList, loanDetails]);

	const copyLink =
		window.location.origin + "/account/loan-application/" + loanDetails.loan_app_enc_id;
	const copy = async () => {
		await navigator.clipboard.writeText(copyLink);
		toast.success("Link copied");
	};

	const searchParams = useParams();
	const handleShareAccess = row => {
		setSelectedApp(row);
	};
	const hideAccessModal = () => {
		setSelectedApp({});
	};
	const handleShareDealer = row => {
		setDealerName(row);
	};
	const hideDealerModal = () => {
		setDealerName({});
	};
	const hideReferenceModal = () => {
		setShowReferenceModal({ show: false, refType: "", references: "" });
	};

	const fetchCibil = (rawData, cibil_type) => {
		const data = cibilEvaluationData(cibil_type, rawData);
		axios
			.post(`${process.env.REACT_APP_SERVICES_API_URL}${cibilUrls[cibil_type]}`, data)
			.then(response => {
				if (response.data.code === 200) {
					handlePageLoaded(false);
					setCibilLoading(false);
					setCibilReportDetail(response.data.PdfLink);
				} else {
					setCibilLoading(false);
					handlePageLoaded(false);
					toast.error(
						response.data.response && response.data.response.ErrorDesc
							? response.data.response.ErrorDesc
							: response.data.message
								? response.data.message
								: "An error has occurred, Please try again later. Code:C201"
					);
				}
			})
			.catch(error => {
				setCibilLoading(false);
				handlePageLoaded(false);
				toast.error(
					"An Internal server error has occurred, Please try again " +
						(error ? "code:" + error : "")
				);
			});
	};
	
	const handleCibilCheck = (data, cibil_type = "equifax") => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		const canCheckWithOneProof =
			["E-Rickshaw", "EV Two Wheeler", "Two Wheeler"].includes(loanDetails.loan_product) &&
			data.gender == "2";
		if (
			!canCheckWithOneProof &&
			!(
				(data.aadhaar_number && data.pan_number) ||
				(data.aadhaar_number && data.voter_card_number) ||
				(data.aadhaar_number && data.driving_license_number) ||
				(data.pan_number && data.voter_card_number) ||
				(data.pan_number && data.driving_license_number) ||
				(data.aadhaar_number && data.passport_number) ||
				(data.voter_card_number && data.passport_number) ||
				(data.voter_card_number && data.driving_license_number) ||
				(data.pan_number && data.passport_number)
			)
		) {
			toast.error(
				`Id details (PAN, Aadhaar, Voter, Passport, Driving License) not found, Please fill the data to fetch ${cibil_type}`
			);
			return false;
		}
		if (
			canCheckWithOneProof &&
			!(data.aadhaar_number || (data.pan_number && data.voter_card_number && data.passport_number))
		) {
			toast.error(
				`Please add Aadhaar Number or PAN Card and Voter Card number to fetch ${cibil_type}`
			);
			return false;
		}
		if (!data.loan_co_app_enc_id) {
			data["co_applicant_dob"] = data.applicant_dob;
			data["name"] = data.applicant_name;
		} else {
			data["amount"] = loanDetails.amount;
			data["loan_type_code"] = loanDetails.loan_type_code;
		}
		for (let i = 0; i < requiredDetails.length; i++) {
			let val = requiredDetails[i];
			if (!data[val]) {
				toast.error(
					(val === "co_applicant_dob" ? "DOB" : val) +
						" is not found, Please fill the data to fetch CIBIL"
				);
				return false;
			}
		}
		if (!data["phone"] && user.designation !== "State Credit Head") {
			toast.error(`Phone is not found, Please fill the data to fetch ${cibil_type} report`);
			return false;
		}
		handlePageLoaded(true);
		setCibilLoading(
			(data.loan_co_app_enc_id ? data.loan_co_app_enc_id : data.phone) + cibil_type
		);
		// setTimeout(() => {
		//   handlePageLoaded(false);
		//   setCibilLoading(false);
		//   setCibilReportDetail("https://eycdn.ams3.digitaloceanspaces.com/files/loans/credit-documents/woJYaiektm/YiROvXDU.pdf?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=7NQ22L3JWB5YJK7SZ5VJ%2F20230908%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230908T110456Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Signature=55f820ce8be531934dea4ddcb28a6ac5e9d1d518c65534536832ac6ec8318e8b");
		// }, 2000);
		// return false;
		axios
			.post(`${process.env.REACT_APP_SERVICES_API_URL}auth/get-token`, {})
			.then(response => {
				if (response.data.code === 200) {
					data["tokenValue"] = response.data.response.tokenValue;
					fetchCibil(data, cibil_type);
				} else {
					toast.error(
						response.data.message
							? response.data.message
							: "An error has occurred, Please try again later. Code:T201"
					);
					setCibilLoading(false);
					handlePageLoaded(false);
				}
			})
			.catch(error => {
				setCibilLoading(false);
				handlePageLoaded(false);
				toast.error(
					"An Internal server error has occurred, Please try again " +
						(error ? "code:" + error : "")
				);
			});
	};

	let totalCount = 0;

	const defaultPurposeOptions = loanDetails.loanPurposes
		? loanDetails.loanPurposes.map(value => ({
			value: value.financer_loan_purpose_enc_id,
			label: value.purpose
		  }))
		: [];

	const purposeOptions = purposeStructures.map(purpose => ({
		value: purpose.financer_loan_product_purpose_enc_id,
		label: purpose.purpose
	}));

	const selectedPurposes = selected => {
		setSelectedOptions(selected);
	};

	const updatePurposes = () => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (selectedOptions && selectedOptions.length) {
			handlePageLoaded(true);
			let data = {
				type: "purposes",
				value: selectedOptions.map(option => option.value),
				id: loanDetails.loan_app_enc_id
			};
			axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/loan-update`,
					data
				)
				.then(response => {
					if (response.data.response.status === 200) {
						openSideBar(loanDetails.loan_app_enc_id);
						toast.success("Purposes Updated Successfully");
					}
					handlePageLoaded(false);
				})
				.catch(error => {
					toast.error(error.message);
					handlePageLoaded(false);
				});
		} else {
			toast.error("Please Update Purpose");
			return false;
		}
	};

	const changeBorrowerModal = currentId => {
		if (readOnlyUsers.includes(user.username)) {
			return false;
		}
		if (loanDetails && loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length) {
			const findBorrowerType = loanDetails.loanCoApplicants.filter(
				borrower => borrower.borrower_type === "Borrower"
			);
			if (findBorrowerType.length) {
				setUpdateBorrower({
					id: findBorrowerType[0].loan_co_app_enc_id,
					name: findBorrowerType[0].name,
					currentId
				});
			}
		  }
	};

	const onHide = () => {
		setUpdateBorrower({});
	};

	const pointsType = watch("points_type");
	const onsubmit = values => {
		setIsLoading(true);
		values.loan_app_enc_id = loanDetails.loan_app_enc_id;
		values.employee = currentEmployee.value ? currentEmployee.value : currentEmployee;
		if(values.employee === "empty" || values.employee === "" ){
			setError("employee", { type: "custom", message: "This field is required" });
			return false;
		}
		if(isUpdate){
			values.points_enc_id = isUpdate;
		}
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/${isUpdate ? "update-incentive-points" : "add-incentive-points"}`, values).then(
			response => {
				setIsLoading(false);
				if(response.data.response.status === 200){
					reset();
					setCurrentEmployee("empty");
					setIsUpdate(false);
					getIncentivePoints(loanDetails.loan_app_enc_id);
				}
			}
		);
		
	};

	const getIncentivePoints = () => {
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/get-incentive-points`, {loan_id: loanDetails.loan_app_enc_id})
			.then(
				response => {
					if(response.data.response.status === 200){
						setIncentivePoints(response.data.response.incentivePoints);
					}else{
						setIncentivePoints([]);
					}
				}
			).catch(error => {
				toast.error(error.message);
			});
	};

	const deleteIncentive = (points_id) => {
		if (!window.confirm("Are you sure you want to delete Incentive points?")) {
			return false;
		}
		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee-incentive-points/delete-incentive-points`, {points_id: points_id})
			.then(
				response => {
					if(response.data.response.status === 200){
						getIncentivePoints(loanDetails.loan_app_enc_id);
					} else {
						toast.error("An error has occurred. Please try again later");
					}
				}
			).catch(error => {
				toast.error(error.message);
			});
	};

	const handleAssignInverstigationOfficer = () => {
		if (!window.confirm("Are you sure you want to Assign Investigation Officer?")) {
			return false;
		}
		let data = {
			type: "field_officer_id",
			field_officer: selectedManager.name,
			value: selectedManager.user_enc_id,
			id: loanDetails.loan_app_enc_id
		};

		axios.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loans/loan-update`, data)
			.then(
				response => {
					if(response.data.response.status === 200){
						toast.success("Investigation Officer Assigned Successfully");
						handleChangeData(selectedManager.name, "field_officer");
						setTyped(false);
					} else {
						toast.error("An error has occurred. Please try again later");
					}
				}
			).catch(error => {
				toast.error(error.message);
			});
	};

	const OnChangeData=(e)=>{
		setTyped(e);
		setSelectedManager(e[0]);
	};

	const editIncentive = (points_id) => {
		setIsUpdate(points_id);
		let editincentive = incentivePoints.filter(item => item.points_enc_id === points_id);
		setValue("employee",editincentive[0]["user_enc_id"]);
		if(editincentive[0]["points"] === "login" || editincentive[0]["points"] === "disbursment number" || 
			editincentive[0]["points"] === "disbursment amount" || editincentive[0]["points"] === "referral"){
			setValue("points_type", editincentive[0]["points"]);
			setValue("points_type_others", "");
		}else{
			setValue("points_type", "others");
			setValue("points_type_others", editincentive[0]["points"]);
		}
		setValue("points_value", editincentive[0]["points_value"]);
		setCurrentEmployee({value: editincentive[0]["user_enc_id"], label: editincentive[0]["employee"]});
	};

	const cancelIncentiveUpdate = () => {
		reset();
		setIsUpdate(false);
		setCurrentEmployee("empty");
	};

	const downloadQr = () => {
		window.open(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-applications/download-qr-code?url=${copyLink}&name=Qr-code${loanDetails.application_number ? loanDetails.application_number : ""}`, "_blank").focus();
	};

	useEffect(() => {
		if (loanDetails.loan_app_enc_id) {
			setCreditCheckedOnce({ status: false, id: "" });
			const appBorrrower = loanDetails.loanCoApplicants.filter(
				borrower => borrower.borrower_type === "Borrower"
			);
			setApplicationBorrower(appBorrrower[0]);
			if (loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length) {
				const findOtherUsers = loanDetails.loanCoApplicants.filter(
					borrower => borrower.borrower_type !== "Borrower"
				);
				for (let i = 0; i < findOtherUsers.length; i++) {
					if (findOtherUsers[i].creditLoanApplicationReports.length > 0) {
						setCreditCheckedOnce({
							status: true,
							id: findOtherUsers[i].loan_co_app_enc_id
						});
						// return false
					}
				}
			}
			getIncentivePoints();
		}
		if(loanDetails.loan_app_enc_id && vehicleProductsList.includes(loanDetails.loan_product)){
			fetchCollectionManagers();
		}
		if (loanDetails && loanDetails.loan_app_enc_id && loanDetails.assignedLoanPayments.length) {
			let tempPayment = 0;
			loanDetails.assignedLoanPayments.map(payment => {
				if (payment.payment_status === "captured") {
					tempPayment += parseFloat(payment.payment_amount);
				}
			});
			setCollectedAmount(tempPayment);
			
		} else {
			setCollectedAmount(0);
		}
	}, [loanDetails]);

	useEffect(() => {	
		if (searchParams.id) {
			setIsHidden(true);
		}
	}, []);

	useEffect(() => {
		if (loanDetails && loanDetails.loan_product && loan_products && loan_products.length) {
			const tempFee = loan_products.filter(
				product => product.name === loanDetails.loan_product
			);

			const finalFee = tempFee.length ? tempFee[0].financerLoanProductLoginFeeStructures : [];

			const finalPurposes = tempFee.length ? tempFee[0].financerLoanProductPurposes : [];

			const tempCharges = tempFee.length
				? tempFee[0].financerLoanProductDisbursementCharges
				: [];
			let finalCharges = [];
			setPurposeStructures(finalPurposes);
			setFeeStructures(finalFee);
			if (tempCharges && tempCharges.length) {
				tempCharges.forEach(function (item) {
					let tempAmount = { amount: null };
					if (
						loanDetails.assignedDisbursementCharges &&
						loanDetails.assignedDisbursementCharges.length
					) {
						const existedVal = loanDetails.assignedDisbursementCharges.filter(
							e => e.disbursement_charges_enc_id === item.disbursement_charges_enc_id
						);
						if (existedVal && existedVal.length) {
							tempAmount["amount"] = existedVal[0].amount;
						}
					}
					finalCharges.push({ ...item, ...tempAmount });
				});
			}
			setCharges(finalCharges);
		} else {
			setFeeStructures([]);
			setPurposeStructures([]);
		}
		if (
			loanDetails &&
			loanDetails.assignedDisbursementCharges &&
			loanDetails.assignedDisbursementCharges.length
		) {
			let tempCharges = 0;
			loanDetails.assignedDisbursementCharges.forEach(function (item) {
				tempCharges += item.amount ? parseFloat(item.amount) : 0;
			});
			setChargesDetails({ hasCharges: true, alreadyAdded: tempCharges });
		}

		if (
			loanDetails &&
			loanDetails.loanApplicationsReferences &&
			loanDetails.loanApplicationsReferences.length
		) {
			setBdoRefs(
				loanDetails.loanApplicationsReferences.filter(item => item.reference === "BDO")
			);
			setCreditRefs(
				loanDetails.loanApplicationsReferences.filter(item => item.reference === "Credit")
			);
		}
	}, [loan_products, loanDetails]);

	return (
		<>
			{user.user_type !== "Lender Auditor" ? (
				<>
					<div className="row">
						<div className="col-12 sidebar-actions justify-content-end">
							<div className="mx-1">
								<InitiateTvr
									feeStructures={feeStructures}
									loanDetails={loanDetails}
									reload={openSideBar}
									collectedAmount={collectedAmount}
								/>
							</div>
							{propertyProductsList.includes(loanDetails.loan_product) ? (
								<>
									<div className="mx-1">
										<InitiateTechnical
											feeStructures={feeStructures}
											loanDetails={loanDetails}
											reload={openSideBar}
											collectedAmount={collectedAmount}
										/>
									</div>
									<div className="mx-1">
										<InitiateLegal
											feeStructures={feeStructures}
											loanDetails={loanDetails}
											reload={openSideBar}
											collectedAmount={collectedAmount}
										/>
									</div>
								</>
							): ""}
							{fiEligibleProducts.includes(loanDetails.loan_product) && (user.department === "Credit" || user.user_type === "Financer") ? (
								<div className="mx-1">
									<InitiateFi
										loanDetails={loanDetails}
										reload={openSideBar}
									/>
								</div>
							) : (
								""
							)}
							{!["E-Rickshaw", "EV Two Wheeler", "Two Wheeler"].includes(
								loanDetails.loan_product
							) ? (
									<div className="mx-1">
										<InitiatePd loanDetails={loanDetails} reload={openSideBar} />
									</div>
								) : (
									""
								)}
							<PaymentDetails
								feeStructures={feeStructures}
								loanDetails={loanDetails}
								applicationBorrower={applicationBorrower}
								reload={openSideBar}
							/>
							<div className="mx-1">
								{ loanDetails.loan_status == 28 ||loanDetails.loan_status == 32 ? <button className="btn btn-primary" disabled={true}> <IoPersonAdd /> Add Co-Borrower/Guarantor</button>
									:
									<button
										className="btn btn-primary"
										onClick={
											loanDetails.loan_status !== 28 && loanDetails.loan_status !== 32 // feeStructures.length &&
												? //   moment(loanDetails.apply_date).isAfter(
											  //     "2023-08-09 23:59:59"
											  //   ) &&
											  //   loanDetails.assignedLoanPayments.filter(
											  //     loan => loan.payment_status === "captured"
											  //   ).length === 0
											  //   ? () => {
											  //       toast.error("Login Fee Due");
											  //     }
											  //   :
											  () => setOpenAddBorrower(true)
												: () => {
													const selectedStatus = loanStatusData.loanStatus.filter(
														value =>
															value.value ===
															parseInt(loanDetails.loan_status)
													);
													toast.error(
														`You cannot add a new borrower because case is ${
															selectedStatus && selectedStatus.length > 0
																? selectedStatus[0].name
																: "--"
														}.`
													);
											  }
										}
									>
										<IoPersonAdd /> Add Co-Borrower/Guarantor
									</button>
								}
							</div>

							<div className="mx-1">
								<InitiateReleasePayment
									loanDetails={loanDetails}
									reload={openSideBar}
									branches={branches_list}
								/>
							</div>
							<div className="mx-1">
								<DropLocation openSideBar={openSideBar} label="Drop Location" modal={true} loanDetails={loanDetails} type="sidebar"/>
							</div>
						</div>
					</div>
					<hr />
				</>
			) : ""}
			{loanDetails && loanDetails.old_application_number && loanDetails.old_application_number !== loanDetails.application_number ? (
				<marquee className="text-danger"><sup>*</sup>Please note that the Loan Account Number has been changed
					from <b>{loanDetails.old_application_number}</b> to <b>{loanDetails.application_number}</b>. Please refer to the physical file and update Loan Account Number accordingly.</marquee>
			) : ""}
			<div className="row mb-1 mb-3 p-2 position-relative">
				<p className="d-flex align-items-center justify-content-end last-updated">
					<span className="mr-1">Last Updated : </span>
					{loanDetails.updated_on ? moment(loanDetails.updated_on).format("DD MMM YYYY" + " / " + "h:mm a") : "-"}
				</p>
				{(process.env.REACT_APP_TYPE === "DEV" || user?.organization_username === "phfleasing") && user?.user_type !== "Lender Auditor" && !["Active", "Issued"].includes(loanDetails?.nach_approved) ? (
					<div className="mx-1 mt-1 d-flex justify-content-end">
						<NachRegisterModal loanDetails={loanDetails} reload={openSideBar} applicantName={applicationBorrower?.name} type="loan_applications"/>
					</div>
				): ""}
				<div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize sidebar-input-width">
						<span className="me-1">Loan Number:</span>
						{loanDetails.application_number ? (
							loanDetails.application_number
						) : (
							<EditInput
							    getData={(_, val) => handleChangeData(val, "application_number")}
								placeholder="Add Loan Number"
								defaultValue={
									loanDetails.application_number
										? loanDetails.application_number
										: ""
								}
								api={"loans/update-application-number"}
								id={loanDetails.loan_app_enc_id}
								inheritedCondition={loanDetails.is_completed !== "1"}
								loanDetailStatus={
									loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
								}
							/>
						)}
					</p>
				</div>
				<div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
						<span className="me-2">Branch:</span>
						{loanDetails.nach_approved !== "Issued" && loanDetails.nach_approved !== "Active" ? 
							<EditSelectInput
								optionList={branches_list}
								getData={openSideBar}
								defaultValue={loanDetails.branch_id ? loanDetails.branch_id : ""}
								defaultLabel={loanDetails.branch ? loanDetails.branch : ""}
								api={"loans/add-loan-branch"}
								id={loanDetails.loan_app_enc_id}
								selectPlaceholder="Choose Branch"
								parentId={user.organization_enc_id}
								inheritedCondition={loanDetails.is_completed !== "1"}
								required={true}
								loanDetailStatus={
									loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
								} 
							/> :
							<>
								{loanDetails.branch}
							</>
						}
					</p>
				</div>
				
				<div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
						<span className="me-1">Applicant Name:</span> 
						{applicationBorrower?.name} 
						{applicationBorrower?.father_name && (
							<p key={applicationBorrower?.loan_co_app_enc_id} className="mb-0 ms-1">
								<span className="me-1 color-black">{applicationBorrower?.gender === "1" ? "S/o" : "D/o"}</span>
								{applicationBorrower?.father_name}
							</p>
						)}
					</p>
				</div>
				<div className="col-md-6">
					<p className="emiAmt d-flex flex-wrap align-items-center ht-40">
						<span>Loan Amount:</span>
						<BiRupee />{" "}
						{loanDetails.amount
							? parseFloat(loanDetails.amount).toLocaleString("en-IN")
							: ""}
					</p>
				</div>
				{loanDetails.loan_product ? (
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
							<span className="me-1">Loan Product:</span> {loanDetails.loan_product}
							{(user.user_type === "Financer" || user.specialAccessRole) && loanDetails.is_completed !== "1" && (loanDetails.nach_approved !== "Issued" && loanDetails.nach_approved !== "Active") ? (
								<span
									onClick={() => setProductChange(true)}
									className="pointer-cursor ml-5"
								>
									<FaEdit color="#00a0e3" fontSize="16px" />
								</span>
							) : (
								""
							)}
						</p>
					</div>
				) : (
					""
				)}
				<div className="col-md-6">
					<div className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
						{loanDetails.nach_approved ? (
							<>
								Nach Status: {" "}
								<Badge
									className="text-capitalize mx-2 py-1"
									bg={statusColorObj[loanDetails.nach_approved || "Not Registered"]}
									pill
								>
									{loanDetails.nach_approved || "Not Registered"}
								</Badge>
								{loanDetails.nach_approved === "Issued" && (["nisha123", "Satparkash"].includes(user.username) || user.user_type === "Financer") ? (
									<span className="text-red pointer-cursor" onClick={() =>  setCancelNach({loan_account_enc_id:loanDetails.loan_account_enc_id, payment_registration_enc_id: loanDetails.payment_registration_enc_id})}><AiOutlineCloseCircle size={18}/></span>
								) : ""}
							</>
						): ""}
					</div>
				</div>
				<div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
						<span className="me-2">EOB:</span>
						{loanDetails.priority ? (
							<EditSelectInput
								optionList={priorityList}
								getData={(_, val) => handleChangeData(val, "priority")}
								defaultValue={loanDetails.priority}
								defaultLabel={loanDetails.priority}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								selectPlaceholder="Choose Priority"
								parentId={user.organization_enc_id}
								inheritedCondition={loanDetails.is_completed !== "1"}
								required={true}
								type="priority"
								loanDetailStatus={
									loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
								} 
							/>	
						) : !loanDetails.priority && (user.user_type === "Financer" || user.department === "Credit") ? (
							<EditRadioInput
								optionList={priorityList}
								defaultValue={loanDetails.priority}
								type="priority"
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								parentId={user.organization_enc_id}
								inheritedCondition={loanDetails.is_completed !== "1"}
								required={true}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								reload={(_, val) => handleChangeData(val, "priority")}
							/>
						) : "-"}
					</p>
				</div>
				{loanDetails.loan_product === "E-Rickshaw" ? (
					<>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">E-Rickshaw Purpose:</span>
								<EditRadioInput
									optionList={eRishawPurposeList}
									defaultValue={loanDetails.erickshaw_purpose}
									reload={(_, val) => handleChangeData(val, "erickshaw_purpose")}
									type="erickshaw_purpose"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									inheritedCondition={loanDetails.is_completed !== "1"}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									// reload={openSideBar}
								/>
							</p>
						</div>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">E-Rickshaw Type:</span>
								<EditRadioInput
									optionList={eRishawTypeList}
									defaultValue={loanDetails.erickshaw_type}
									reload={(_, val) => handleChangeData(val, "erickshaw_type")}
									type="erickshaw_type"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
								/>
							</p>
						</div>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">Asset Type:</span>
								<EditRadioInput
									optionList={eRishawBatteryType}
									defaultValue={loanDetails.battery_type}
									reload={(_, val) => handleChangeData(val, "battery_type")}
									type="battery_type"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									inheritedCondition={loanDetails.is_completed !== "1"}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									// reload={openSideBar}
								/>
							</p>
						</div>
					</>
				) : (
					""
				)}
				<div className="col-md-6">
					<p className="d-flex align-items-center ht-40">
						<span className="mr-1">Collected Amount:</span> <BiRupee />{" "} {collectedAmount.toLocaleString("en-IN")}
					</p>
				</div>
				{/* <div className="col-md-6">*/}
				{/*  <p>*/}
				{/*    <span>Loan Type:</span>{" "}*/}
				{/*    {loanDetails.loan_type ? loanDetails.loan_type : ""}*/}
				{/*  </p>*/}
				{/* </div>*/}
				<div className="col-md-6">
					<p className="d-flex align-items-center ht-40">
						<span className="me-1">Loan Applied:</span>{" "}
						{loanDetails.apply_date
							? moment(loanDetails.apply_date).format("DD MMM YYYY h:mm a")
							: ""}
					</p>
				</div>
				{applicationBorrower?.email ? (
					<div className="col-md-6">
						<p className="d-flex align-items-center ht-40">
							<span className="me-1">Applicant Email:</span> {applicationBorrower?.email}
						</p>
					</div>
				) : ""}
				<div className="col-md-6">
					<p className="d-flex align-items-center ht-40">
						<span className="me-1">Phone:</span> {applicationBorrower?.phone}
					</p>
				</div>
				{applicationBorrower?.has_smartphone &&
					<div className="col-md-6">
						<p className="d-flex align-items-center ht-40">
							<span className="me-1">Has Smartphone:</span> {applicationBorrower?.has_smartphone ? hasSmartphone[applicationBorrower.has_smartphone] : "-"}
						</p>
					</div>
				}
				{loanDetails?.fi_collection_manager ? (
					<div className="col-md-6">
						<p className="d-flex align-items-center ht-40 text-capitalize">
							<span className="me-1">Fi Collection Manager:</span> {loanDetails?.fi_collection_manager}
						</p>
					</div>
				) : ""}
				<div className="col-md-6">
					<p className="d-flex align-items-center ht-40">
						<span className="me-1">Purpose: </span>
						<div className="list-data d-inline-block">
							{loanDetails.loanPurposes && loanDetails.loanPurposes.length
								? loanDetails.loanPurposes.map((value, index) => {
									return (
										<span
											key={`purposes-${loanDetails.loan_app_enc_id}${index}`}
										>
											{value.purpose}
										</span>
									);
								  })
								: "-"}
								
						</div>
						{loanDetails.loan_status &&
						loanDetails.loan_status !== "31" &&
						loanDetails.loan_status !== "32" ? (
								<span className="mx-2">
									{purposeStructures && purposeStructures.length && loanDetails.is_completed !== "1" && (loanDetails.nach_approved !== "Issued" && loanDetails.nach_approved !== "Active") ? (
										<>
											<span
												onClick={() => setPurposeModal(true)}
												className="pointer-cursor"
											>
												<FaEdit color="#00a0e3" fontSize="16px"/>
											</span>
										</>
									) : (
										""
									)}
								</span>
							) : (
								""
							)}
					</p>
				</div>

				{loanDetails.loan_product === "E-Rickshaw" ? (
					<div className="col-md-6 sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
							<span className="mr-1">Number of Batteries: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
							<EditInput
								getData={(_, val) => handleChangeData(val, "number_of_batteries")}
								placeholder="Add Number of Batteries"
								defaultValue={
									loanDetails.number_of_batteries
										? loanDetails.number_of_batteries
										: ""
								}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								formType={"number_of_batteries"}
								validateAsNumber={true}
								inheritedCondition={loanDetails.is_completed !== "1"}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								editInputActive={true}
								type="amount"
							/>
						</p>
					</div>
				) : ""}
				{vehicleProductsList.includes(loanDetails.loan_product) ? (
					<>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Vehicle Delivery Date: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									type="customDate"
									getData={(_, val) => handleChangeData(val, "vehicle_delivery_date")}
									placeholder="Vehicle Delivery Date"
									defaultValue={
										loanDetails?.vehicle_delivery_date ? loanDetails.vehicle_delivery_date : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"vehicle_delivery_date"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									editInputActive={true}
									inheritedCondition={loanDetails.is_completed !== "1"}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Invoice Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "invoice_number")}
									placeholder="Add Invoice Number"
									defaultValue={
										loanDetails.invoice_number ? loanDetails.invoice_number : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"invoice_number"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									editInputActive={true}
									inheritedCondition={loanDetails.is_completed !== "1"}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Invoice Date: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									type="customDate"
									getData={(_, val) => handleChangeData(val, "invoice_date")}
									placeholder="Enter Invoice Date"
									defaultValue={
										loanDetails?.invoice_date ? loanDetails.invoice_date : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"invoice_date"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									editInputActive={true}
									inheritedCondition={loanDetails.is_completed !== "1"}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Vehicle Color: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "vehicle_color")}
									placeholder="Add Vehicle Color"
									defaultValue={
										loanDetails.vehicle_color ? loanDetails.vehicle_color : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"vehicle_color"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Motor Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "motor_number")}
									placeholder="Add Motor Number"
									defaultValue={loanDetails.motor_number ? loanDetails.motor_number : ""}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"motor_number"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Battery Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "battery_number")}
									placeholder="Add Battery Number"
									defaultValue={
										loanDetails.battery_number ? loanDetails.battery_number : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"battery_number"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">RC Number: </span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "rc_number")}
									placeholder="Add RC Number"
									defaultValue={
										loanDetails.rc_number ? loanDetails.rc_number : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"rc_number"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="me-1">Chassis Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "chassis_number")}
									placeholder="Add Chassis Number"
									defaultValue={
										loanDetails.chassis_number ? loanDetails.chassis_number : ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"chassis_number"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="mr-1">Year of Model: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "model_year")}
									placeholder="Add Year of Model"
									defaultValue={
										loanDetails.model_year
											? loanDetails.model_year
											: ""
									}
									type="amount"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"model_year"}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
								/>
							</p>
						</div>
						{loanDetails.loan_product === "Two Wheeler" ? (
							<div className="col-md-6 sm-input-box sm-inputbox">
								<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
									<span className="mr-1">Engine Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
									<EditInput
										getData={(_, val) => handleChangeData(val, "engine_number")}
										placeholder="Add Engine Number"
										defaultValue={
											loanDetails.engine_number
												? loanDetails.engine_number
												: ""
										}
										api={"loans/loan-update"}
										id={loanDetails.loan_app_enc_id}
										formType={"engine_number"}
										loanDetailStatus={
											loanDetails.loan_status
												? parseInt(loanDetails.loan_status)
												: ""
										}
										inheritedCondition={loanDetails.is_completed !== "1"}
										editInputActive={true}
									/>
								</p>
							</div>
						) : ("")}
						
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="mr-1">Ex-Showroom Price: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "ex_showroom_price")}
									placeholder="Add Ex-Showroom Price"
									defaultValue={
										loanDetails.ex_showroom_price
											? loanDetails.ex_showroom_price
											: ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"ex_showroom_price"}
									asAmount={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
									type="amount"
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="mr-1">On-Road Price: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "on_road_price")}
									placeholder="Add On-Road Price"
									defaultValue={
										loanDetails.on_road_price
											? loanDetails.on_road_price
											: ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"on_road_price"}
									asAmount={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
									type="amount"
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="mr-1">Margin Money: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "margin_money")}
									placeholder="Add Margin Money"
									defaultValue={
										loanDetails.margin_money
											? loanDetails.margin_money
											: ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"margin_money"}
									asAmount={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									editInputActive={true}
									type="amount"
								/>
							</p>
						</div>
						<div className="col-md-6 sm-input-box sm-inputbox">
							<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
								<span className="mr-1 me-10">LTV: </span>
								<EditInput
									getData={(_, val) => handleChangeData(val, "ltv")}
									placeholder="Add Loan-to-Value"
									defaultValue={
										loanDetails.ltv
											? loanDetails.ltv
											: ""
									}
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									formType={"ltv"}
									asAmount={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									editInputActive={true}
									inheritedCondition={loanDetails.is_completed !== "1"}
									type="amount"
								/>
							</p>
						</div>
						<div className="col-md-6">
							<p className="d-flex align-items-center ht-48">
								<span className="mr-1">Assign Dealer: </span>
								{loanDetails.dealer_name ? (
									<>
										<img className="dealerLogo" src={loanDetails.dealer_logo} />{" "}
										{loanDetails.dealer_name}
									</>
								) : ("")}
								{!readOnlyUsers.includes(user.username) && loanDetails.is_completed !== "1" && (!loanDetails.dealer_name || (user.user_type === "Financer" || user.specialAccessRole)) ? (
									<button
										onClick={() => handleShareDealer(loanDetails)}
										className="btn-share mb-0 ml-3"
									>
										<RiUserSharedFill color="#00a0e3"
											size={22}/>
									</button>
								): ""}
							</p>
						</div>
					</>
				) : (
					""
				)}
				{propertyProductsList.includes(loanDetails.loan_product) ? (
					<>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">Mortgage Type:</span>
								<EditRadioInput
									optionList={mortgage_type_options}
									defaultValue={loanDetails.mortgage_type}
									reload={(_, val) => handleChangeData(val, "mortgage_type")}
									type="mortgage_type"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									// reload={openSideBar}
								/>
							</p>
						</div>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">Asset Type:</span>
								<EditRadioInput
									optionList={property_asset_types}
									defaultValue={loanDetails.asset_type}
									reload={(_, val) => handleChangeData(val, "asset_type")}
									type="asset_type"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									// reload={openSideBar}
								/>
							</p>
						</div>
						<div className="col-md-6">
							<p className="d-flex flex-wrap align-items-center ht-40 text-capitalize">
								<span className="me-1">TV Scheme:</span>
								<EditRadioInput
									optionList={property_tv_scheme}
									defaultValue={loanDetails.has_tv_scheme}
									reload={(_, val) => handleChangeData(val, "has_tv_scheme")}
									type="has_tv_scheme"
									api={"loans/loan-update"}
									id={loanDetails.loan_app_enc_id}
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status
											? parseInt(loanDetails.loan_status)
											: ""
									}
									inheritedCondition={loanDetails.is_completed !== "1"}
									// reload={openSideBar}
								/>
							</p>
						</div>
					</>
				) : (
					""
				)}
				<div className="col-md-6  sm-input-box sm-inputbox">
					<p className="d-flex align-items-center ht-48">
						<span className="mr-1">EMI Collection Date: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditEmiCollectionDate
							getData={openSideBar}
							isVehicleCase={vehicleProductsList.includes(loanDetails.loan_product)}
							loanDetails={loanDetails}
							defaultValue={
								loanDetails.emi_collection_date
									? loanDetails.emi_collection_date
									: ""
							}
							api={"loans/loan-update"}
							id={loanDetails.loan_app_enc_id}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							inheritedCondition={loanDetails.is_completed !== "1"}
						/>
					</p>
				</div>
				<div className="col-md-6  sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width ">
						<span className="mr-1">Number of EMIs: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "number_of_emis")}
							placeholder="Enter Number of EMI's"
							defaultValue={
								loanDetails.number_of_emis ? loanDetails.number_of_emis : ""
							}
							api={"loans/loan-update"}
							id={loanDetails.loan_app_enc_id}
							formType={"number_of_emis"}
							validateAsNumber={true}
							loanDetailStatus={	
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
							type="amount"
							inheritedCondition={loanDetails.is_completed !== "1"}
						/>
					</p>
				</div>
				<div className="col-md-6  sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>EMI Amount: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "emi_amount")}
							placeholder="Enter EMI Amount"
							defaultValue={
								loanDetails.emi_amount ? loanDetails.emi_amount : ""
							}
							type="amount"
							api={"loans/loan-update"}
							id={loanDetails.loan_app_enc_id}
							formType={"emi_amount"}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							asAmount={true}
							inheritedCondition={loanDetails.is_completed !== "1"}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6  sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span className="mr-1 me-14">PF: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "pf")}
							placeholder="Enter PF"
							defaultValue={loanDetails.pf ? loanDetails.pf : ""}
							api={"loans/loan-update"}
							id={loanDetails.loan_app_enc_id}
							formType={"pf"}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
							type="amount"
							inheritedCondition={loanDetails.is_completed !== "1"}
						/>
					</p>
				</div>
				<div className="col-md-6  sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span className="mr-1 me-9">ROI: <span style={{ color: "red", fontSize: "13px" }}>*</span></span> 
						<EditInput
							getData={(_, val) => handleChangeData(val, "roi")}
							placeholder="Add ROI"
							defaultValue={loanDetails.roi ? loanDetails.roi : ""}
							api={"loans/loan-update"}
							id={loanDetails.loan_app_enc_id}
							formType={"roi"}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
							type="amount"
							inheritedCondition={loanDetails.is_completed !== "1"}
						/>
					</p>
				</div>
				{/* <div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-48">
						<span className="mr-1">Payment QR:</span>
						<a href={PaymentQr} target="_blank" rel="noreferrer">
							Preview
						</a>
					</p>
				</div>
				<div className="col-md-6">
					<p className="d-flex flex-wrap align-items-center ht-48">
						<span className="mr-1">Payment Link:</span>
						<a href={PaymentLink} target="_blank" rel="noreferrer">
							Preview
						</a>
					</p>
				</div> */}
				{user.user_type !== "Lender Auditor" ? (
					<>
						<div className="col-md-6">
							<p className="d-flex align-items-center ht-48 sm-input-box sm-inputbox">
								<span className="mr-1">Partnership Model: </span>
								<AggrementTypeEdit
									optionList={all_financers}
									getData={openSideBar}
									defaultValue={loanDetails}
									api={"company-dashboard/assign-loan-partner"}
									id={loanDetails.loan_app_enc_id}
									selectPlaceholder="Choose Financer"
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={
										loanDetails.loan_status ? loanDetails.loan_status : ""
									}
									editInputActive={true}
									inheritedCondition={loanDetails.is_completed !== "1"}
								/>
							</p>
						</div>

						<div className="col-md-6">
							<p className="d-flex align-items-center ht-48">
								<span className="mr-1">Shared With: </span>
								{user.user_type === "Financer" || loanDetails.access !== "Read Only" ? (
									<>
										{loanDetails.sharedTo && loanDetails.sharedTo.length > 0 ? (
											<div
												className="register-count"
												onClick={() => handleShareAccess(loanDetails)}
											>
												<div className="reg-img">
													{loanDetails.sharedTo.map((value, index) => {
														if (index <= 6) {
															return (
																<span
																	className={`reg${index + 1} reg`}
																	key={`accessList-${index}`}
																>
																	<img src={value.image} alt="shared" />
																</span>
															);
														} else {
															totalCount++;
														}
													})}
												</div>
												{totalCount > 0 ? (
													<span
														style={{
															color: "#00a0e3",
															fontSize: "13px"
														}}
													>
														{" "}
														+{totalCount}{" "}
													</span>
												) : (
													""
												)}
												<span className="cont">
													<RiUserSharedFill color="#00a0e3" size={22} />
													{/* <?= count($web['webinarRegistrations']) ?> Registered*/}
												</span>
											</div>
										) : (
											<button
												onClick={() => handleShareAccess(loanDetails)}
												className="btn-share mb-0 ml-3"
											>
												<RiUserSharedFill color="#00a0e3"
													size={22}/>
											</button>
										)}
									</>
								) : (
									"-"
								)}
							</p>
						</div>
					</>
				) : ""}
			
				<div className="col-md-12">
					<p className="d-flex ">
						<span>Application Link: </span>
						<div className="d-flex ms-2">
							<div className="d-flex flex-column ">
								<QRCode
									size={115}
									value={copyLink}
									viewBox={"0 0 0 0"}
									className="mb-1"
								/>
								<button type="button" className="btn btn-primary btn-sm" onClick={downloadQr}>
									QR <FaDownload style={{ margin: "0px 2px" }} size={18}/>
								</button>
							</div>

							<div className="d-flex flex-column">
								<button
									type="button"
									className="btn btn-primary btn-sm mb-1"
									onClick={copy}
									style={{ marginLeft: "4px", padding: "6px 8px" }}
								>
									<MdOutlineContentCopy style={{ margin: "0px 2px" }} size={18} />
								</button>
								{!isHidden ? (
									<a
										href={copyLink}
										target="_blank"
										className="btn btn-primary btn-sm mb-1"
										style={{ marginLeft: "4px", padding: "6px 8px" }}
										rel="noreferrer"
									>
										<IoOpenOutline style={{ margin: "0px 2px" }} size={18} />
									</a>
								) : (
									""
								)}
								<a
									href={`https://wa.me/?text=${copyLink}`}
									target="_blank"
									className="btn btn-success btn-sm mb-0"
									style={{ marginLeft: "4px", padding: "6px 8px" }}
									rel="noreferrer"
								>
									<FaWhatsapp size={18} />
								</a>
							</div>
						</div>
					</p>
				</div>
			</div>
			{vehicleProductsList.includes(loanDetails.loan_product) ? (
				<div className="row mb-1 mb-3 p-2">
					<h3 className="mb-3">Vehicle Insurance Details</h3>
					<div className="col-md-6 sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width text-capitalize ">
							<span className="me-1">Company Name: <span style={{ color: "red", fontSize: "13px" }}>*</span></span> 
							<EditInput
								getData={(_, val) => handleChangeData(val, "name_of_company")}
								placeholder="Add Company Name"
								defaultValue={
									loanDetails.name_of_company
										? loanDetails.name_of_company
										: ""
								}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								formType={"name_of_company"}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								editInputActive={true}
								inheritedCondition={loanDetails.is_completed !== "1"}
							/>
						</p>
					</div>
					<div className="col-md-6 sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
							<span className="me-1">Policy Number: <span style={{ color: "red", fontSize: "13px" }}>*</span></span> 
							<EditInput
								getData={(_, val) => handleChangeData(val, "policy_number")}
								placeholder="Add Policy Number"
								defaultValue={
									loanDetails.policy_number
										? loanDetails.policy_number
										: ""
								}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								formType={"policy_number"}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								editInputActive={true}
								inheritedCondition={loanDetails.is_completed !== "1"}
							/>
						</p>
					</div>
					<div className="col-md-6  sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
							<span className="me-4">Valid Till: <span style={{ color: "red", fontSize: "13px" }}>*</span></span> 
							<EditInput
								type="validTill"
							    getData={(_, val) => handleChangeData(val, "valid_till")}
								placeholder="Add Validity Date"
								defaultValue={
									loanDetails.valid_till
										? loanDetails.valid_till
										: ""
								}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								formType={"valid_till"}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								editInputActive={true}
								inheritedCondition={loanDetails.is_completed !== "1"}
							/>
						</p>
					</div>
					<div className="col-md-6 sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width ">
							<span className="me-1">Payable Value: <span style={{ color: "red", fontSize: "13px" }}>*</span></span> 
							<EditInput
								getData={(_, val) => handleChangeData(val, "payable_value")}
								placeholder="Add Payable Value"
								defaultValue={
									loanDetails.payable_value
										? loanDetails.payable_value
										: ""
								}
								api={"loans/loan-update"}
								id={loanDetails.loan_app_enc_id}
								formType={"payable_value"}
								asAmount={true}
								loanDetailStatus={
									loanDetails.loan_status
										? parseInt(loanDetails.loan_status)
										: ""
								}
								editInputActive={true}
								type="amount"
								inheritedCondition={loanDetails.is_completed !== "1"}
							/>
						</p>
					</div>
					<div className="col-md-6 sm-input-box sm-inputbox">
						<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
							<span className="me-1">Field Investigation Officer: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
							<div className="field-fix">
								{!loanDetails?.field_officer || user.user_type === "Financer" ?
									(managerIndex?.length && loanDetails.field_officer ? (
										<>
											<Typeahead
												id="select-collection-manager"
												labelKey="name"
												defaultSelected={managerIndex}
												onChange={OnChangeData}
												options={managerList}
												placeholder="Set Field Investigation Officer"
												renderMenuItemChildren={(option) => (
													<div className="d-flex mb-2">
														<img src={option.image} className="EmployeeAvatar"/>
														<p className="m-0 text-black-50">{option.name} ({option.designation})</p>
													</div>
												)
												}
											/>
										</>
									) :	(
										<Typeahead
											id="select-collection-manager"
											labelKey="name"
											onChange={OnChangeData}
											options={managerList}
											placeholder="Set Field Investigation Officer"
											renderMenuItemChildren={(option) => (
												<div className="d-flex mb-2">
													<img src={option.image} className="EmployeeAvatar"/>
													<p className="m-0 text-black-50">{option.name} ({option.designation})</p>
												</div>
											)
											}
										/>
									)
									) 
									: <p>{loanDetails.field_officer}</p>
								}
								{typed && (selectedManager && selectedManager?.name !== loanDetails?.field_officer) ?(
									<span
										style={{
											margin: "0px 5px",
											marginTop: "-2px",
											cursor: "pointer",
											color: "green"
										}}
										onClick={() => {
											handleAssignInverstigationOfficer(selectedManager);
										} }
									>
										<GiCheckMark size={18} />
									</span>

								):""}
							</div>
						</p>
					</div>

				</div>
			) : (
				""
			)}

			{loanDetails.loanApplicationPds &&
			loanDetails.loanApplicationPds.length &&
			loanDetails.loanApplicationPds.filter(v => v.preferred_date !== null).length ? (
					<div className="row mb-1 w-100 p-2">
						<div className="col-md-12"></div>
						{loanDetails.loanApplicationPds.map((value, index) => {
							const pdDates = value.preferred_date
								? JSON.parse(value.preferred_date)
								: [];
							// const pdDates = tempDates ? JSON.parse(tempDates) : [];
							return (
								<div className="col-md-12 disFlex" key={`pd-${index}`}>
									{pdDates.map((val, i) => {
										const flagColor = flagColors[i % flagColors.length];
										return (
											<div className="col-md-4" key={`pdDate-${i}`}>
												<p className="d-flex flex-wrap align-items-center ht-48">
													<span style={{ color: flagColor }}>
														<RiFlag2Fill style={{ marginRight: "5px" }} />
													</span>
													{moment(val).format("DD MMM YYYY")}
												</p>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				) : (
					""
				)}

			<div className="amounts-main row mb-1 p-2">
				<h3 className="mb-3">Amounts</h3>
				<div className="col-md-6 col-sm-12 sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>BDO Approved: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "bdo_approved_amount")}
							placeholder="Enter Amount"
							defaultValue={
								loanDetails.bdo_approved_amount
									? loanDetails.bdo_approved_amount
									: ""
							}
							type="amount"
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"bdo_approved_amount"}
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							asAmount={true}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6 sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>TL Approved: <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "tl_approved_amount")}
							placeholder="Enter Amount"
							defaultValue={
								loanDetails.tl_approved_amount ? loanDetails.tl_approved_amount : ""
							}
							type="amount"
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"tl_approved_amount"}
							asAmount={true}
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6 sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>TVR / Soft Approval : <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "soft_approval")}
							placeholder="Enter Amount"
							defaultValue={
								loanDetails.soft_approval ? loanDetails.soft_approval : ""
							}
							type="amount"
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"soft_approval"}
							asAmount={true}
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6 sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>Conditional / Soft Sanction : <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "soft_sanction")}
							placeholder="Enter Amount"
							defaultValue={
								loanDetails.soft_sanction ? loanDetails.soft_sanction : ""
							}
							type="amount"
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"soft_sanction"}
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							asAmount={true}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6 sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span className="me-10">Valuation : <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "valuation")}
							placeholder="Enter Amount"
							defaultValue={loanDetails.valuation ? loanDetails.valuation : ""}
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"valuation"}
							type="amount"
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							asAmount={true}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				<div className="col-md-6 sm-input-box sm-inputbox">
					<p className="d-flex flex-wrap align-items-center justify-content-between ht-48 sidebar-input-width">
						<span>Disbursement Approval : <span style={{ color: "red", fontSize: "13px" }}>*</span></span>
						<EditInput
							getData={(_, val) => handleChangeData(val, "disbursement_approved")}
							placeholder="Enter Amount"
							defaultValue={
								loanDetails.disbursement_approved
									? loanDetails.disbursement_approved
									: ""
							}
							type="amount"
							api={"loans/update-loan-amounts"}
							provider={user.organization_enc_id}
							parentId={loanDetails.loan_app_enc_id}
							id={"disbursement_approved"}
							inheritedCondition={((user.department === "Credit" || user.user_type === "Financer") && loanDetails.is_completed !== "1")}
							asAmount={true}
							validateAsNumber={true}
							loanDetailStatus={
								loanDetails.loan_status ? parseInt(loanDetails.loan_status) : ""
							}
							editInputActive={true}
						/>
					</p>
				</div>
				{loanDetails.insurance_charges ? (
					<div className="col-md-6">
						<p className="d-flex flex-wrap align-items-center ht-48">
							<span>Insurance & Incident Charges :</span>
							<>
								<BiRupee size={16} />
								{parseFloat(loanDetails.insurance_charges).toLocaleString("en-IN")}
							</>
						</p>
					</div>
				) : (
					""
				)}
				{charges.length ? (
					<div className="col-md-6">
						<p className="d-flex align-items-center ht-48">
							<span className="me-1">Disbursement Charges: <span style={{ color: "red", fontSize: "13px" }}>*</span> </span>{chargesDetails.alreadyAdded !== 0 ? (
								<>
									<BiRupee size={16} />
									{parseFloat(chargesDetails.alreadyAdded).toLocaleString(
										"en-IN"
									)}
								</>
							) : (
								"--"
							)}
							{(user.user_type === "Financer" || user.specialAccessRole || user.department === "Credit") && loanDetails.is_completed !== "1" ? (
								<span
									onClick={() => setDisbursementChargesModal(true)}
									className="mx-2 pointer-cursor"
								>
									<FaEdit color="#00a0e3" fontSize="16px" />
								</span>
							) : (
								""
							)}
						</p>
					</div>
				) : (
					""
				)}
			</div>
			
			{(user.user_type === "Financer" || incentiveAccessForAll.includes(user.username)) || (vehicleProductsList.includes(loanDetails.loan_product) && incentiveAccessForVehicle.includes(user.username)) ? (
				<>
					<div className="row mb-1 w-100 p-2">
						<div className="col-md-12">
							<h3>Incentive Points</h3>
						</div>
						<form onSubmit={handleSubmit(onsubmit)}>
							<div className="row">
								<div className="col-md-3 mb-2 sm-inputbox">
									<label className="form-label" htmlFor="org_name">
										Select Employee
									</label>
									<AutoComplete 
		                                
										setFieldValue={(event, value) => setCurrentEmployee(value)}
										link={`company-dashboard/employee-search?type=employees&loan_id=${loanDetails.loan_app_enc_id}&employee_search=`}
										name={"employee"}
										method={"GET"}
										placeholder={"Enter Username"}
										{...register("employee", {})}
										val={currentEmployee && currentEmployee != "empty"  ? currentEmployee.label : currentEmployee}
									/>							
									<ErrorMessage
										errors={errors}
										name="employee"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
								<div className="col-md-3 mb-2 sm-inputbox">
									<label className="form-label" htmlFor="points_type">
										Select Points Type
									</label>
									<select 
										className="form-control" 
										{...register("points_type", {
											required: "This is required."
										})}
										style={{height:"48px"}}
									>
										<option value="login">Login</option>
										<option value="disbursment number">Disbursment Number</option>
										<option value="disbursment amount">Disbursment Amount</option>
										<option value="referral">Referral</option>
										<option value="others">Others</option>
									</select>
									<ErrorMessage
										errors={errors}
										name="user"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
								{pointsType === "others" ? (
									<div className="col-md-3 mb-2 sm-inputbox">
										<label className="form-label" htmlFor="points_type_others">
											Enter Points Name
										</label>
										<input type="text" className="form-control" placeholder="Enter Points Name"
											{...register("points_type_others", {})}
										/>
										<ErrorMessage
											errors={errors}
											name="points_type_others"
											render={({ message }) => (
												<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
											)}
										/>
									</div>
								) : ""}
								<div className="col-md-3 mb-2 sm-inputbox">
									<label className="form-label" htmlFor="points_value">
										Enter Points Value
									</label>
									<input type="number" className="form-control" placeholder="Enter Points Value" min="0"
										{...register("points_value", {
											required: "This field is required",
											pattern: {
												value: numberValidateRegex,
												message: "Please enter numeric only"
											}
										})}
									/>
									<ErrorMessage
										errors={errors}
										name="points_value"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
								<div className="col-md-3 d-flex align-items-end sm-inputbox">
									{isLoading ? 
										<button type="button" className="btn btn-primary mb-2" disabled={true}>
											<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
										</button>
										: 
										<button type="submit" className="btn btn-primary mb-2">
											Save
										</button>
									}

									{isUpdate ? 
										<button type="button" onClick={() => cancelIncentiveUpdate()} className="btn btn-primary ms-1 mb-2">Cancel</button>  : ""}
								</div>
							</div>
						</form>
					</div>

					{incentivePoints && incentivePoints.length ? 
						<table className="table mb-3">
							<thead>
								<tr>
									<th>Employee</th>
									<th>Points</th>
									<th>Points Value</th>
									<th className="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
							
								{incentivePoints.map((value, index) => {
									return (
										<tr key={`points-${index}`}>
											<td>
												<div className="branch-info">
													<p className="mb-0 text-capitalize">
														{value.employee ? value.employee : ""}
													</p>
												</div>
											</td>
											<td>
												<div className="branch-info">
													<p className="mb-0 text-capitalize">
														{value.points}
													</p>
												</div>
											</td>
											<td>
												<div className="branch-info">
													<p className="mb-0 text-capitalize">
														{value.points_value}
													</p>
												</div>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-xs me-1"
													onClick={() => editIncentive(value.points_enc_id)}
												>
													<FaEdit fontSize={15} />
												</button>
												<button
													type="button"
													className="btn btn-primary btn-xs"
													onClick={() => deleteIncentive(value.points_enc_id)}
												>
													<MdDelete fontSize={15} />
												</button>
											</td>
										</tr>
									);
								})}
								
							</tbody>
						</table>
						: ""}
				</>
			) : (
				""
			)}
			
			{loanDetails.capital_roi ? (
				<div className="row mb-1 w-100 p-2">
					<div className="col-md-12 mb-2">
						<h3>Capital ROI</h3>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>ROI:&nbsp;</span>
							{loanDetails.capital_roi}%
						</p>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>Updated by:&nbsp;</span>
							<img
								style={{
									width: "25px",
									borderRadius: "20px",
									marginRight: "5px"
								}}
								src={loanDetails.update_image}
								alt={loanDetails.capital_roi_updated_by}
							/>{" "}
							{loanDetails.capital_roi_updated_by}
						</p>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>Updated on:&nbsp;</span>
							{moment(loanDetails.capital_roi_updated_on).format(
								"DD MMM YYYY h:mm a"
							)}
						</p>
					</div>
				</div>
			) : (
				""
			)}
			{loanDetails.registry_status === "1" ? (
				<div className="row mb-1 w-100 p-2">
					<div className="col-md-12 mb-2">
						<h3>Registry Status</h3>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>Status:&nbsp;</span>
							Collected
						</p>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>Collected by:&nbsp;</span>
							<img
								style={{
									width: "25px",
									borderRadius: "20px",
									marginRight: "5px"
								}}
								src={loanDetails.rs_image}
								alt={loanDetails.registry_status_updated_by}
							/>{" "}
							{loanDetails.registry_status_updated_by}
						</p>
					</div>
					<div className="col-md-4">
						<p className="d-flex flex-wrap">
							<span>Collected on:&nbsp;</span>
							{moment(loanDetails.registry_status_updated_on).format(
								"DD MMM YYYY h:mm a"
							)}
						</p>
					</div>
				</div>
			) : (
				""
			)}
			<div className="row mb-1 w-100 p-2">
				<div className="desFlex mb-2">
					<div className="col-md-12">
						<h3>Reference Mobile Numbers</h3>
					</div>
				</div>
				<div className="col-6 mb-3 btn-sm-width sm-inputbox">
					<h5>
						BDO
						{!readOnlyUsers.includes(user.username) && loanDetails.is_completed !== "1" ? (
							<span className="ml-5">
								<button
									type="button"
									onClick={() =>
										setShowReferenceModal({
											show: true,
											refType: "BDO",
											references: loanDetails.loanApplicationsReferences
												? loanDetails.loanApplicationsReferences.filter(
													item => item.reference === "BDO"
												  )
												: ""
										})
									}
									style={{ color: "#00a0e3" }}
									className="btn-share"
								>
									<MdAddCircleOutline
										color="#00a0e3"
										size={16}
									/>{" "}
									Add
								</button>
							</span>
						) : ""}
					</h5>
					{showReferenceModal.refType === "BDO" ? (
						<AddReferenceModal
							showDiv={showReferenceModal.show}
							hide={hideReferenceModal}
							refType={showReferenceModal.refType}
							references={showReferenceModal.references}
							allReferences={loanDetails.loanApplicationsReferences}
							loanId={loanDetails.loan_app_enc_id}
							reload={openSideBar}
						/>
					) : ""}
					{bdoRefs && bdoRefs.length
						? bdoRefs.map((value, index) => {
							return (
								<div
									key={`bdoRefs-${index}`}
									className="d-flex align-items-center ht-48"
									style={{
										textTransform: "capitalize",
										marginBottom: "5px",
										fontSize: "14px"
									}}
								>
									<IoPerson className="me-1" /> {value.name}{" "}
									<span className="ms-1" style={{ color: "#000" }}>
											({value.value})
									</span>
								</div>
							);
						  })
						: "No references have been added"}
				</div>
				<div className="col-6 mb-3 inp-box-sm sm-inputbox">
					<h5>
						Credit Manager References
						{!readOnlyUsers.includes(user.username) && loanDetails.is_completed !== "1" ? (
							<span className="ml-5">
								<button
									type="button"
									onClick={() =>
										setShowReferenceModal({
											show: true,
											refType: "Credit",
											references: loanDetails.loanApplicationsReferences
												? loanDetails.loanApplicationsReferences.filter(
													item => item.reference === "Credit"
												  )
												: ""
										})
									}
									style={{ color: "#00a0e3" }}
									className="btn-share"
								>
									<MdAddCircleOutline
										color="#00a0e3"
										size={16}
									/>{" "}
									Add
								</button>
							</span>
						) : ""}
					</h5>
					{showReferenceModal.refType === "Credit" ? (
						<AddReferenceModal
							showDiv={showReferenceModal.show}
							hide={hideReferenceModal}
							refType={showReferenceModal.refType}
							references={showReferenceModal.references}
							allReferences={loanDetails.loanApplicationsReferences}
							loanId={loanDetails.loan_app_enc_id}
							reload={openSideBar}
						/>
					) : ""}
					{creditRefs && creditRefs.length
						? creditRefs.map((value, index) => {
							return (
								<div
									key={`creditManagerReference${index}`}
									className="d-flex align-items-center ht-48"
									style={{
										textTransform: "capitalize",
										marginBottom: "5px",
										fontSize: "14px"
									}}
								>
									<IoPerson className="me-1" /> {value.name}{" "}
									<span className="ms-1" style={{ color: "#000" }}>
											({value.value})
									</span>
								</div>
							);
						  })
						: "No references have been added"}
				</div>
			</div>
			<div className="row mb-1 w-100 p-2">
				<div className="col-md-12">
					<h3>Borrowers</h3>
				</div>
				{loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length
					? loanDetails.loanCoApplicants
						.filter((a) => a.borrower_type !== "Others")
						.slice() // Create a shallow copy of the array to avoid modifying the original array
						.sort((a, b) => a.borrower_type.localeCompare(b.borrower_type))
						.map((value, index) => {
							return (
								<BorrowerDetailCard
									key={`coApplicantCard-${index}`}
									loanDetails={loanDetails}
									openSideBar={openSideBar}
									getImageData={getImageData}
									setOpenAddBorrower={setOpenAddBorrower}
									setSelectedBorrower={setSelectedBorrower}
									genderSelect={genderSelect}
									hasSmartphone={hasSmartphone}
									setCibilReportDetail={setCibilReportDetail}
									setReportReload={setReportReload}
									handleCibilCheck={handleCibilCheck}
									cibilLoading={cibilLoading}
									value={value}
									feeStructures={feeStructures}
									creditCheckedOnce={creditCheckedOnce}
									changeBorrowerModal={changeBorrowerModal}
									applicationBorrower={applicationBorrower}
								/>
							);
						})
					: ""}
				{loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length && loanDetails.loanCoApplicants.filter((a) => a.borrower_type === "Others").length ? (
					<div className="col-md-12">
						<h3>Others</h3>
					</div>
				) : ""}
				{loanDetails.loanCoApplicants && loanDetails.loanCoApplicants.length
					? loanDetails.loanCoApplicants
						.filter((a) => a.borrower_type === "Others")
						.slice()
						.sort((a, b) => a.borrower_type.localeCompare(b.borrower_type))
						.map((value, index) => {
							return (
								<BorrowerDetailCard
									key={`coApplicantCard-${index}`}
									loanDetails={loanDetails}
									openSideBar={openSideBar}
									getImageData={getImageData}
									setOpenAddBorrower={setOpenAddBorrower}
									setSelectedBorrower={setSelectedBorrower}
									genderSelect={genderSelect}
									hasSmartphone={hasSmartphone}
									setCibilReportDetail={setCibilReportDetail}
									setReportReload={setReportReload}
									handleCibilCheck={handleCibilCheck}
									cibilLoading={cibilLoading}
									value={value}
									feeStructures={feeStructures}
									creditCheckedOnce={creditCheckedOnce}
									changeBorrowerModal={changeBorrowerModal}
									applicationBorrower={applicationBorrower}
								/>
							);
						})
					: ""}
			</div>
		
			<ShareAccessModal
				selectedApp={selectedApp}
				onHide={hideAccessModal}
				setTableLoading={setTableLoading ? setTableLoading : ""}
				getDashboardLoanApplications={getDashboardLoanApplications}
				params={params}
				openSideBar={openSideBar}
				loanDetails={loanDetails}
				sharedTo={loanDetails.sharedTo}
			/>

			<DealerSearch
				selectedApp={dealerName}
				api={"loans/loan-update"}
				onHide={hideDealerModal}
				setTableLoading={setTableLoading ? setTableLoading : ""}
				params={params}
				getDashboardLoanApplications={getDashboardLoanApplications}
				openSideBar={openSideBar}
			/>

			<Modal
				show={openAddBorrower}
				onHide={() => {
					setOpenAddBorrower(false);
					setSelectedBorrower({});
				}}
				size={"lg"}
				centered
			>
				<Modal.Header>
					<Modal.Title>
						{selectedBorrower.loan_co_app_enc_id ? "Update" : "Add"} Details
						<span className="close-icon" onClick={() => { setOpenAddBorrower(false); setSelectedBorrower({});}}><IoCloseCircle /></span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddBorroweForm
						selectedBorrower={selectedBorrower}
						loanDetails={loanDetails}
						callback={() => {
							setOpenAddBorrower(false);
							openSideBar(loanDetails.loan_app_enc_id);
							setSelectedBorrower({});
						}}
					/>
				</Modal.Body>
			</Modal>

			<Modal show={cibilReportDetail !== ""} size={"xl"} centered>
				<Modal.Header>
					<Modal.Title>Credit Report</Modal.Title>
					<button
						type="button"
						className={"link-button closeBtn"}
						onClick={() => {
							if(!reportReload){openSideBar(loanDetails.loan_app_enc_id);}
							setCibilReportDetail("");
							setReportReload(false);
						}}
					>
						<MdOutlineClose size={18} />
					</button>
				</Modal.Header>
				<iframe
					title="Credit Report"
					src={`${cibilReportDetail}`}
					width="100%"
					height="580"
					style={{ border: "none" }}
				></iframe>
			</Modal>

			<Modal show={purposeModal} onHide={() => setPurposeModal(false)} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>Purpose</Modal.Title><span className="close-icon" onClick={()=>setPurposeModal(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<div className="purposes-modal">
						<Select
							options={purposeOptions}
							// formatOptionLabel={formatOptionLabel}
							isMulti={true}
							onChange={selectedPurposes}
							placeholder={"Choose Purpose"}
							defaultValue={defaultPurposeOptions}
						/>
						<div className="text-center mt-4">
							<button className="btn btn-primary" onClick={updatePurposes}>
								Save
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<UpdateBorrowerStatus
				updateBorrower={updateBorrower}
				onHide={onHide}
				loanDetails={loanDetails}
				openSideBar={openSideBar}
			/>

			<Modal
				show={disbursementChargesModal}
				onHide={() => setDisbursementChargesModal(false)}
				size={"md"}
				centered
			>
				<Modal.Header>
					<Modal.Title>Disbursement Charges</Modal.Title><span className="close-icon" onClick={()=>setDisbursementChargesModal(false)}><IoCloseCircle /></span>
				</Modal.Header>
				<Modal.Body>
					<div className="disbursement-modal">
						<div className="row">
							{charges && charges.length
								? charges.map((value, index) => {
									return (
										<div className="col-6 mb-3" key={`details${index}`}>
											<h5 className="m-0 text-capitalize">
												{value.name} <span style={{ color: "red", fontSize: "13px" }}>*</span>
											</h5>
											<div className="d-flex align-items-center disbuse-input">
												<BiRupee size={16} />
												{value.amount ? (
													<span>
														{parseFloat(
															value.amount
														).toLocaleString("en-IN")}
													</span>
												) : (
													<input
														type="number"
														min="0"
														className="form-control"
														placeholder="Enter Amount"
														name={index}
														onChange={e =>
															handleInputChange(
																e,
																value.disbursement_charges_enc_id
															)
														}
													/>
												)}
											</div>
										</div>
									);
								  })
								: ""}
							<>
								{charges.length &&
								charges.filter(value => value.amount === null).length > 0 ? (
										<div className="text-center">
											<button
												className="btn btn-primary"
												onClick={handleSubmitCharges}
											>
											Save
											</button>
										</div>
									) : (
										""
									)}
							</>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<ProductChangeModal
				productChange={productChange}
				setProductChange={setProductChange}
				loan_products={loan_products}
				loanDetails={loanDetails}
				openSideBar={openSideBar}
			/>
			<ActionRemarks
				selectedId={cancelNach}
				onHide={() => setCancelNach(false)}
				title="Change Nach Status"
				confirmMessage="Are you sure you want to cancel nach request status"
				callback={() => openSideBar(loanDetails.loan_app_enc_id)}
				api="payments/cancel-enach-links"
			/>
		</>
	);
};

export default TabBasicDetails;
