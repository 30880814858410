import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import MultiDataFilters from "../MultiDataFilters";
import CollectionReportHeadings from "./CollectionReportHeadings";
import { MainContext } from "../../context/MainState";

const DailyReportTableBranches = ({ loadDefault = true }) => {
	const { getDailyCollectionReportBranches, dailyReportBranches, isLoading } =
		useContext(EmployeeReportsContext);
	const { branches_list, states_list} = useContext(OrganizationDashboardContext);
	const {loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_type: "",
		fields_sort: ""
	});
	const [sortArr, setSortArr] = useState({});

	const DataTableDetails = ({totalCases,colletionCases,verifiedAmount,unVerifiedAmount,interactionCount,interactionAmount}) => {
		const formatValue = (value, isCurrency = false) => {
			if (value === "0" || (isCurrency && value === "0.00")) {
				return "-";
			}
			return isCurrency ? `₹ ${parseFloat(value).toLocaleString("en-IN")}` : value;
		};

		return (
			<div className="d-flex justify-content-around fix-collection-w w-100">
				<div>{formatValue(totalCases)}</div>
				<div>{formatValue(colletionCases, true)}</div>
				<div>{formatValue(verifiedAmount, true)}</div>
				<div>{formatValue(unVerifiedAmount, true)}</div>
				<div>{formatValue(interactionCount)}</div>
				<div>{formatValue(interactionAmount, true)}</div>
			</div>
		);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.location_name ? row.location_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"Total"} type={"total"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "total",
			style: { textAlign: "center" },
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.total_cases_count}
						colletionCases={row?.total_collected_cases_sum}
						verifiedAmount={row?.total_collected_verified_amount}
						unVerifiedAmount={row?.total_collected_unverified_amount}
						interactionCount={row?.total_interaction_count}
						interactionAmount={row?.total_interaction_sum}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"On Time"} type={"OnTime"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.OnTime_total_cases_count}
						colletionCases={row?.OnTime_collected_cases_sum}
						verifiedAmount={row?.OnTime_collected_verified_amount}
						unVerifiedAmount={row?.OnTime_collected_unverified_amount}
						interactionCount={row?.OnTime_total_interaction_count}
						interactionAmount={row?.OnTime_total_interaction_sum}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"0-30"} type={"SMA0"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "0-30",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA0_total_cases_count}
						colletionCases={row?.SMA0_collected_cases_sum}
						verifiedAmount={row?.SMA0_collected_verified_amount}
						unVerifiedAmount={row?.SMA0_collected_unverified_amount}
						interactionCount={row?.SMA0_total_interaction_count}
						interactionAmount={row?.SMA0_total_interaction_sum}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"30-60"} type={"SMA1"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "30-60",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA1_total_cases_count}
						colletionCases={row?.SMA1_collected_cases_sum}
						verifiedAmount={row?.SMA1_collected_verified_amount}
						unVerifiedAmount={row?.SMA1_collected_unverified_amount}
						interactionCount={row?.SMA1_total_interaction_count}
						interactionAmount={row?.SMA1_total_interaction_sum}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"60-90"} type={"SMA2"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "60-90",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA2_total_cases_count}
						colletionCases={row?.SMA2_collected_cases_sum}
						verifiedAmount={row?.SMA2_collected_verified_amount}
						unVerifiedAmount={row?.SMA2_collected_unverified_amount}
						interactionCount={row?.SMA2_total_interaction_count}
						interactionAmount={row?.SMA2_total_interaction_sum}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportHeadings name={"90+"} type={"NPA"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters} isRemove={"Registered eNACH"}/>,
			id: "90+",
			minWidth: "1000px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.NPA_total_cases_count}
						colletionCases={row?.NPA_collected_cases_sum}
						verifiedAmount={row?.NPA_collected_verified_amount}
						unVerifiedAmount={row?.NPA_collected_unverified_amount}
						interactionCount={row?.NPA_total_interaction_count}
						interactionAmount={row?.NPA_total_interaction_sum}
					/>
				);
			},
			sortable: false
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getDailyCollectionReportBranches({
			page: page.selected + 1,
			limit: rowsPerPage,
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getDailyCollectionReportBranches({
			page: 1,
			limit: parseInt(e.target.value),
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			fields_sort: sortArr,
		});
		setRowsPerPage(parseInt(e.target.value));
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(dailyReportBranches.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (dailyReportBranches && dailyReportBranches.data.length > 0) {
			return dailyReportBranches.data;
		} else if (
			dailyReportBranches &&
			dailyReportBranches.data.length === 0 &&
			isFiltered
		) {
			return [];
		} else {
			return dailyReportBranches && dailyReportBranches.data.length > 0
				? dailyReportBranches.data.slice(0, rowsPerPage)
				: [];
		}
	};


	useEffect(() => {
		if(loadDefault){
			if (filters.start_date && filters.end_date) {
				const timerId = setTimeout(() => {
					getDailyCollectionReportBranches({
						page: 1,
						limit: rowsPerPage,
						start_date: filters.start_date,
						end_date: filters.end_date,
						loan_type: filters.loan_type,
						fields_search: searchArray,
						fields_sort: sortArr,
					});
					setCurrentPage(1);
				}, 800);

				const valLength = Object.values(searchArray);
				if (!valLength.length || !valLength.filter(val => val != "").length) {
					setShowClearBtn(false);
				} else {
					setClearFilters(false);
					setShowClearBtn(true);
				}

				return () => {
					clearTimeout(timerId);
				};
			}
		}
		
	}, [filters, searchArray, loadDefault, sortArr]);

	const getDataApi = (filters) => {
		setFilters({...searchArray, ...filters});
	};

	return (
		<div className="loan-application-table mb-5">
			<Row className="mx-0 mt-1 mb-5">
				<Col sm="9">
					<div className="d-flex align-items-center py-2 flex-wrap">
						<div className="form-group me-2">
							<label htmlFor="float-text" className="floating-label fs-5">
								Show Results
							</label>
							<select
								className="dataTable-select form-select"
								style={{ width: "95px", padding: "2px 8px", height: "48px" }}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
						</div>
						<MultiDataFilters
							branches_list={branches_list}
							loan_types={loanAccountTypes}
							getData={getDataApi}
							type={["loanAccountsType"]}
							defaultDate={{value: "3", label: "Today"}}
						/>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
					sm="3"
				>
					<div className="filters-container d-flex justify-content-end">
						{showClearBtn ? (
							<button
								className="btn btn-primary btn-xs my-2"
								onClick={e => clearFilter(e)}
							>
								Reset
							</button>
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
			<DataTable
				noHeader
				pagination
				paginationServer
				columns={columns}
				data={dataToRender()}
				sortServer={true}
				paginationComponent={CustomPagination}
				progressPending={isLoading === "employeeStat"}
				highlightOnHover="true"
				progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
				noDataComponent={
					<p className="w-100 m-5 text-start">There are no records to display</p>
				}
				persistTableHead={true}
				className="loan-app-table-container fix-overflow report-tables z-0"
				fixedHeader={true}
				fixedHeaderScrollHeight="80vh"
			/>
		</div>
	);
};
export default DailyReportTableBranches;
