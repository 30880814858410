// export const customFunctions = {
// getFloatNumber
// };
import { toast } from "react-toastify";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDYtKKbGvXpQ4xcx4AQcwNVN6w_zfzSg8c");

export function getFloatNumber(val) {
	return val && val !== "0" ? parseFloat(val) : 0;
}

export function formatLoanNumber(str) {
	// Regular expression to match alphanumeric characters and hyphen
	const regex = /[^a-zA-Z0-9-]/g;
	// Replace characters that are not alphanumeric or hyphen with an empty string
	return str.replace(regex, "").toUpperCase();
}

export function getLogo() {
	const user = JSON.parse(localStorage.getItem("user"));
	if (window.location.hostname.split(".").includes("credif") || (user && user.organization_username === "phfleasing")) {
		return "/images/brand/logo/credifin_logo.png";
	}
	return "/images/brand/logo/logo-el-final.png";
}

export function isCredifinUser() {
	return window.location.hostname.split(".").includes("credif");
}

export function isLocationEnabled() {
	return new Promise((resolve) => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(function (result) {
				resolve(result.state === "granted" || result.state === "prompt");
			});
		} else {
			resolve(false);
		}
	});
};

export async function getLatLong() {
	return new Promise((resolve, reject) => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				pos => {
					const crd = pos.coords;
					resolve({
						latitude: crd.latitude,
						longitude: crd.longitude
					});
				},
				err => {
					toast.error("Please enable location to continue");
					// alert(`ERROR(${err.code}): ${err.message}`);
					reject(err);
				}
			);
		} else {
			const error = new Error("Geolocation not available");
			toast.error("Geolocation not available");
			alert(error.message);
			reject(error);
		}
	});
}

export async function getLocation() {
	return new Promise((resolve, reject) => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				async pos => {
					const crd = pos.coords;
					try {
						const response = await Geocode.fromLatLng(crd.latitude, crd.longitude);
						let city, state, postalCode, route, sublocality, sublocality_2, streetAdd;
						for (let i = 0; i < response.results[0].address_components.length; i++) {
							for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
								switch (response.results[0].address_components[i].types[j]) {
								case "locality":
									city = response.results[0].address_components[i].long_name;
									break;
								case "administrative_area_level_1":
									state = response.results[0].address_components[i].long_name;
									break;
								case "postal_code":
									postalCode = response.results[0].address_components[i].long_name;
									break;
								case "premise":
									route = response.results[0].address_components[i].long_name;
									break;
								case "sublocality":
									sublocality = response.results[0].address_components[i].long_name;
									break;
								case "sublocality_level_2":
									sublocality_2 = response.results[0].address_components[i].long_name;
									break;
								}
							}
						}
						if (route && sublocality_2 && sublocality) {
							streetAdd = route + ", " + sublocality_2 + ", " + sublocality;
						} else if (route && sublocality) {
							streetAdd = route + ", " + sublocality;
						} else {
							streetAdd = route;
						}
						resolve({
							latitude: crd.latitude,
							longitude: crd.longitude,
							postal_code: postalCode,
							streetAdd,
							city,
							state
						});
					} catch (error) {
						console.error(error);
						reject(error);
					}
				},
				err => {
					toast.error("Please enable location to continue");
					alert(`ERROR(${err.code}): ${err.message}`);
					reject(err);
				}
			);
		} else {
			const error = new Error("Geolocation not available");
			toast.error("Geolocation not available");
			alert(error.message);
			reject(error);
		}
	});
}