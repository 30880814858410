import axios from "axios";
import { toast } from "react-toastify";
// import { MainContext } from "../../context/MainState";
// import { useContext } from "react";
import { BiRupee } from "react-icons/bi";
import DataTable from "react-data-table-component";
import EmiCollectedCashSidebarData from "./EmiCollectedCashSidebarData";
import moment from "moment";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { useState, useContext, useEffect, useRef } from "react";
import { MainContext } from "../../context/MainState";
import { firebase } from "../../helpers/firebaseInit";
import AuthCode from "react-auth-code-input";
import { authServices } from "../../helpers/authServices";

const ApproveCashEmployee = ({setCashReceived, cashReceived, getEmiList}) => {
	const AuthInputRef = useRef(null);
	const { user } = useContext(MainContext);
	const [otp, setOtp] = useState("");
	const [otpModal, setOtpModal] = useState(false);
	const [showSubmitOtpBtn, setShowSubmitOtpBtn] = useState(false);
	const [formIsLoading, setFormIsLoading] = useState(false);
	const [formData, setFormData] = useState("");

	const [emiReceivedData, setEmiReceivedData] = useState("");

	useEffect(() => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container-main", {
			size: "invisible",
			callback: function (response) {
				console.log(response);
				console.log("Captcha Resolved");
			},
			defaultCountry: "IN"
		});
	}, []);
    
	const approveToBank = async () => {
		const data = {
			cash_id: formData.id,
			type: formData.type
		};
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/approve-by-employee`,data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					toast.success(`Transaction ${formData.type === 1 ? "Approved" : "Rejected"} Successfully`);
					handleResetData();
				}else {
					toast.error(
						response.data.response
							? response.data.response.message
							: "An error has occurred, Please try again"
					);
				}
			})
			.catch(error => {
				toast.error("An error has occurred", error);
			});
	};

	const handleResetData = () => {
		setCashReceived(false);
		getEmiList();
		setFormData("");
		setOtpModal(false);
	};

	const sendOtp = async (type) => {
		setFormIsLoading(type);
		await authServices.sendOtp(user.phone, "Credifin_common_otp")
			.then(result => {
				setFormIsLoading(false);
				if (result.status === 200) {
					setOtpModal(result.data.id);
				}
			})
			.catch(err => {
				toast.error(err);
				setFormIsLoading(false);
			});
	};

	const ValidateOtp = (otpnumb = otp) => {
		if (otpnumb === null || !otpModal) return;
		authServices.validateOtp(otpModal, otpnumb)
			.then(response => {
				setFormIsLoading(false);
				if (response?.status === 200) {
					setOtpModal(false);
					approveToBank();
				}
			})
			.catch(err => {
				console.log(err);
				setShowSubmitOtpBtn(false);
				setFormIsLoading(false);
				toast.error("Invalid OTP");
			});
	};

	const handleOtpChange = res => {
		setOtp(res);
		if (res.length === 6) {
			ValidateOtp(res);
			setTimeout(() => {
				setShowSubmitOtpBtn(true);
			}, 1200);
		}
	};


	const columns = [
		{
			name: "Total Amount",
			minWidth: "150px",
			sortable: false,
			cell: row => {
				return (
					<p className="m-0">
						<BiRupee size={16} />{parseFloat(row.remaining_amount).toLocaleString("en-IN")}
					</p>
				);
			}
		},
		{
			name: "Received From",
			minWidth: "160px",
			selector: row => (row.received_from ? row.received_from : "-"),
			sortable: true,
		},
		{
			name: "Received On",
			minWidth: "160px",
			sortable: true,
			cell: row => {
				return (
					<p className="m-0">
						{moment(row.created_on).format(
							"DD MMM YYYY h:mm a"
						)}
					</p>
				);
			}
		},
		{
			name: "Approve",
			maxWidth: "80px",
			sortable: false,
			cell: row => {
				return (
					<>
						{formIsLoading === "accept" ? (
							<button className="btn btn-success btn-xs otp-loader" disabled={true}>
								<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
							</button>
						) : (
							<button
								className="btn btn-success btn-xs" title="Approve"
								onClick={() => {
									setFormData({id: row.cash_report_enc_id, type: 1});
									sendOtp("accept");
								}}
							>
								<FaCheck/>
							</button>
						)}
					</>
				);
			}
		},
		{
			name: "Reject",
			maxWidth: "80px",
			sortable: false,
			cell: row => {
				return (
					<>
						{formIsLoading === "reject" ? (
							<button className="btn btn-danger btn-xs otp-loader" disabled={true}>
								<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
							</button>
						) : (
							<button
								className="btn btn-danger btn-xs" title="Reject"
								onClick={() => {
									setFormData({id: row.cash_report_enc_id, type: 0});
									sendOtp("reject");
								}}
							>
								<FaTimes/>
							</button>)}
					</>
				);
			}
		}
	];

	const getReceivedCashList = () => {
		const data = {
			user_id: user.user_enc_id,
			org_id: user.organization_enc_id
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}emi-collections/approve-by-employee-list`,
				data
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setEmiReceivedData(response.data.response.data);
				} else {
					setEmiReceivedData([]);
				}
			})
			.catch(error => {
				toast.error("An error has occurred while getting received cash list. Please try again", error);
			});
	};

	useEffect(() => {
		if (cashReceived) {
			getReceivedCashList();
		} 
	}, [cashReceived]);

	return (
		<>
			<div id="recaptcha-container-main"></div>
			<Modal show={cashReceived} onHide={() => setCashReceived(false)} size={"lg"} centered>
				<Modal.Body>
					<div className="col-12 mb-4">
						<h3 className="text-center">Received Cash</h3>
						<div className="border">
							<DataTable
								noHeader
								columns={columns}
								striped
								data={emiReceivedData && emiReceivedData.length ? emiReceivedData : []}
								expandableRows 
								expandableRowsComponent={EmiCollectedCashSidebarData}
								progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
								noDataComponent = { <p className="w-100 m-5 text-start">There are no records to display</p>}
							/>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={otpModal} onHide={() => setOtpModal(false)}>
				<Modal.Body>
					Please enter the OTP received on number +91{user.phone}{" "}
					<AuthCode
						autoFocus={true}
						onChange={handleOtpChange}
						ref={AuthInputRef}
						placeholder="-"
						containerClassName="otp-container"
						inputClassName="otp-input-field"
					/>
				</Modal.Body>
				{showSubmitOtpBtn ? (
					<Modal.Footer>
						{formIsLoading ? (
							<button type="button" className="btn btn-primary" disabled={true}>
								<img src="/images/pages/home/loader.svg" alt={process.env.REACT_APP_NAME} />
							</button>
						) : (
							<Button variant="primary" onClick={ValidateOtp}>
								Submit
							</Button>
						)}
					</Modal.Footer>
				) : (
					<div style={{ minHeight: "100px" }}></div>
				)}
			</Modal>
		</>
	);
};
export default ApproveCashEmployee;
