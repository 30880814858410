import React, { createContext, useReducer, useState } from "react";
import AppReducer from "../reducers/AppReducer";
import authData from "../data/authData";
import axios from "axios";
import { toast } from "react-toastify";

// Create context
export const MainContext = createContext(authData);

// Provider component
export const MainProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, authData);
	const [modalLoaded, setModalLoaded] = useState({
		modalStatus: false,
		messageStatus: false,
		message: "",
		title: ""
	});
	const [orgSignUp, setOrgSignUp] = useState(false);
	const [dealerSignUp, setDealerSignUp] = useState(false);
	const [signUp, setSignUp] = useState(false);
	const [applyModal, setApplyModal] = useState(false);
	const [modalLogin, setModalLogin] = useState(false);
	const [allowLocation, setAllowLocation] = useState(false);
	const [resetPasswordModal, setResetPasswordModal] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);

	
	// Actions
	function handlePageLoaded(val) {
		setPageLoaded(val);
	}

	function updateUser(data) {
		const user = {
			access_token: data.access_token,
			source: data.source,
			organization_username: data.organization_username
		};
		localStorage.setItem("user", JSON.stringify(user));
		dispatch({
			type: "SET_USER_DATA",
			payload: data
		});
	}

	function removeUser() {
		dispatch({
			type: "REMOVE_USER_DATA"
		});
	}

	function handleModalLogin(val) {
		setModalLogin(val);
	}
	
	function handleAllowLocation(val) {
		setAllowLocation(val);
	}

	function handleApplyModal(val) {
		setApplyModal(val);
	}

	function orgSignUpHandler() {
		setOrgSignUp(!orgSignUp);
	}

	function dealerSignUpHandler() {
		setDealerSignUp(!dealerSignUp);
	}

	function resetPasswordHandler() {
		setResetPasswordModal(!resetPasswordModal);
	}

	function signUpHandler(value) {
		setSignUp(value);
	}

	const getBrandsList = async (type = "vehicle") => {
		const link_type = type === "mobile" ? "Mobiles" : "Two Wheeler";
		await axios
			.get(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}products/get-brands?type=${link_type}`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_BRANDS_DATA",
						payload: response.data.response.brands
					});
				}
			});
	};

	const getAssignedLoanTypes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/assigned-loan-types`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_TYPES",
						payload: response.data.response.assignedLoanTypes
					});
				} else {
					dispatch({
						type: "SET_LOAN_TYPES",
						payload: []
					});
				}
			});
	};

	const getAssignedLoanProducts = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-loan-products`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_PRODUCT",
						payload: response.data.response.loan_products
					});
				} else {
					dispatch({
						type: "SET_LOAN_PRODUCT",
						payload: []
					});
				}
			});
	};
	const getAssignedLoanDocuments = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-assigned-documents`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_DOCUMENT_TYPES",
						payload: response.data.response.certificates
					});
				} else {
					dispatch({
						type: "SET_DOCUMENT_TYPES",
						payload: []
					});
				}
			});
	};

	const getAssignedLoanImages = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-assigned-images`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_IMAGE_TYPES",
						payload: response.data.response.images
					});
				} else {
					dispatch({
						type: "SET_IMAGE_TYPES",
						payload: []
					});
				}
			});
	};

	const getAssignedLoanPurposes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/get-purpose-list`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_PURPOSES_LIST",
						payload: response.data.response.purpose
					});
				} else {
					dispatch({
						type: "SET_PURPOSES_LIST",
						payload: []
					});
				}
			});
	};

	const getFinancerLoanStatusList = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/financer-loan-status-list`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_FINANCER_STATUS_LIST",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_FINANCER_STATUS_LIST",
						payload: []
					});
				}
			});
	};

	const getAssignedLoanStatus = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/loan-status-list`
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_STATUS_LIST",
						payload: response.data.response.loan_status
					});
				} else {
					dispatch({
						type: "SET_STATUS_LIST",
						payload: []
					});
				}
			});
	};

	const getFinancersList = async () => {
		const financers = [
			{name:"PHF Leasing", slug: "phfleasing", organization_enc_id: "jE3DW981MQMkDnLm1DAlol5zrZyBag"},
			{name: "Capital Small Finance Bank", slug: "capitalbank", organization_enc_id: "DKLb29kg0o07vDmVZXB2RPBlampN8J"},
			{name: "Agile Finserv Pvt. Ltd.", slug: "agile", organization_enc_id: "n9lYWbywLRknNmpVVwlGQE854A1z7M"},
			{name: "Amrit Malwa Capital Limited", slug: "amritmalwa", organization_enc_id: "n9lYWbywLRknVm8XjEv5QE854A1z7M"}
		];
		let financer_list = financers.filter(row => row.slug !== state.user.organization_slug);
		if (state.user.organization_slug !== "phfleasing") {
			financer_list = financer_list.filter(row => row.slug === "phfleasing");
		}
		// await axios
		// 	.post(
		// 		`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/get-financer-list`
		// 	)
		// 	.then(response => {
		// 		if (response.data.response && response.data.response.status === 200) {
		dispatch({
			type: "SET_ALL_FINANCERS",
			// payload: response.data.response.financer_list
			payload: financer_list
		});
		// 	} else {
		// 		dispatch({
		// 			type: "SET_ALL_FINANCERS",
		// 			payload: []
		// 		});
		// 	}
		// });
	};
	const getNotificationsList = async page => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}notifications/get-notifications-list`,
				page
			)
			.then(response => {
				if (response.data.response && response.data.response.status === 200) {
					dispatch({
						type: "SET_" +page.group.toUpperCase()+ "_NOTIFICATION",
						payload: response.data.response
					});
				} else {
					dispatch({
						type:"SET_" +page.group.toUpperCase()+ "_NOTIFICATION",
						payload: []
					});
				}
			})
			.catch(error => {
				toast.error(error.message);
			});
	};

	const getFinancerAssignedLoanTypes = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/assigned-financer-loan-types`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_FIN_ASSIGNED_LOAN_TYPES",
						payload: response.data.response.assignedLoanTypes
					});
				} else {
					dispatch({
						type: "SET_FIN_ASSIGNED_LOAN_TYPES",
						payload: []
					});
				}
			});
	};

	const getLoanAccountTypes = () => {
		axios
			.post(`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/loan-accounts-type`)
			.then(response => {
				if (response.data.response.status === 200) {
					dispatch({
						type: "SET_LOAN_ACCOUNT_TYPES",
						payload: response.data.response.data
					});
				} else {
					dispatch({
						type: "SET_LOAN_ACCOUNT_TYPES",
						payload: []
					});
				}
			});
	};

	function setMessageModal(modalStatus, messageStatus = false, message = "", title = "") {
		// if(modalStatus){
		setModalLoaded({
			modalStatus: modalStatus,
			messageStatus: messageStatus,
			message: message,
			title: title
		});
		// }
	}


	return (
		<MainContext.Provider
			value={{
				user: state.user,
				brands: state.brands,
				loan_types: state.loan_types,
				loan_products: state.loan_products,
				documents_types: state.documents_types,
				images_types: state.images_types,
				purposes: state.purposes,
				loanAccountTypes: state.loanAccountTypes,
				loan_status: state.loan_status,
				all_financers: state.all_financers,
				financer_loan_status: state.financer_loan_status,
				notifications: state.notifications,
				financer_assigned_loan_types: state.financer_assigned_loan_types,
				getBrandsList,
				updateUser,
				removeUser,
				setMessageModal,
				resetPasswordModal,
				resetPasswordHandler,
				modalLoaded,
				applyModal,
				handleApplyModal,
				orgSignUp,
				orgSignUpHandler,
				dealerSignUpHandler,
				dealerSignUp,
				signUp,
				signUpHandler,
				modalLogin,
				handleModalLogin,
				handleAllowLocation,
				allowLocation,
				pageLoaded,
				getFinancerAssignedLoanTypes,
				handlePageLoaded,
				getAssignedLoanDocuments,
				getAssignedLoanImages,
				getAssignedLoanTypes,
				getAssignedLoanPurposes,
				getAssignedLoanStatus,
				getFinancersList,
				getAssignedLoanProducts,
				getFinancerLoanStatusList,
				getNotificationsList,
				getLoanAccountTypes
			}}
		>
			{children}
		</MainContext.Provider>
	);
};
