import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiRupee } from "react-icons/bi";
import NameComp from "../NameComp";
import moment from "moment/moment";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos, MdDelete, MdDoDisturbOn } from "react-icons/md";
import { Col, Row, Nav, Tab, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import EmiCollectionForm from "./EmiCollectionForm";
import EmiLoanAccountSidebar from "./EmiLoanAccountSidebar";
import axios from "axios";
import AssignTelecallerModal from "./AssignTelecallerModal";
import { MainContext } from "../../context/MainState";
import { useForm } from "react-hook-form";
import AutoComplete from "../forms/AutoComplete";
import PriorityModal from "./PriorityModal";
import LoanAccountStats from "./LoanAccountStats";
import CollectionManagerModal from "./CollectionManagerModal";
import EditSelectInput from "../forms/EditSelectInput";
import AggrementTypeEdit from "../forms/AggrementTypeEdit";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import UpdateEmiFields from "../forms/UpdateEmiFields";
import { IoCloseCircle } from "react-icons/io5";
import MinMaxFilter from "../MinMaxFilter";
import { GoDotFill } from "react-icons/go";
import { isLocationEnabled } from "../../helpers/customFunctions";

const LoanAccountsTable = ({ type = "all", locationPath = null }) => {
	const {
		getEmiAccountData,
		accountData,
		loanAccountStats,
		getLoanAccountStats,
		branches_list,
		getBranches,
		getStates,
		states_list
	} = useContext(OrganizationDashboardContext);
	const { handleSubmit, watch, setValue, getValues, reset } = useForm();
	const toBeShared = watch("shared_to");
	let toBeSharedName = watch("name");
	const { user, handlePageLoaded, all_financers, getFinancersList, loanAccountTypes } =
		useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [sortArr, setSortArr] = useState({});
	const [tableLoading, setTableLoading] = useState(false);
	const [showEmiModal, setShowEmiModal] = useState(false);
	const [loanAccountNumber, setLoanAccountNmber] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [bucketVal, setBucketVal] = useState("");
	const [dateVal, setDateVal] = useState("");
	const [dueVal, setDueVal] = useState("all");
	const [loanAccountId, showLoanSideBar] = useState({
		loan_account_enc_id: "",
		loan_account_number: ""
	});
	const [telecallerList, setTelecallerList] = useState([]);
	const [telecallerModal, setTelecallerModal] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [bdoModal, setBdoModal] = useState(false);
	const [sharedModal, setSharedModal] = useState(false);
	const [selectedRows, setSelectedRows] = useState(false);
	const [finalUsers, setFinalUsers] = useState([]);
	const [loanAccountSidebarId, setLoanAccountId] = useState("");
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [reloadSidebar, setReloadSidebar] = useState(false);
	const [priorityModal, setPriorityModal] = useState(false);
	const [userSpecial, setUserSpecial] = useState(false);
	const [editEmiFields, setEditEmiFields] = useState({ loan_account_enc_id: "" });
	const [totalCaseCount, setTotalCaseCount] = useState("");
	const [selectAllCases, setSelectAllCases] = useState(false);

	const handleSelectedRows = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
		if(selectedRows.length === 0){
			setSelectAllCases(false);
		}
	};

	const clearSelectedRows = () => {
		setSelectAllCases(false);
		setSelectedRows(false);
		setToggleClearRows(!toggledClearRows);
	};

	const resetBdoAssign = () => {
		clearSelectedRows();
		setFinalUsers([]);
		setBdoModal(false);
		reloadDetails();
	};

	const hideBdoModal = () => {
		reset();
		setLoanAccountId("");
		setBdoModal(false);
		setFinalUsers([]);
	};

	const assignBdo = (type, userData = null) => {
		const data = {};
		const bdos = [];
		if (type === "bdo") {
			finalUsers.forEach(vals => bdos.push({ id: vals.shared_to, access: vals.access }));
		} else {
			bdos.push({ id: userData.shared_to });
		}
		if(!selectAllCases){
			data["loan_accounts"] = loanAccountSidebarId
				? [loanAccountSidebarId]
				: selectedRows.map(vals => vals.loan_account_enc_id);
		}else{
			data["loan_accounts"] = [];
			data["selected_all"] = true;
			data["fields_search"] = searchArray;
			data["sub_bucket"] = bucketVal;
			data["collection_date"] = dateVal === "all" ? "" : dateVal;
		}
		data["users"] = bdos;
		data["type"] = type;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/share-loan-accounts`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					if (loanAccountSidebarId) {
						setReloadSidebar(new Date());
					}
					setLoanAccountId("");
					toast.success("Submit Successfully");
					resetBdoAssign();
					reset();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				console.log(error);
				toast.error(error.message);
			});
	};

	const hardRecovery = type => {
		if (!window.confirm("Are you sure you want to Change Status")) {
			return false;
		}
		const data = {};
		if(!selectAllCases){
			data["loan_accounts"] = selectedRows.map(vals => vals.loan_account_enc_id);
		}else{
			data["loan_accounts"] = [];
			data["selected_all"] = true;
			data["fields_search"] = searchArray;
			data["sub_bucket"] = bucketVal;
			data["collection_date"] = dateVal === "all" ? "" : dateVal;
		}
		data["value"] = type;
		handlePageLoaded(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/hard-recovery`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Update Successfully");
					reloadDetails();
					clearSelectedRows();
				} else {
					toast.error("An error has occurred, Please try again");
				}
			})
			.catch(error => {
				toast.error(error.message);
				handlePageLoaded(false);
				console.log(error);
			});
	};

	const handleShowEmiModal = async (val) => {
		if (!await isLocationEnabled()) { // Check if location is enabled
			toast.error("Please allow location to continue");
			return;
		}
		setShowEmiModal(val);
	};

	const reloadDetails = () => {
		setTableLoading(type);
		getEmiAccountData({
			page: currentPage,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: bucketVal,
			collection_date: dateVal === "all" ? "" : dateVal,
			type: type,
			page_type: locationPath ? locationPath : ""
		});
		clearSelectedRows();
	};

	const handleCloseEmi = () => {
		setShowEmiModal(false);
	};

	const conditionalRowStyles = [
		{
			when: row => row.priority === "1",
			style: { borderLeft: "4px solid red" }
		},
		{
			when: row => row.priority === "2",
			style: { borderLeft: "4px solid orange" }
		},
		{
			when: row => row.priority === "3",
			style: { borderLeft: "4px solid yellow" }
		},
		{
			when: row => row.priority === "4",
			style: { borderLeft: "4px solid #00a0e3" }
		},
		{
			when: row => row.priority === "5",
			style: { borderLeft: "4px solid #bebebe" }
		}
	];

	const columns = [
		{
			name: (
				<NameComp
					title={"Loan Account Number"}
					id={"loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "260px",
			selector: row => (row.loan_account_number ? row.loan_account_number : "-"),
			cell: row => {
				return (
					<div className="justify-content-between d-flex align-content-center w-100">
						<span
							className={`me-2 text-link ${row.due === "true" ? "text-danger" : ""}`}
							onClick={() =>
								showLoanSideBar({
									loan_account_enc_id: row.loan_account_enc_id,
									loan_account_number: row.loan_account_number,
									assignedLoanAccounts: row.assignedLoanViews,
									has_smartphone: row.has_smartphone
								})
							}
						>
							{row.loan_account_number ? row.loan_account_number : "-"}
							{row.due === "true" ? (
								<OverlayTrigger
									placement="top"
									delay={{ show: 250, hide: 400 }}
									overlay={<Tooltip id="invite-customer">Nach Bounced</Tooltip>}
								>
									<div style={{ display: "inline-block", marginLeft: "8px" }}>
										<MdDoDisturbOn size={16} color="red" />
									</div>
								</OverlayTrigger>
							) : (
								""
							)}
				
						</span>

						<div className="d-flex align-items-center">
							{row?.nach_approved ? (
								<OverlayTrigger
									placement="top"
									delay={{ show: 250, hide: 400 }}
									overlay={<Tooltip id="invite-nach">Nach {row.nach_approved}</Tooltip>}
								>
									<div style={{ display: "inline-block", marginRight: "15px" }}>
										<GoDotFill size={16} color={row.nach_approved === "Active" ? "green" 
											: row.nach_approved === "Issued" ? "yellow" : "red"}/>
									</div>
								</OverlayTrigger>
							) : ""}
							<button
								type="button"
								className="btn-table-emi"
								onClick={() => {
									handleShowEmiModal("emi");
									setLoanAccountNmber({
										loan_account_enc_id: row.loan_account_enc_id,
										loan_account_number: row.loan_account_number
									});
								}}
							>
								Collect EMI
							</button>
						</div>
					</div>
				);
			},
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"LMS Loan Account Number"}
					id={"lms_loan_account_number"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "260px",
			selector: row => (row.lms_loan_account_number ? row.lms_loan_account_number : "-")
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			sortable: false,
			cell: row => {
				return (
					<h6
						className="mb-0 text-capitalize text-link"
						onClick={() =>
							showLoanSideBar({
								loan_account_enc_id: row.loan_account_enc_id,
								loan_account_number: row.loan_account_number,
								assignedLoanAccounts: row.assignedLoanViews
							})
						}
					>
						{row.name ? row.name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Bucket"}
					id={"sub_bucket"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			maxWidth: "155px",
			selector: row => (row.sub_bucket ? <b>{row.sub_bucket}</b> : "-"),
			omit: bucketVal != "",
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Loan Type"}
					id={"loan_accounts"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanTypes={loanAccountTypes}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.loan_type ? row.loan_type : "-"),
			sortable: false,
			minWidth: "180px",
			cell: row => {
				return user.user_type === "Financer" ? (
					<EditSelectInput
						optionList={loanAccountTypes}
						getData={reloadDetails}
						defaultValue={row.loan_type ? row.loan_type : ""}
						defaultLabel={row.loan_type ? row.loan_type : ""}
						api={"loan-accounts/update"}
						id={row.loan_account_enc_id}
						selectPlaceholder="Choose Loan Type"
						parentId={user.organization_enc_id}
						required={true}
						type={"loan_type"}
					/>
				) : row.loan_type ? (
					row.loan_type
				) : (
					"-"
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					branches={branches_list ? branches_list : []}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false,
			cell: row => {
				return user.user_type === "Financer" ? (
					<EditSelectInput
						optionList={branches_list}
						getData={reloadDetails}
						defaultValue={row.branch_enc_id ? row.branch_enc_id : ""}
						defaultLabel={row.branch_name ? row.branch_name : ""}
						api={"loan-accounts/update-branch"}
						id={row.loan_account_enc_id}
						selectPlaceholder="Choose Branch"
						parentId={user.organization_enc_id}
						required={true}
					/>
				) : row.branch_name ? (
					row.branch_name
				) : (
					"-"
				);
			}
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list ? states_list : []}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Case Number"}
					id={"case_no"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return (
					<div>
						{row.case_no}&nbsp;
						{user.username === "KKB" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditEmiFields(row);
								}}
							/>
						) : (
							""
						)}
					</div>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Company ID"}
					id={"company_id"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			sortable: false,
			omit: user.username !== "KKB",
			minWidth: "150px",
			cell: row => {
				return (
					<div>
						{row.company_id}&nbsp;
						{user.username === "KKB" ? (
							<FaRegEdit
								style={{
									color: "rgb(41, 121, 255)",
									cursor: "pointer"
								}}
								size={14}
								onClick={() => {
									setEditEmiFields(row);
								}}
							/>
						) : (
							""
						)}
					</div>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Target Collection Amount"}
					id={"target_collection_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "220px",
			selector: row => (row.target_collection_amount ? row.target_collection_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt bold">
							<BiRupee size={16} />{" "}
							{row.target_collection_amount
								? parseFloat(row.target_collection_amount).toLocaleString("en-IN")
								: "0"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Net Outstanding Amount"}
					id={"total_pending_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "170px",
			selector: row => (row.total_pending_amount ? row.total_pending_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.total_pending_amount
								? parseFloat(row.total_pending_amount).toLocaleString("en-IN")
								: "0"}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Amount"}
					id={"proposed_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row =>
				row.emiCollectionsCustom ? row.emiCollectionsCustom.proposed_amount : "-",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emiCollectionsCustom ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(
									row.emiCollectionsCustom.proposed_amount
								).toLocaleString("en-IN")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"PTP Date"}
					id={"proposed_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "200px",
			selector: row =>
				row.emiCollectionsCustom ? row.emiCollectionsCustom.proposed_date : "-",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.emiCollectionsCustom
							? moment(row.emiCollectionsCustom.proposed_date).format("DD MMM YYYY")
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"EMI Date"}
					id={"emi_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
				/>
			),
			minWidth: "170px",
			selector: row => (row.emi_date ? moment(row.emi_date).valueOf() : "-"),
			sortable: false,
			cell: row => (row.emi_date ? moment(row.emi_date).format("DD") : "-")
		},
		{
			name: (
				<MinMaxFilter
					title={"EMI Amount"}
					id={"emi_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "150px",
			selector: row => (row.emi_amount ? row.emi_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 emiAmt">
							<BiRupee size={16} />{" "}
							{row.emi_amount
								? parseFloat(row.emi_amount).toLocaleString("en-IN")
								: ""}
						</h6>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Overdue EMI"}
					id={"overdue_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.overdue_amount ? row.overdue_amount : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.overdue_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.overdue_amount) > 0 ? parseFloat(row.overdue_amount).toLocaleString("en-IN") : 0}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"POS"}
					id={"pos"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.pos ? row.pos : "-"),
			omit: !(
				user.user_type === "Financer" || ["monika200", "phf1136"].includes(user.username)
			),
			sortable: false,
			cell: row => {
				return (
					<>
						{row.pos ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} /> {parseFloat(row.pos).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Dealer Name"}
					id={"dealer_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize">
						{row.dealer_name ? row.dealer_name : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Nach Status"}
					id={"nach_approved"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
					// type = "loan_accounts"
				/>
			),
			selector: row => (row.nach_approved ? row.nach_approved : "-"),
			sortable: false,
			minWidth: "180px"
		},
		{
			name: (
				<NameComp
					title={"Address"}
					id={"address"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
				/>
			),
			selector: row => (row.address ? <span style={{whiteSpace: "normal"}}>{row.address}</span> : "-"),
			sortable: false,
			minWidth: "220px"
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Amount"}
					id={"last_emi_received_amount"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					placeholder={"Amount"}
				/>
			),
			minWidth: "190px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_amount ? (
							<h6 className="mb-0 emiAmt">
								<BiRupee size={16} />{" "}
								{parseFloat(row.last_emi_received_amount).toLocaleString("en-IN")}
							</h6>
						) : (
							"--"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Last Collection Date"}
					id={"last_emi_received_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					inputType={"datepicker"}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "170px",
			sortable: false,
			cell: row => {
				return (
					<>
						{row.last_emi_received_date
							? moment(row.last_emi_received_date).format("DD MMM")
							: "-"}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Sales Priority"}
					id={"sales_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchFrom="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.sales_priority ? row.sales_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.sales_priority ? row.sales_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.sales_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.sales_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<NameComp
					title={"Telecaller Priority"}
					id={"telecaller_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchFrom="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.telecaller_priority ? row.telecaller_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.telecaller_priority ? row.telecaller_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.telecaller_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.telecaller_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<NameComp
					title={"Collection Priority"}
					id={"collection_priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchFrom="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.collection_priority ? row.collection_priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">
							{row.collection_priority ? row.collection_priority : "-"}
						</h6>
					</>
				);
			},
			omit: !userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.collection_priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.collection_priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<NameComp
					title={"Priority"}
					id={"priority"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					searchFrom="loan_account"
				/>
			),
			minWidth: "100px",
			selector: row => (row.priority ? row.priority : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<h6 className="mb-0 bold">{row.priority ? row.priority : "-"}</h6>
					</>
				);
			},
			omit: userSpecial,
			style: { justifyContent: "center" },
			conditionalCellStyles: [
				{
					when: row => row.priority === "1",
					style: { background: "red", fontWeight: "700" }
				},
				{
					when: row => row.priority === "2",
					style: { background: "orange", fontWeight: "700" }
				},
				{
					when: row => row.priority === "3",
					style: { background: "yellow", fontWeight: "700" }
				},
				{
					when: row => row.priority === "4",
					style: { background: "#00A0E3", fontWeight: "700" }
				},
				{
					when: row => row.priority === "5",
					style: { background: "#BEBEBE", fontWeight: "700" }
				}
			]
		},
		{
			name: (
				<MinMaxFilter
					title={"Sales Target Date"}
					id={"sales_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "180px",
			selector: row => (row.sales_target_date ? row.sales_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.sales_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.sales_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Telecaller Target Date"}
					id={"telecaller_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType={"numeric"}
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "180px",
			selector: row => (row.telecaller_target_date ? row.telecaller_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.telecaller_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.telecaller_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Collection Target Date"}
					id={"collection_target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					inputType={"datepicker"}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="numeric"
					clearFilters={clearFilters}
					futureDate={true}
				/>
			),
			minWidth: "180px",
			selector: row => (row.collection_target_date ? row.collection_target_date : "-"),
			sortable: false,
			omit: !userSpecial,
			cell: row => {
				return (
					<>
						{row.collection_target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.collection_target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<MinMaxFilter
					title={"Target Date"}
					id={"target_date"}
					placeholder="Date"
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					inputType={"datepicker"}
					setSortArr={setSortArr}
					clearFilters={clearFilters}
					sortType="numeric"
					futureDate={true}
				/>
			),
			minWidth: "180px",
			selector: row => (row.target_date ? row.target_date : "-"),
			sortable: false,
			omit: userSpecial,
			cell: row => {
				return (
					<>
						{row.target_date ? (
							<h6 className="mb-0 bold">
								{moment(row.target_date).format("DD MMM YYYY")}
							</h6>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Assigned Telecaller"}
					id={"assigned_caller"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
					type="loan_accounts"
				/>
			),
			minWidth: "170px",
			selector: row => row.assigned_caller,
			sortable: false,
			cell: row => (row.assigned_caller ? row.assigned_caller : "-")
		},

		{
			name: (
				<NameComp
					title={"BDO"}
					id={"assigned_bdo"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
					type="loan_accounts"
				/>
			),
			minWidth: "140px",
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								<div className="register-count">
									<div
										className="reg-img"
										onClick={() => setSharedModal({ ...row, hide: "CM" })}
									>
										{row.assignedLoanViews &&
											row.assignedLoanViews
												.filter(name => name.user_type === "bdo")
												.map((value, index) => {
													if (index <= 1) {
														return (
															<span
																className={`reg${index + 1} reg`}
																key={`accessList-${index}`}
															>
																<img src={value.image} />
															</span>
														);
													}
												})}
									</div>
									{row.assignedLoanViews &&
									row.assignedLoanViews.filter(name => name.user_type === "bdo")
										.length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
											+
												{row.assignedLoanViews.filter(
													name => name.user_type === "bdo"
												).length - 2}{" "}
											</span>
										) : (
											""
										)}

									<span
										className="cont"
										onClick={() => {
											setLoanAccountId(row.loan_account_enc_id),
											setBdoModal("bdo");
										}}
									>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								</div>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Collection Officer"}
					id={"collection_manager"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
					type="loan_accounts"
				/>
			),
			minWidth: "140px",
			selector: row => moment(row.apply_date).valueOf(), // omit: user.user_type !== 'Financer',
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" || row.access !== "Read Only" ? (
							<>
								<div className="register-count">
									<div
										className="reg-img"
										onClick={() => setSharedModal({ ...row, hide: "BDO" })}
									>
										{row.assignedLoanViews &&
											row.assignedLoanViews
												.filter(
													name => name.user_type === "collection_manager"
												)
												.map((value, index) => {
													if (index <= 1) {
														return (
															<span
																className={`reg${index + 1} reg`}
																key={`accessList-${index}`}
															>
																<img src={value.image} />
															</span>
														);
													}
												})}
									</div>
									{row.assignedLoanViews &&
									row.assignedLoanViews.filter(
										name => name.user_type === "collection_manager"
									).length > 2 ? (
											<span style={{ color: "#00a0e3", fontSize: "13px" }}>
												{" "}
											+
												{row.assignedLoanViews.filter(
													name => name.user_type === "collection_manager"
												).length - 2}{" "}
											</span>
										) : (
											""
										)}
									<span
										className="cont"
										onClick={() => {
											setLoanAccountId(row.loan_account_enc_id),
											setBdoModal("collection_manager");
										}}
									>
										<BsPersonFillAdd color="#00a0e3" size={22} />
									</span>
								</div>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Assigned Financer"}
					id={"financer"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			cell: row => {
				return (
					<>
						{user.user_type === "Financer" ? (
							<>
								<AggrementTypeEdit
									optionList={all_financers}
									getData={() => reloadDetails()}
									defaultValue={row}
									type="loan_account"
									api={"loan-accounts/financer-assigned"}
									id={row.loan_account_enc_id}
									selectPlaceholder="Choose Financer"
									parentId={user.organization_enc_id}
									required={true}
									loanDetailStatus={0}
									editInputActive={true}
								/>
							</>
						) : (
							"-"
						)}
					</>
				);
			}
		}
	];

	const clearFilter = () => {
		setClearFilters(true);
		setDueVal("all");
		setSearchArray({ name: "" });
	};

	useEffect(() => {
		if (Object.keys(searchArray).length) {
			const timerId = setTimeout(() => {
				setCurrentPage(1);
				setTableLoading(type);
				getEmiAccountData({
					page: 1,
					limit: rowsPerPage,
					fields_search: searchArray,
					fields_sort: sortArr,
					sub_bucket: bucketVal,
					collection_date: dateVal === "all" ? "" : dateVal,
					type: type,
					page_type: locationPath ? locationPath : ""
				});
			}, 1000);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}
			clearSelectedRows();
			return () => {
				clearTimeout(timerId);
			};
			// if(user.user_type === "Financer" && (user.organization_username === "phfleasing" || process.env.REACT_APP_TYPE === "DEV")){
			// getLoanAccountStats({bucketVal, fields_search: searchArray});
			// }
		}
	}, [searchArray]);

	useEffect(() => {
		if (Object.keys(sortArr).length) {
			setCurrentPage(1);
			setTableLoading(type);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: sortArr,
				sub_bucket: bucketVal,
				collection_date: dateVal === "all" ? "" : dateVal,
				type: type,
				page_type: locationPath ? locationPath : ""
			});
			clearSelectedRows();
		}
	}, [sortArr]);

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setTableLoading(type);
		getEmiAccountData({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: bucketVal,
			type: type,
			page_type: locationPath ? locationPath : "",
			collection_date: dateVal === "all" ? "" : dateVal
		});
		setCurrentPage(page.selected + 1);
		clearSelectedRows();
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setTableLoading(type);
		getEmiAccountData({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			fields_sort: sortArr,
			sub_bucket: bucketVal,
			type: type,
			page_type: locationPath ? locationPath : "",
			collection_date: dateVal === "all" ? "" : dateVal
		});
		setRowsPerPage(parseInt(e.target.value));
		clearSelectedRows();
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = accountData ? parseInt(accountData[type].count) / rowsPerPage : 1;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	const showAssignTeleModal = () => {
		setTelecallerModal(true);

		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/get-telecaller-list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setTelecallerList(response.data.response.data);
				} else {
					setTelecallerList([]);
				}
			})
			.catch(error => {
				console.log(error);
				handlePageLoaded(false);
				toast.error(
					"An error has occurred while getting Telecaller Data, Please try again"
				);
			});
	};

	const hideTelecallerModal = () => {
		setTelecallerModal(false);
	};

	useEffect(() => {
		if (locationPath) {
			setCurrentPage(1);
			setTableLoading(type);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: searchArray,
				fields_sort: {},
				sub_bucket: bucketVal,
				collection_date: dateVal === "all" ? "" : dateVal,
				type: type,
				page_type: locationPath ? locationPath : ""
			});
			clearSelectedRows();
			showLoanSideBar("");
		}
	}, [locationPath, bucketVal]);

	const updateBucket = val => {
		setBucketVal(val);
	};

	useEffect(() => {
		if (dateVal) {
			setTableLoading(type);
			getEmiAccountData({
				page: 1,
				limit: rowsPerPage,
				fields_search: {},
				fields_sort: {},
				sub_bucket: bucketVal,
				collection_date: dateVal === "all" ? "" : dateVal,
				type: type,
				page_type: locationPath ? locationPath : ""
			});
			clearSelectedRows();
		}
	}, [dateVal]);

	const handleEmiDueDate = val => {
		setTableLoading(type);
		setDueVal(val);
		setSearchArray({ ...searchArray, emi_due: val !== "all" ? val : "" });
	};

	useEffect(() => {
		getFinancersList();
		if (!branches_list) {
			getBranches();
		}
		if (!states_list) {
			getStates();
		}
		if (user.user_type === "Financer" || user.specialAccessRole) {
			setUserSpecial(true);
		}
	}, []);

	useEffect(() => {
		if (accountData && accountData[type]) {
			setTableLoading(false);
			setTotalCaseCount(accountData[type].count);
		}
	}, [accountData]);

	const handleBdoRemoved = id => {
		const tempAll = finalUsers.filter(list => list.shared_to !== id);
		setFinalUsers(tempAll);
	};

	const onSubmit = async data => {
		if (bdoModal === "bdo") {
			const alreadyExist = finalUsers.filter(user => user.shared_to === data.shared_to);
			if (alreadyExist.length) {
				reset();
				toast.error("Already Selected");
				return false;
			}
			setFinalUsers([...finalUsers, data]);
			reset();
		} else {
			assignBdo(bdoModal, data);
		}
	};

	const hidePriorityModal = () => {
		setPriorityModal(false);
	};


	const handleSendEmiLink = () => {
		if (!selectedRows.length) {
			toast.error("Please Choose Loan account to send payment link");
		}
		if (
			!window.confirm(
				"Are you sure you want to send emi collection payment links to the selected Loan Accounts?"
			)
		) {
			return false;
		}
		const selectedIds = selectedRows.map(vals => vals.loan_account_enc_id);
		handlePageLoaded(true);
		const data = {};
		data["loan_account_enc_ids"] = selectedIds;
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}loan-accounts/send-payment-links`,
				data
			)
			.then(response => {
				handlePageLoaded(false);
				if (response.data.response.status === 200) {
					toast.success("Payment Links successfully sent to selected loan accounts");
					clearSelectedRows();
				} else {
					toast.error("An error has occurred while sending payment links");
				}
			})
			.catch(error => {
				handlePageLoaded(false);
				toast.error(error.message);
			});
	};

	useEffect(() => {
		if (toBeShared) {
			onSubmit(getValues());
		}
	}, [toBeShared]);


	return (
		<>
			{locationPath !== "foreclosure-requests" && locationPath !== "noc-requests" && locationPath !== "initiate-requests" ? (
				<div className="container-fluid bg-white">
					<LoanAccountStats
						accountStats={loanAccountStats}
						getLoanAccountStats={getLoanAccountStats}
						loanTypes={loanAccountTypes}
						states_list={states_list}
						bucketVal={bucketVal}
						branches_list={branches_list}
						searchArray={searchArray}
						setSearchArray={setSearchArray}
						dateVal={dateVal}
						user={user}
						dueVal={dueVal}
						updateBucket={updateBucket}
						clearFilters={clearFilters}
						clearFilter={clearFilter}
						setSortArr={setSortArr}
					/>
				</div>
			) : (
				""
			)}
			<div className="dashboard-dsa pb-5">
				<div className="shadow-lg rounded-3 py-4 px-6">
					<div className="date-filterr">
						<h2 className="ptp-heading mb-0">
							{locationPath === "foreclosure-requests"
								? "Foreclosure Requests" 
								: locationPath === "noc-requests" ?
									 "NOC Requests" : locationPath === "initiate-requests" ? "Initiate Requests" 
									 : "Loan Accounts"}
						</h2>
						<div className="d-flex flex-wrap mb-3">
							{bucketVal !== "X" && locationPath !== "foreclosure-requests" && locationPath !== "noc-requests" && locationPath !== "initiate-requests" ? (
								<div className="date-fil-btn my-2 mb-0 txt-btn-sm-w me-3">
									<p className="m-0 fw-medium">EMI&#39;s Due</p>
									<Tab.Container id="top-tabs-example" defaultActiveKey="date">
										<Nav variant="pills" className="flex-row">
											<Nav.Item className="accountBucket">
												<button
													type="button"
													className={`${dueVal === "all" ? "active" : ""} nav-link py-1 px-3`}
													onClick={() => handleEmiDueDate("all")}
												>
												All
												</button>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<button
													type="button"
													className={`${dueVal === "30" ? "active" : ""} nav-link py-1 px-3`}
													onClick={() => handleEmiDueDate("30")}
												>
												30
												</button>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<button
													type="button"
													className={`${dueVal === "45" ? "active" : ""} nav-link py-1 px-3`}
													onClick={() => handleEmiDueDate("45")}
												>
												45
												</button>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<button
													type="button"
													className={`${dueVal === "60" ? "active" : ""} nav-link py-1 px-3`}
													onClick={() => handleEmiDueDate("60")}
												>
												60
												</button>
											</Nav.Item>
											{process.env.REACT_APP_TYPE === "DEV" ? (
												<Nav.Item className="accountBucket">
													<button type="button" className={`${dueVal === "non-starter" ? "active" : ""} nav-link py-1 px-3`}
														onClick={() => handleEmiDueDate("non-starter")}> Non Starter</button>
												</Nav.Item>
											) : ""}
										</Nav>
									</Tab.Container>
								</div>
							) : ""}

							{locationPath !== "foreclosure-requests" && locationPath !== "noc-requests" && locationPath !== "initiate-requests" ? (
								<div className="date-fil-btn my-2 mb-2 mb-md-0 txt-btn-sm-w">
									<p className="m-0 fw-medium">EMI&#39;s Date</p>
									<Tab.Container id="top-tabs-example" defaultActiveKey="date">
										<Nav variant="pills" className="flex-row">
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date"
													className="py-1 px-3"
													onClick={() => setDateVal("all")}
												>
													All
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date0"
													className="py-1 px-3"
													onClick={() =>setDateVal("01")}
												>
													01
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date1"
													className="py-1 px-3"
													onClick={() =>setDateVal("05")}
												>
													05
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date2"
													className="py-1 px-3"
													onClick={() =>setDateVal("11")}
												>
													11
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date3"
													className="py-1 px-3"
													onClick={() =>setDateVal("15")}
												>
													15
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className="accountBucket">
												<Nav.Link
													eventKey="date4"
													className="py-1 px-3"
													onClick={() =>setDateVal("21")}
												>
													21
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</Tab.Container>
								</div>
							) : (
								""
							)}

							<div className="d-flex flex-column justify-content-end me-4 mx-5">
								<div className="d-flex align-items-center">
									<label htmlFor="sort-select" className="fw-medium">
										Show
									</label>
									<select
										className="dataTable-select form-select loan_acc_select"
										style={{
											width: "65px",
											padding: "2px 8px",
											margin: "0px 10px"
										}}
										id="sort-select"
										value={rowsPerPage}
										onChange={e => handlePerPage(e)}
									>
										<option value={10}>10</option>
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={75}>75</option>
										<option value={100}>100</option>
									</select>
									<label htmlFor="sort-select">Results</label>
								</div>
							</div>
						</div>
					</div>

					<Tab.Container id="top-tabs-example" defaultActiveKey="buckit">
						<Row>
							<Row className="mx-0 mt-1 mb-50 align-items-center">
								<Col sm="12" className="pr-0">
									<div className="d-flex align-items-center justify-content-end flex-wrap">
										{selectedRows && selectedRows.length ? (
											<>
												<div className="">
													<button
														type="button"
														className={`btn ${!selectAllCases ? "btn-outline-primary-custom" : "btn-outline-primary-active"} btn-xs txt-btn-sm-w `}
														onClick={() => setSelectAllCases(prevCheck => !prevCheck)}
													>
														Select All ({totalCaseCount ? totalCaseCount : ""})	 Cases
													</button>
												</div>
												{user.user_type === "Financer" && !selectAllCases ? (
													<div>
														<button
															type="button"
															className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
															onClick={() => handleSendEmiLink()}
														>
															Send Payment Link ({selectedRows.length})
														</button>
													</div>
												) : (
													""
												)}

												<div>
													<button
														type="button"
														className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
														onClick={() => setBdoModal("bdo")}
													>
														Assign BDO&#39;s ({!selectAllCases ? selectedRows.length : totalCaseCount})
													</button>
												</div>
												<div>
													<button
														type="button"
														className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
														onClick={() =>
															setBdoModal("collection_manager")
														}
													>
														Assign Collection Manager ({!selectAllCases ? selectedRows.length : totalCaseCount})
													</button>
												</div>
												{(user.user_type === "Financer" ||
													user.username === "tandon") &&
												(user.organization_username === "phfleasing" ||
													process.env.REACT_APP_TYPE === "DEV") ? (
														<div>
															<button
																type="button"
																className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
																onClick={() => hardRecovery("1")}
															>
															Mark Hard Recovery ({!selectAllCases ? selectedRows.length : totalCaseCount})
															</button>
														</div>
													) : (
														""
													)}
												{user.user_type === "Financer" ||
													user.specialAccessRole ||
													user.username === "ghuman" ? (
														<div>
															<button
																type="button"
																className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
																onClick={() => setPriorityModal(true)}
															>
															Assign Priority ({!selectAllCases ? selectedRows.length : totalCaseCount})
															</button>
														</div>
													) : (
														""
													)}
											</>
										) : (
											""
										)}
										{showClearBtn ? (
											<button
												className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
												onClick={e => clearFilter(e)}
											>
												Reset
											</button>
										) : (
											""
										)}
										{(user.user_type === "Financer" || user.username === "phf891" ||
											user.specialAccessRole) &&
										bucketVal ? (
												<button
													type="button"
													className="btn btn-primary btn-xs txt-btn-sm-w ms-2"
													onClick={() => showAssignTeleModal()}
												>
												Assign Telecaller
												</button>
											) : (
												""
											)}

										

									</div>
								</Col>
							</Row>
							<hr />
							<DataTable
								columns={columns}
								conditionalRowStyles={conditionalRowStyles}
								data={
									accountData && accountData[type] ? accountData[type].data : []
								}
								pagination
								paginationServer
								paginationComponent={CustomPagination}
								highlightOnHover="true"
								persistTableHead={true}
								progressPending={tableLoading === type}
								progressComponent={
									<h2 className="w-100 m-5 text-start">Loading...</h2>
								}
								reloadData={reloadDetails}
								className="loan-account-table-container fix-overflow"
								selectableRows={true}
								onSelectedRowsChange={handleSelectedRows}
								clearSelectedRows={toggledClearRows}
								noDataComponent={
									<p className="w-100 m-5 text-start">
										There are no records to display
									</p>
								}
								// fixedHeader={true}
							/>
						</Row>
					</Tab.Container>
				</div>
			</div>
			{user.user_type === "Financer" || user.specialAccessRole || user.username === "phf891" ? (
				<AssignTelecallerModal
					show={telecallerModal}
					hide={hideTelecallerModal}
					callerList={telecallerList}
					bucket={bucketVal}
					reload={reloadDetails}
				/>
			) : (
				""
			)}

			<EmiCollectionForm
				show={showEmiModal}
				hide={handleCloseEmi}
				loan_number={loanAccountNumber}
			/>

			<EmiLoanAccountSidebar
				handleCloseSideBar={() => showLoanSideBar("")}
				loanAccountId={loanAccountId}
				reloadDetails={reloadDetails}
				reloadSidebar={reloadSidebar}
			/>

			<PriorityModal
				show={priorityModal}
				hide={hidePriorityModal}
				selectedRows={selectedRows}
				reload={reloadDetails}
				selectAllCases={selectAllCases}
				searchArray={searchArray}
				bucketVal={bucketVal}
				dateVal={dateVal}
			/>

			<Modal show={bdoModal} onHide={hideBdoModal} size={"md"} centered>
				<Modal.Header>
					<Modal.Title>
						Assign {!loanAccountSidebarId ? "Loan Accounts to" : ""}{" "}
						{bdoModal === "bdo" ? "BDO's" : "Collection Manager"}
					</Modal.Title>
					<span className="close-icon" onClick={() => hideBdoModal()}>
						<IoCloseCircle />
					</span>
				</Modal.Header>
				<Modal.Body>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						{bdoModal === "bdo" ? (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												type="loan_accounts"
											/>
										</div>
									</div>
								</div>
								{finalUsers && finalUsers.length ? (
									<div className="row">
										<h4 className="m-0 mt-2">Selected Users</h4>
										<div className="imageList">
											<table className="table">
												<thead>
													<tr>
														<th>User</th>
														<th className="text-center">Delete</th>
													</tr>
												</thead>
												<tbody className="imageListTd">
													{finalUsers.map((value, index) => {
														return (
															<>
																{value.shared_to ? (
																	<tr
																		key={`users.${index}-${value.shared_to}`}
																	>
																		<td>
																			<div>
																				<img
																					src={
																						value.image
																					}
																					alt={value.name}
																					className="emp-img"
																				/>{" "}
																				{value.name}
																			</div>
																		</td>
																		<td className="text-center">
																			<button
																				type="button"
																				onClick={() =>
																					handleBdoRemoved(
																						value.shared_to
																					)
																				}
																				className="btn btn-primary btn-xs"
																			>
																				<MdDelete
																					fontSize={15}
																				/>
																			</button>
																		</td>
																	</tr>
																) : (
																	""
																)}
															</>
														);
													})}
												</tbody>
											</table>
										</div>
										<div className="col-sm-12 col-12">
											<Col size={12} className="text-center mt-2">
												<button
													type="button"
													className="btn btn-primary"
													onClick={() => assignBdo(bdoModal)}
												>
													Save
												</button>
											</Col>
										</div>
									</div>
								) : (
									""
								)}
							</>
						) : (
							<>
								<div className="col-sm-12 col-12">
									<div className="mb-3 d-flex">
										<div className="w-100">
											<AutoComplete
												setFieldValue={setValue}
												link={`company-dashboard/employee-search?type=${bdoModal}&employee_search=`}
												name={"shared_to"}
												val={toBeSharedName ? toBeSharedName : "empty"}
												method={"GET"}
												placeholder={"Enter Username"}
												// type="loan_accounts"
											/>
											<div className="col-sm-12 col-12">
												<Col size={12} className="text-center mt-2">
													<button
														type="submit"
														className="btn btn-primary"
													>
														Save
													</button>
												</Col>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</form>
				</Modal.Body>
			</Modal>

			<CollectionManagerModal
				sharedModal={sharedModal}
				setSharedModal={setSharedModal}
				reloadDetails={reloadDetails}
			/>

			<UpdateEmiFields
				emiData={editEmiFields}
				onHide={() => setEditEmiFields({ loan_account_enc_id: "" })}
				reload={() => {
					setEditEmiFields({ loan_account_enc_id: "" });
					reloadDetails();
				}}
				api={"loan-accounts/update-loan-account"}
			/>
		</>
	);
};

export default LoanAccountsTable;
