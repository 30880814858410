import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import NameComp from "../NameComp";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { EmployeeReportsContext } from "../../context/EmployeeReports";
import MultiDataFilters from "../MultiDataFilters";
import CollectionReportFilters from "./CollectionReportFilters";
import { MainContext } from "../../context/MainState";
import axios from "axios";
import SkeletonCard from "./SkeletonCard";
import CollectionReportSidebar from "./CollectionReportSidebar";

const CollectionReportTable = () => {
	const { getCollectionReports, collectionReports} =
		useContext(EmployeeReportsContext);
	const { branches_list, getBranches, getStates, states_list, getDesignationList, designationList, departmentList } = useContext(
		OrganizationDashboardContext
	);
	const {loanAccountTypes } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [searchArray, setSearchArray] = useState({});
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [sortArr, setSortArr] = useState({});
	const [userId, setUserId] = useState("");
	const [collectionReportsStats, setCollectionReportsStats] = useState([]);
	const [loading,setLoading]=useState(true);
	const [pageLoading, setPageLoading] = useState(true);
	const [showSidebar, setShowSidebar] = useState(false);
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		product_id: "",
		loan_type: "",
		field: "",
		order_by: "",
		report_type: ""
	});
	// const DataTableHeading = ({ name }) => {
	// 	return (
	// 		<div className="w-100">
	// 			<h3 className="text-center">{name}</h3>
	// 			<div className="d-flex justify-content-around fix-collection-w">
	// 				<span>Total Cases</span>
	// 				<span>Collection in Cases</span>
	// 				<span>Target Amount</span>
	// 				<span>Verified Amount</span>
	// 				<span>Unverified Amount</span>
	// 			</div>
	// 		</div>
	// 	);
	// };

	const DataTableDetails = ({ emiDemand, totalCases, colletionCases, targetAmount, verifiedAmount, unVerifiedAmount }) => {
		return (
			<div className="d-flex justify-content-around fix-collection-w w-100">
				<div>{totalCases ? parseFloat(totalCases).toLocaleString("en-In") : "0"}</div>
				<div>
					{colletionCases ? parseFloat(colletionCases).toLocaleString("en-In") : "0"}
				</div>
				<div> ₹ 
					{emiDemand ? (
						<>  {parseFloat(emiDemand).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
				<div> ₹ 
					{targetAmount ? (
						<> {parseFloat(targetAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
				<div> ₹ 
					{verifiedAmount ? (
						<> {parseFloat(verifiedAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
				<div> ₹ 
					{unVerifiedAmount ? (
						<> {parseFloat(unVerifiedAmount).toLocaleString("en-IN")}</>
					) : (
						"0"
					)}
				</div>
			</div>
		);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Name"}
					id={"employee_name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.employee_name ? row.employee_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize text-link"
					 onClick={() => showCollectionReportSidebar(`${row.user_enc_id}`)}
					>
						<img
							src={row.employee_image}
							alt={row.employee_name}
							className="img-fluid rounded-50 mr-1"
							style={{  width: 25, height:25, objectFit: "cover" }}
						/>
						{row.employee_name}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Employee Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "120px",
			selector: row => (row.employee_code ? row.employee_code : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					departmentList={departmentList}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			omit: filters.report_type,
			selector: row => (row.department ? row.department : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
					designations={designationList}
				/>
			),
			minWidth: "200px",
			selector: row => (row.designation ? row.designation : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			sortable: false,
			cell: row => {
				return (
					<h6 className="mb-0 text-capitalize d-flex align-items-center">
						{row.reporting_person ? (
							<>
								<img
									src={row.reporting_image}
									alt={row.reporting_person}
									className="img-fluid rounded-50 mr-1"
									style={{ width: 25, height:25, objectFit: "cover" }}
								/>
								{row.reporting_person}
							</>
						) : "-"}
					</h6>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortArr={sortArr}
					setSortArr={setSortArr}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: <CollectionReportFilters name={"Total"} type={"total"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "total",
			style: { textAlign: "center" },
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.total_cases_count}
						colletionCases={row?.total_collected_cases_count}
						emiDemand={row?.total_emi_demand}
						targetAmount={row?.total_target_amount}
						verifiedAmount={row?.total_collected_verified_amount}
						unVerifiedAmount={row?.total_collected_unverified_amount}	
					/>
				);
			},
			sortable: false
		},
		{
			name:  <CollectionReportFilters name={"On Time"} type={"OnTime"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "on-time",
			style: { textAlign: "center" },
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.OnTime_total_cases_count}
						colletionCases={row?.OnTime_collected_cases_count}
						emiDemand={row?.OnTime_emi_demand}
						targetAmount={row?.OnTime_target_amount}
						verifiedAmount={row?.OnTime_collected_verified_amount}
						unVerifiedAmount={row?.OnTime_collected_unverified_amount}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportFilters name={"0-30"} type={"SMA0"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "0-30",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA0_total_cases_count}
						colletionCases={row?.SMA0_collected_cases_count}
						emiDemand={row?.SMA0_emi_demand}
						targetAmount={row?.SMA0_target_amount}
						verifiedAmount={row?.SMA0_collected_verified_amount}
						unVerifiedAmount={row?.SMA0_collected_unverified_amount}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportFilters name={"30-60"} type={"SMA1"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "30-60",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA1_total_cases_count}
						colletionCases={row?.SMA1_collected_cases_count}
						emiDemand={row?.SMA1_emi_demand}
						targetAmount={row?.SMA1_target_amount}
						verifiedAmount={row?.SMA1_collected_verified_amount}
						unVerifiedAmount={row?.SMA1_collected_unverified_amount}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportFilters name={"60-90"} type={"SMA2"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "60-90",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.SMA2_total_cases_count}
						colletionCases={row?.SMA2_collected_cases_count}
						emiDemand={row?.SMA2_emi_demand}
						targetAmount={row?.SMA2_target_amount}
						verifiedAmount={row?.SMA2_collected_verified_amount}
						unVerifiedAmount={row?.SMA2_collected_unverified_amount}
					/>
				);
			},
			sortable: false
		},
		{
			name: <CollectionReportFilters name={"90+"} type={"NPA"} searchArray={searchArray} setSearchArray={setSearchArray} 
				sortArr={sortArr} setSortArr={setSortArr} clearFilters={clearFilters}/>,
			id: "90+",
			minWidth: "900px",
			cell: row => {
				return (
					<DataTableDetails
						totalCases={row?.NPA_total_cases_count}
						colletionCases={row?.NPA_collected_cases_count}
						emiDemand={row?.NPA_emi_demand}
						targetAmount={row?.NPA_target_amount}
						verifiedAmount={row?.NPA_collected_verified_amount}
						unVerifiedAmount={row?.NPA_collected_unverified_amount}
					/>
				);
			},
			sortable: false
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		setPageLoading(true);
		getCollectionReports({
			page: page.selected + 1,
			limit: rowsPerPage,
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			order_by: filters.order_by,
			report_type: filters.report_type,
			fields_sort: sortArr,
		});
		setCurrentPage(page.selected + 1);
	};

	const getCollectionReportStats = filters => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/employee-collected-emi-overall-stats`,
				filters
			)
			.then(response => {
				setLoading(false);
				if (response.data.response.status === 200) {
					setCollectionReportsStats(response.data.response.data);
				} else {
					setCollectionReportsStats([]);
				}
			})
			.catch(error => {
				console.error("An Error Occured", error);
				setLoading(false);
			});
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		setPageLoading(true);
		getCollectionReports({
			page: 1,
			limit: parseInt(e.target.value),
			start_date: filters.start_date,
			end_date: filters.end_date,
			loan_type: filters.loan_type,
			fields_search: searchArray,
			order_by: filters.order_by,
			report_type: filters.report_type,
			fields_sort: sortArr,
		});
		setRowsPerPage(parseInt(e.target.value));
	};
     
	const handleClose = () => {
		setShowSidebar(false);
		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	const showCollectionReportSidebar =(row) => {
		setShowSidebar(true);
		setUserId(row);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({});
	};

	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(collectionReports.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}

		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	// ** Table data to render
	const dataToRender = () => {
		const subFilters = {
			q: ""
		};

		const isFiltered = Object.keys(subFilters).some(function (k) {
			return subFilters[k].length > 0;
		});

		if (collectionReports && collectionReports.data.length > 0) {
			return collectionReports.data;
		} else if (collectionReports && collectionReports.data.length === 0 && isFiltered) {
			return [];
		} else {
			return collectionReports && collectionReports.data.length > 0
				? collectionReports.data.slice(0, rowsPerPage)
				: [];
		}
	};
	const handleSort = async (column, sortDirection) => {
		setFilters({
			...filters,
			field: column.id,
			order_by: sortDirection === "asc" ? 0 : 1
		});
	};

	useEffect(()=>{
		if(collectionReports){
			setPageLoading(false);
		}
	},[collectionReports]);
	useEffect(() => {
		if(!designationList){
			getDesignationList();
		}
		if (!branches_list) {
			getBranches();
		}
		getStates();
	}, []);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			setPageLoading(true);
			const timerId = setTimeout(() => {
				getCollectionReports({
					page: 1,
					limit: rowsPerPage,
					// keyword: searchValue,
					start_date: filters.start_date,
					end_date: filters.end_date,
					loan_type: filters.loan_type,
					fields_search: searchArray,
					order_by: filters.order_by,				
					report_type: filters.report_type,
					fields_sort: sortArr,
				});
				setCurrentPage(1);
			}, 1000);

			const valLength = Object.values(searchArray);
			if (!valLength.length || !valLength.filter(val => val != "").length) {
				setShowClearBtn(false);
			} else {
				setClearFilters(false);
				setShowClearBtn(true);
			}

			return () => {
				clearTimeout(timerId);
			};
		}
		  
	}, [filters, sortArr, searchArray]);

	useEffect(() => {
		if (filters.start_date && filters.end_date) {
			setPageLoading(true);
			getCollectionReportStats({
				start_date: filters.start_date + " 00:00:00",
				end_date: filters.end_date + " 23:59:59",
				loan_type: filters.loan_type,
				report_type: filters.report_type,
			});
		}
	},[filters]);

	const getDataApi = (filters) => {
		setFilters({...searchArray, ...filters});
	};

	return (
		<>
			<div className="loan-application-table mb-5">
				{loading ? ( <div className="row" style={{minHeight:"3px"}}>
					{[...Array(6)].map((index) => (
						<SkeletonCard key={index} type={"sm"}/>
					))}
				</div>) :
					(<div className="dsa-stats">
						<div className="row">
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionReportsStats?.total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>Total Cases</span>
									</p>
									<div className="loan-amounts">
								 <p className="mb-0 text-white text-end bold">Collected Cases</p>
										<p className="mb-0 text-white text-end bold">
								  {collectionReportsStats?.total_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionReportsStats?.OnTime_total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>On Time Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">Collected Cases</p>
										<p className="mb-0 text-white text-end bold">
									 {collectionReportsStats?.OnTime_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionReportsStats?.SMA0_total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>0-30 Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
									 <p className="mb-0 text-white text-end bold">Collected Cases</p>
									 {collectionReportsStats?.SMA0_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionReportsStats?.SMA1_total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>30-60 Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
								 <p className="mb-0 text-white text-end bold">Collected Cases</p>
								 	{collectionReportsStats?.SMA1_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
								 {collectionReportsStats?.SMA2_total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>60-90 Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
									 <p className="mb-0 text-white text-end bold">Collected Cases</p>
									 {collectionReportsStats?.SMA2_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body">
									<img
										src="/images/pages/dashboard/total-applications.png"
										className="card-right-img"
										alt="ds"
									/>
									<p className="card-stat mb-0">
										{collectionReportsStats?.NPA_total_cases_count}
									</p>
									<p className="card-head mb-1">
										<span>90+ Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
											<p className="mb-0 text-white text-end bold">Collected Cases</p>
											{collectionReportsStats?.NPA_collected_cases_count}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>)}
				<div className="d-flex align-items-center justify-content-between flex-wrap">
					<div className="d-flex flex-wrap align-items-center py-2">
						<div className="form-group" style={{marginRight: 10}}>
							<label htmlFor="float-text" className="floating-label fw400 ft14 fs-5">
									Show Results
							</label>
							<select
								className="dataTable-select form-select"
								style={{ width: "95px", padding: "2px 8px", height: "48px" }}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
						</div>
						<MultiDataFilters
							branches_list={branches_list}
							loan_types={loanAccountTypes}
							getData={getDataApi}
							type={["loanAccountsType"]}
						/>
					</div>
					<Row className="mx-0 mt-1">
						<Col sm="12" className="date-fil-btn my-2 mb-0 txt-btn-sm-w me-3">
							<Tab.Container id="top-tabs-example" defaultActiveKey="reportType0">
								<Nav variant="pills" className="flex-row">
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="reportType0" className="py-1 px-3" onClick={() => setFilters({...filters, report_type: ""})}>All</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="reportType1"  className="py-1 px-3" onClick={() => setFilters({...filters, report_type: 1})}>Sales</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="reportType2"  className="py-1 px-3" onClick={() => setFilters({...filters, report_type:  2})}>Collection</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="reportType3"  className="py-1 px-3" onClick={() => setFilters({...filters, report_type:  3})}>Telecaller</Nav.Link>
									</Nav.Item>
								</Nav>
							</Tab.Container>
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary btn-xs my-2"
										onClick={e => clearFilter(e)}
									>
									Reset
									</button>
								) : (
									""
								)}
							</div>
						</Col>
					</Row>
				</div>
				<DataTable
					noHeader
					pagination
					paginationServer
					columns={columns}
					data={dataToRender()}
					sortServer={true}
					onSort={handleSort}
					paginationComponent={CustomPagination}
					progressPending={pageLoading}
					highlightOnHover="true"
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
					persistTableHead={true}
					className="loan-app-table-container fix-overflow report-tables z-0"
					fixedHeader={true}
				/>
			</div>
			<CollectionReportSidebar
				showSideBar={showSidebar}
				handleClose={handleClose}
				filters={filters}
				userId={userId}
			/>
		</>
	);
};
export default CollectionReportTable;
