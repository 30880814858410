import "../../assets/css/formstyles.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import { MainContext } from "../../context/MainState";
import AutoComplete from "../forms/AutoComplete";
import { toast } from "react-toastify";
import { BiRupee } from "react-icons/bi";

export default function EmiCollectionNewStep2({ formStep, setFormData, nextFormStep, fullFormData, data }) {
	const {
		register,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		setValue,
		formState: { errors }
	} = useForm();
	const { user } = useContext(MainContext);
	const [ptpShow, setPtpShow] = useState(false);
	const [paidPayment, setPaidPayment] = useState(false);
	const [totalTarget, setTotalTarget] = useState({target: 0, outstanding: 0});
	const otherPayment = watch("payment_mode");
	const onlineOffsystem = watch("online_offsystem");
	const delaySystem = watch("delay_reason");
	const ptpPayment = watch("ptp_payment_method");
	const amounts = watch("amount");
	const [numberExist, setNumberExist] = useState({
		ref: false
	});
	const [inputRefNo, setInputRefNo] = useState("");
	const [selectedVal, setSelectedVal] = useState("~");

	const numberValidateRegex = /^[0-9]\d*$/;
	
	const checkRefNumber = async (value, key) => {
		if (value) {
			let data = { ref: inputRefNo };
			await axios
				.post(
					`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}organizations/emi-ref-check`,
					data
				)
				.then(response => {
					if (response.data.response.status === 201) {
						setError(key, {
							type: "custom",
							message: "Already Exists"
						});
						setNumberExist({ ...numberExist, [key]: true });
					} else {
						clearErrors(key);
						setNumberExist({ ...numberExist, [key]: false });
					}
				})
				.catch(error => {
					toast.error(error.message);
				});	
		}
	};

	const handleChange = (e) => {
		setInputRefNo(e.target.value);
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			checkRefNumber(inputRefNo, "reference_number");
		}, 500);

		return () => {
			clearTimeout(timer);
		};
	}, [inputRefNo]);

	useEffect(() => {
		setValue("payment_method", paidPayment ? "0" : null);
		setValue("online_offsystem", null);
	}, [otherPayment]);
	
	useEffect(() => {
		if(paidPayment){
			setValue("amount", "0");
			setValue("payment_method", "0");
			setValue("payment_mode", "0");
		}else{
			setValue("amount", "");
		}
	}, [paidPayment]);
	
	useEffect(() => {
		if(amounts === "0"){
			setPaidPayment(true);
		}
	}, [amounts]);

	useEffect(() => {
		const targetAmount = data.target_collection_amount ? parseFloat(data?.target_collection_amount) : "0";
		const outStandingAmount = data.total_pending_amount ? parseFloat(data?.total_pending_amount) : "0";
		const collectionCharges = 400;
		const visitCharges = fullFormData.customer_interaction === "Visit" ? 118 : 0;
		const totalAmount = targetAmount + collectionCharges + visitCharges;
		const totalOutstandingAmount = outStandingAmount + collectionCharges + visitCharges;
	  
		setTotalTarget({target: Math.round(totalAmount).toLocaleString("en-IN"), outstanding: Math.round(totalOutstandingAmount).toLocaleString("en-IN")});
	  }, [data,fullFormData]);

	  const handleAmountClick = (type) => {
		let unformattedTotal = "";
		if (type === "target") {
			unformattedTotal = totalTarget.target.replace(/,/g, "");
		} else if (type === "outstanding") {
			unformattedTotal = totalTarget.outstanding.replace(/,/g, "");
		}
		if (!paidPayment) {
			setValue("amount", unformattedTotal);
		}
	};

	const onSubmit = values => {
		if (selectedVal !== "~") {
			values["collection_date"] = moment(selectedVal).format("YYYY-MM-DD");
		}

		setFormData(values);
		nextFormStep();
	};

	return (
		<div className={formStep === 1 ? "showForm" : "hideForm"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row mb-3">
					<div className="col-6 my-2">
						<p className="d-flex flex-wrap mb-3 mt-1">
							<span style={{ fontWeight: 600, color: "#444" }}>Target Collection Amount:</span>
							<div className="text-secondary fw-medium mx-2 d-flex align-items-center fw-bold cursor-point" onClick={() => handleAmountClick("target")}>
								<BiRupee size={16} /> {totalTarget.target}
							</div>
						</p>
					</div>
					<div className="col-6 my-2">
						<p className="d-flex flex-wrap mb-3 mt-1">
							<span>Net Outstanding Amount:</span>
							<div className="text-gray-800 fw-medium mx-2 d-flex align-items-center cursor-point" onClick={() => handleAmountClick("outstanding")}>
								<BiRupee size={16} /> {totalTarget.outstanding}
							</div>
						</p>
					</div>

					<div className="col-12">
						<label className="form-label mb-0" htmlFor="amount">
							Amount Collected {!paidPayment ? (<span style={{ color: "red" }}>*</span>) : ""}
						</label>
					</div>
					
					<div className="col-lg-5 mb-3">
						<input
							placeholder=""
							className="form-control"
							{...register("amount", {
								required: !paidPayment ? "This is required." : false,
								minLength: {
									value: 1,
									message: "Loan Amount is too Less"
								},
								maxLength: {
									value: 8,
									message: "Loan Amount is too High"
								},
								pattern: {
									value: numberValidateRegex,
									message: "Loan Amount is invalid"
								}
							})}
							disabled={paidPayment}
						/>
						<ErrorMessage
							errors={errors}
							name="amount"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</div>
				
					<div className="col-lg-4">
						<div className="radio-wrapper">
							<div className="radio-item">
								<input
									type="checkbox"
									name="payment_delay"
									className="radioInput"
									id="not_collected"
									value="0"
									onChange={() => setPaidPayment(paidPayment => !paidPayment)}
									checked = {paidPayment ? "checked" : ""}
								/>
								<label htmlFor="not_collected" className="option option-1 mb-2">
									<span>Not Collected</span>
								</label>
							</div>
						</div>
					</div>
					
					<div className="col-lg-12">
						<label className="form-label mb-0" htmlFor="">
							Reason For Delay In Payment
						</label>
					</div>
					<div className="radio-wrapper">
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_1"
								value="Nach/E-Nach"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_1" className="option option-1 mb-2">
								<span>Nach/E-Nach Issue</span>
							</label>
						</div>
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_2"
								value="Loss of Job"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_2" className="option option-1 mb-2">
								<span>Loss of Job</span>
							</label>
						</div>
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_3"
								value="Business Loss"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_3" className="option option-1 mb-2">
								<span>Business Loss</span>
							</label>
						</div>
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_4"
								value="Medical Emergency"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_4" className="option option-1 mb-2">
								<span>Medical Emergency</span>
							</label>
						</div>
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_5"
								value="Forgot to Pay"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_5" className="option option-1 mb-2">
								<span>Forgot to Pay</span>
							</label>
						</div>
						<div className="radio-item">
							<input
								type="checkbox"
								name="payment_delay"
								className="radioInput"
								id="payment_delay_6"
								value="others"
								{...register("delay_reason")}
							/>
							<label htmlFor="payment_delay_6" className="option option-1 mb-2">
								<span>Others</span>
							</label>
						</div>
					</div>
					{delaySystem && delaySystem.includes("others") ? (
						<div className="col-lg-6 mb-3">
							<label className="form-label mb-0" htmlFor="other_delay_reason">
								Other Reason For Delay{" "}
							</label>
							<input
								placeholder=""
								className="form-control"
								{...register("other_delay_reason", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="other_delay_reason"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					) : (
						""
					)}
					{delaySystem && delaySystem.includes("Nach/E-Nach") ? (
						<div className="row mb-3">
							<div className="col-lg-12">
								<label className="form-label mb-0" htmlFor="">
									Choose Reason <span style={{ color: "red" }}>*</span>
								</label>
							</div>
							<div className="radio-wrapper">
								<div className="radio-item">
									<input
										type="radio"
										name="payment_delay"
										className="radioInput"
										id="nach"
										value="Nach/Enach not registered"
										{...register("delay_reason_payment", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="nach"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Nach/E-Nach not registered</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="payment_delay"
										className="radioInput"
										id="Insufficient-balance"
										value="Insufficient balance"
										{...register("delay_reason_payment", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="Insufficient-balance"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Insufficient balance</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="payment_delay"
										className="radioInput"
										id="Not-using-bank"
										value="Not using bank account"
										{...register("delay_reason_payment", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="Not-using-bank"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Not using bank account</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="payment_delay"
										className="radioInput"
										id="account-blocked"
										value="Bank account blocked"
										{...register("delay_reason_payment", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="account-blocked"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Bank account blocked</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="payment_delay"
										className="radioInput"
										id="Nach-Error"
										value="Nach/Enach Error"
										{...register("delay_reason_payment", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="Nach-Error"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Nach/E-Nach Error</span>
									</label>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>

				{!paidPayment ? (
					<div className="row mb-3">
						<div className="col-lg-12">
							<label className="form-label mb-0" htmlFor="">
								Mode Of Payment <span style={{ color: "red" }}>*</span>
							</label>
						</div>
						<div className="radio-wrapper">
							<div className="radio-item">
								<input
									type="radio"
									name="payment_method"
									className="radioInput"
									id="pay_now"
									value="1"
									{...register("payment_mode", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="pay_now"
									className="option option-1"
									style={{
										justifyContent: "flex-start",
										marginBottom: "10px"
									}}
								>
									<div className="dot"></div>
									<span>Pay Now</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="payment_method"
									className="radioInput"
									id="payment_manual_collection"
									disabled={fullFormData.customer_interaction === "On Call"}
									value="2"
									{...register("payment_mode", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="payment_manual_collection"
									className={`option option-1 ${fullFormData.customer_interaction === "On Call" ? "bg-gray-200" : ""}`}
									style={{
										justifyContent: "flex-start",
										marginBottom: "10px"
									}}
								>
									<div className="dot"></div>
									<span>Manual Collection</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="payment_method"
									className="radioInput"
									id="pay_by_eod"
									value="3"
									{...register("payment_mode", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="pay_by_eod"
									className="option option-1"
									style={{
										justifyContent: "flex-start",
										marginBottom: "10px"
									}}
								>
									<div className="dot"></div>
									<span>Pay By EOD</span>
								</label>
							</div>
							<div className="radio-item">
								<input
									type="radio"
									name="payment_method"
									className="radioInput"
									id="online_off_system"
									value="4"
									{...register("payment_mode", {
										required: "This is required."
									})}
								/>
								<label
									htmlFor="online_off_system"
									className="option option-1"
									style={{
										justifyContent: "flex-start",
										marginBottom: "10px"
									}}
								>
									<div className="dot"></div>
									<span>Online Off System Transaction</span>
								</label>
							</div>
						</div>

						<ErrorMessage
							errors={errors}
							name="payment_mode"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>

						{otherPayment === "1" ? (
							<>
								<label className="form-label mb-0">
									Choose Pay Now Options <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="qr-code"
											className="radioInput"
											id="QrCode"
											value="1"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="QrCode"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Generate QR</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="qr-link"
											className="radioInput"
											id="link"
											value="2"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="link"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Generate Link</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											disabled={true}
											type="radio"
											name="POS_Service"
											className="radioInput"
											id="pos-service"
											value=""
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pos-service"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>POS Machine</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="payment_method"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</>
						) : (
							""
						)}

						{otherPayment === "2" ? (
							<>
								<label className="form-label mb-0">
									Manual Collection By <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="manual_collection"
											className="radioInput"
											id="manual_cash"
											value="4"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="manual_cash"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Cash</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="manual_collection"
											className="radioInput"
											id="manual_cheque"
											value="5"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="manual_cheque"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Cheque</span>
										</label>
									</div>
									{/* <div className="radio-item">
					<input
						type="radio"
						name="manual_collection"
						className="radioInput"
						id="paid_to_dealer"
						value="4"
						{...register("payment_method", {
						required: "This is required.",
						})}
					/>
					<label
						htmlFor="paid_to_dealer"
						className="option option-1"
						style={{
						justifyContent: "flex-start",
						marginBottom: "10px",
						}}
					>
						<div className="dot"></div>
						<span>Paid To Dealer</span>
					</label>
					</div> */}
								</div>
								<ErrorMessage
									errors={errors}
									name="payment_method"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</>
						) : (
							""
						)}
						{otherPayment === "3" ? (
							<>
								<label className="form-label mb-0">
									EOD By <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="pay_by_eod"
											className="radioInput"
											id="pay_by_eod_nach"
											value="6"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pay_by_eod_nach"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Nach</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="pay_by_eod"
											className="radioInput"
											id="pay_by_eod_enach"
											value="7"
											{...register("payment_method", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="pay_by_eod_enach"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Enach</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="payment_method"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</>
						) : (
							""
						)}
						{otherPayment === "4" ? (
							<>
								<label className="form-label mb-0">
									Online Off System By <span style={{ color: "red" }}>*</span>
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="online_offsystem"
											className="radioInput"
											id="directBank"
											value="Direct Deposit In Bank Account"
											{...register("online_offsystem", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="directBank"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Direct Deposit In Bank Account</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="online_offsystem"
											className="radioInput"
											id="digital_platform"
											value="Through Digital Platform"
											{...register("online_offsystem", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="digital_platform"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Through Digital Platform</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="manual_collection"
											className="radioInput"
											id="paid_to_dealer"
											value="11"
											{...register("online_offsystem", {
												required: "This is required."
											})}
										/>
										<label
											htmlFor="paid_to_dealer"
											className="option option-1"
											style={{
												justifyContent: "flex-start",
												marginBottom: "10px"
											}}
										>
											<div className="dot"></div>
											<span>Paid To Dealer</span>
										</label>
									</div>
								</div>
								<ErrorMessage
									errors={errors}
									name="online_offsystem"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</>
						) : (
							""
						)}
					</div>
				): ""}

				{otherPayment === "4" && onlineOffsystem === "Direct Deposit In Bank Account" ? (
					<>
						<div className="radio-wrapper">
							<label className="form-label mb-0">
								Direct Deposit In Bank Account By{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<div className="radio-wrapper">
								<div className="radio-item">
									<input
										type="radio"
										name="direct_deposit"
										className="radioInput"
										id="directBankCash"
										value="81"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="directBankCash"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Cash</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="direct_deposit"
										className="radioInput"
										id="directBankCheque"
										value="82"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="directBankCheque"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Cheque</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="direct_deposit"
										className="radioInput"
										id="directBankNetBanking"
										value="83"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="directBankNetBanking"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Net Banking</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="direct_deposit"
										className="radioInput"
										id="directBankNeft"
										value="84"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="directBankNeft"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>RTGS/NEFT</span>
									</label>
								</div>
							</div>
						</div>
						<ErrorMessage
							errors={errors}
							name="payment_method"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</>
				) : (
					""
				)}
				{otherPayment === "4" && onlineOffsystem === "Through Digital Platform" ? (
					<>
						<div className="radio-wrapper">
							<label className="form-label mb-0">
								Through Digital Platform By <span style={{ color: "red" }}>*</span>
							</label>
							<div className="radio-wrapper w-100">
								<div className="radio-item">
									<input
										type="radio"
										name="through_digital_platform"
										className="radioInput"
										id="digitalUpi"
										value="9"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="digitalUpi"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Scanned QR Code/UPI</span>
									</label>
								</div>
								<div className="radio-item">
									<input
										type="radio"
										name="through_digital_platform"
										className="radioInput"
										id="digitalTransfer"
										value="10"
										{...register("payment_method", {
											required: "This is required."
										})}
									/>
									<label
										htmlFor="digitalTransfer"
										className="option option-1"
										style={{
											justifyContent: "flex-start",
											marginBottom: "10px"
										}}
									>
										<div className="dot"></div>
										<span>Digital Transfer</span>
									</label>
								</div>
							</div>
						</div>
						<ErrorMessage
							errors={errors}
							name="payment_method"
							render={({ message }) => (
								<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
							)}
						/>
					</>
				) : (
					""
				)}
				<div className="row mb-3">
					{otherPayment === "2" || otherPayment === "3" || otherPayment === "4" ? (
						<div className="col-md-6 col-12">
							<label className="form-label mb-0">
								Physical Receipt Number
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Refrence Number"
								{...register("reference_number", {
									required: false
								})}
								onChange={handleChange}
							/>
							<ErrorMessage
								errors={errors}
								name="reference_number"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					) : (
						""
					)}

					{otherPayment !== "1" && (user.designation === "Tele Caller Collection" || user.designation === "Tele Sales Executive") && !paidPayment ? (
						<div className="col-md-6 col-12 d-flex flex-column">
							<label className="form-label mb-0" htmlFor="collection_date">
								Collection Date
								<span style={{ color: "red" }}>*</span>
							</label>
							<ReactDatePicker
								selected={selectedVal !== "~" ? selectedVal : ""}
								onChange={date => setSelectedVal(date)}
								placeholderText="Enter Collection Date"
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								className={"form-control"}
								isClearable={selectedVal !== "~"}
								maxDate={new Date()}
								dateFormat="dd MMM yyyy"
							/>
						</div>
					) : ("")}

					{otherPayment === "4" && onlineOffsystem === "11" ? (
						<div className="col-md-6 col-12">
							<label className="form-label mb-0">
								Dealer Name
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Dealer Name"
								{...register("dealer_name", {
									required: "This is required."
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="dealer_name"
								render={({ message }) => (
									<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
								)}
							/>
						</div>
					) : (
						""
					)}
				</div>

				<hr />
				<div className="row">
					<div className="col-lg-12 mb-3">
						<button
							type="button"
							className="btn btn-primary btn-sm"
							onClick={() => setPtpShow(prevPtpShow => !prevPtpShow)}
						>
							Promise To Pay
							{!ptpShow ? (
								<AiOutlinePlus style={{ marginTop: "-3px", marginLeft: "5px" }} />
							) : (
								<AiOutlineMinus style={{ marginTop: "-3px", marginLeft: "5px" }} />
							)}
						</button>
						<p style={{ marginBottom: "0px", fontSize: "14px" }}>
							Click to activate Promise To Pay
						</p>
					</div>
					{ptpShow || paidPayment  ? (
						<>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="organization_name">
								PTP Amount
								</label>
								<input
									placeholder=""
									className="form-control"
									{...register("ptp_amount", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="ptp_amount"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="ptp_date">
								PTP Date
								</label>
								<input
									type="date"
									placeholder=""
									className="form-control"
									min={moment().format("YYYY-MM-DD")}
									{...register("ptp_date", {
										required: "This is required."
									})}
								/>
								<ErrorMessage
									errors={errors}
									name="ptp_date"
									render={({ message }) => (
										<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
									)}
								/>
							</div>
							<div className="col-lg-6 mb-3">
								<label className="form-label mb-0" htmlFor="ptp_payment_method">
									PTP Payment Method
								</label>
								<div className="radio-wrapper">
									<div className="radio-item">
										<input
											type="radio"
											name="ptp_payment_method"
											className="radioInput"
											id="ptp_payment_method_1"
											value="1"
											{...register("ptp_payment_method", {
												required: "This is required."
											})}
										/>
										<label htmlFor="ptp_payment_method_1" className="option option-1 mb-2">
											<span>Cash</span>
										</label>
									</div>
									<div className="radio-item">
										<input
											type="radio"
											name="ptp_payment_method"
											className="radioInput"
											id="ptp_payment_method_2"
											value="2"
											{...register("ptp_payment_method", {
												required: "This is required."
											})}
										/>
										<label htmlFor="ptp_payment_method_2" className="option option-1 mb-2">
											<span>Online</span>
										</label>
									</div>
									<ErrorMessage
										errors={errors}
										name="ptp_payment_method"
										render={({ message }) => (
											<p style={{ color: "red", fontSize: "13px" }}>{message}</p>
										)}
									/>
								</div>
							</div>
							{ptpPayment === "1" && fullFormData.branch_enc_id === "yVgawN7rxoLL9A10jpnYoYOM5kelbv" && (user.designation === "Tele Sales Executive" || user.designation === "Tele Caller Collection") ? (
								<div className="col-lg-6 mb-3">
									<label className="form-label mb-0" htmlFor="collection_manager">Collection Manager</label>
									<div className="w-100">
										<AutoComplete
											setFieldValue={setValue}
											link={`company-dashboard/employee-search?type=employees&loan_id=${fullFormData.loan_account_enc_id}&employee_search=`}
											name={"ptp_collection_manager"}
											method={"GET"}
											placeholder={"Enter Username"}
										/>
									</div>
								</div>
							) : ""}
						</>
					) : (
						""
					)}
				</div>

				<hr />
				<button type="submit" className="btn btn-primary d-block m-auto">
					Next
				</button>
			</form>
		</div>
	);
}
