import { useEffect, useState} from "react";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import CollectionCalender from "./CollectionCalender";

const CollectionReportSidebar = ({ showSideBar, handleClose, filters, userId}) => {
	const[loanAccountPortfolio,setLoanAccountPortfolio]=useState([]);
	const [loading, setLoading] = useState(false);
	const [totalCounts, setTotalCounts] = useState({ loanTypeCount: 0, bucketCount: 0 , NPACount:0, collectionCount: 0});

	useEffect(() => {
		if (loanAccountPortfolio && Object.keys(loanAccountPortfolio).length) {
			let totalLoanTypeCount = 0;
			let totalBucketCount= 0;
			let totalCollectionCount= 0;
			let totalNPACount=0;
			loanAccountPortfolio["bucket_wise"]
				.filter(val => [7,8].includes(val.bucket))
				.forEach(val => {
					totalNPACount += val.count ? parseInt(val.count) : 0;

				});
			loanAccountPortfolio["loan_type_wise"]?.forEach(val => {
				totalLoanTypeCount += val.count ? parseInt(val.count) : 0;
					
			});
			loanAccountPortfolio["bucket_wise"]?.forEach(val => {
				totalBucketCount += val.count ? parseInt(val.count) : 0;

			});
			loanAccountPortfolio["collection_bucket_wise"]?.forEach(val => {
				totalCollectionCount += val.count ? parseInt(val.count) : 0;

			});
			setTotalCounts({ loanTypeCount: totalLoanTypeCount, bucketCount:totalBucketCount, NPACount:totalNPACount, collectionCount:totalCollectionCount});
		}
	}, [loanAccountPortfolio]);

	const openSideBar = () => {
		setLoading(true);
		if (typeof window != "undefined" && window.document) {
			document.body.style.overflow = "hidden";
			document.querySelector(".backdrop-blur").classList.add("backdrop-blur-block");
		}
         
		let data = {
			start_date: filters.start_date + " 00:00:00",
			end_date: filters.end_date + " 23:59:59",
			user_enc_id:userId
		};
		axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}employee/user-collection-report`,data
			)
			.then((response) => {
				if (response.data.response.status === 200) {
					setLoanAccountPortfolio(response.data.response.data);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("An error has occurred, Please try again");
				setLoading(false);
			});
	};

	useEffect(() => {
		if (showSideBar === true) {
			openSideBar();
		}
	}, [showSideBar]);

	const nth = (d) => {
		if (d > 3 && d < 21) return "th";
		switch (d % 10) {
		  case 1:  return "st";
		  case 2:  return "nd";
		  case 3:  return "rd";
		  default: return "th";
		}
	};

	


	return (
		<>
			<div className="backdrop-blur"></div>
			<div className={`expend-detail pt-3 ${showSideBar === false ? "expend-detail-close" : ""}`}>
				<div className="closeIcon" onClick={handleClose}>
					<AiFillCloseCircle />
				</div>
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					 {loading ? ( 
					 <div className="cus-loader">
							<img
								src="/images/pages/home/loader-black.svg"
								alt={process.env.REACT_APP_NAME}
							/>
						</div>
					 ):
					 	(	
							<div className="expend-data expand-data-details">
								<div className="row mb-3">
									<h3>Employee Details</h3>
									<div className="col-md-4">
										<p style={{ textTransform: "capitalize" }}>
											<span>Name:</span>
											<img src= {loanAccountPortfolio?.user_data?.user_image}
												className="EmployeeAvatar" />
											{loanAccountPortfolio?.user_data?.name ? loanAccountPortfolio.user_data.name : "-"}
										</p>
									</div>
									<div className="col-md-4">
										<p style={{ textTransform: "capitalize" }}>
											<span>Code:</span> {loanAccountPortfolio?.user_data?.employee_code ? loanAccountPortfolio.user_data.employee_code : "-"}
										</p>
									</div>
									<div className="col-md-4">
										<p style={{ textTransform: "capitalize" }}>
											<span>Branch:</span> {loanAccountPortfolio?.user_data?.location_name ? loanAccountPortfolio.user_data.location_name : "-"}
										</p>
									</div>
									<div className="col-md-4">
										<p>
											<span>Email:</span> {loanAccountPortfolio?.user_data?.email ? loanAccountPortfolio.user_data.email : "-"}
										</p>
									</div>
									<div className="col-md-4">
										<p style={{ textTransform: "capitalize" }}>
											<span>Phone:</span> {loanAccountPortfolio?.user_data?.phone ? loanAccountPortfolio.user_data.phone : "-"}
										</p>
									</div>
									<div className="col-md-4">
										<p style={{ textTransform: "capitalize" }}>
											<span>Reporting Person:</span>
											{loanAccountPortfolio?.user_data?.reporting_person ? (
												<>
													<img src={loanAccountPortfolio?.user_data?.reporting_person_image}
														className="EmployeeAvatar" />
													{loanAccountPortfolio?.user_data?.reporting_person}
												</>
											) : ("-")}
								
										</p>
									</div>
								</div>

								<div className="row mb-3">
									<h3>Stats</h3>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">NPA Cases:</span>
											<div>
												<span>{totalCounts.NPACount}</span>
											</div>
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex">
											<span className="me-1">eNACH Not Registered Cases: </span>
											{loanAccountPortfolio && loanAccountPortfolio["nach_data"] ?
												(
													<div>
														<span>{loanAccountPortfolio["nach_data"][0].not_registered} </span>
													</div>
												)
												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Total EMI Demand:</span>
											{loanAccountPortfolio && loanAccountPortfolio["emi_target"] ?
												(
													<div>
														<span>₹{" "}{loanAccountPortfolio["emi_target"][0].total_emi_demand ? parseFloat(loanAccountPortfolio["emi_target"][0].total_emi_demand).toLocaleString("en-IN") : "0"}</span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Total Target Amount: </span>
											{loanAccountPortfolio && loanAccountPortfolio["emi_target"] ?
												(
													<div>
														<span>₹{" "}
															{
																loanAccountPortfolio["emi_target"][0].total_target_amount ? parseFloat(loanAccountPortfolio["emi_target"][0].total_target_amount).toLocaleString("en-IN") : "0"}</span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Amount Collected This Month: </span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ? (
												<div>
													<span>₹{" "}
														{(parseFloat(loanAccountPortfolio["user_emi_stats"].amount_collected) +
                      										parseFloat(loanAccountPortfolio["user_emi_stats"].amount_collected_by_others)).toLocaleString("en-IN")}
													</span>
												</div>
											) : ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Amount Collected by {loanAccountPortfolio?.user_data?.name ? loanAccountPortfolio.user_data.name : "-"}: </span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ?
												(
													<div>
														<span>₹{" "}
															{
																loanAccountPortfolio["user_emi_stats"].amount_collected ? parseFloat(loanAccountPortfolio["user_emi_stats"].amount_collected).toLocaleString("en-IN") : "0"}</span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Amount Collected by Others: </span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ?
												(
													<div>
														<span>₹{" "}
															{
																loanAccountPortfolio["user_emi_stats"].amount_collected_by_others ? parseFloat(loanAccountPortfolio["user_emi_stats"].amount_collected_by_others).toLocaleString("en-IN") : "0"}</span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Cases Uncollected in Last 30 Days:</span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ?
												(
													<div>
														<span>{loanAccountPortfolio["user_emi_stats"].count_30_days} </span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Cases Uncollected in Last 45 Days: </span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ?
												(
													<div>
														<span>{loanAccountPortfolio["user_emi_stats"].count_45_days} </span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-6 mb-2">
										<p className="d-flex text-capitalize">
											<span className="me-1">Cases Uncollected in Last 60 Days: </span>
											{loanAccountPortfolio && loanAccountPortfolio["user_emi_stats"] ?
												(
													<div>
														<span>{loanAccountPortfolio["user_emi_stats"].count_60_days} </span>
													</div>
												)

												: ""}
										</p>
									</div>
									<div className="col-md-12 mb-2">
										<span className="me-1">Number of Loan Accounts Portfolio: </span>
										<p className="d-flex flex-wrap text-capitalize mt-2">
											<span
												className="ptp-stats-div mb-2 text-white">Total Count: {totalCounts.loanTypeCount}</span>
											{loanAccountPortfolio && loanAccountPortfolio["loan_type_wise"] ?
												loanAccountPortfolio["loan_type_wise"].map((value, index) => (
													<div className="ptp-stats-div mb-2" key={`${index}`}>
														<span>{value.loan_type} : {value.count} </span>
													</div>
												)
												)
												: ""}

										</p>
									</div>

									<div className="col-md-12 mb-2">
										<span className="me-1">Current Loan Portfolio: </span>
										<p className="d-flex flex-wrap text-capitalize mt-2">
											<span
												className="ptp-stats-div mb-2 text-white">Total Count <br /> {totalCounts.bucketCount}</span>
											{loanAccountPortfolio && loanAccountPortfolio["bucket_wise"] ?
												loanAccountPortfolio["bucket_wise"].map((value, index) => (
													<div className="ptp-stats-div mb-2 px-3" key={`${index}`}>
														<span className="d-block">{value.count}</span>
														<span
															className="d-block">{value.bucket ? <>Bucket {value.bucket}</> : "Unassigned"}</span>
														{/* <span>₹{parseFloat(value.sum).toLocaleString("en-IN")}</span> */}
													</div>
												)
												)
												: ""}
										</p>
									</div>

									{/* <div className="col-md-12 ht35">
						<p className="d-flex align-items-center ht35">
							<span className="me-1">Loan Accounts Due: </span> 
						</p>
					</div> */}
									<div className="col-md-12 mb-2">
										<p className="d-flex">
											<span className="me-1">PTP Collected: </span>
											{loanAccountPortfolio && loanAccountPortfolio["ptp_data"] ?
												(
													<div>
														<span
															className="ptp-stats-div">Count: {loanAccountPortfolio["ptp_data"][0].count}</span>
														<span
															className="ptp-stats-div">Amount: ₹{parseFloat(loanAccountPortfolio["ptp_data"][0].sum).toLocaleString("en-IN")}</span>
													</div>
												)

												: ""}
										</p>
									</div>
									{/* <div className="col-md-12 ht35">
						<p className="d-flex align-items-center ht35">
							<span className="me-1">PTP Fulfilled: </span> 
						</p>
					</div> */}
									<div className="col-md-12 mb-2">
										<span className="me-1">Portfolio by EMI Dates: </span>
										<p className="d-flex flex-wrap text-capitalize mt-2">
											{loanAccountPortfolio && loanAccountPortfolio["by_collection_day"]?.length ?
												loanAccountPortfolio["by_collection_day"].map((value, index) => (
													<div className="mb-2 ptp-stats-div" key={`${index}`}>
														<span>{value.emi_date}<sup>{nth(value.emi_date)}</sup> : {value.count}</span>
													</div>
												))
												: ""}
										</p>
									</div>

									<div className="col-md-12 mb-2">
										<span className="me-1">Monthly Collection Report: </span>
										<p className="d-flex flex-wrap text-capitalize mt-2">
											<span className="ptp-stats-div mb-2 text-white">Total Count <br /> {totalCounts.collectionCount}</span>
											{loanAccountPortfolio && loanAccountPortfolio["collection_bucket_wise"] ?
												loanAccountPortfolio["collection_bucket_wise"].map((value, index) => (
													<div className="ptp-stats-div mb-2 px-3" key={`${index}`}>
														<span className="d-block">{value.count}</span>
														<span className="d-block">Bucket {value.bucket}</span>
														<span>₹{value.sum ? parseFloat(value.sum).toLocaleString("en-IN") : "0"}</span>
													</div>
												)
												)
												: ""}
										</p>
									</div>
								</div>
								<div className="row">
									<h3>Day Wise Collection</h3>
									<CollectionCalender userId={userId} showSideBar={showSideBar} />
								</div>
							</div>
						)
					 }
				</PerfectScrollbar>


			</div>

		</>
	);
};
export default CollectionReportSidebar;

	