const AllowLocation = () => {
	return (
		<>
			<div className="overlay-container">
				<div className="text-center">
					<img
						className="img-fluid mb-3 location-img"
						src="/images/Redirect/location.png"
						alt="mobiles"
					/>
					<h3 className="mb-3">Please allow the Location to access this page</h3>
					<button type="btn" className="btn btn-secondary btn-sm" onClick={() => window.location.reload()}>
						Refresh
					</button>
				</div>
			</div>
		</>
	);
};
export default AllowLocation;
