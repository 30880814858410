import React, { useContext, useEffect, useState } from "react";
import { OrganizationUsersContext } from "../../context/OrganizationUsersState";
import DataTable from "react-data-table-component";
import UserStatusField from "./UserStatusField";
import EditInput from "../forms/EditInput";
import EditTypehead from "../EditTypehead";
import EditSelectInput from "../forms/EditSelectInput";
import { OrganizationDashboardContext } from "../../context/OrganizationDashboardState";
import { MainContext } from "../../context/MainState";
import { RiDeleteBin6Fill } from "react-icons/ri";
import moment from "moment";
import NameComp from "../NameComp";
import UsersResetPassword from "./UsersResetPassword";
import UsersSideBar from "./UsersSideBar";
import ReactPaginate from "react-paginate";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const UsersTable = ({designationList , gradesList}) => {
	const { getUsers, users, updateStatus, isLoading } = useContext(OrganizationUsersContext);
	const { user } = useContext(MainContext);
	const { branches_list, getBranches, getOrganizationDepartments, departmentList, states_list, getStates } = useContext(OrganizationDashboardContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	// const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [overflowToggle, setOverflowToggle] = useState(false);
	const [searchArray, setSearchArray] = useState({});
	const [passwordModal, setPasswordModal] = useState(false);
	const [showClearBtn, setShowClearBtn] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [userId, setUserId] = useState("");

	const reloadData = () => {
		getUsers({
			type: "employees",
			fields_search: searchArray,
			page: 1,
			limit: rowsPerPage,
		});
	};

	const handleTableLayout = type => {
		setOverflowToggle(type);
	};

	const clearFilter = () => {
		setClearFilters(true);
		setSearchArray({ name: "" });
	};

	useEffect(() => {
		if (searchArray) {
			getUsers({
				fields_search: searchArray,
				type: "employees",
				page: 1,
				limit: rowsPerPage,
			});
			setCurrentPage(1);
		}
		const valLength = Object.values(searchArray);
		if (!valLength.length || !valLength.filter(val => val != "").length) {
			setShowClearBtn(false);
		} else {
			setClearFilters(false);
			setShowClearBtn(true);
		}
	}, [searchArray]);

	const handleClose = () => {
		setShowSidebar(false);

		// Unsets Background Scrolling to use when SideDrawer/Modal is closed
		document.body.style.overflow = "unset";
		document.querySelector(".backdrop-blur").classList.remove("backdrop-blur-block");
	};

	const showUserSideBar = row => {
		setShowSidebar(true);
		setUserId(row);
	};

	const columns = [
		{
			name: (
				<NameComp
					title={"Code"}
					id={"employee_code"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.first_name ? row.first_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditInput
						getData={reloadData}
						defaultValue={row.employee_code ? row.employee_code : ""}
						api={"company-dashboard/update-employee"}
						parentId={row.user_enc_id}
						placeholder={"Employee Code"}
						validateAsAplhaNumeric={true}
						id={"employee_code"}
						type="users-table"
					/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Name"}
					id={"name"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.name ? row.name : "-"),
			sortable: false,
			cell: row => {
				return (
					<>
						<img src={row.image} alt={row.name} className="emp-img" />
						<EditInput
							type={"users-table"}
							getData={reloadData}
							defaultValue={row.name ? row.name : "-"}
							api={"company-dashboard/update-employee"}
							parentId={row.user_enc_id}
							placeholder={"Name"}
							id={"user_name"}
						/>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Department"}
					id={"department"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					departmentList={departmentList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.department ? row.department : "-"),
			sortable: false,
		},
		{
			name: (
				<NameComp
					title={"Designation"}
					id={"designations_list"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					designations={designationList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "220px",
			selector: row => (row.first_name ? row.first_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditSelectInput
						optionList={designationList}
						getData={reloadData}
						defaultValue={row.designation_id ? row.designation_id : ""}
						defaultLabel={row.designation ? row.designation : ""}
						api={"company-dashboard/update-employee"}
						id={"designation_id"}
						selectPlaceholder="Choose Designation"
						parentId={row.user_enc_id}
						required={true}
						isSearchable={true}
						callback={handleTableLayout}
						type="designation"
					/>
					// <EditTypehead getData={reloadData} api={`company-dashboard/update-employee`} defaultValue={row.designation ? row.designation : ''}
					//               parentId={row.user_enc_id} colName={`designation_enc_id`}/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Grade"}
					id={"grade"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					grades={gradesList}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.first_name ? row.first_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditSelectInput
						optionList={gradesList}
						getData={reloadData}
						defaultValue={row.grade ? row.grade : ""}
						defaultLabel={row.grade ? row.grade : ""}
						api={"company-dashboard/update-employee"}
						id={"grade"}
						selectPlaceholder="Choose Grade"
						parentId={row.user_enc_id}
						required={true}
					/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Reporting Person"}
					id={"reporting_person"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.first_name ? row.first_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditTypehead
						getData={reloadData}
						api={"company-dashboard/update-employee"}
						defaultValue={row.reporting_person ? row.reporting_person : ""}
						callback={handleTableLayout}
						parentId={row.user_enc_id}
						colName={"reporting_person"}
						placeHolder="Search Employee"
						searchUrl="company-dashboard/employee-search?type=employees&loan_id=&employee_search="
					/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Branch"}
					id={"multi_branches"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					branches={branches_list}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "200px",
			selector: row => (row.branch_name ? row.branch_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditSelectInput
						optionList={branches_list}
						getData={reloadData}
						defaultValue={row.branch_id ? row.branch_id : ""}
						defaultLabel={row.branch_name ? row.branch_name : ""}
						api={"company-dashboard/update-employee"}
						id={"branch_enc_id"}
						selectPlaceholder="Choose Branch"
						parentId={row.user_enc_id}
						required={true}
					/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"State"}
					id={"multi_states"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					states={states_list}
					sortType="alpha"
					clearFilters={clearFilters}
				/>
			),
			minWidth: "140px",
			selector: row => (row.state_name ? row.state_name : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Phone"}
					id={"phone"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.phone,
			sortable: false,
			cell: row => {
				return (
					<>
						<EditInput
							type={"number"}
							getData={reloadData}
							defaultValue={row.phone ? row.phone : "-"}
							api={"company-dashboard/update-employee"}
							parentId={row.user_enc_id}
							placeholder={"Phone Number"}
							id={"user_number"}
						/>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Email"}
					id={"email"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => row.email,
			sortable: false,
			cell: row => {
				return (
					<>
						<EditInput
							type={"users-table"}
							getData={reloadData}
							defaultValue={row.email ? row.email : "-"}
							api={"company-dashboard/update-employee"}
							parentId={row.user_enc_id}
							placeholder={"E-mail"}
							id={"user_email"}
						/>
					</>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Date of Joining"}
					id={"employee_joining_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.first_name ? row.first_name : "-"),
			sortable: false,
			cell: row => {
				return (
					<EditInput
						type={"date"}
						getData={reloadData}
						defaultValue={
							row.employee_joining_date
								? moment(row.employee_joining_date).format("DD MMM YYYY")
								: "-"
						}
						api={"company-dashboard/update-employee"}
						parentId={row.user_enc_id}
						placeholder={"Date of Joining"}
						id={"employee_joining_date"}
					/>
				);
			}
		},
		{
			name: (
				<NameComp
					title={"Username"}
					id={"username"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.username ? row.username : "-"),
			sortable: false
		},
		{
			name: (
				<NameComp
					title={"Status"}
					id={"status"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					loanStatus={[
						{ value: "Active", loan_status: "Active" },
						{ value: "Pending", loan_status: "Pending" }
					]}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "150px",
			selector: row => (row.status ? row.status : "-"),
			sortable: false,
			cell: row => <UserStatusField row={row} updateStatus={updateStatus} type="employees"/>
		},
		{
			name: (
				<NameComp
					title={"Date of Platform Joining"}
					id={"platform_joining_date"}
					searchArray={searchArray}
					setSearchArray={setSearchArray}
					clearFilters={clearFilters}
				/>
			),
			minWidth: "180px",
			selector: row => (row.status ? row.status : "-"),
			sortable: false,
			cell: row => (
				<h6 className="mb-0 emiAmt">
					{moment(row.platform_joining_date).format("DD MMM YYYY")}
				</h6>
			)
		},
		{
			name: "Activity Log",
			minWidth: "180px",
			sortable: false,
			cell: row => (
				<>
					<button
						className="btn btn-primary btn-xs"
						onClick={() => showUserSideBar(`${row.user_enc_id}`)}
					>
						View
					</button>
				</>
			)
		},
		{
			name: "Reset Password",
			minWidth: "180px",
			sortable: false,
			cell: row => (
				<>
					<button
						className="btn btn-primary btn-xs"
						onClick={() => setPasswordModal({id:row.user_enc_id})}
					>
						Reset
					</button>
				</>
			)
		},
		{
			name: "Delete",
			minWidth: "70px",
			style: { textAlign: "center" },
			omit: user.user_type !== "Financer" || user.specialAccessRole,
			cell: row => {
				return (
					<div>
						<RiDeleteBin6Fill
							className="cursor-point"
							size={16}
							color={"red"}
							onClick={() => {
								if (window.confirm("Are you sure you want to delete?")) {
									updateStatus(row.user_enc_id, "delete", "employees");
								}
							}}
						/>
					</div>
				);
			}
		}
	];

	// ** Function to handle Pagination and get data
	const handlePagination = page => {
		getUsers({
			page: page.selected + 1,
			limit: rowsPerPage,
			fields_search: searchArray,
			type: "employees"
		});
		setCurrentPage(page.selected + 1);
	};

	// ** Function to handle per page
	const handlePerPage = e => {
		getUsers({
			page: 1,
			limit: parseInt(e.target.value),
			fields_search: searchArray,
			type: "employees"
		});
		setRowsPerPage(parseInt(e.target.value));
	};


	// ** Custom Pagination
	const CustomPagination = () => {
		// const count = Number((store.total / rowsPerPage).toFixed(0));
		let totalPageCount = parseInt(users?.count) / rowsPerPage;
		if (!Number.isInteger(totalPageCount)) {
			totalPageCount = totalPageCount + 1;
			totalPageCount = parseInt(totalPageCount);
		}
		return (
			<ReactPaginate
				previousLabel={<MdArrowBackIos />}
				nextLabel={<MdArrowForwardIos />}
				breakLabel="..."
				pageCount={totalPageCount || 1}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				activeClassName="active"
				forcePage={currentPage !== 0 ? currentPage - 1 : 0}
				onPageChange={page => handlePagination(page)}
				pageClassName={"page-item"}
				nextLinkClassName={"page-link"}
				nextClassName={"page-item next"}
				previousClassName={"page-item prev"}
				previousLinkClassName={"page-link"}
				pageLinkClassName={"page-link"}
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName={
					"pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
				}
			/>
		);
	};

	useEffect(() => {
		if (!branches_list) {
			getBranches();
		}
		if (!states_list || !states_list.length) {
			getStates();
		}
		if (!departmentList || !departmentList.length) {
			getOrganizationDepartments();
		}
	}, []);


	// ** Table data to render
	const dataToRender = () => {
		if (users?.employees && users?.employees.length > 0) {
			return users.employees;
		} else {
			return users?.employees && users?.employees.length > 0
				? users.employees.slice(0, rowsPerPage)
				: [];
		}
	};

	return (
		<>
			<div
				className={`loan-application-table height-fix mb-5 ${
					overflowToggle ? "fixOverflow" : ""
				}`}
			>
				<Row className="mx-0 mt-1 mb-3">
					<Col sm="9">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<label htmlFor="sort-select">Show</label>
							<select
								className="dataTable-select form-select loan_acc_select"
								style={{
									width: "65px",
									padding: "2px 8px",
									margin: "0px 10px"
								}}
								id="sort-select"
								value={rowsPerPage}
								onChange={e => handlePerPage(e)}
							>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={75}>75</option>
								<option value={100}>100</option>
							</select>
							<label htmlFor="sort-select">Results</label>
						</div>
					</Col>
					<Col className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1" sm="3">
						<div className="reset-filters">
							<div className="filters-container d-flex justify-content-end">
								{showClearBtn ? (
									<button
										className="btn btn-primary btn-xs my-2"
										onClick={e => clearFilter(e)}
									>
								Reset
									</button>
								) : (
									""
								)}
							</div>
						</div>
					</Col>
				</Row>
				<DataTable
					columns={columns}
					data={dataToRender()}
					pagination
					paginationServer
					paginationComponent={CustomPagination}
					// paginationResetDefaultPage={resetPaginationToggle}
					// paginationPerPage={50}
					// paginationRowsPerPageOptions={[50, 100, 150, 200]}
					highlightOnHover="true"
					progressPending={isLoading === "employees"}
					persistTableHead={true}
					className="fix-overflow myUser-table"
					progressComponent={<h2 className="w-100 m-5 text-start">Loading...</h2>}
					noDataComponent={
						<p className="w-100 m-5 text-start">There are no records to display</p>
					}
				/>
			</div>

			<UsersResetPassword
			    setPasswordModal={()=> setPasswordModal("")}
				passwordModal={passwordModal}
			/>

			<UsersSideBar 
				showSideBar={showSidebar}
				userId={userId}
				handleClose={handleClose}
			/>
		</>
	);
};
export default UsersTable;
