import Navbar from "../../components/Navbar";
import { Helmet } from "react-helmet";
import "../../assets/css/accountStyles.css";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainState";
import { useNavigate } from "react-router-dom";
import { OrganizationUsersProvider } from "../../context/OrganizationUsersState";
import UsersTable from "../../components/dashboard/UsersTable";
import DsaUsersTable from "../../components/dashboard/DsaUsersTable";
import ConnectorUsersTable from "../../components/dashboard/ConnectorUsersTable";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "react-bootstrap";
import DealersTable from "../../components/dashboard/DealersTable";
import DeletedEmployees from "../../components/dashboard/DeletedEmployees";
import InactiveUsersTable from "../../components/dashboard/InactiveUsersTable";
import axios from "axios";
import CreateEmployeeModal from "../../components/CreateEmployeeModal";
import { toast } from "react-toastify";
import ValuatorTable from "../../components/dashboard/ValuatorTable";
import CreateValuatorModal from "../../components/CreateValuatorModal";

const OrganizationUsers = () => {
	const { user, dealerSignUpHandler } = useContext(MainContext);
	const history = useNavigate();
	const [active, setActive] = useState("1");
	const [tabActive, setTabActive] = useState(false);
	const [createEvaluator, setCreateEvaluator] = useState(false);
	const [designationList, setDesignationList] = useState([]);
	const [show, setShow] = useState(false);

	const gradesList = [
		{ id: "unassigned", value: "Unassigned" },
		{ id: "Grade 1", value: "Grade 1" },
		{ id: "Grade 2", value: "Grade 2" },
		{ id: "Grade 3", value: "Grade 3" },
		{ id: "Grade 4", value: "Grade 4" },
		{ id: "Grade 5", value: "Grade 5" },
		{ id: "Grade 6", value: "Grade 6" },
		{ id: "Grade 7", value: "Grade 7" }
	];
	
	const toggle = tab => {
		if (active !== tab) {
			setActive(tab);
		}
		setTabActive(tab);
	};

	const onHide = () => {
		setShow(false);
	};

	const getDesignationList = async () => {
		await axios
			.post(
				`${process.env.REACT_APP_BASE_API_URL}${process.env.REACT_APP_API_URL_VERSION}company-dashboard/financer-designation-list`
			)
			.then(response => {
				if (response.data.response.status === 200) {
					setDesignationList(response.data.response.data);
				} else {
					setDesignationList([]);
				}
			})
			.catch(error => {
				console.log(error);
				toast.error(
					"An error has occurred while getting department lists, Please try again"
				);
				// handlePageLoaded(false);
			});
	};

	useEffect(() => {
		if(!designationList || !designationList.length){
			getDesignationList();
		}
	}, []);

	useEffect(() => {
		if (
			user &&
			user.user_type !== "Financer" &&
			user.designation !== "HR Manager" &&
			user.designation !== "HR Executive" &&
			user.username !== "Naveenkirpal"
		) {
			history("/");
		}
	}, [user]);

	return (
		<div>
			<Helmet>
				<title>My Users | {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={`${process.env.REACT_APP_NAME} Dashboard`} />
			</Helmet>
			<Navbar />
			<OrganizationUsersProvider>
				<div className="dashboard-dsa py-5">
					<div className="container-fluid">
						{/* <OrganizationStats/>*/}
						<div className="loan-application-table shadow-lg rounded-3 py-4 px-6">
							<div className={"d-flex justify-content-between mb-3"}>
								<h4 className="mb-0">My Users</h4>
								<div>
									{active === "1" ?
										<button className="btn btn-primary"
											onClick={() => setShow(true)}>
										Create Employee
										</button>
										: active === "4" ? (
											<button className="btn btn-primary mx-2" onClick={() => dealerSignUpHandler()}>Create Dealer</button>
										) : active === "7" ? (
											<button className="btn btn-primary mx-2" onClick={() => setCreateEvaluator(true)}>Create Valuator</button>
										) : ""}
								</div>
							</div>
							<CreateEmployeeModal show={show} onModalHide={onHide} designationList={designationList} gradesList = {gradesList} />
							<CreateValuatorModal show={createEvaluator} onModalHide={() => setCreateEvaluator(false)}/>
							<Nav className="mb-4 users-nav">
								<NavItem>
									<NavLink
										active={active === "1"}
										onClick={() => {
											toggle("1");
										}}
									>
										Employees
									</NavLink>
								</NavItem>
								{user.user_type === "Financer" || user.designation === "HR Manager" || user.designation === "HR Executive" ? (
									<>
										<NavItem>
											<NavLink
												active={active === "6"}
												onClick={() => {
													toggle("6");
												}}
											>
												Inactive Employees
											</NavLink>
										</NavItem>
										{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
											<>
												<NavItem>
													<NavLink
														active={active === "2"}
														onClick={() => {
															toggle("2");
														}}
													>
														DSA Users
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														active={active === "3"}
														onClick={() => {
															toggle("3");
														}}
													>
														Connectors
													</NavLink>
												</NavItem>
											</>
										): ""}
										<NavItem>
											<NavLink
												active={active === "4"}
												onClick={() => {
													toggle("4");
												}}
											>
												Dealers
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												active={active === "5"}
												onClick={() => {
													toggle("5");
												}}
											>
												Deleted Employees
											</NavLink>
										</NavItem>
										{process.env.REACT_APP_TYPE === "DEV" ? (
											<NavItem>
												<NavLink
													active={active === "7"}
													onClick={() => {
														toggle("7");
													}}
												>
													Valuators
												</NavLink>
											</NavItem>)
											: ""}
									</>
								) : ""}
							</Nav>
							<TabContent className="py-50" activetab={active}>
								<TabPane tabid="1" className={active === "1" ? "active" : ""}>
									<UsersTable designationList={designationList} gradesList={gradesList} />
								</TabPane>
								{user.user_type === "Financer" || user.designation === "HR Manager" || user.designation === "HR Executive" ? (
									<>
										<TabPane tabid="6" className={active === "6" ? "active" : ""}>
											<InactiveUsersTable tabActive={tabActive} designationList={designationList} />
										</TabPane>
										{process.env.REACT_APP_TYPE === "DEV" || (user.organization_username && user.organization_username === "phfleasing") ? (
											<>
												<TabPane tabid="2" className={active === "2" ? "active" : ""}>
													<DsaUsersTable  tabActive={tabActive}/>
												</TabPane>
												<TabPane tabid="3" className={active === "3" ? "active" : ""}>
													<ConnectorUsersTable tabActive={tabActive}/>
												</TabPane>
											</>
										) : ""}
										<TabPane tabid="4" className={active === "4" ? "active" : ""}>
											<DealersTable tabActive={tabActive}/>
										</TabPane>
										<TabPane tabid="5" className={active === "5" ? "active" : ""}>
											<DeletedEmployees tabActive={tabActive} designationList={designationList}/>
										</TabPane>
										<TabPane tabid="7" className={active === "7" ? "active" : ""}>
											<ValuatorTable tabActive={tabActive}/>
										</TabPane>
									</>
								) : ""}
							</TabContent>
						</div>
					</div>
				</div>
			</OrganizationUsersProvider>
		</div>
	);
};
export default OrganizationUsers;
