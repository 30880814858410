import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import SkeletonCard from "./SkeletonCard";

const LoanAccountStats = ({ accountStats, getLoanAccountStats, loanTypes, dateVal, dueVal, bucketVal, branches_list, states_list, searchArray, setSearchArray, setSortArr, user, updateBucket, clearFilters, clearFilter }) => {
	const [filters, setFilters] = useState({
		start_date: "",
		end_date: "",
		loan_type: "",
		loan_product: "",
		branch_name: "",
		state_name:""
	});
	const [loading, setLoading] = useState(true); 
	const [selectedStatusModel, setSelectedStatusModel] = useState({
		value: "1",
		label: "This Month"
	});
	const [loanTypesOptions, setLoanTypesOptions] = useState([]);
	const [branchesOptions, setBranchesOptions] = useState([]);
	const [statesOptions ,setStatesOptions] = useState([]);
	const [selectAllArr, setSelectAllArr] = useState({});
	const [previousAllArr, setpreviousAllArr] = useState({});
	useEffect(() => {
		if (selectedStatusModel.value === "1") {
			getCurrentMonthData();
		} else if (selectedStatusModel.value === "0") {
			getPrevMonthData();
		} else if (selectedStatusModel.value === "2") {
			getThreeMonthData();
		} 
	}, [selectedStatusModel]);
	
	const getCurrentMonthData = () => {
		const tempDate = new Date();
		const fromDate = moment(
			tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1) + "-1"
		).format("YYYY-MM-DD");
		const toDate = moment(tempDate).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	const getPrevMonthData = () => {
		const tempDate = new Date();
		const lastDay = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
		const fromDate = moment((tempDate.getMonth() === 0 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (tempDate.getMonth() === 0 ? 12 : tempDate.getMonth()) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	const getThreeMonthData = () => {
		const tempDate = new Date();
		const startingMonth = (tempDate.getMonth() - 3 + 12) % 12;
		const lastDay = new Date((tempDate.getMonth() < 3 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()), startingMonth + 3, 0);
		const fromDate = moment((tempDate.getMonth() <= 2 ? tempDate.getFullYear() - 1 : tempDate.getFullYear()) + "-" + (startingMonth + 1) + "-1").format("YYYY-MM-DD");
		const toDate = moment(lastDay).format("YYYY-MM-DD");
		setFilters({ ...filters, start_date: fromDate, end_date: toDate });
	};

	useEffect(() => {
		if (loanTypes && loanTypes.length) {
			const temploanTypesOptions = [{label:"All", value: "all"}, ...loanTypes.map(loanType => ({
				value: loanType.loan_type,
				label: loanType.loan_type
			}))];
			setLoanTypesOptions(temploanTypesOptions);
		}
	}, [loanTypes]);

	useEffect(() => {
		if (branches_list && branches_list.length) {
			const tempBranchesOptions = [{label: "All", value:"all"}, {label:"Unassigned", value: "unassigned"}, ...branches_list.map(value => ({
				value: value.id,
				label: value.value,
				enc: value.state_enc_id
			}))];
			setBranchesOptions(tempBranchesOptions);
		}
		if (states_list && states_list.length) {
			const states = [{label: "All", value: "all"}, {label:"Unassigned", value: "unassigned"}, ...states_list.map(value => ({
				value: value.state_enc_id,
				label: value.name
			}))];
			setStatesOptions(states);
		}
	}, [branches_list,states_list]);

	useEffect(() => {
		if(searchArray && Object.values(searchArray).some(arr => arr.length)){
			let idArr = [
				{ name: "loan_type", options: loanTypesOptions }, 
				{ name: "branch", options: branchesOptions },
				{ name: "state_enc_id", options: statesOptions }
			];

			const optionSelected = {};
			const counts = {};

			idArr.forEach(({ name, options }) => {
				if (searchArray && searchArray[name]) {
					const tempdata = [];
					if (searchArray[name]?.length) {
						searchArray[name].forEach(val => {
							if (options) {
								const sb = options.filter(item => item.value === val);
								tempdata.push({ label: sb[0].label, value: sb[0].value });
							} else {
								tempdata.push({ label: val, value: val });
							}
						});
					}
					optionSelected[name] = tempdata;
					counts[name] = searchArray[name].length;
				}
			});

			setMultiSelectValues(optionSelected);
			setSelectedCount(counts);
		}
		else{
			setMultiSelectValues({});
			setSelectedCount({});
			setSortArr({});
		}

		if (filters.start_date && filters.end_date) {
			setLoading(true);
			const timerId = setTimeout(() => {
				getLoanAccountStats({
					sub_bucket: bucketVal, 
					start_date: filters.start_date,
					end_date: filters.end_date,
					loan_type: filters.loan_type,
					loan_product: filters.loan_product,
					branch_name: filters.branch_name,
					fields_search: dueVal ? {...searchArray, emi_due : dueVal !== "all" ? dueVal : "" }: searchArray,
					collection_date: dateVal === "all" ? "" : dateVal,
				}).then(() => setLoading(false));
			}, 1000);
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [searchArray, filters, dateVal, dueVal]);
	
	const [multiSelectValues, setMultiSelectValues] = useState({loan_type:"~", branch:"~", state_enc_id:"~"});
	const [selectedCount, setSelectedCount] = useState({loan_type: "0", branch:"0", state_enc_id:"0"});

	const selectedCustomData = (selected, list =null, id) => {
		let selectAll = selected.filter(item => item.value === "all");
		selectAll = selectAll.length ? selectAll[0] : "";
		let prevSelectAll = previousAllArr && previousAllArr[id] ? previousAllArr[id].filter(item => item.value === "all") : ""; 
		let flag = false;

		if(list && selected.length === list.length - 1 && !selectAll && prevSelectAll.length){
			flag = false;
			selected = [];
		}else if((selected.length && selectAll && !selectAllArr[id]) || (list && selected.length === list.length - 1 && !selectAll)){
			flag = true;
			selected = list;
		}else{
			flag = false;
			selected = selected.filter(item => item.value != "all");
		}
						
		setpreviousAllArr({...previousAllArr, [id]: selected});		
		setSelectAllArr({...selectAllArr, [id]: flag});

		setSearchedArray(selected.map(option => option.value),id);
		let optionSelected = multiSelectValues;
		optionSelected[id] = selected;
		setMultiSelectValues(optionSelected);
		let counts = selectedCount;
		counts[id]=  selected.length;
		setSelectedCount(counts);

	};

	const handleBucketChange = (val) => {
		updateBucket(val);
		setSearchArray({});
		clearFilter();
	};

	const setSearchedArray = (value, id) => {
		let data = [];
		data[id] = value;
		setSearchArray({ ...searchArray, ...data });
	};

	useEffect(() => {
		setSelectedStatusModel({value: "1", label: "This Month"});
		setFilters({start_date: "",
			end_date: "",
			loan_type: "",
			loan_product: "",
			branch_name: ""
		});
	}, [bucketVal]);

	useEffect(() => {
		if (clearFilters) {
			setMultiSelectValues({loan_type:"~", branch:"~", state_enc_id:"~"});
			setSelectedCount({loan_type: 0, branch:0, state_enc_id:"0"});
		}
	}, [clearFilters]);

	const renderOptions = (list, value = []) =>{
		return value.length ? list.filter(branch => 
			value.includes(branch.enc)
		) : list;
	};
	
	return (
		<>
			{/* <div className=""> */}
			<Tab.Container id="top-tabs-example" defaultActiveKey="buckit">
				<Row className="pt-3">
					<Col sm="6 mb-3">
						<p className="m-0 fw-medium d-md-none">Bucket</p>
						<Nav variant="pills" className="flex-row">
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckit" onClick={() => handleBucketChange("")}>All</Nav.Link>
							</Nav.Item>
							{user.user_type === "Financer" || ["phf1148", "phf110", "ghuman", "wishey", "phf1164", "Satparkash"].includes(user.username) ? (
								<Nav.Item className="accountBucket">
									<Nav.Link eventKey="buckit0" onClick={() => handleBucketChange("X")}>X</Nav.Link>
								</Nav.Item>
							) : ""}
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckit1" onClick={() => handleBucketChange("1")}>1</Nav.Link>
							</Nav.Item>
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckit2" onClick={() => handleBucketChange("2")}>2</Nav.Link>
							</Nav.Item>
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckit3" onClick={() => handleBucketChange("3")}>3</Nav.Link>
							</Nav.Item>
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckit4" onClick={() => handleBucketChange("4")}>4</Nav.Link>
							</Nav.Item>
							{user.department !== "Call Center" ? (
								<>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="buckit5" onClick={() => handleBucketChange("5")}>5</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="buckit6" onClick={() => handleBucketChange("6")}>6</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="buckit7" onClick={() => handleBucketChange("7")}>7</Nav.Link>
									</Nav.Item>
									<Nav.Item className="accountBucket">
										<Nav.Link eventKey="buckit8" onClick={() => handleBucketChange("8")}>8</Nav.Link>
									</Nav.Item>
								</>
							) : ""}
							<Nav.Item className="accountBucket">
								<Nav.Link eventKey="buckitC" onClick={() => handleBucketChange("C")}>C</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
					<Col sm="6 d-flex align-items-center mb-4 justify-content-end flex-wrap">
						<div className="form-group mx-2">
							<label htmlFor="float-text" className="floating-label fw400 fs-5">
							Loan Type
							</label>
							<Multiselect
								className="dashboard-stats-design smSelect m-0 fs-5 w-100"
								avoidHighlightFirstOption
								emptyRecordMsg="No Loan Type Found"
								placeholder={selectedCount.loan_type > 0 ? selectedCount.loan_type + " " + "Selected" : ""}
								displayValue="label"
								selectedValues={multiSelectValues.loan_type !== "~" ? multiSelectValues.loan_type : []}
								options={loanTypesOptions}
								showCheckbox
								hideSelectedList
								onRemove={e => selectedCustomData(e, loanTypesOptions, "loan_type")}
								onSelect={e => selectedCustomData(e, loanTypesOptions, "loan_type")}
							/>	
						</div>
						<div className="form-group mx-2">
							<label htmlFor="float-text" className="floating-label fw400 fs-5">
							Branch
							</label>
							<Multiselect
								className="dashboard-stats-design smSelect m-0 fs-5 w-100"
								avoidHighlightFirstOption
								emptyRecordMsg="No Branches Found"
								placeholder={selectedCount.branch > 0 ? selectedCount.branch + " " + "Selected" : ""}
								displayValue="label"
								selectedValues={multiSelectValues.branch !== "~" ? multiSelectValues.branch : []}
								options={renderOptions(branchesOptions, searchArray?.state_enc_id)}
								showCheckbox
								hideSelectedList
								closeIcon="circle2"
								onRemove={e => selectedCustomData(e, branchesOptions, "branch")}
								onSelect={e => selectedCustomData(e, branchesOptions, "branch")}
							/>
						</div>
						<div className="form-group ms-2">
							<label htmlFor="float-text" className="floating-label fw400 fs-5">
							State
							</label>
							<Multiselect
								className="dashboard-stats-design smSelect m-0 fs-5 w-100"
								avoidHighlightFirstOption
								emptyRecordMsg="No State Found"
								placeholder={selectedCount.state_enc_id > 0 ? selectedCount.state_enc_id + " " + "Selected" : ""}
								displayValue="label"
								selectedValues={multiSelectValues.state_enc_id !== "~" ? multiSelectValues.state_enc_id : []}
								options={statesOptions}
								showCheckbox
								hideSelectedList
								closeIcon="circle2"
								onRemove={e => selectedCustomData(e, statesOptions, "state_enc_id")}
								onSelect={e => selectedCustomData(e, statesOptions, "state_enc_id")}
							/>
						</div>
					</Col>
				</Row>
			</Tab.Container>
				
			{/* <div className="d-flex align-items-center mb-4 justify-content-end flex-wrap"> */}
					
			{/* <div className="form-group">
						<label htmlFor="float-text" className="floating-label fw400 fs-5">
							Sort By
						</label>
						<Select
							options={[
								{ value: "1", label: "This Month" },
								{ value: "0", label: "Last Month" },
								{ value: "2", label: "Last Three Months" },
								{ value: "Custom", label: "Custom Range" }
							]}
							className="dashboard-stats-design customStyleSelect smSelect m-0 fs-5 w-100"
							value={selectedStatusModel}
							name="status"
							isSearchable={false}
							placeholder="Choose Status"
							onChange={option => {
								if (option.value !== selectedStatusModel.value) {
									setSelectedStatusModel(option);
								}
							}}
						/>
					</div>
					{selectedStatusModel && selectedStatusModel.value === "Custom" ? (
						<>
							<div className="form-group mx-2">
								<label
									htmlFor="float-text"
									className="floating-label fw400 ft14"
								>
									From
								</label>
								<input
									type="date"
									className="form-control"
									style={{ maxWidth: "150px", height: "48px" }}
									value={filters.start_date}
									onChange={e => {
										setFilters({
											...filters,
											start_date: e.target.value
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label
									htmlFor="float-text"
									className="floating-label fw400 ft14"
								>
									To
								</label>
								<input
									type="date"
									className="form-control"
									style={{ maxWidth: "150px", height: "48px" }}
									value={filters.end_date}
									onChange={e => {
										setFilters({
											...filters,
											end_date: e.target.value
										});
									}}
								/>
							</div>
						</>
					) : (
						""
					)} */}
			{/* <div className="form-group mx-2">
						<label htmlFor="float-text" className="floating-label fw400 fs-5">
							Loan Type
						</label>
						<Multiselect
							className="dashboard-stats-design smSelect m-0 fs-5 w-100"
							avoidHighlightFirstOption
							emptyRecordMsg="No Loan Type Found"
							placeholder={selectedCount.loan_type > 0 ? selectedCount.loan_type + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues.loan_type !== "~" ? multiSelectValues.loan_type : []}
							options={loanTypesOptions}
							showCheckbox
							hideSelectedList
							onRemove={e => selectedCustomData(e, "loan_type")}
							onSelect={e => selectedCustomData(e, "loan_type")}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="float-text" className="floating-label fw400 fs-5">
							Branch
						</label>
						<Multiselect
							className="dashboard-stats-design smSelect m-0 fs-5 w-100"
							avoidHighlightFirstOption
							emptyRecordMsg="No Branches Found"
							placeholder={selectedCount.branch > 0 ? selectedCount.branch + " " + "Selected" : ""}
							displayValue="label"
							selectedValues={multiSelectValues.branch !== "~" ? multiSelectValues.branch : []}
							options={branchesOptions}
							showCheckbox
							hideSelectedList
							closeIcon="circle2"
							onRemove={e => selectedCustomData(e, "branch")}
							onSelect={e => selectedCustomData(e, "branch")}
						/>
					</div> */}
			{/* </div> */}
			{/* </div> */}

			{loading ? (
				<div className="row" style={{minHeight:"155px"}}>
					{[...Array(6)].map((index) => (
						<SkeletonCard key={index} type={"sm"}/>
					))}
				</div>
			) : (
				<>		      
					<div className="row emi-stats mb-3">
						<div className="col-xl-2 col-md-4 col-sm-6">
							<div className="card-body">
								<img
									src={"/images/pages/dashboard/loan-account-stats/total-loan-accounts.png"}
									className="card-right-img"
									alt={"total-loan-accounts"}
								/>
								<p className="card-head mb-1">
									<span className="bold d-block mb-1">
										{accountStats && accountStats.loan_accounts_count
											? accountStats.loan_accounts_count
											: "0"}
									</span>
								</p>
								<p className="card-head mb-1">
									<span className="fs-5 text-capitalize">Total Loan Accounts</span>
								</p>
								{/* <div className="loan-amounts">
								<p className="mb-0 text-white text-end bold">
									{accountStats && accountStats.loan_accounts_sum
										? `₹ ${parseFloat(accountStats.loan_accounts_sum).toLocaleString(
											"en-IN"
										)}`
										: ""}
								</p>
							</div> */}
							</div>
						</div>
						<div className="col-xl-2 col-md-4 col-sm-6">
							<div className="card-body d-block emi-card-stats loan-stats">
								<img
									src={"/images/pages/dashboard/loan-account-stats/emi_received_amount.png"}
									className="card-right-img"
									alt={"emi_received_amount"}
								/>
								<p className="card-head mb-1">
									<span className="bold d-block mb-1">
										{accountStats && accountStats.emi_received_count
											? accountStats.emi_received_count
											: "0"}
									</span>
								</p>
								<p className="card-head mb-1">
									<span className="fs-5 text-capitalize">EMI Received Amount</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										{accountStats && accountStats.emi_received_sum
											? `₹ ${parseFloat(accountStats.emi_received_sum).toLocaleString(
												"en-IN"
											)}`
											: ""}
									</p>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-md-4 col-sm-6">
							<div className="card-body d-block emi-card-stats loan-stats">
								<img
									src={"/images/pages/dashboard/loan-account-stats/ledger-amount.png"}
									className="card-right-img"
									alt={"ledger_amount"}
								/>
								<p className="card-head mb-1">
									<span className="bold d-block mb-1">
										{accountStats && accountStats.ledger_count
											? accountStats.ledger_count
											: "0"}
									</span>
								</p>
								<p className="card-head mb-1">
									<span className="fs-5 text-capitalize">Ledger Amount</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										{accountStats && accountStats.ledger_sum
											? `₹ ${parseFloat(accountStats.ledger_sum).toLocaleString("en-IN")}`
											: ""}
									</p>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-md-4 col-sm-6">
							<div className="card-body d-block emi-card-stats loan-stats">
								<img
									src={"/images/pages/dashboard/loan-account-stats/overdue-amount.png"}
									className="card-right-img"
									alt={"overdue_amount"}
								/>
								<p className="card-head mb-1">
									<span className="bold d-block mb-1">
										{accountStats && accountStats.overdue_count
											? accountStats.overdue_count
											: "0"}
									</span>
								</p>
								<p className="card-head mb-1">
									<span className="fs-5 text-capitalize">Overdue EMI</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										{accountStats && accountStats.overdue_sum
											? `₹ ${parseFloat(accountStats.overdue_sum).toLocaleString(
												"en-IN"
											)}`
											: ""}
									</p>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-md-4 col-sm-6">
							<div className="card-body d-block emi-card-stats loan-stats">
								<img
									src={"/images/pages/dashboard/loan-account-stats/total-pending-amount.png"}
									className="card-right-img"
									alt={"total_pending_amount"}
								/>
								<p className="card-head mb-1">
									<span className="bold d-block mb-1">
										{accountStats && accountStats.total_pending_count
											? accountStats.total_pending_count
											: "0"}
									</span>
								</p>
								<p className="card-head mb-1">
									<span className="fs-5 text-capitalize">Total Pending Amount</span>
								</p>
								<div className="loan-amounts">
									<p className="mb-0 text-white text-end bold">
										{accountStats && accountStats.total_pending_sum
											? `₹ ${parseFloat(accountStats.total_pending_sum).toLocaleString(
												"en-IN"
											)}`
											: ""}
									</p>
								</div>
							</div>
						</div>
						{user.user_type === "Financer" && (user.organization_username === "phfleasing" || process.env.REACT_APP_TYPE === "DEV") ? (
							<div className="col-xl-2 col-md-4 col-sm-6">
								<div className="card-body d-block emi-card-stats loan-stats">
									<img
										src={"/images/pages/dashboard/loan-account-stats/ptp.png"}
										className="card-right-img"
										alt={"total_pending_amount"}
									/>
									<p className="card-head mb-1">
										<span className="bold d-block mb-1">
											{accountStats && accountStats.ptp_count
												? accountStats.ptp_count
												: "0"}
										</span>
									</p>
									<p className="card-head mb-1">
										<span className="fs-5 text-capitalize">Total PTP Cases</span>
									</p>
									<div className="loan-amounts">
										<p className="mb-0 text-white text-end bold">
											{accountStats && accountStats.ptp_sum
												? `₹ ${parseFloat(accountStats.ptp_sum).toLocaleString(
													"en-IN"
												)}`
												: ""}
										</p>
									</div>
								</div>
							</div>
						):""}
						{/* <div className="col-xl-2 col-md-4 col-sm-6">*/}
						{/*	<div className="card-body d-block emi-card-stats loan-stats">*/}
						{/*		<img*/}
						{/*			src={"/images/pages/dashboard/loan-account-stats/emi_received_amount.png"}*/}
						{/*			className="card-right-img"*/}
						{/*			alt={"total_pending_amount"}*/}
						{/*		/>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="bold d-block mb-1">*/}
						{/*				{accountStats && accountStats.total_count_SMA0*/}
						{/*					? accountStats.total_count_SMA0*/}
						{/*					: "0"}*/}
						{/*			</span>*/}
						{/*		</p>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="fs-5 text-capitalize">0-30 Days</span>*/}
						{/*		</p>*/}
						{/*		<div className="loan-amounts">*/}
						{/*			<p className="mb-0 text-white text-end bold">*/}
						{/*				{accountStats && accountStats.total_sum_SMA0*/}
						{/*					? `₹ ${parseFloat(accountStats.total_sum_SMA0).toLocaleString(*/}
						{/*						"en-IN"*/}
						{/*					)}`*/}
						{/*					: ""}*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/* </div>*/}
						{/* <div className="col-xl-2 col-md-4 col-sm-6">*/}
						{/*	<div className="card-body d-block emi-card-stats loan-stats">*/}
						{/*		<img*/}
						{/*			src={"/images/pages/dashboard/loan-account-stats/emi_received_amount.png"}*/}
						{/*			className="card-right-img"*/}
						{/*			alt={"total_pending_amount"}*/}
						{/*		/>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="bold d-block mb-1">*/}
						{/*				{accountStats && accountStats.total_count_SMA1*/}
						{/*					? accountStats.total_count_SMA1*/}
						{/*					: "0"}*/}
						{/*			</span>*/}
						{/*		</p>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="fs-5 text-capitalize">30-60 Days</span>*/}
						{/*		</p>*/}
						{/*		<div className="loan-amounts">*/}
						{/*			<p className="mb-0 text-white text-end bold">*/}
						{/*				{accountStats && accountStats.total_sum_SMA1*/}
						{/*					? `₹ ${parseFloat(accountStats.total_sum_SMA1).toLocaleString(*/}
						{/*						"en-IN"*/}
						{/*					)}`*/}
						{/*					: ""}*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/* </div>*/}
						{/* <div className="col-xl-2 col-md-4 col-sm-6">*/}
						{/*	<div className="card-body d-block emi-card-stats loan-stats">*/}
						{/*		<img*/}
						{/*			src={"/images/pages/dashboard/loan-account-stats/emi_received_amount.png"}*/}
						{/*			className="card-right-img"*/}
						{/*			alt={"total_pending_amount"}*/}
						{/*		/>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="bold d-block mb-1">*/}
						{/*				{accountStats && accountStats.total_count_SMA2*/}
						{/*					? accountStats.total_count_SMA2*/}
						{/*					: "0"}*/}
						{/*			</span>*/}
						{/*		</p>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="fs-5 text-capitalize">60-90 Days</span>*/}
						{/*		</p>*/}
						{/*		<div className="loan-amounts">*/}
						{/*			<p className="mb-0 text-white text-end bold">*/}
						{/*				{accountStats && accountStats.total_sum_SMA2*/}
						{/*					? `₹ ${parseFloat(accountStats.total_sum_SMA2).toLocaleString(*/}
						{/*						"en-IN"*/}
						{/*					)}`*/}
						{/*					: ""}*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/* </div>*/}
						{/* <div className="col-xl-2 col-md-4 col-sm-6">*/}
						{/*	<div className="card-body d-block emi-card-stats loan-stats">*/}
						{/*		<img*/}
						{/*			src={"/images/pages/dashboard/loan-account-stats/emi_received_amount.png"}*/}
						{/*			className="card-right-img"*/}
						{/*			alt={"total_pending_amount"}*/}
						{/*		/>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="bold d-block mb-1">*/}
						{/*				{accountStats && accountStats.total_count_NPA*/}
						{/*					? accountStats.total_count_NPA*/}
						{/*					: "0"}*/}
						{/*			</span>*/}
						{/*		</p>*/}
						{/*		<p className="card-head mb-1">*/}
						{/*			<span className="fs-5 text-capitalize">NPA</span>*/}
						{/*		</p>*/}
						{/*		<div className="loan-amounts">*/}
						{/*			<p className="mb-0 text-white text-end bold">*/}
						{/*				{accountStats && accountStats.total_sum_NPA*/}
						{/*					? `₹ ${parseFloat(accountStats.total_sum_NPA).toLocaleString(*/}
						{/*						"en-IN"*/}
						{/*					)}`*/}
						{/*					: ""}*/}
						{/*			</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/* </div>*/}
					</div>
				</>
			)}
		</>
	);
};
export default LoanAccountStats;
